import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { uniqueId } from "lodash";

import { useMobileContext } from "context/mobileContext";

import Text from "components/common/Text";
import Button from "components/common/Button";

import { getAgentsIsFetchingSelector, getSuccessBookingSelector } from "store/reducers/agents";
import { BookingMeetingResponceI } from "store/models/agent";
import { routesConstant } from "constants/appRoutesConstants";
import { getIsThankYouPageVisibleSelector, setIsThankYouPageVisible } from "store/reducers/booking";

import MeetingCard from "./components/MeetingCard";
import PageLoader from "components/PageLoader";
import { getStructuredText } from "utils/getStructuredText";

const ThankYouPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const data = useAppShallowSelector(getSuccessBookingSelector);
  const isVisible = useAppShallowSelector(getIsThankYouPageVisibleSelector);
  const isLoading = useAppShallowSelector(getAgentsIsFetchingSelector);

  const { booked_meetings, customer_analysis, meetings } = data;

  const handleSendCustomerAnalysis = () => 
    navigate(`${routesConstant.customAnalysisAbsolutely}/${customer_analysis}`);

  useEffect(() => {
    // if (!isVisible) {
    //   navigate(routesConstant.booking);
    // }
  }, [isVisible, navigate]);
  
  useEffect(() => {
    return () => {
      dispatch(setIsThankYouPageVisible(false))
    }
  }, [dispatch]);

  if (isLoading) return <PageLoader />

  return (
    <div className={`page_container thank_you_page_container ${isMobile ? "mobile_container" : ""}`}>
      <Text variant="title-medium" className="title">
        {t("translation.thankYouPage.title")}
      </Text>
      <Text variant="label-small" className={`description ${isMobile ? "paragraph-body" : ""}`}>
        {t("translation.thankYouPage.description", {number: booked_meetings})}
      </Text>
      <div className="meeting_cards_container">
        {meetings?.map((meeting: BookingMeetingResponceI) => (
          <MeetingCard key={uniqueId()} meeting={meeting} />
        ))}
      </div>
      <Text variant="title-medium" className="subtitle">
        {t("translation.thankYouPage.subtitle")}
      </Text>
      <div className={`subdescription ${isMobile ? "paragraph-body" : ""}`}>
        {getStructuredText(t("translation.thankYouPage.subdescription"))}
      </div>
      <div className="buttons_container">
        <Button
          variant="contained"
          sizeVariant="Medium"
          className="filter_month_today_button"
          onClick={handleSendCustomerAnalysis}
        >
          {t("translation.thankYouPage.analysisButton")}
        </Button>
      </div>
    </div>
  );
};

export default ThankYouPage;
