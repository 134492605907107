import { useEffect } from "react";

import { useAppDispatch, useAppSelector, useAppShallowSelector } from "./redux";
import {
  getDataTypeHousingSelector,
  getIsFetchingTypeHousingSelector,
  TypeHosingStoreI,
} from "store/reducers/typeHousing";
import { getTypeHousing } from "store/asyncActions/typeHousing";

export const useTypeHousing = (): TypeHosingStoreI => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(getIsFetchingTypeHousingSelector);
  const data = useAppShallowSelector(getDataTypeHousingSelector);

  useEffect(() => {
    dispatch(getTypeHousing());
  }, [dispatch]);

  return { data, isFetching };
};
