import { Outlet } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { routesConstant } from "constants/appRoutesConstants";
import Text from "components/common/Text";
import Link from "components/common/Link";
import { useMobileContext } from "context/mobileContext";
import ClientGeneralProfilePage from "pages/ClientGeneralProfilePage";
import ClientLocationProfilePage from "pages/ClientLocationProfilePage";
import Card from "mobile_components/Card";
import StickyButton from "mobile_components/StickyButton";

const ClientProfilePage = () => {
  const [activeCardId, setActiveCardId] = useState(0);
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const handleActiveCard = (id: number) => {
    if (id === activeCardId) return setActiveCardId(0);
    setActiveCardId(id);
  }

  return (
    <div className={`page_container client_profile_page_container ${isMobile ? "mobile_container" : ""}`}>
      <div className="aside_wrap">
        <Text variant="title-medium" className={`client_profile_title ${isMobile ? "title-small20" : ""}`}>
          {t('translation.clientProfilePage.title')}
        </Text>
        {!isMobile && (
          <>
            <Text variant="paragraph-body" className="client_profile_subtitle">
              {t('translation.clientProfilePage.subtitle')}
            </Text>
            <Link
              to={routesConstant.clientProfile}
              className="profile_link"
              isMatchActive
            >
              {t('translation.clientProfilePage.settings')}
            </Link>
            <Link
              to={routesConstant.clientProfileLocation}
              className="profile_link"
              isMatchActive
            >
              {t('translation.clientProfilePage.location')}
            </Link>
          </>
        )}
      </div>
      <div className="subpages_wrap">
        {isMobile ? (
          <>
            <Card 
              title={t('translation.clientProfilePage.settings')}
              level={1} 
              isActive={activeCardId === 1}
              handleActiveCard={handleActiveCard}
            >
              <ClientGeneralProfilePage />
            </Card>
            <Card 
              title={t('translation.clientProfilePage.location')}
              level={2} 
              isActive={activeCardId === 2}
              handleActiveCard={handleActiveCard}
            >
              <ClientLocationProfilePage />
            </Card>
            {activeCardId === 0 && (
              <StickyButton
                className="label-bold"
                type="submit"
                name="submit_button"
                disabled={true}
                >
                  {t('translation.clientProfilePage.saveButton')}
              </StickyButton>
            )}
          </>
         ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default ClientProfilePage;
