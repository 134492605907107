import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";

export const getTypeHousing = createAsyncThunk(
  "typeHousing/getTypeHousing",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.typeHousing.getTypeHousing();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
