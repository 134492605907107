import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import i18next from "i18next";

import {
  AgencyListParamsI,
  AgentDelayHourI,
  AgentsFilterParamsI,
  SendEmailAboutDirectBookMeetingRequest,
} from "store/models/agent";
import { showAlerts } from "store/reducers/alerts";

export const getAgentsList = createAsyncThunk(
  "agent/getAgentsList",
  async (body: AgentsFilterParamsI, { rejectWithValue }) => {
    try {
      const { data } = await api.agent.getAgents(body);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentDetail = createAsyncThunk(
  "agent/getAgentDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await api.agent.getAgentById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAgencyList = createAsyncThunk(
  "agent/getAgencyList",
  async (param: AgencyListParamsI | undefined, { rejectWithValue }) => {
    try {
      const res = await api.agent.getAgencyList(param);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDelayHours = createAsyncThunk(
  "agent/getDelayHours",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.agent.getDelayHours();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const setDelayHours = createAsyncThunk(
  "agent/setDelayHours",
  async (body: AgentDelayHourI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.agent.setDelayHours(body);
      dispatch(
        showAlerts({
          title: "Success",
          message: i18next.t("translation.alertMessage.delayHours"),
          type: "success",
          autoHideDuration: 10000,
        })
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentPostalCode = createAsyncThunk(
  "agent/getAgentPostalCode",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.agent.getAgentPostalCode();

      return data.results;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.failedAddedAgentAddress"
          ),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const sendEmailAboutDirectBookMeeting = createAsyncThunk(
  "agent/sendEmailAboutDirectBookMeeting",
  async (
    body: SendEmailAboutDirectBookMeetingRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { status } = await api.agent.sendEmailAboutDirectBookMeeting(body);
      if (status === 200) {
        dispatch(
          showAlerts({
            message: i18next.t(
              "translation.alertMessage.contactMeDirectSuccess"
            ),
            type: "success",
            autoHideDuration: 10000,
          })
        );
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
