import { Route } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import CustomerAnalysis from "pages/CustomerAnalysis";

export const customerAnalysisRoute = (
  <Route
    path={`${routesConstant.customAnalysisAbsolutely}/:customerId`}
    element={
      <RequireAuth
        userRoleOnly={Role.SELLER}
        navigateTo={routesConstant.rootPage}
        requaredAuth={true}
      >
        <CustomerAnalysis />
      </RequireAuth>
    }
  />
);
