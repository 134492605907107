import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useAppSelector,
  useAppDispatch,
  useAppShallowSelector,
} from "hooks/redux";
import { styled } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";

import RoundAvatar from "components/RoundAvatar";
import Button from "components/common/Button";
import CropImage from "components/CropImage";

import {
  getAgentProfileAvatarSelector,
  getIsFetchingProfileSelector,
  getIsFetchingDeleteAvatarSelector,
} from "store/reducers/profile";
import {
  changeAgentAvatar,
  deleteAgentAvatar,
} from "store/asyncActions/profile";
import { useMobileContext } from "context/mobileContext";

const Input = styled("input")({
  display: "none",
});

const AvatarBlock: React.FC = () => {
  const dispatch = useAppDispatch();
  const avatarSrc = useAppShallowSelector(getAgentProfileAvatarSelector);
  const isFetching = useAppSelector(getIsFetchingProfileSelector);
  const isFetchingDelete = useAppSelector(getIsFetchingDeleteAvatarSelector);
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const [imgSrc, setImgSrc] = useState<string>("");
  const [openCropPopup, setOpenCropPopup] = useState(false);
  const [settings, setSetting] = useState({ name: "", type: "" });

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setOpenCropPopup(true);
      setSetting({
        name: e.target.files[0].name,
        type: e.target.files[0].type,
      });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleUpload = (formData: FormData) => {
    dispatch(changeAgentAvatar(formData));
    setOpenCropPopup(false);
  };

  const handleClose = () => setOpenCropPopup(false);

  const handleDelete = () => {
    dispatch(deleteAgentAvatar());
  };

  return (
    <div
      className={`${isMobile ? "mobile" : ""} profile_avatar_block_container`}
    >
      <RoundAvatar
        className={`${isMobile ? "mobile" : ""} avatar`}
        src={avatarSrc}
        alt="avatar"
      />
      <label htmlFor="icon-button-file">
        <Input
          accept="image/*"
          id="icon-button-file"
          type="file"
          onChange={onSelectFile}
          disabled={isFetching || isFetchingDelete}
        />
        <Button
          className="button upload_button"
          sizeVariant={isMobile ? "Small" : "Medium"}
          startIcon={<FileUploadOutlinedIcon />}
          aria-label="upload picture"
          component="span"
          showLoadingIndicator={isFetching}
        >
          {t("translation.avatar.upload")}
        </Button>
      </label>
      {openCropPopup && imgSrc && (
        <CropImage
          isOpen={openCropPopup}
          imgSrc={imgSrc}
          fileName={settings.name}
          fileType={settings.type}
          onUpload={handleUpload}
          onClose={handleClose}
        />
      )}
      {!isMobile ? (
        <Button
          className="button delete_button"
          variant="outlined"
          sizeVariant="Medium"
          endIcon={<DeleteOutlineOutlinedIcon />}
          disabled={isFetchingDelete || isFetching}
          showLoadingIndicator={isFetchingDelete}
          onClick={handleDelete}
        >
          {t("translation.avatar.delete")}
        </Button>
      ) : (
        <button onClick={handleDelete} className="overlay_delete_button">
          <CloseIcon className="icon" />
        </button>
      )}
    </div>
  );
};

export default AvatarBlock;
