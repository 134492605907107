import React from "react";

import Spinner from "components/common/Spinner";

const PageLoader = () => {
  return (
    <div className="loader">
      <Spinner size={80} />
    </div>
  );
};

export default PageLoader;
