import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getIsAuthUserSelector } from "store/reducers/profile";
import { logout } from "store/asyncActions/profile";
import IdleTimer from "utils/idle";
import { routesConstant } from "constants/appRoutesConstants";

import { useAppDispatch, useAppSelector } from "./redux";

export const useIdle = (timeout: number) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuth = useAppSelector(getIsAuthUserSelector);

  const _logout = useCallback(() => {
    dispatch(logout())
    .then(() => {
      if (pathname === routesConstant.booking) {
        navigate(routesConstant.auth);
      }
    });
  }, [dispatch, navigate, pathname]);

  useEffect(() => {
    if (isAuth) {
      const timer = new IdleTimer({
        timeout,
        onTimeout: _logout,
        onExpired: _logout,
      });
      return () => {
        timer.cleanUp();
      };
    }
  }, [_logout, isAuth, timeout]);
};
