import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routesConstant } from "constants/appRoutesConstants";
import Text from "components/common/Text";
import Button from "components/common/Button";
import NextMeetingList from "components/NextMeetingList";
import { useMobileContext } from "context/mobileContext";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { getUpcommingMeetingByNextThreeDaySelector } from "store/reducers/meetings";
import { getUpcomingMeetingsNextThreeDay } from "store/asyncActions/meetings";

const TopSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const upMeetByNextThreeDay = useAppShallowSelector(
    getUpcommingMeetingByNextThreeDaySelector
  );

  const handleClickPreviousMeetingButton = useCallback(() => {
    navigate(routesConstant.agentPreviousMeerings);
  }, [navigate]);

  useEffect(() => {
    dispatch(getUpcomingMeetingsNextThreeDay());
  }, [dispatch]);

  return (
    <div className={`meeteng_page_top_section ${isMobile ? "mobile" : ""}`}>
      <div className="top_section_item left_block">
        <Text
          text={t("translation.agentMeetings.title")}
          component={"h1"}
          className="page_title"
          variant="medium-header"
        />
        <Text
          text={t("translation.agentMeetings.subtitle")}
          className="page_description"
          variant="paragraph-large"
        />
        <Button
          variant="outlined"
          className="previous_meetings_button"
          onClick={handleClickPreviousMeetingButton}
          sizeVariant="Large"
        >
          {t("translation.agentMeetings.prevMeetButton")}
        </Button>
      </div>
      <NextMeetingList
        className="top_section_item right_block"
        isMobile={isMobile}
        isAgent={true}
        data={upMeetByNextThreeDay}
      />
    </div>
  );
};

export default TopSection;
