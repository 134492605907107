import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";

import api from "api";
import { CustomerAnalysisRequest } from "store/models/customerAnalysis";
import { showAlerts } from "store/reducers/alerts";

export const getCustomerAnalysisQuestions = createAsyncThunk(
  "customerAnalysis/getCustomerAnalysisQuestions",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await api.customerAnalysis.getQuestions(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveCustomerAnalysisAnswers = createAsyncThunk(
  "customerAnalysis/saveCustomerAnalysisAnswers",
  async (
    { id, body }: { id: string; body: CustomerAnalysisRequest },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.customerAnalysis.saveAnswers(id, body);
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedAnalysisAdded"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getCustomerAnalysisPDF = createAsyncThunk(
  "customerAnalysis/getCustomerAnalysisPDF",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.customerAnalysis.getCustomerAnalysisPDF(id);
      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Customer-analisys.pdf");
        document.body.appendChild(link);
        link.click();
      }

      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: `${error.response.data.detail}`,
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
