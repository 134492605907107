import { Route } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import ClientProfilePage from "pages/ClientProfilePage";
import ClientGeneralProfilePage from "pages/ClientGeneralProfilePage";
import ClientLocationProfilePage from "pages/ClientLocationProfilePage";

export const sellerProfileRoutes = (
  <Route
    path={routesConstant.clientProfileAbsolutely}
    element={
      <RequireAuth userRoleOnly={Role.SELLER}>
        <ClientProfilePage />
      </RequireAuth>
    }
  >
    <Route index element={<ClientGeneralProfilePage />} />
    <Route
      path={routesConstant.clientProfileLocationAbsolutely}
      element={<ClientLocationProfilePage />}
    />
  </Route>
);
