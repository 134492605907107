import React from "react";
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from "@mui/material/ToggleButtonGroup";

interface ToggleButtonGroupStyledPropsI extends ToggleButtonGroupProps {
  className?: string;
}

const ToggleButtonGroupStyled: React.FC<ToggleButtonGroupStyledPropsI> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <ToggleButtonGroup
      className={`toggle_button_group_styled ${className}`}
      {...rest}
    >
      {children}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonGroupStyled;
