import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import {
  getAddressesByPostalCode,
  getAddressesByStreet,
} from "store/asyncActions/locations";
import {
  getAddressesListSelector,
  getIsNextAddressesListSelector,
} from "store/reducers/addresses";
import { StoredAddressesI } from "store/models/locations";

import AutocompleteInput from "components/common/AutocompleteInput";
import { getPostalCode, getStreetData } from "common/inputAddressRule";

const defaultRequestParams = {
  field: "",
  value: "",
  limit: 20,
};

interface SellerAddressPropsI {
  formik: FormikProps<any>;
  disabled?: boolean;
  isErrorMessage?: boolean;
}

const SellerAddress: React.FC<SellerAddressPropsI> = ({
  formik,
  disabled,
  isErrorMessage,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const addresses = useAppShallowSelector(getAddressesListSelector);
  const isNextAddress = useAppShallowSelector(getIsNextAddressesListSelector);

  const [addressList, setAddressList] = useState<StoredAddressesI[]>(addresses);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState<boolean>(false);
  const [isOpenAreaNumberPopup, setIsOpenAreaNumberPopup] =
    useState<boolean>(false);

  const [requestParams, setRequestParams] = useState(defaultRequestParams);

  const handleChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      setRequestParams(() => ({
        field: field,
        value: e.target.value,
        limit: 20,
      }));
    };

  const handlePickOptionItem = (option: StoredAddressesI) => {
    formik.setValues({
      ...formik.values,
      address: `${option.street}, ${option.houseNumber}`,
      areaNumber: option.postalCode,
      address_id: option.address_id,
      district: option.district,
      county: option.county,
    });
  };

  const handleLoadRequest = () => {
    const { field, value, limit } = requestParams;
    const isLength = field === "street" ? value.length > 1 : value.length > 4;

    if (isLength && isNextAddress) {
      if (field === "street") {
        const { streetName, streetNumber } = getStreetData(value);
        dispatch(
          getAddressesByStreet({
            limit: limit + 20,
            street: streetName,
            number: streetNumber,
          })
        );
      } else {
        const postalCode = getPostalCode(value);
        dispatch(
          getAddressesByPostalCode({
            postal_code: Number(postalCode),
            limit: limit + 20,
          })
        );
      }

      setRequestParams((prev) => ({ ...prev, limit: prev.limit + 20 }));
    }
  };

  useEffect(() => {
    if (addresses.length) {
      setAddressList(addresses);
    }
  }, [addresses]);

  useEffect(() => {
    const { field, value } = requestParams;

    if (!value) return;

    const { streetName, streetNumber } = getStreetData(value);

    const timer = setTimeout(() => {
      // logic for street field (street and number match)
      if (field === "street") {
        dispatch(
          getAddressesByStreet({
            limit: 20,
            street: streetName,
            number: streetNumber,
          })
        );
        setIsOpenAddressPopup(true);
      }

      // logic for postal code field
      if (field === "postal_code" && value.length > 4) {
        const postalCode = getPostalCode(value);
        dispatch(
          getAddressesByPostalCode({
            postal_code: Number(postalCode),
            limit: 20,
          })
        );
        setIsOpenAreaNumberPopup(true);
      }
    }, 500);

    setRequestParams((prev) => ({ ...prev, limit: 20 }));
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams.field, requestParams.value]);

  return (
    <>
      <div className="form_row">
        <AutocompleteInput
          label={t("translation.inputLabel.address")}
          name="address"
          placeholder={t("translation.inputPlaceholder.address")}
          value={formik.values.address}
          errorMessage={
            (formik.touched.address ? formik.errors.address : "") as string
          }
          options={addressList}
          isOpen={isOpenAddressPopup}
          isNextAddress={isNextAddress}
          disabled={disabled}
          emptyStateMessage={t("translation.inputLabel.noAddress")}
          handleClosePopup={setIsOpenAddressPopup}
          handlePickOptionItem={handlePickOptionItem}
          onChange={handleChange("street")}
          onBlur={formik.handleBlur}
          handleLoadRequest={handleLoadRequest}
        />
      </div>
      <div className="form_row with_helper_text">
        <AutocompleteInput
          label={t("translation.inputLabel.areaNameNumber")}
          name="areaNumber"
          placeholder="111 54 Stockholm"
          helperText={t("translation.bookingPage.realEstate")}
          value={formik.values.areaNumber}
          errorMessage={
            (formik.touched.areaNumber
              ? formik.errors.areaNumber
              : "") as string
          }
          options={addressList}
          isOpen={isOpenAreaNumberPopup}
          isNextAddress={isNextAddress}
          disabled={disabled}
          emptyStateMessage={t("translation.inputLabel.noAddress")}
          handleClosePopup={setIsOpenAreaNumberPopup}
          handlePickOptionItem={handlePickOptionItem}
          onChange={handleChange("postal_code")}
          onBlur={formik.handleBlur}
          handleLoadRequest={handleLoadRequest}
        />
      </div>
      {isErrorMessage && (
        <Alert severity="error" className="disabled_inputs_notification">
          {t("translation.alertMessage.disabledAddress")}
        </Alert>
      )}
    </>
  );
};

export default SellerAddress;
