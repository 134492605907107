import React from "react";

import TextInputMask, {
  TextInputMaskProps,
} from "components/common/TextInputMask";
import { useTranslation } from "react-i18next";

const NumberOfSqmTextInput: React.FC<TextInputMaskProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TextInputMask
      suffix="m&#178;"
      placeholder="m&#178;"
      label={t("translation.inputLabel.sqm")}
      isAllowed={({ floatValue = 0 }) => floatValue <= 9999}
      {...props}
    />
  );
};

export default NumberOfSqmTextInput;
