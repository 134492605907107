import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";
import TextInput from "components/common/TextInput";
import PhoneTextInput from "components/PhoneTextInput";
import Button from "components/common/Button";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getAgentProfileGeneralFormSelector,
  getIsFetchingUpdateProfileSelector,
} from "store/reducers/profile";
import { updateAgentProfile } from "store/asyncActions/profile";
import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { useTranslation } from "react-i18next";
import { AgentProfileGeneralSchema } from "common/validationSchemas";

interface ProfileFormInterface {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
  presentation: string;
  whatClientsSay: string;

  accomplishments_one: string;
  accomplishments_two: string;
  accomplishments_three: string;
}

const ProfileFormBlock: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const { id, ...initialValues } = useAppShallowSelector(
    getAgentProfileGeneralFormSelector
  );
  const isFetching = useAppSelector(getIsFetchingUpdateProfileSelector);

  const formik = useFormik<ProfileFormInterface>({
    initialValues,
    onSubmit(values, helpers) {
      const phone_number = values.phone.replaceAll(" ", "");
      const clientFeedback = [
        values.accomplishments_one,
        values.accomplishments_two,
        values.accomplishments_three,
      ].filter(Boolean);

      dispatch(
        updateAgentProfile({
          id,
          phone_number,
          agent_profile: {
            title: values.title,
            presentation: values.presentation,
            what_clients_say: values.whatClientsSay,
            client_feedback: clientFeedback,
          },
        })
      );
      helpers.resetForm({ values });
    },
    validationSchema: AgentProfileGeneralSchema(),
  });

  const handleClickPreviewMyProfile = () => {
    navigate(`${routesConstant.agentPresentationProfile}/${id}`);
  };

  const handleChange = (e: any) =>
    formik.setFieldValue("phone", e.target.value.replace(/[ _]+/g, ""));

  return (
    <form
      className={`${isMobile ? "mobile" : ""} profile_form_block_container`}
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <div className="names_section">
        <TextInput
          label={t("translation.inputLabel.firstName")}
          placeholder={t("translation.inputPlaceholder.firstName")}
          classNameContainer="names_input"
          name="firstName"
          value={formik.values.firstName}
          errorMessage={formik.errors.firstName}
          disabled
          onChange={formik.handleChange}
        />
        <TextInput
          label={t("translation.inputLabel.lastName")}
          placeholder={t("translation.inputPlaceholder.lastName")}
          classNameContainer="names_input"
          name="lastName"
          value={formik.values.lastName}
          errorMessage={formik.errors.lastName}
          disabled
          onChange={formik.handleChange}
        />
      </div>
      <TextInput
        label={t("translation.inputLabel.email")}
        placeholder={t("translation.inputPlaceholder.email")}
        classNameContainer="row_inputs email"
        name="email"
        value={formik.values.email}
        disabled
        onChange={formik.handleChange}
      />
      <PhoneTextInput
        label={t("translation.inputLabel.phone")}
        classNameContainer="row_inputs phone"
        name="phone"
        value={formik.values.phone}
        errorMessage={formik.errors.phone}
        onChange={handleChange}
        onBlur={formik.handleBlur}
      />
      <TextInput
        label={t("translation.inputLabel.title")}
        placeholder={t("translation.inputPlaceholder.title")}
        helperText={t("translation.agentProfileGeneralSetting.titleHelperText")}
        classNameContainer="row_inputs title"
        helperTextClassName={isMobile ? "label-extra-small" : ""}
        name="title"
        value={formik.values.title}
        errorMessage={formik.errors.title}
        onChange={formik.handleChange}
      />
      <TextInput
        label={t("translation.agentProfileGeneralSetting.presentation")}
        placeholder={t("translation.agentProfileGeneralSetting.presentation")}
        classNameContainer="row_inputs presentation"
        className="presentation_input"
        helperTextClassName={isMobile ? "label-extra-small" : ""}
        multiline
        minRows={4}
        helperText={t(
          "translation.agentProfileGeneralSetting.presentationHelperText"
        )}
        name="presentation"
        value={formik.values.presentation}
        errorMessage={formik.errors.presentation}
        onChange={formik.handleChange}
      />

      <TextInput
        label={t("translation.agentProfileGeneralSetting.accomplishmentsTitle")}
        placeholder={t(
          "translation.agentProfileGeneralSetting.accomplishmentsPlaceholder"
        )}
        classNameContainer="accomplishments_input"
        name="accomplishments_one"
        value={formik.values.accomplishments_one}
        errorMessage={formik.errors.accomplishments_one}
        onChange={formik.handleChange}
      />
      <TextInput
        classNameContainer="accomplishments_input"
        placeholder={t(
          "translation.agentProfileGeneralSetting.accomplishmentsPlaceholder"
        )}
        name="accomplishments_two"
        value={formik.values.accomplishments_two}
        errorMessage={formik.errors.accomplishments_two}
        onChange={formik.handleChange}
      />
      <TextInput
        classNameContainer="row_inputs"
        placeholder={t(
          "translation.agentProfileGeneralSetting.accomplishmentsPlaceholder"
        )}
        name="accomplishments_three"
        value={formik.values.accomplishments_three}
        errorMessage={formik.errors.accomplishments_three}
        onChange={formik.handleChange}
      />
      <TextInput
        label={t("translation.agentProfileGeneralSetting.clientReviewTitle")}
        placeholder={t(
          "translation.agentProfileGeneralSetting.clientReviewPlaceholder"
        )}
        classNameContainer="row_inputs presentation"
        className="presentation_input"
        helperTextClassName={isMobile ? "label-extra-small" : ""}
        multiline
        minRows={4}
        name="whatClientsSay"
        value={formik.values.whatClientsSay}
        errorMessage={formik.errors.whatClientsSay}
        onChange={formik.handleChange}
      />
      <div className="buttons_section">
        {!isMobile ? (
          <Button
            className="button save_button"
            type="submit"
            sizeVariant="Large"
            showLoadingIndicator={isFetching}
            disabled={isFetching || !formik.dirty}
          >
            {t("translation.agentProfileGeneralSetting.saveButton")}
          </Button>
        ) : (
          <StickyButton
            className="button save_button"
            type="submit"
            showLoadingIndicator={isFetching}
            disabled={isFetching || !formik.dirty}
          >
            {t("translation.agentProfileGeneralSetting.saveButton")}
          </StickyButton>
        )}

        <Button
          className="button preview_button"
          variant="outlined"
          type="button"
          sizeVariant={isMobile ? "Medium" : "Large"}
          onClick={handleClickPreviewMyProfile}
        >
          {t("translation.agentProfileGeneralSetting.previewButton")}
        </Button>
      </div>
    </form>
  );
};

export default ProfileFormBlock;
