import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";
import { setTokens } from "utils/auth";
import { profileAboutSelf } from "./profile";

export const getBankIdAuthInitiate = createAsyncThunk(
  "bankId/getBankIdAuthInitiate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.bankId.authInitiate();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkCollectBankId = createAsyncThunk(
  "bankId/checkCollectBankId",
  async (orderRef: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.bankId.checkCollect(orderRef);
      if (data.jwt_pair) {
        setTokens(data.jwt_pair);
        dispatch(profileAboutSelf());
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQrCode = createAsyncThunk(
  "bankId/updateQrCode",
  async (orderRef: string, { rejectWithValue }) => {
    try {
      const res = await api.bankId.updateQrCode(orderRef);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
