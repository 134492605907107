import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";

import { DeleteMeetingQueryParams, UpcomingMeetingsQueryParams } from "store/models/meetings";

export const getUpcomingMeetings = createAsyncThunk(
  "getUpcomingMeetings/meetings",
  async (
    params: UpcomingMeetingsQueryParams | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.meetings.getUpcomingMeetings(params);

      return data;
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const getUpcomingMeetingsNextThreeDay = createAsyncThunk(
  "getUpcomingMeetingsNextThreeDay/meetings",
  async (_, { rejectWithValue }) => {
    try {
      /// Should suffice of limit 50 to load meetings in last three day
      const { data } = await api.meetings.getUpcomingMeetings({ limit: 50 });

      return data.results;
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const getPassedMeetings = createAsyncThunk(
  "getPassedMeetings/meetings",
  async (
    params: UpcomingMeetingsQueryParams | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.meetings.getPassedMeetings(params);

      return data;
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const deleteMeeting = createAsyncThunk(
  "deleteMeeting/meetings",
  async (params: DeleteMeetingQueryParams, { rejectWithValue }) => {
    try {
      const { status } = await api.meetings.deleteMeeting(params);
      if (status === 204) {
        const { data } = await api.meetings.getUpcomingMeetings({ limit: 50 });

        return data;
      }
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);
