import i18next from "i18next";
import * as Yup from "yup";

const postalCodeReg = /^\d{3}(\s?)\d{2}[\sA-Za-zéäöåÄÖÅÉ-]+$/;

export const CreateClientFormSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.firstName"),
        })
      )
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 })),
    lastName: Yup.string()
      .trim()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.lastName"),
        })
      )
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 })),
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      )
      .max(254, i18next.t("translation.validation.max", { max: 254 })),
    phone: Yup.string()
      .min(7, i18next.t("translation.validation.min", { min: 7 }))
      .max(13, i18next.t("translation.validation.max", { max: 11 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.phone"),
        })
      ),
    address: Yup.string()
      .min(4, i18next.t("translation.validation.min", { min: 4 }))
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.address"),
        })
      ),
    areaNumber: Yup.string()
      .min(5, i18next.t("translation.validation.min", { min: 5 }))
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.zipCode"),
        })
      )
      .test(
        "areaNumber",
        i18next.t("translation.validation.areaNumber"),
        (value) => {
          if (value) return postalCodeReg.test(value);

          return false;
        }
      ),
    gdpr: Yup.bool().isTrue(
      i18next.t("translation.validation.checked", {
        field: i18next.t("translation.inputLabel.gdpr"),
      })
    ),
  });

export const AgentProfileGeneralSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
    phone: Yup.string().test(
      "phone",
      i18next.t("translation.validation.min", { min: 7 }),
      (value?: string) => {
        if (value && value.length >= 1) {
          const schema = Yup.string()
            .min(7, i18next.t("translation.validation.min", { min: 7 }))
            .max(13, i18next.t("translation.validation.max", { max: 11 }));

          return schema.isValid(value);
        }

        return true;
      }
    ),
    title: Yup.string().max(
      50,
      i18next.t("translation.validation.max", { max: 50 })
    ),
    presentation: Yup.string().max(
      1000,
      i18next.t("translation.validation.max", { max: 1000 })
    ),
    whatClientsSay: Yup.string().max(
      1000,
      i18next.t("translation.validation.max", { max: 1000 })
    ),

    accomplishments_one: Yup.string().max(
      50,
      i18next.t("translation.validation.max", { max: 50 })
    ),
    accomplishments_two: Yup.string().max(
      50,
      i18next.t("translation.validation.max", { max: 50 })
    ),
    accomplishments_three: Yup.string().max(
      50,
      i18next.t("translation.validation.max", { max: 50 })
    ),
  });

export const AgentSignInSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
    password: Yup.string().required(
      i18next.t("translation.validation.required", {
        field: i18next.t("translation.inputLabel.password"),
      })
    ),
  });

export const AdminSignInSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
    password: Yup.string().required(
      i18next.t("translation.validation.required", {
        field: i18next.t("translation.inputLabel.password"),
      })
    ),
  });

export const AgentForgotPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
  });

export const AgentUpdatePasswordSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must have min 8 symbols")
      .max(16, "Password must haven't more 16 symbols")
      .required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password mismatch")
      .required(),
  });

export const BookingSignupSchema = () =>
  Yup.object().shape({
    target: Yup.string().required(
      i18next.t("translation.validation.required", {
        field: i18next.t("translation.inputLabel.field"),
      })
    ),
    residenceType: Yup.string().required(
      i18next.t("translation.validation.required", {
        field: i18next.t("translation.inputLabel.field"),
      })
    ),
    numberRooms: Yup.string(),
    numberOfSqm: Yup.string().test(
      "",
      i18next.t("translation.validation.positive"),
      (value) => {
        if (!value) return true;

        if (value.includes(".")) return false;

        return Number(value) > 0;
      }
    ),
    address: Yup.string()
      .min(4, i18next.t("translation.validation.min", { min: 4 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.address"),
        })
      )
      .max(50, i18next.t("translation.validation.max", { max: 50 })),
    areaNumber: Yup.string()
      .min(5, i18next.t("translation.validation.min", { min: 5 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.zipCode"),
        })
      )
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .test(
        "areaNumber",
        i18next.t("translation.validation.areaNumber"),
        (value) => {
          if (value) return postalCodeReg.test(value);

          return false;
        }
      ),
  });

export const ClientGeneralProfileSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.firstName"),
        })
      )
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 })),
    lastName: Yup.string()
      .trim()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.lastName"),
        })
      )
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 })),
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
    phone: Yup.string()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.phone"),
        })
      )
      .min(7, i18next.t("translation.validation.min", { min: 7 }))
      .max(13, i18next.t("translation.validation.max", { max: 11 })),
  });

export const ClientLocationProfileSchema = () =>
  Yup.object().shape({
    address: Yup.string()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.address"),
        })
      )
      .min(4, i18next.t("translation.validation.min", { min: 4 }))
      .max(50, i18next.t("translation.validation.max", { max: 50 })),
    areaNumber: Yup.string()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.zipCode"),
        })
      )
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .test(
        "areaNumber",
        i18next.t("translation.validation.areaNumber"),
        (value) => {
          if (value) return postalCodeReg.test(value);

          return false;
        }
      ),
  });

export const ContactMeSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.name"),
        })
      )
      .min(1, i18next.t("translation.validation.min", { min: 1 }))
      .max(150, i18next.t("translation.validation.max", { max: 150 })),
    email: Yup.string()
      .email(i18next.t("translation.validation.email"))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.email"),
        })
      ),
    phone: Yup.string()
      .min(7, i18next.t("translation.validation.min", { min: 7 }))
      .max(13, i18next.t("translation.validation.max", { max: 11 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.phone"),
        })
      ),
    residenceType: Yup.string().required(
      i18next.t("translation.validation.required", {
        field: i18next.t("translation.inputLabel.field"),
      })
    ),
    address: Yup.string()
      .min(4, i18next.t("translation.validation.min", { min: 4 }))
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.address"),
        })
      ),
    areaNumber: Yup.string()
      .min(5, i18next.t("translation.validation.min", { min: 5 }))
      .max(50, i18next.t("translation.validation.max", { max: 50 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t("translation.inputLabel.zipCode"),
        })
      )
      .test(
        "areaNumber",
        i18next.t("translation.validation.areaNumber"),
        (value) => {
          if (value) return postalCodeReg.test(value);

          return false;
        }
      ),
    acceptedPolicy: Yup.bool().isTrue(
      i18next.t("translation.validation.checked", {
        field: i18next.t("translation.inputLabel.gdpr"),
      })
    ),
    message: Yup.string()
      .max(1000, i18next.t("translation.validation.max", { max: 1000 }))
      .required(
        i18next.t("translation.validation.required", {
          field: i18next.t(
            "translation.agentPresentationProfilePage.messageFieldPlaceholder"
          ),
        })
      ),
  });
