import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  DistrictsSearchParams,
  DistrictsT,
  AddDistricBody,
} from "store/models/district";

export class DistrictsApi extends CRUD {
  getDistrics(params: DistrictsSearchParams): AxiosPromise<DistrictsT> {
    return this.request({
      url: `${this.url}/`,
      params,
    });
  }

  addDistrictOfAgentFocusArea(
    data: AddDistricBody
  ): AxiosPromise<AddDistricBody> {
    return this.request({
      url: `${this.url}/agent_focus/`,
      method: "POST",
      data,
    });
  }

  deleteDistrictsFromAgentFocusArea(id: string): AxiosPromise {
    return this.request({
      url: `${this.url}/agent_focus/${id}/`,
    });
  }
}

export default function districtsApi(request: AxiosInstance) {
  return new DistrictsApi({
    url: "/district",
    request,
  });
}
