import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import unionBy from "lodash/unionBy";
import isEmpty from "lodash/isEmpty";

import { TimeSlotDataT, ChosenAgentI, HourSlot } from "store/models/booking";
import { AgentsTimeSlots } from "store/models/agent";
import {
  getAgentTimeSlots,
  reserveTimeSlot,
  cancelReserveTimeSlot,
  confirmBookingMeeting,
} from "store/asyncActions/booking";
import { createTypedSelector } from "store/utils";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";

interface InitialStateI {
  selectedTimeSlot: TimeSlotDataT | null;
  isFetchingTimeSlots: boolean;
  chosenAgents: ChosenAgentI[];
  selectedHourSlot: HourSlot;
  timeSlots: AgentsTimeSlots;
  isFetchingReserveTimeSlot: boolean;
  noSlotsMessage: string;
  // methods to control modals/pages
  isPreBookingHidden: boolean;
  isOpenConfirmBookingWindow: boolean;
  isThankYouPageVisible: boolean;
}

const initialState: InitialStateI = {
  timeSlots: [],
  noSlotsMessage: "",
  isFetchingTimeSlots: false,
  selectedTimeSlot: null,
  selectedHourSlot: {
    day: null,
    hour: null,
  },
  chosenAgents: [],
  isFetchingReserveTimeSlot: false,
  isPreBookingHidden: false,
  isOpenConfirmBookingWindow: false,
  isThankYouPageVisible: false,
};

export const getNoSlotsMessageSelector = createTypedSelector(
  (state) => state.booking.noSlotsMessage
);

export const getSelectedHourSlotSelector = createTypedSelector(
  (state) => state.booking.selectedHourSlot
);

export const getChosenAgentsLengthSelector = createTypedSelector((state) =>
  state.profile.isAuth ? state.booking.chosenAgents.length : 0
);

export const getIsPreBookingHiddenSelector = createTypedSelector(
  (state) => state.booking.isPreBookingHidden
);

export const getIsFetchingTimeSlotsSelector = createTypedSelector(
  (state) => state.booking.isFetchingTimeSlots
);

export const getIsTimeSlotsEmptyListSelector = createTypedSelector((state) =>
  Boolean(state.booking.timeSlots.length)
);

export const getSelectedTimeSlotSelector = createTypedSelector(
  (state) => state.booking.selectedTimeSlot
);

export const getChosenAgentsSelector = createTypedSelector(
  (state) => state.booking.chosenAgents
);

export const getIsOpenConfirmBookingWindowSelector = createTypedSelector(
  (state) => state.booking.isOpenConfirmBookingWindow
);

export const getIsThankYouPageVisibleSelector = createTypedSelector(
  (state) => state.booking.isThankYouPageVisible
);

export const getIsFetchingReserveTimeSlotSelector = createTypedSelector(
  (state) => state.booking.isFetchingReserveTimeSlot
);

export const getAgentsTimeSlotsByDay = (day: number) =>
  createTypedSelector((state) => {
    return state.booking.timeSlots.find(
      (dayTimeSlots) => new Date(dayTimeSlots.date).getDate() === day
    )?.time_slots;
  });

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setIsPreBookingHidden: (state, action: PayloadAction<boolean>) => {
      state.isPreBookingHidden = action.payload;
    },
    clearFreeTimeSlots: (state) => {
      state.timeSlots = initialState.timeSlots;
    },
    setSelectedTimeSlot: (
      state,
      action: PayloadAction<TimeSlotDataT | null>
    ) => {
      state.selectedTimeSlot = action.payload;
    },
    deleteAgentFromChosenList: (state, action: PayloadAction<string>) => {
      state.chosenAgents = state.chosenAgents.filter(
        (agent) => agent.id !== action.payload
      );
    },
    setSelectedHourSlot: (state, action: PayloadAction<HourSlot>) => {
      state.selectedHourSlot = action.payload;
    },
    setIsOpenConfirmBookingWindow: (state, action: PayloadAction<boolean>) => {
      state.isOpenConfirmBookingWindow = action.payload;
    },
    setIsThankYouPageVisible: (state, action: PayloadAction<boolean>) => {
      state.isThankYouPageVisible = action.payload;
    },
  },
  extraReducers: {
    [getAgentTimeSlots.pending.type]: (state) => {
      state.isFetchingTimeSlots = true;
    },
    [getAgentTimeSlots.fulfilled.type]: (
      state,
      action: PayloadAction<AgentsTimeSlots>
    ) => {
      state.isFetchingTimeSlots = false;
      state.noSlotsMessage = "";

      const isOneAvailableTime = !!(action as any).meta.arg?.params?.start_date;
      state.timeSlots = isOneAvailableTime
        ? !isEmpty(action.payload)
          ? unionBy(state.timeSlots, action.payload, "date")
          : []
        : action.payload;
    },
    [getAgentTimeSlots.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingTimeSlots = false;
      state.noSlotsMessage = action.payload;
    },
    [reserveTimeSlot.pending.type]: (state) => {
      state.isFetchingReserveTimeSlot = true;
    },
    [reserveTimeSlot.fulfilled.type]: (state) => {
      state.isFetchingReserveTimeSlot = false;
    },
    [reserveTimeSlot.rejected.type]: (state) => {
      state.isFetchingReserveTimeSlot = false;
    },
    [cancelReserveTimeSlot.pending.type]: (state) => {
      state.isFetchingReserveTimeSlot = true;
    },
    [cancelReserveTimeSlot.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isFetchingReserveTimeSlot = false;
      state.chosenAgents = state.chosenAgents.filter(
        (agent) => agent.id !== action.payload
      );
    },
    [cancelReserveTimeSlot.rejected.type]: (state) => {
      state.isFetchingReserveTimeSlot = false;
    },
    [confirmBookingMeeting.fulfilled.type]: (state) => {
      state.chosenAgents = [];
    },
    [getAgentsListBlueBox.fulfilled.type]: (state, action) => {
      state.chosenAgents = action.payload.length ? action.payload : [];
    },
  },
});

export const {
  setIsPreBookingHidden,
  clearFreeTimeSlots,
  setSelectedTimeSlot,
  deleteAgentFromChosenList,
  setSelectedHourSlot,
  setIsOpenConfirmBookingWindow,
  setIsThankYouPageVisible,
} = BookingSlice.actions;

export default BookingSlice.reducer;
