import { createContext, useState, useContext, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import AuthPage from "./AuthPage";

export enum AuthAgentViewType {
  AUTH_FORM = 1,
  FORGOT_PASSWORD_SEND_EMAIL,
  FORGOT_PASSWORD_EMAIL_WAS_SENT,
  FORGOT_PASSWORD_UPDATE_PASSWORD,
  PASSWORD_WAS_UPDATED_SUCCESS,
  PASSWORD_WAS_UPDATED_FAILED,
}

export enum AuthTabs {
  CLIEN,
  AGENT,
}

export enum SearchParams {
  token = "token",
}

export interface AuthContextValue {
  isMobile: boolean;
  setViewType: (type: AuthAgentViewType) => void;
  viewType: AuthAgentViewType;
  activeTab: AuthTabs;
  setActiveTab: (tabIndex: AuthTabs) => void;
  passwordToken: string | null;
}

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);

const AuthPageContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const passwordToken = useRef(searchParams.get(SearchParams.token));

  const [activeTab, setActiveTab] = useState<AuthTabs>(AuthTabs.CLIEN);

  const [viewType, setViewType] = useState<AuthAgentViewType>(
    AuthAgentViewType.AUTH_FORM
  );

  useEffect(() => {
    if (passwordToken.current) {
      setViewType(AuthAgentViewType.FORGOT_PASSWORD_UPDATE_PASSWORD);
      setActiveTab(AuthTabs.AGENT);
      setSearchParams("", { replace: true });
    }
  }, [setSearchParams]);

  return (
    <AuthContext.Provider
      value={{
        isMobile,
        setViewType,
        viewType,
        activeTab,
        setActiveTab,
        passwordToken: passwordToken.current,
      }}
    >
      <AuthPage />
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthPageContainer;
