import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import Alert from "@mui/material/Alert";

import Text from "components/common/Text";
import Button from "components/common/Button";
import SellerAddress from "components/SellerAddress";
import NextArow from "components/Svg/NextArow";
import NumberOfSqmTextInput from "components/NumberOfSqmTextInput/NumberOfSqmTextInput";
import Select, { SelectItemValue } from "components/common/Select";

import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { numberOfRoomsSelect } from "constants/bookingMeetingForm";
import { getIsFetchingPostalCodeActiveStatusSelector } from "store/reducers/addresses";
import {
  checkIsPostalCodeActive,
  getSelectedAddress,
} from "store/asyncActions/locations";
import { useAppDispatch } from "hooks/redux";
import BlockLoader from "components/BlockLoader";

import { FormProps } from "../types";

import AddressAbsentModal from "./AddressAbsentModal";
import {
  trackingFailedLead,
  trackingSecondStep,
  trackingStartFillingSecondStep,
} from "utils/facebookTracking";
import {
  getAreaName,
  getPostalCode,
  getStreetData,
} from "common/inputAddressRule";
import { usePrevious } from "hooks/usePrev";
import { SellerAddress as SellerAddressI } from "store/models/locations";

const FormPartTwo: React.FC<FormProps> = ({
  handleSaveForm,
  formik,
  disabledStep,
}) => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const prevState = usePrevious(formik.values);
  const isFirstLoad = useRef(true);

  const handleSelect = (fieldName: string) => (values: SelectItemValue) => {
    formik.setFieldValue(fieldName, values.value, false);
  };

  const handleChange = (value: string) => {
    const cuttedValue = value.slice(0, 4);
    formik.setFieldValue("numberOfSqm", cuttedValue);
  };

  const handleClickNextForm = async () => {
    if (
      (!formik.values.address && !formik.values.areaNumber) ||
      formik.errors.areaNumber
    ) {
      return formik.setTouched(
        {
          address: true,
          areaNumber: true,
          numberOfSqm: true,
          numberRooms: true,
        },
        true
      );
    }

    const postalCode = getPostalCode(formik.values.areaNumber);

    const { streetName, streetNumber } = getStreetData(formik.values.address);

    dispatch(
      getSelectedAddress({
        postal_code: Number(getPostalCode(formik.values.areaNumber)),
        street_exact: streetName,
        number_exact: streetNumber,
        postal_code_area_name: getAreaName(formik.values.areaNumber),
      })
    ).then(({ payload }) => {
      if (!payload) return;
      const { address_id, address, areaNumber, district, county } =
        payload as SellerAddressI;
      dispatch(
        checkIsPostalCodeActive({
          id: postalCode,
          type_housing_id: formik.values.residenceType,
        })
      ).then((data) => {
        if (data.payload) {
          formik.setValues({
            ...formik.values,
            address,
            areaNumber,
            address_id,
            district,
            county,
          });

          return handleSaveForm && handleSaveForm();
        }

        setIsNotificationModalOpen(true);
        trackingFailedLead();
      });
    });
  };

  useEffect(() => {
    trackingSecondStep(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstLoad.current && prevState) {
      if (!isEqual(prevState, formik.values)) {
        trackingStartFillingSecondStep(formik.values);
        isFirstLoad.current = false;
      }
    }
  }, [prevState, formik.values]);

  // update validation after automatic deleting dot from field
  // NumberOfSqmTextInput (custom deleting)
  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.numberOfSqm]);

  return (
    <>
      {!isMobile && (
        <Text
          text={t("translation.bookingPage.secondStepTitle")}
          variant="title-medium"
          className="form_title"
        />
      )}
      <SellerAddress formik={formik} disabled={disabledStep} />
      <div className="row row-margin-bottom">
        <NumberOfSqmTextInput
          name="numberOfSqm"
          value={formik.values.numberOfSqm}
          errorMessage={
            formik.errors.numberOfSqm && formik.touched.numberOfSqm
              ? formik.errors.numberOfSqm
              : ""
          }
          classNameContainer="row_form_item"
          onBlur={formik.handleBlur}
          isAllowed={({ floatValue = 0 }) => floatValue <= 9999}
          onValueChange={({ value }) => handleChange(value)}
          disabled={disabledStep}
        />
        <Select
          controlProps={{
            label: t("translation.bookingPage.roomsNumber"),
            placeholder: t("translation.bookingPage.number"),
            name: "numberRooms",
            disabled: disabledStep,
            errorMessage:
              !formik.values.numberRooms &&
              formik.errors.numberRooms &&
              formik.touched.numberRooms
                ? formik.errors.numberRooms
                : "",
          }}
          handleClickSelectItem={handleSelect("numberRooms")}
          className="row_form_item"
          value={formik.values.numberRooms}
        >
          {numberOfRoomsSelect.map((item) => (
            <Select.SelectItem
              key={item.value}
              title={item.title}
              value={item.value}
            />
          ))}
        </Select>
      </div>
      {disabledStep && (
        <Alert severity="error" className="disabled_inputs_notification">
          {t("translation.alertMessage.disabledAddress")}
        </Alert>
      )}
      <div className="bootom_buttons_container">
        {!isMobile ? (
          <Button
            sizeVariant="Large"
            type="button"
            onClick={handleClickNextForm}
            className="next_button"
          >
            {t("translation.bookingPage.showAgents")}
            <NextArow className="button_icon" />
          </Button>
        ) : (
          <StickyButton
            sizeVariant="Large"
            type="button"
            onClick={handleClickNextForm}
            className="next_button"
          >
            {t("translation.bookingPage.showAgents")}
            <NextArow className="button_icon" />
          </StickyButton>
        )}
      </div>
      <AddressAbsentModal
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
      />
      <BlockLoader selector={getIsFetchingPostalCodeActiveStatusSelector} />
    </>
  );
};

export default FormPartTwo;
