import React, { useContext } from "react";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Link as ReactLink } from "react-router-dom";

import LinkLogo from "components/LinkLogo";
import Button from "components/common/Button";
import Text from "components/common/Text";
import Link from "components/common/Link";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { Role } from "store/models/profile";
import { MobileContext } from "context/mobileContext";
import { routesConstant } from "constants/appRoutesConstants";
import { setIsPreBookingHidden } from "store/reducers/booking";

const Footer: React.FC = () => {
  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAuth = useAppSelector(getIsAuthUserSelector);
  const { isMobile } = useContext(MobileContext);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(setIsPreBookingHidden(false));
  };

  const isAgent = roleUser === Role.AGENT;
  const isSeller = roleUser === Role.SELLER;

  const syntax = isMobile ? <>{","}&nbsp;</> : null;

  const privacyPolicy =
    !isAuth || isSeller
      ? process.env.REACT_APP_PRIVACY_POLICY_URL_SELLER_OR_NO_USER
      : process.env.REACT_APP_PRIVACY_POLICY_URL_AGENT;

  const userPolicy =
    !isAuth || isSeller
      ? process.env.REACT_APP_USER_POLICY_URL_SELLER_OR_NO_USER
      : process.env.REACT_APP_USER_POLICY_URL_AGENT;

  return (
    <footer className={`${isMobile ? "mobile_" : ""}footer`}>
      <div
        className={`${
          isMobile ? "mobile_container" : ""
        } page_container footer_container`}
      >
        <div className="top_section">
          <div className="logo_section">
            <LinkLogo className="logo" />
            {!isAgent && (
              <div className="footer_button">
                <Button
                  sizeVariant="Small"
                  fullWidth={true}
                  component={ReactLink}
                  to={routesConstant.booking}
                  onClick={handleClick}
                  startIcon={<VideocamOutlinedIcon />}
                >
                  {t("translation.footer.bookingButton")}
                </Button>
              </div>
            )}
          </div>
          <div className="right_block">
            {!isAgent && !isMobile && (
              <div className="navigation_section">
                <Text className="block_title" variant="paragraph-medium">
                  {t("translation.footer.about")}
                </Text>
                <Link
                  className="footer_nav_link"
                  native
                  href={process.env.REACT_APP_PUBLIC_URL_HOW_IT_WORKS}
                >
                  {t("translation.footer.works")}?
                </Link>
                <Link
                  className="footer_nav_link"
                  native
                  href={process.env.REACT_APP_PUBLIC_URL_ABOUT}
                >
                  {t("translation.footer.aboutHittaHem")}
                </Link>
                <Link
                  className="footer_nav_link"
                  native
                  href={process.env.REACT_APP_PUBLIC_URL_FAQ}
                >
                  {t("translation.footer.faq")}
                </Link>
              </div>
            )}
            <div className="contacts_section">
              <Text className="block_title" variant="paragraph-medium">
                {t("translation.footer.contacts")}
              </Text>
              <Text className="block_content" variant="paragraph-body">
                {t("translation.footer.hittaHemAB")}
                {syntax}
              </Text>
              <Text className="block_content" variant="paragraph-body">
                {t("translation.footer.street")}
                {syntax}
              </Text>
              <Text className="block_content" variant="paragraph-body">
                {t("translation.footer.city")}
              </Text>
            </div>
          </div>
        </div>
        {!isMobile ? (
          <div className="bootom_section">
            <div className="privicy_block">
              <Text
                className="block_content bottom_section_content"
                variant="paragraph-body"
              >
                © 2023 {t("translation.footer.author")}
              </Text>
              <Link
                className="footer_nav_link bottom_section_content"
                native
                target="_blank"
                rel="noopener noreferrer"
                href={privacyPolicy}
              >
                &#x2022; {t("translation.footer.privacy")}
              </Link>
              <Link
                className="footer_nav_link"
                native
                target="_blank"
                rel="noopener noreferrer"
                href={userPolicy}
              >
                &#x2022; {t("translation.footer.term")}
              </Link>
            </div>
            <div className="email_block">
              <Link
                className="footer_nav_link email_link"
                native
                href="mailto:info@hittahem.com"
              >
                <LocalPostOfficeOutlinedIcon className="email_link_icon" />
                {t("translation.footer.email")}
              </Link>
            </div>
          </div>
        ) : (
          <div className="bootom_section">
            <Text
              className="block_content bottom_section_content"
              variant="paragraph-body"
            >
              © 2023 {t("translation.footer.author")}
            </Text>
            <Link
              className="footer_nav_link email_link"
              native
              href="mailto:info@hittahem.com"
            >
              <LocalPostOfficeOutlinedIcon className="email_link_icon" />
              {t("translation.footer.email")}
            </Link>
            <div className="row">
              <Link
                className="footer_nav_link bottom_section_content"
                native
                href={privacyPolicy}
              >
                {t("translation.footer.privacy")}
              </Link>
              <Link className="footer_nav_link" native href={userPolicy}>
                &#x2022; &nbsp;{t("translation.footer.term")}
              </Link>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
