import { useFormik } from "formik";
//import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import { useAppDispatch } from "hooks/redux";

import { AgentForgotPasswordSchema } from "common/validationSchemas";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";
import { passwordReatoreSendEmail } from "store/asyncActions/profile";
import { useTranslation } from "react-i18next";

interface AgentForm {
  email: string;
}

const webhookUrl = process.env.REACT_APP_RESTORE_PASSWORD_LINK || "";

const AgentForgotPasswordForm = () => {
  const { isMobile, setViewType } = useAuthContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formik = useFormik<AgentForm>({
    initialValues: {
      email: "",
    },
    onSubmit({ email }) {
      dispatch(
        passwordReatoreSendEmail({
          email,
          webhook_url: webhookUrl,
        })
      )
        .unwrap()
        .then(() => {
          setViewType(AuthAgentViewType.FORGOT_PASSWORD_EMAIL_WAS_SENT);
        });
    },
    validationSchema: AgentForgotPasswordSchema(),
    validateOnBlur: true,
  });

  return (
    <>
      {!isMobile && (
        <>
          <Text
            text={t("translation.agentAuthPage.forgotPassword")}
            variant="title-medium"
            className="panel_item_title"
          />
          <Text
            text={t("translation.agentAuthPage.enterEmail")}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <form
        className="agent_signin_form_container"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div className={`${isMobile ? "mobile_form_group" : ""}`}>
          <TextInput
            label={t("translation.inputLabel.email")}
            placeholder={t("translation.inputPlaceholder.email")}
            classNameContainer="agent_sing_input last"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ""
            }
          />
          <div className="buttons_group">
            <Button
              sizeVariant={isMobile ? undefined : "Large"}
              type="button"
              className="login_btn back_button"
              variant="outlined"
              onClick={() => setViewType(AuthAgentViewType.AUTH_FORM)}
            >
              {t("translation.agentAuthPage.backButton")}
            </Button>
            <Button
              sizeVariant={isMobile ? undefined : "Large"}
              type="submit"
              className="login_btn submit_button"
            >
              {t("translation.agentAuthPage.sendButton")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AgentForgotPasswordForm;
