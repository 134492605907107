import React, { useEffect, useRef, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { getAgentDetailSelector } from "store/reducers/agents";
import { useMobileContext } from "context/mobileContext";
import Text from "components/common/Text";
import { getTypeHousing } from "store/asyncActions/typeHousing";

import rateIcon from "../../../assets/img/star_rate.svg";
import checkIcon from "../../../assets/img/green_chack.svg";

import BookingMeetingCalendar from "components/BookingMeetingCalendar";
import Button from "components/common/Button";
import { getRoleProfileSelector } from "store/reducers/profile";
import { Role } from "store/models/profile";

import AgentFoto from "./AgentFoto";
import ContactMeModal from "./ContactMeModal";
import { trackingAgentOpenContactMeForm } from "utils/facebookTracking";

interface AgentDetailPropI {
  isFetching?: boolean;
}

export enum DomainName {
  reco = "Reco",
  hitta = "HittaMaklare",
}

const AgentDetail: React.FC<AgentDetailPropI> = () => {
  const { id, email, agent_profile, first_name, last_name, max_rating } =
    useAppShallowSelector(getAgentDetailSelector);
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAgent = roleUser === Role.AGENT;

  // state to contol visibility of whole statistic container on mobile
  const [isHideStatisticInformation, setIsHideStatisticInformation] =
    useState(false);

  // states to control what display Show More or Show Less button
  const [isShownWhatClientsSay, setIsShownWhatClientsSay] = useState(false);
  const [isShownAchievement, setIsShownAchievement] = useState(false);
  const [isHideFullDescription, setIsHideFullDescription] = useState(true);

  // states to control visibility of Show More button, hide it if text isn't long enough
  const [
    isShowDescriptionMoreButtonByHeight,
    setIsShowDescriptionMoreButtonByHeight,
  ] = useState(false);
  const [
    isShowWhatClientsSayMoreButtonByHeight,
    setIsShowWhatClientsSayMoreButtonByHeight,
  ] = useState(false);
  const [
    isShowAchievementsMoreButtonByHeight,
    setIsShowAchievementsMoreButtonByHeight,
  ] = useState(false);

  // state for contact me modal
  const [isContactMeModalOpened, setIsContactMeModalOpened] = useState(false);

  const agentDescriptionRef = useRef<HTMLImageElement>(null);
  const clientReviewRef = useRef<HTMLImageElement>(null);
  const achievementsRef = useRef<HTMLImageElement>(null);

  const presentation =
    agent_profile?.presentation && agent_profile?.presentation.split("\n");

  const hideFullDescription = () => setIsHideFullDescription((prev) => !prev);

  const handleShowTextContainer = () =>
    setIsShownWhatClientsSay(!isShownWhatClientsSay);

  const handleShowAchievementTextContainer = () =>
    setIsShownAchievement(!isShownAchievement);

  const handleCloseContactMeModal = () => setIsContactMeModalOpened(false);

  const handleOpenContactMeModal = () => {
    trackingAgentOpenContactMeForm([
      { id: id, agentName: `${first_name} ${last_name}` },
    ]);
    setIsContactMeModalOpened(true);
  };

  useEffect(() => {
    if (
      agentDescriptionRef.current &&
      agentDescriptionRef.current?.clientHeight > 97
    ) {
      setIsShowDescriptionMoreButtonByHeight(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent_profile?.presentation, agentDescriptionRef.current]);

  useEffect(() => {
    if (clientReviewRef.current && clientReviewRef.current?.clientHeight > 69) {
      setIsShowWhatClientsSayMoreButtonByHeight(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent_profile?.what_clients_say, clientReviewRef.current]);

  useEffect(() => {
    if (achievementsRef.current && achievementsRef.current?.clientHeight > 50) {
      setIsShowAchievementsMoreButtonByHeight(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent_profile?.client_feedback, achievementsRef.current]);

  useEffect(() => {
    dispatch(getTypeHousing());
  }, [dispatch]);

  const AgentStatisticContainer = () => {
    return (
      <div
        className={`agent_statistic_container ${
          isMobile && isHideStatisticInformation ? "hidden" : ""
        }`}
      >
        <div className="agent_statistic_item">
          <Text className="agent_statistic_item_title">
            {t("translation.agentPresentationProfilePage.statistic")}
          </Text>

          <div className="rate_container">
            <img src={rateIcon} alt="rate star icon" />
            <Text
              text={
                max_rating?.rating
                  ? `${max_rating?.rating.toFixed(1)}/5.0`
                  : "-/5.0"
              }
              variant="label-small"
              className="rate_text"
            />
          </div>

          <div className="statistic_container">
            <Text
              text={max_rating?.reviews || "-"}
              className="statistic_value"
            />
            <Text
              text={` ${t(
                "translation.agentPresentationProfilePage.reviewOn"
              )} ${max_rating?.type_domain || "-"} `}
              className="statistic_description"
            />
          </div>
          <div className="statistic_container">
            <Text
              text={
                max_rating?.type_domain
                  ? max_rating?.type_domain === DomainName.reco
                    ? max_rating?.year_sales_last || "-"
                    : max_rating?.sales_last_six_months || "-"
                  : "-"
              }
              className="statistic_value"
            />{" "}
            <Text
              text={t(
                "translation.agentPresentationProfilePage.lastMonthSales",
                {
                  monthCount: max_rating?.type_domain
                    ? max_rating?.type_domain === DomainName.reco
                      ? 12
                      : 6
                    : "X",
                }
              )}
              className="statistic_description"
            />
          </div>
        </div>

        {!!agent_profile?.client_feedback?.length && (
          <div className="agent_statistic_item agent_achievements">
            <Text className="agent_statistic_item_title">
              {t("translation.agentPresentationProfilePage.accomplishments")}
            </Text>
            <div ref={achievementsRef} className="">
              {agent_profile.client_feedback.map((item, ind) => (
                <div className="statistic_item_box" key={ind}>
                  <img src={checkIcon} alt="check icon" />
                  <Text
                    text={item}
                    className={`statistic_description ${
                      !isShownAchievement ? "collapse" : ""
                    }`}
                  />
                </div>
              ))}
            </div>

            {isShowAchievementsMoreButtonByHeight && (
              <div
                onClick={handleShowAchievementTextContainer}
                className="collapse_button"
              >
                {isShownAchievement ? (
                  <>
                    {t("translation.agentPresentationProfilePage.hideText")}{" "}
                    <KeyboardArrowUpOutlinedIcon />
                  </>
                ) : (
                  <>
                    {t("translation.agentPresentationProfilePage.showMore")}{" "}
                    <KeyboardArrowDownOutlinedIcon />
                  </>
                )}
              </div>
            )}
          </div>
        )}

        {agent_profile?.what_clients_say && (
          <div className="agent_statistic_item client_review">
            <Text className="agent_statistic_item_title">
              {t("translation.agentPresentationProfilePage.clientReview")}
            </Text>
            <div ref={clientReviewRef}>
              <Text
                className={`agent_client_feedback ${
                  !isShownWhatClientsSay ? "collapse" : ""
                }`}
              >
                {agent_profile.what_clients_say}
              </Text>
              {isShowWhatClientsSayMoreButtonByHeight && (
                <div
                  onClick={handleShowTextContainer}
                  className="collapse_button"
                >
                  {isShownWhatClientsSay ? (
                    <>
                      {t("translation.agentPresentationProfilePage.hideText")}{" "}
                      <KeyboardArrowUpOutlinedIcon />
                    </>
                  ) : (
                    <>
                      {t("translation.agentPresentationProfilePage.showMore")}{" "}
                      <KeyboardArrowDownOutlinedIcon />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="agent_detail">
      <div className="agent_detail_left_container">
        <AgentFoto url={agent_profile?.image} />
        {!isMobile && <AgentStatisticContainer />}
      </div>

      <div className="agent_detail_right_section">
        <Text
          variant={isMobile ? "title-small" : "medium-header"}
          className="agent_name"
        >
          {`${first_name} ${last_name}`}
        </Text>
        <div className="agent_signature_section">
          <div className="agent_sig_foto_wrap">
            <img
              alt="agency signature foto"
              src={agent_profile?.agency?.logotype}
              className="agent_sig_foto"
            />
          </div>
          <Text variant="paragraph-body" className="agency_name">
            {agent_profile?.title?.toUpperCase()}
          </Text>
        </div>
        {presentation && (
          <>
            <div
              ref={agentDescriptionRef}
              className={`agent_description_section ${
                isHideFullDescription && isShowDescriptionMoreButtonByHeight
                  ? "hide_text"
                  : ""
              }`}
            >
              {presentation.map((sentence: string) => (
                <div key={uniqueId()}>
                  <Text variant="paragraph-body" text={sentence} />
                  <br />
                </div>
              ))}
            </div>
            {isShowDescriptionMoreButtonByHeight && (
              <div onClick={hideFullDescription} className="hide_button">
                <Text variant="paragraph-body" className="paragraph-medium">
                  {isHideFullDescription
                    ? t("translation.agentPresentationProfilePage.showMore")
                    : t("translation.agentPresentationProfilePage.hideText")}
                </Text>
                {isHideFullDescription ? (
                  <KeyboardArrowDownOutlinedIcon />
                ) : (
                  <KeyboardArrowUpOutlinedIcon />
                )}
              </div>
            )}
          </>
        )}

        <Button
          className="contact_me_button"
          disabled={isAgent}
          sizeVariant={isMobile ? "Small" : "Medium"}
          onClick={handleOpenContactMeModal}
        >
          {t("translation.agentPresentationProfilePage.contactDirectMeButton")}
        </Button>
        {isMobile && (
          <div className="mobile_statistic_container">
            <div className="statistic_wrapper">
              <div
                className="more_information_button"
                onClick={() =>
                  setIsHideStatisticInformation(!isHideStatisticInformation)
                }
              >
                <Text
                  className={`more_information_button_title ${
                    !isHideStatisticInformation ? "opened_info" : ""
                  }`}
                >
                  {t(
                    "translation.agentPresentationProfilePage.moreInformation"
                  )}
                </Text>
                {isHideStatisticInformation ? (
                  <KeyboardArrowDownOutlinedIcon />
                ) : (
                  <KeyboardArrowUpOutlinedIcon />
                )}
              </div>
              <AgentStatisticContainer />
            </div>
          </div>
        )}

        <BookingMeetingCalendar isAgentCalendar={true} />
      </div>

      <ContactMeModal
        isOpen={isContactMeModalOpened}
        agentEmails={[email]}
        agentsData={[{ id: id, agentName: `${first_name} ${last_name}` }]}
        onClickBack={handleCloseContactMeModal}
      />
    </div>
  );
};

export default AgentDetail;
