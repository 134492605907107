import React, { useCallback, createContext } from "react";
import isEqual from "lodash/isEqual";

import { CalendarContextValueI } from "./types";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import {
  setSelectedTimeSlot,
  getSelectedTimeSlotSelector,
  getSelectedHourSlotSelector,
  setSelectedHourSlot,
} from "store/reducers/booking";
import { HourSlot } from "store/models/booking";

export const Context = createContext<CalendarContextValueI>(
  {} as CalendarContextValueI
);

const CalendarContext: React.FC<{ isAgentCalendar: boolean }> = ({
  children,
  isAgentCalendar,
}) => {
  const dispatch = useAppDispatch();

  const selectedTimeSlot = useAppShallowSelector(getSelectedTimeSlotSelector);
  const selectedHourSlot = useAppShallowSelector(getSelectedHourSlotSelector);

  const handleClickTimeSlot = useCallback(
    (slotData) => {
      const _new = isEqual(selectedTimeSlot, slotData) ? null : slotData;
      dispatch(setSelectedTimeSlot(_new));
    },
    [dispatch, selectedTimeSlot]
  );

  const handleSelectHourSlot = useCallback(
    (hourSlot: HourSlot) => {
      dispatch(setSelectedHourSlot(hourSlot));
    },
    [dispatch]
  );

  const handleCloseOpenedHourSlots = useCallback(() => {
    dispatch(setSelectedHourSlot({ day: null, hour: null }));
    dispatch(setSelectedTimeSlot(null));
  }, [dispatch]);

  return (
    <Context.Provider
      value={{
        isAgentCalendar,
        handleClickTimeSlot,
        selectedTimeSlot,
        selectedHourSlot,
        handleSelectHourSlot,
        handleCloseOpenedHourSlots,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CalendarContext;
