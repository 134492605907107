import { useState, useCallback, useEffect } from "react";
import orderBy from "lodash/orderBy";

import { SortDirectionT } from "constants/sortConstants";

type FieldT<T> = T | undefined | "";

export function useSort<T, K extends keyof T>(
  data: T[],
  field: FieldT<K> = "",
  direction: SortDirectionT,
  isRemote: boolean,
  remoteFunc?: (f: FieldT<K>, d: SortDirectionT) => void
): {
  sortData: T[];
  handleSortData: (sortField: FieldT<K>, sortDirection: SortDirectionT) => void;
  direction: SortDirectionT;
  field: FieldT<K>;
} {
  const [sortData, setSortData] = useState<T[]>(data);
  const [sortParams, setSortParams] = useState<{
    direction: SortDirectionT;
    field: FieldT<K>;
  }>({
    direction,
    field,
  });

  const handleSortData = useCallback(
    (sortField: FieldT<K> | undefined, sortDirection: SortDirectionT): void => {
      setSortParams({ field: sortField, direction: sortDirection });
      if (isRemote) {
        if (typeof remoteFunc === "function") {
          remoteFunc(sortField, sortDirection);
        }
      } else {
        setSortData(orderBy(data, [sortField || ""], [sortDirection]));
      }
    },
    [data, isRemote, remoteFunc]
  );

  useEffect(() => {
    if (!isRemote && sortParams.field) {
      setSortData(orderBy(data, [sortParams.field], [sortParams.direction]));
    } else {
      setSortData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isRemote]);

  return {
    sortData,
    handleSortData,
    direction: sortParams.direction,
    field: sortParams.field,
  };
}
