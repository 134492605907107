import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AgentProfileAvatarSection from "components/AgentProfileAvatarSection";
import Text from "components/common/Text";
import InputLabelText from "components/common/InputLabelText";

import { calendarAuth } from "store/asyncActions/calendars";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getProfileIdSelector } from "store/reducers/profile";
import { getCalendarConnectUrlSelector } from "store/reducers/calendars";
import {
  checkConnectCalendar,
  connectTeam,
} from "store/asyncActions/calendars";
import { lsApi } from "utils/localStorageApi";
import { ProviderTypeT, ProviderType } from "store/models/calendars";
import { useMobileContext } from "context/mobileContext";

import CalendarConnectionSection from "./components/CalendarConnectionSection";

enum SearchParams {
  code = "code",
  state = "state",
  last_linked_profile_id = "last_linked_profile_id",
}

const AgentProfileConnectedCalendars = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getProfileIdSelector);
  const connectCalendarUrl = useAppSelector(getCalendarConnectUrlSelector);
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const calendarQueryParam = useRef(searchParams.get(SearchParams.code));

  const zoomQueryParam = useRef(
    searchParams.get(SearchParams.last_linked_profile_id)
  );

  useEffect(() => {
    if (
      calendarQueryParam.current &&
      typeof calendarQueryParam.current === "string"
    ) {
      const providerType = lsApi.get<ProviderTypeT>("redirectCalendarProvider");
      dispatch(checkConnectCalendar());
      if (providerType) {
        dispatch(
          calendarAuth({
            code: calendarQueryParam.current,
            agent_id: userId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            calendar_provider: providerType,
            isMobile: isMobile,
          })
        );
        setSearchParams("", { replace: true });
        lsApi.removeItem("redirectCalendarProvider");
      }
    } else {
      if (
        zoomQueryParam.current &&
        typeof zoomQueryParam.current === "string"
      ) {
        dispatch(
          connectTeam({
            last_linked_profile_id: zoomQueryParam.current,
            agent_id: userId,
            provider_name: ProviderType.TEAM,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(checkConnectCalendar());
          });
        setSearchParams("", { replace: true });
      } else {
        dispatch(checkConnectCalendar());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSearchParams, userId]);

  useEffect(() => {
    if (connectCalendarUrl) {
      window.open(connectCalendarUrl, "_parent");
    }
  }, [connectCalendarUrl]);

  useEffect(() => {
    const handleFocus = () => {
      dispatch(checkConnectCalendar());
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <div className="agent_profile_connect_calendar main_section_container">
      <AgentProfileAvatarSection />
      <div className="page_title_container">
        <InputLabelText
          isRequared
          label={t("translation.agentProfileCalendars.connectionLabel")}
          variant="title-small20"
          className={`${isMobile ? "mobile" : ""} title`}
        />
        {t("translation.agentProfileCalendars.connectionNotification")
          .split("\n")
          .map((sentence, ind) => (
            <React.Fragment key={ind}>
              <Text variant="paragraph-body" text={sentence} /> <br />
            </React.Fragment>
          ))}
      </div>
      <CalendarConnectionSection />
    </div>
  );
};

export default AgentProfileConnectedCalendars;
