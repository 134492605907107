import { useRef } from "react";

import Cropper, { ReactCropperElement } from "react-cropper";

import Button from "components/common/Button";
import Modal from "components/common/Modal";

import "cropperjs/dist/cropper.css";

interface CropImageProps {
  imgSrc?: string;
  isOpen: boolean;
  fileName: string;
  fileType: string;
  onUpload(file: FormData): void;
  onClose(): void;
}

const CropImage = ({
  imgSrc,
  isOpen,
  fileName,
  fileType,
  onClose,
  onUpload,
}: CropImageProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImageUrl = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      urltoFile(
        croppedImageUrl,
        `${new Date().valueOf()}${fileName}`,
        fileType
      ).then((generateFile) => {
        const formData = new FormData();
        formData.append("image", generateFile);
        onUpload(formData);
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      classNameContainer="crop_image_modal"
      handleClose={onClose}
    >
      <div className="crop_image_container">
        <Cropper
          style={{ height: 400, width: "100%" }}
          ref={cropperRef}
          zoomable={false}
          initialAspectRatio={1 / 1}
          aspectRatio={1 / 1}
          preview=".img-preview"
          src={imgSrc}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={false}
          autoCropArea={1}
          checkOrientation={false}
          guides={false}
        />
        <Button
          sizeVariant="Medium"
          className="bankId_button mobile"
          onClick={getCropData}
        >
          Crop Image
        </Button>
      </div>
    </Modal>
  );
};

export default CropImage;
