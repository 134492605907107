import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import {
  BankIdAuthInitialI,
  CheckCollectBankIdResponceI,
  UpdateQrCodeI,
  BankIdUserI,
} from "../models/bankId";
import {
  getBankIdAuthInitiate,
  checkCollectBankId,
  updateQrCode,
} from "../asyncActions/bankId";

interface BankIdStateI {
  isOpenBankIdQrCodeModal: boolean;
  isLoading: boolean;
  isRedirectCreateSeller: boolean;
  bankIDAuthInitiate: BankIdAuthInitialI;
  error: string;
  checkCollect: CheckCollectBankIdResponceI;
  bankIdUserPrefillData: BankIdUserI;
}

const initialState: BankIdStateI = {
  isOpenBankIdQrCodeModal: false,
  isRedirectCreateSeller: false,
  bankIDAuthInitiate: {
    order_ref: "",
    auto_start_token: "",
    qr_content: "",
  },
  isLoading: false,
  error: "",
  checkCollect: {
    hint_code: "outstandingTransaction",
    status: "pending",
    order_ref: "",
  },
  bankIdUserPrefillData: { given_name: "", personal_number: "", surname: "" },
};

export const getIsOpenBankIdQRCodeModalSelector = createTypedSelector(
  (state) => state.bankId.isOpenBankIdQrCodeModal
);

export const getBankIdAuthInitiateSelector = createTypedSelector(
  (state) => state.bankId.bankIDAuthInitiate
);

export const getCheckCollectSelector = createTypedSelector(
  (state) => state.bankId.checkCollect
);

export const getOrderRefForUpdateQrCode = createTypedSelector(
  (state) => state.bankId.checkCollect.order_ref
);

export const getIsRedirectCreateSellerSelector = createTypedSelector(
  (state) => state.bankId.isRedirectCreateSeller
);

export const getBankIdUserInfoSelector = createTypedSelector(
  (state) => state.bankId.bankIdUserPrefillData
);

const bankIdSlice = createSlice({
  name: "bankId",
  initialState,
  reducers: {
    setIsOpenBankIdQrCodeModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenBankIdQrCodeModal = action.payload;
    },
    clearBankIdState: (state) => {
      state.bankIDAuthInitiate = initialState.bankIDAuthInitiate;
      state.checkCollect = initialState.checkCollect;
    },
    clearPrefillData: (state) => {
      state.bankIdUserPrefillData = initialState.bankIdUserPrefillData;
      state.isRedirectCreateSeller = initialState.isRedirectCreateSeller;
    },
  },
  extraReducers: {
    [getBankIdAuthInitiate.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getBankIdAuthInitiate.fulfilled.type]: (
      state,
      action: PayloadAction<BankIdAuthInitialI>
    ) => {
      state.isLoading = false;
      state.isOpenBankIdQrCodeModal = true;
      state.error = "";
      state.bankIDAuthInitiate = action.payload;
    },
    [getBankIdAuthInitiate.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.bankIDAuthInitiate = initialState.bankIDAuthInitiate;
      state.error = action.payload;
    },
    [checkCollectBankId.fulfilled.type]: (
      state,
      action: PayloadAction<CheckCollectBankIdResponceI>
    ) => {
      state.checkCollect.status = action.payload.status;
      state.checkCollect.hint_code = action.payload.hint_code;
      state.isRedirectCreateSeller = !action.payload.jwt_pair;
      if (action.payload.bankid_user) {
        state.bankIdUserPrefillData = action.payload.bankid_user;
      }
    },
    [updateQrCode.fulfilled.type]: (
      state,
      action: PayloadAction<UpdateQrCodeI>
    ) => {
      state.bankIDAuthInitiate.qr_content = action.payload.qr_content;
    },
  },
});

export const {
  setIsOpenBankIdQrCodeModal,
  clearBankIdState,
  clearPrefillData,
} = bankIdSlice.actions;

export default bankIdSlice.reducer;
