import React, {
  useState,
  Children,
  NamedExoticComponent,
  useEffect,
} from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SvgIconProps } from "@mui/material/SvgIcon";
import isEqual from "lodash/isEqual";

import SelectControl from "./components/SelectControl";
import SelectItem from "./components/SelectItem";
import { TextInputPropsI } from "components/common/TextInput/TextInput";

export interface SelectItemValue {
  title: string;
  value?: unknown;
}

export interface SelectItemPropsI extends SelectItemValue {
  className?: string;
  onClick?(item: SelectItemValue, event?: React.MouseEvent<HTMLElement>): void;
  type?: "button" | "link";
  to?: string;
  icon?: React.FC<SvgIconProps>;
  selected?: boolean;
  endIcon?: React.FC<SvgIconProps>;
  disabled?: boolean;
}

export interface SelectControlPropsI extends TextInputPropsI {
  isOpen: boolean;
  withSearch?: boolean;
}

export interface SelectPropsI {
  className?: string;
  value?: string | number | boolean;
  handleClickSelectItem?: (item: SelectItemValue) => void;
  renderBody?: React.ReactElement | React.ReactElement[];
  renderControl?: React.FC<SelectControlPropsI>;
  isCloseAfterClick?: boolean;
  alignBody?: "left" | "right";
  controlProps?: Omit<
    TextInputPropsI,
    "value" | "contentEditable" | "type" | "onClick"
  >;
  withSearch?: boolean;
}

type SelectT = React.FunctionComponent<SelectPropsI> & {
  SelectItem: NamedExoticComponent<SelectItemPropsI>;
};

const Select: SelectT = ({
  className = "",
  value,
  handleClickSelectItem,
  children,
  isCloseAfterClick = true,
  renderBody = null,
  renderControl,
  alignBody = "left",
  controlProps = {},
  withSearch = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value);
  };

  let _label = "";

  Children.forEach(
    children as React.ReactElement<SelectItemPropsI>[],
    (child) => {
      if (!child) return;

      if (child?.type === SelectItem && isEqual(child.props.value, value)) {
        _label = child.props.title || "";
      }
    }
  );

  const handleOpenClick = () => {
    if (controlProps.disabled) return;
    setIsOpen((prev) => !prev);
  };

  const handleCloseClick = () => {
    setIsOpen(false);
  };

  const handleClickItem = (item: SelectItemValue) => {
    if (typeof handleClickSelectItem === "function") {
      handleClickSelectItem(item);
    }
    isCloseAfterClick && handleCloseClick();
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchStr("");
    }
  }, [isOpen]);

  const Control = renderControl || SelectControl;

  return (
    <ClickAwayListener onClickAway={handleCloseClick}>
      <div className={`select_container ${className}`}>
        <div className="select_control_container">
          <Control
            {...controlProps}
            onClick={handleOpenClick}
            isOpen={isOpen}
            value={isOpen && withSearch ? searchStr : _label}
            withSearch={withSearch}
            onChange={handleSearch}
          />
        </div>
        {isOpen && (
          <div className={`select_body ${alignBody}`}>
            {renderBody
              ? renderBody
              : Children.map(
                  children as React.ReactElement<SelectItemPropsI>[],
                  (child) => {
                    if (!child) return null;

                    if (child.type === SelectItem) {
                      return withSearch && searchStr ? (
                        child.props.title
                          .toLowerCase()
                          .includes(searchStr.toLowerCase()) && (
                          <SelectItem
                            {...child.props}
                            onClick={(item, event) => {
                              if (child.props.onClick) {
                                child.props.onClick(item, event);
                                isCloseAfterClick && handleCloseClick();
                              } else {
                                handleClickItem(item);
                              }
                            }}
                            selected={value === child.props.value}
                          />
                        )
                      ) : (
                        <SelectItem
                          {...child.props}
                          onClick={(item, event) => {
                            if (child.props.onClick) {
                              child.props.onClick(item, event);
                              isCloseAfterClick && handleCloseClick();
                            } else {
                              handleClickItem(item);
                            }
                          }}
                          selected={value === child.props.value}
                        />
                      );
                    }
                    return null;
                  }
                )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

Select.SelectItem = SelectItem;

export default Select;
