import { Route } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";
import AuthPage from "pages/AuthPage";
import AuthCreatePage from "pages/AuthCreatePage";

export const authRoutes = (
  <Route path={routesConstant.authAbsolutely}>
    <Route index element={<AuthPage />} />
    <Route
      path={routesConstant.authCreateAbsolutely}
      element={<AuthCreatePage />}
    />
  </Route>
);
