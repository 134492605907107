import React from "react";

import InputLabelText from "components/common/InputLabelText";
import ToggleButtonGroupStyled from "components/common/ToggleButtonGroup";
import ToggleButton from "components/common/ToggleButton";
import { useTypeHousing } from "hooks/useTypeHousing";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getAgentTypeHousingSelector,
  getIsFetchingUpdateProfileSelector,
} from "store/reducers/profile";
import { updateAgentTypeHousing } from "store/asyncActions/profile";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";

const ResidenceType = () => {
  const { data } = useTypeHousing();
  const { isMobile } = useMobileContext();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const agentTypeHousingSelect = useAppShallowSelector(
    getAgentTypeHousingSelector
  );

  const isFetchingUpdate = useAppSelector(getIsFetchingUpdateProfileSelector);

  const handleFormat = (
    _event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    dispatch(updateAgentTypeHousing({ type_housing_ids: newFormats }));
  };

  return (
    <div className={`${isMobile ? "mobile" : ""} residence_type_section`}>
      <InputLabelText
        label={`${t("translation.inputLabel.residence")} (${t(
          "translation.agentProfileFocusArea.chosenColor"
        )})`}
        isRequared
      />
      <ToggleButtonGroupStyled
        value={agentTypeHousingSelect}
        onChange={handleFormat}
        disabled={isFetchingUpdate}
      >
        {data.map((item) => (
          <ToggleButton value={item.id} key={item.id} isMobile={isMobile}>
            {item.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroupStyled>
    </div>
  );
};

export default ResidenceType;
