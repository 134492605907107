import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { defaultNS } from "i18n";
import { sv, enUS } from "date-fns/locale";
import { format, utcToZonedTime } from "date-fns-tz";

import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { routesConstant } from "constants/appRoutesConstants";
import { timeZoneSV } from "constants/timeZoneConstants";
import { ChosenAgentI } from "store/models/booking";
import { cancelReserveTimeSlot } from "store/asyncActions/booking";
import { useAppDispatch } from "hooks/redux";
import { useMobileContext } from "context/mobileContext";

import Text from "components/common/Text";
import Button from "components/common/Button";

interface SelectAgentItemPropsI {
  agentsData: ChosenAgentI;
}

const AgentItem: React.FC<SelectAgentItemPropsI> = ({ agentsData }) => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id = "" } = agentsData;

  const handleCancelEvent = () => {
    dispatch(
      cancelReserveTimeSlot({
        id,
        startDate: agentsData.selectedTimeSlot.startDate,
      })
    );
  };

  const handleChangeTime = () => {
    dispatch(
      cancelReserveTimeSlot({
        id,
        startDate: agentsData.selectedTimeSlot.startDate,
      })
    ).then(() => {
      navigate(`${routesConstant.agentPresentationProfile}/${id}`, {
        state: {
          backRoute: routesConstant.booking,
          changeTime: true,
        },
      });
    });
  };

  const startDate = utcToZonedTime(
    agentsData.selectedTimeSlot.startDate,
    timeZoneSV
  );
  const endDate = utcToZonedTime(
    agentsData.selectedTimeSlot.endDate,
    timeZoneSV
  );

  const lang = defaultNS === "en" ? enUS : sv;

  const eventDateText = format(startDate, "EEE, d MMM", { locale: lang });
  const startTime = format(startDate, "H:mm");
  const endTime = format(endDate, "H:mm");

  if (isMobile)
    return (
      <div className="agent_card">
        <div className="wrapper">
          <div className="agent_card_foto">
            {agentsData.fotoUrl ? (
              <img
                src={agentsData.fotoUrl}
                alt="Agent foto"
                className="agent_foto"
              />
            ) : (
              <AccountCircleIcon className="agent_foto no_agent_foto" />
            )}
          </div>
          <div className="main_data">
            <div className="agent_card_name_section">
              <Text variant="label-bold" className="agent_card_name">
                {`${agentsData.firstName} ${agentsData.lastName}`}
              </Text>
              <div className="agent_card_agency_logo">
                <img
                  src={agentsData.agencyLogo}
                  alt="Agency logo"
                  className="agency_logo"
                />
              </div>
              <IconButton className="delete_button" onClick={handleCancelEvent}>
                <CancelIcon />
              </IconButton>
            </div>
            <div className="agent_card_schedule_section">
              <div className="date_wrapper">
                <Text className="event_date" variant="paragraph-body-bold">
                  <EventIcon className="event_icon" />
                  {eventDateText}
                </Text>
                <Text className="event_date" variant="paragraph-body-bold">
                  <ScheduleIcon className="event_icon" />
                  {`${startTime} - ${endTime}`}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="outlined"
          sizeVariant="Small"
          onClick={handleChangeTime}
        >
          {t("translation.calendarPage.changeTime")}
        </Button>
      </div>
    );

  return (
    <div className="agent_card">
      <div className="wrapper">
        <div className="agent_card_foto">
          {agentsData.fotoUrl ? (
            <img
              src={agentsData.fotoUrl}
              alt="Agent foto"
              className="agent_foto"
            />
          ) : (
            <AccountCircleIcon className="agent_foto no_agent_foto" />
          )}
        </div>
        <div className="agent_card_name_section">
          <Text variant="label-bold" className="agent_card_name">
            {`${agentsData.firstName} ${agentsData.lastName}`}
          </Text>
          <div className="agent_card_agency_logo">
            <img
              src={agentsData.agencyLogo}
              alt="Agency logo"
              className="agency_logo"
            />
          </div>
          <IconButton className="delete_button" onClick={handleCancelEvent}>
            <CancelIcon />
          </IconButton>
        </div>
        <div className="agent_card_schedule_section">
          <div className="date_wrapper">
            <Text className="event_date" variant="paragraph-body-small">
              <EventIcon className="event_icon" />
              {eventDateText}
            </Text>
            <Text className="event_date" variant="paragraph-body-small">
              <ScheduleIcon className="event_icon" />
              {`${startTime} - ${endTime}`}
            </Text>
          </div>
          <Button
            endIcon={<ScheduleIcon />}
            variant="outlined"
            sizeVariant="Small"
            onClick={handleChangeTime}
          >
            {t("translation.calendarPage.changeTime")}
          </Button>
        </div>
      </div>
      <IconButton className="delete_button" onClick={handleCancelEvent}>
        <CancelIcon />
      </IconButton>
    </div>
  );
};

export default AgentItem;
