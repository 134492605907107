import React, { memo } from "react";
import { Link, LinkProps, useMatch } from "react-router-dom";

interface LinkPropsI extends Omit<LinkProps, "to"> {
  className?: string;
  variant?:
    | "navigation-link"
    | "text-link-large"
    | "text-link-medium"
    | "link-paragraph";
  to?: string;
  native?: boolean;
  href?: string;
  isMatchActive?: boolean;
  matchRoutes?: string;
}

const LinkCustom: React.FC<LinkPropsI> = ({
  className = "",
  children,
  variant = "text-link-medium",
  native = false,
  isMatchActive = false,
  to,
  href,
  ...rest
}) => {
  const _to = href || to || "*";

  const match = useMatch(_to);

  const activeClassName = match && isMatchActive ? "active" : "";

  if (native)
    return (
      <a
        className={`custom_link ${variant} ${activeClassName} ${className}`}
        href={_to}
        {...rest}
      >
        {children}
      </a>
    );

  return (
    <Link
      className={`custom_link ${variant} ${activeClassName} ${className}`}
      to={_to}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default memo(LinkCustom);
