import { useAppShallowSelector } from "hooks/redux";
import React, { memo } from "react";
import groupBy from "lodash/groupBy";
import { parseISO } from "date-fns";

import { TimeSlot } from "store/models/agent";
import { getAgentsTimeSlotsByDay } from "store/reducers/booking";
import HourSlotItem from "./HourSlotItem";

export interface CalendarColumnProps {
  day: number;
  isOneSelected?: boolean;
}

const CalendarColumn: React.FC<CalendarColumnProps> = ({ day, isOneSelected }) => {
  const agentsDayTimeSlots = useAppShallowSelector(
    getAgentsTimeSlotsByDay(day)
  );

  const dayTimeSlotsGroupedByHours: { [key: string]: TimeSlot[] } = groupBy(
    agentsDayTimeSlots,
    ({ start_time }) => {
      return parseISO(start_time).getUTCHours();
    }
  );

  return (
    <div className="calendar_column calendar_column_body">
      {Object.entries(dayTimeSlotsGroupedByHours).map(
        ([hour, availableTimeSlots]) => {
          return (
            <HourSlotItem
              key={hour}
              hour={hour}
              availableTimeSlots={availableTimeSlots}
              day={day}
              isOneSelected={isOneSelected}
            />
          );
        }
      )}
    </div>
  );
};

export default memo(CalendarColumn);
