import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  UsersI,
  CreateUserDataI,
  CreateUserResponseI,
  EditUserDataT,
} from "store/models/profile";

export class Profile extends CRUD {
  aboutSelf(): AxiosPromise<UsersI> {
    return this.request({
      url: `${this.url}/about_self/`
    });
  }
  createUser(data: CreateUserDataI): AxiosPromise<CreateUserResponseI> {
    return this.request({
      url: `${this.url}/`,
      method: "POST",
      data,
    });
  }

  editUser({ id, ...data }: EditUserDataT): AxiosPromise<UsersI> {
    return this.request({
      url: `${this.url}/${id}/`,
      method: "PATCH",
      data,
    });
  }
}

export default function profile(request: AxiosInstance) {
  return new Profile({
    url: "/user",
    request,
  });
}
