import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

import TextInput from "components/common/TextInput";
import PhoneTextInput from "components/PhoneTextInput";
import Button from "components/common/Button";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getIsFetchingUpdateProfileSelector,
  getProfileSelector,
} from "store/reducers/profile";
import { editClientProfile } from "store/asyncActions/profile";
import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { ClientGeneralProfileSchema } from "common/validationSchemas";
import { getChosenAgentsLengthSelector } from "store/reducers/booking";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";
import { showAlerts } from "store/reducers/alerts";

interface ClientProfileFormI {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const ClientGeneralProfilePage = () => {
  const dispatch = useAppDispatch();

  const profile = useAppShallowSelector(getProfileSelector);
  const isFetching = useAppSelector(getIsFetchingUpdateProfileSelector);
  const isAgentsInBlueBox = Boolean(useSelector(getChosenAgentsLengthSelector));

  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const _initValues = {
    firstName: profile.first_name,
    lastName: profile.last_name,
    email: profile.email,
    phone: profile.phone_number,
  };

  const formik = useFormik<ClientProfileFormI>({
    initialValues: _initValues,
    onSubmit(values, formikHelpers) {
      dispatch(getAgentsListBlueBox()).then((data: any) => {
        if (data.payload?.length) {
          dispatch(
            showAlerts({
              type: "error",
              title: "Error",
              message: t("translation.alertMessage.disabledAddress"),
              autoHideDuration: 10000,
            })
          );

          return;
        }
        dispatch(
          editClientProfile({
            id: profile.id,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone_number: values.phone,
          })
        );
        formikHelpers.resetForm({ values });
      });
    },
    validationSchema: ClientGeneralProfileSchema(),
    validateOnBlur: true,
  });

  const handleChange = (e: any) =>
    formik.setFieldValue("phone", e.target.value.replace(/[ _]+/g, ""));

  return (
    <form
      className={`client_general_profile_form client_profile_form ${
        isMobile ? "mobile" : ""
      }`}
      onSubmit={formik.handleSubmit}
      onBlur={formik.handleBlur}
      autoComplete="off"
    >
      <div className="form_row name">
        <TextInput
          label={t("translation.inputLabel.firstName")}
          name="firstName"
          placeholder={t("translation.inputPlaceholder.firstName")}
          isRequared
          disabled={isAgentsInBlueBox}
          value={formik.values.firstName}
          errorMessage={formik.touched.firstName ? formik.errors.firstName : ""}
          onChange={formik.handleChange}
        />
        <TextInput
          label={t("translation.inputLabel.lastName")}
          name="lastName"
          placeholder={t("translation.inputPlaceholder.lastName")}
          disabled={isAgentsInBlueBox}
          isRequared
          value={formik.values.lastName}
          errorMessage={formik.touched.lastName ? formik.errors.lastName : ""}
          onChange={formik.handleChange}
        />
      </div>
      <div className="form_row">
        <TextInput
          label={t("translation.inputLabel.email")}
          name="email"
          placeholder={t("translation.inputPlaceholder.email")}
          disabled
          isRequared
          value={formik.values.email}
          errorMessage={formik.touched.email ? formik.errors.email : ""}
          onChange={formik.handleChange}
        />
      </div>
      <div className="form_row phone">
        <PhoneTextInput
          name="phone"
          value={formik.values.phone}
          disabled={isAgentsInBlueBox}
          errorMessage={formik.touched.phone ? formik.errors.phone : ""}
          onChange={handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      {isAgentsInBlueBox && (
        <Alert severity="error" className="disabled_inputs_notification">
          {t("translation.alertMessage.disabledAddress")}
        </Alert>
      )}
      {!isMobile ? (
        <Button
          type="submit"
          sizeVariant="Large"
          name="saveProfile"
          showLoadingIndicator={isFetching}
          disabled={isFetching || !formik.dirty}
        >
          {t("translation.clientProfilePage.saveButton")}
        </Button>
      ) : (
        <StickyButton
          className="label-bold"
          type="submit"
          name="submit_button"
          showLoadingIndicator={isFetching}
          disabled={isFetching || !formik.dirty}
        >
          {t("translation.clientProfilePage.saveButton")}
        </StickyButton>
      )}
    </form>
  );
};

export default ClientGeneralProfilePage;
