import { useFormik } from "formik";
//import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import { changePassword } from "store/asyncActions/profile";
import { useAppDispatch } from "hooks/redux";
import { AgentUpdatePasswordSchema } from "common/validationSchemas";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";

interface AgentForm {
  password: string;
  confirmPassword: string;
}

const AgentUpdatePassword = () => {
  const { isMobile, setViewType, passwordToken } = useAuthContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formik = useFormik<AgentForm>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit({ password }) {
      if (passwordToken) {
        dispatch(changePassword({ password, token: passwordToken }))
          .unwrap()
          .then(() => {
            setViewType(AuthAgentViewType.PASSWORD_WAS_UPDATED_SUCCESS);
          })
          .catch(() => {
            setViewType(AuthAgentViewType.PASSWORD_WAS_UPDATED_FAILED);
          });
      }
    },
    validationSchema: AgentUpdatePasswordSchema(),
    validateOnBlur: true,
  });

  return (
    <>
      {!isMobile && (
        <>
          <Text
          text={t("translation.agentAuthPage.updatePassword")}
          variant="title-medium"
          className="panel_item_title"
          />
          <Text
            text={t("translation.agentAuthPage.createPassword")}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <form
        className="agent_signin_form_container"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div className={`${isMobile ? "mobile_form_group" : ""}`}>
          <TextInput
            label={t("translation.inputLabel.newPassword")}
            placeholder={t("translation.inputPlaceholder.newPassword")}
            classNameContainer="agent_sing_input last"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ""
            }
          />
          <TextInput
            label={t("translation.inputLabel.confirmPassword")}
            placeholder={t("translation.inputPlaceholder.newPassword")}
            classNameContainer="agent_sing_input last"
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.confirmPassword && formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ""
            }
          />

          <div className="buttons_group">
            <Button
              sizeVariant={isMobile ? "Medium" : "Large"}
              type="submit"
              className="login_btn"
            >
              {t("translation.agentAuthPage.update")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AgentUpdatePassword;
