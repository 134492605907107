import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import sv from "./sv.json";

const language = process.env.REACT_APP_CUSTOM_NODE_ENV === "development" ? "en" : "sv";

export const defaultNS = language;
export const resources = {
  en: {
    en,
    sv,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  ns: ["en", "sv"],
  defaultNS,
  resources,
});
