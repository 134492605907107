import React from "react";

import { IconTypesInterface } from "./iconTypesInterface";

const BankidLogo: React.FC<IconTypesInterface> = ({ className = "" }) => {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.4328 19.3437L14.7034 11.2367C14.1847 11.2367 13.3163 11.2367 13.3163 11.2367C12.681 11.2367 11.8475 10.8928 11.5853 10.2284C11.4979 9.99528 11.323 9.22013 12.3954 8.46829C12.7684 8.20602 13.0307 7.92043 13.0598 7.6873C13.1181 7.45418 13.0598 7.25602 12.885 7.08117C12.681 6.87718 12.2206 6.7373 11.6436 6.7373C10.6644 6.7373 9.97086 7.3143 9.91258 7.71645C9.8543 8.03117 10.1166 8.2643 10.3147 8.41C10.9209 8.87043 11.0666 9.53485 10.6877 10.1701C10.2856 10.8054 9.44632 11.2367 8.5546 11.2367C8.5546 11.2367 7.66289 11.2367 7.13835 11.2367C7.02178 12.0177 5.11595 24.1345 4.97607 25.0845H12.5994C12.6285 24.6824 12.9724 22.3744 13.4328 19.3437Z"
        fill="#459BBE"
      />
      <path
        d="M23.6206 4.1377H15.8865L14.8491 10.6886H16.178C16.9007 10.6886 17.5942 10.3739 17.8798 9.87849C17.9672 9.73279 18.0255 9.58708 18.0255 9.47635C18.0255 9.21408 17.8507 8.98678 17.6525 8.87021C17.1338 8.4972 17.0464 8.08923 17.0464 7.80365C17.0464 7.74536 17.0464 7.68708 17.0464 7.65794C17.1629 6.96438 18.0838 6.21254 19.3252 6.21254C20.077 6.21254 20.6249 6.38739 20.9688 6.70211C21.2602 6.99352 21.4 7.39567 21.3126 7.79782C21.1961 8.28739 20.7065 8.68954 20.4209 8.89352C19.6691 9.41224 19.7565 9.87267 19.8148 10.0184C19.9605 10.4205 20.5666 10.6828 21.027 10.6828H23.0494C25.7887 10.7119 27.2632 11.9533 26.7737 14.8966C26.3424 17.6067 24.2617 18.7898 21.7847 18.819L20.7473 25.0785H22.1927C28.3356 25.0785 33.3304 21.127 34.3387 14.9199C35.5626 7.22665 30.6611 4.1377 23.6206 4.1377Z"
        fill="#235971"
      />
      <path
        d="M4.55069 27.8818H7.66879C8.99763 27.8818 9.31235 28.5463 9.22493 29.1815C9.1375 29.6711 8.82278 30.0499 8.21664 30.2772C8.96848 30.5686 9.25407 30.9999 9.1375 31.6935C8.9918 32.5619 8.24579 33.1913 7.26082 33.1913H3.71143L4.55069 27.8818ZM6.5964 30.1024C7.20253 30.1024 7.48812 29.7877 7.5464 29.4088C7.60468 29.0067 7.42983 28.7153 6.8237 28.7153H6.27584L6.04272 30.1024H6.5964ZM6.25253 32.4104C6.88781 32.4104 7.23167 32.1481 7.3191 31.6294C7.37738 31.1981 7.14425 30.9067 6.53812 30.9067H5.93198L5.69885 32.4045H6.25253V32.4104Z"
        fill="#235971"
      />
      <path
        d="M13.4622 33.2498C12.652 33.3081 12.2499 33.2206 12.075 32.8768C11.6437 33.139 11.1833 33.2789 10.6588 33.2789C9.73792 33.2789 9.41737 32.8185 9.50479 32.2706C9.53393 32.0084 9.67964 31.7811 9.93608 31.5771C10.4256 31.1458 11.6962 31.0875 12.1858 30.7378C12.2149 30.3648 12.0692 30.2191 11.6088 30.2191C11.0609 30.2191 10.6296 30.394 9.84865 30.9127L10.0235 29.7004C10.6879 29.2108 11.3232 29.0068 12.0692 29.0068C13.0192 29.0068 13.8585 29.3798 13.6836 30.4231L13.5088 31.6062C13.4505 32.0084 13.4505 32.1541 13.9109 32.1541L13.4622 33.2498ZM12.0809 31.4314C11.6496 31.6936 10.8395 31.6645 10.752 32.2124C10.7229 32.4746 10.8686 32.6728 11.125 32.6728C11.3815 32.6728 11.702 32.5562 11.9352 32.3814C11.906 32.294 11.9352 32.1774 11.9643 32.0084L12.0809 31.4314Z"
        fill="#235971"
      />
      <path
        d="M14.9947 29.0944H16.6092L16.5217 29.6423C17.0404 29.211 17.4135 29.0361 17.938 29.0361C18.8297 29.0361 19.2668 29.584 19.1211 30.5048L18.684 33.2208H17.0696L17.4135 30.9711C17.4717 30.569 17.3552 30.365 17.0404 30.365C16.7782 30.365 16.5509 30.5107 16.3177 30.7963L15.9447 33.1917H14.3303L14.9947 29.0944Z"
        fill="#235971"
      />
      <path
        d="M20.386 27.8818H22.0063L21.6041 30.5045L23.1602 29.0883H25.1535L23.1602 30.8484L24.7455 33.2205H22.6998L21.4584 31.3147H21.4293L21.1379 33.2205H19.5234L20.386 27.8818Z"
        fill="#235971"
      />
      <path
        d="M4.55069 27.8818H7.66879C8.99763 27.8818 9.31235 28.5463 9.22493 29.1815C9.1375 29.6711 8.82278 30.0499 8.21664 30.2772C8.96848 30.5686 9.25407 30.9999 9.1375 31.6935C8.9918 32.5619 8.24579 33.1913 7.26082 33.1913H3.71143L4.55069 27.8818ZM6.5964 30.1024C7.20253 30.1024 7.48812 29.7877 7.5464 29.4088C7.60468 29.0067 7.42983 28.7153 6.8237 28.7153H6.27584L6.04272 30.1024H6.5964ZM6.25253 32.4104C6.88781 32.4104 7.23167 32.1481 7.3191 31.6294C7.37738 31.1981 7.14425 30.9067 6.53812 30.9067H5.93198L5.69885 32.4045H6.25253V32.4104Z"
        fill="#235971"
      />
      <path
        d="M13.4622 33.2498C12.652 33.3081 12.2499 33.2206 12.075 32.8768C11.6437 33.139 11.1833 33.2789 10.6588 33.2789C9.73792 33.2789 9.41737 32.8185 9.50479 32.2706C9.53393 32.0084 9.67964 31.7811 9.93608 31.5771C10.4256 31.1458 11.6962 31.0875 12.1858 30.7378C12.2149 30.3648 12.0692 30.2191 11.6088 30.2191C11.0609 30.2191 10.6296 30.394 9.84865 30.9127L10.0235 29.7004C10.6879 29.2108 11.3232 29.0068 12.0692 29.0068C13.0192 29.0068 13.8585 29.3798 13.6836 30.4231L13.5088 31.6062C13.4505 32.0084 13.4505 32.1541 13.9109 32.1541L13.4622 33.2498ZM12.0809 31.4314C11.6496 31.6936 10.8395 31.6645 10.752 32.2124C10.7229 32.4746 10.8686 32.6728 11.125 32.6728C11.3815 32.6728 11.702 32.5562 11.9352 32.3814C11.906 32.294 11.9352 32.1774 11.9643 32.0084L12.0809 31.4314Z"
        fill="#235971"
      />
      <path
        d="M14.9947 29.0944H16.6092L16.5217 29.6423C17.0404 29.211 17.4135 29.0361 17.938 29.0361C18.8297 29.0361 19.2668 29.584 19.1211 30.5048L18.684 33.2208H17.0696L17.4135 30.9711C17.4717 30.569 17.3552 30.365 17.0404 30.365C16.7782 30.365 16.5509 30.5107 16.3177 30.7963L15.9447 33.1917H14.3303L14.9947 29.0944Z"
        fill="#235971"
      />
      <path
        d="M20.386 27.8818H22.0063L21.6041 30.5045L23.1602 29.0883H25.1535L23.1602 30.8484L24.7455 33.2205H22.6998L21.4584 31.3147H21.4293L21.1379 33.2205H19.5234L20.386 27.8818Z"
        fill="#235971"
      />
      <path
        d="M26.1034 27.8818H27.9801L27.1409 33.1913H25.2642L26.1034 27.8818Z"
        fill="#459BBE"
      />
      <path
        d="M28.8719 27.8818H31.5237C33.5694 27.8818 34.1756 29.3797 33.9774 30.6211C33.8026 31.8334 32.8234 33.2205 31.0342 33.2205H28.0618L28.8719 27.8818ZM30.6029 31.9499C31.4946 31.9499 32.0191 31.5186 32.159 30.5628C32.2756 29.8693 32.0424 29.1757 31.0633 29.1757H30.5446L30.1133 31.9732L30.6029 31.9499Z"
        fill="#459BBE"
      />
    </svg>
  );
};

export default BankidLogo;
