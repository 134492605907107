import React, { useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { closeAlert, getAlertsStateSelector } from "store/reducers/alerts";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";

const AlertBox = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    type,
    message,
    autoHideDuration,
    persist = false,
    isOpen,
  } = useAppShallowSelector(getAlertsStateSelector);

  const dispatch = useAppDispatch();
  const { key } = useLocation();

  const action = (snackbarId?: string | number): ReactNode => (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  useEffect(() => {
    closeSnackbar();
  }, [closeSnackbar, key]);

  useEffect(() => {
    if (isOpen) {
      enqueueSnackbar(message, {
        variant: type,
        persist: persist,
        autoHideDuration: persist ? undefined : autoHideDuration,
        action,
      });
      dispatch(closeAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    autoHideDuration,
    enqueueSnackbar,
    message,
    persist,
    type,
    isOpen,
  ]);

  return null;
};

export default AlertBox;
