import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";
import { AdminLoginParamsI, RefreshAdminLoginParamsI } from "store/models/profile";
import { deleteToken, setAccesToken } from "utils/auth";
import { profileAboutSelf } from "./profile";

export const getAgentEmails = createAsyncThunk(
  "login/getAgentEmails",
  async (params: AdminLoginParamsI, { rejectWithValue }) => {
    try {
      const { data } = await api.login.adminLogin(params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAdminLogin = createAsyncThunk(
  "login/refreshAdminLogin",
  async (body: RefreshAdminLoginParamsI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.login.refreshAdminLogin(body);
      if (data) {
        deleteToken();
        setAccesToken(data.access);
        dispatch(profileAboutSelf());
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
