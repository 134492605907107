import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { getAgentPostalCode } from "store/asyncActions/agents";
import { getPostalCodesAgentFocusAreaSelector } from "store/reducers/agents";
import InputLabelText from "components/common/InputLabelText";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { useMobileContext } from "context/mobileContext";

import ChoosenAreasItem from "./ChoosenAreasItem";

const ChoosenAreas = () => {
  const dispatch = useAppDispatch();
  const postalCodes = useAppShallowSelector(
    getPostalCodesAgentFocusAreaSelector
  );

  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAgentPostalCode());
  }, []);

  if (!postalCodes.length) return null;

  return (
    <div className={`${isMobile ? "mobile" : ""} choosen_areas_section`}>
      <InputLabelText
        label={t("translation.agentProfileFocusArea.chooseArea")}
      />
      <div className="choosen_areas">
        {postalCodes.map((item, ind) => (
          <ChoosenAreasItem key={ind} {...item} />
        ))}
      </div>
    </div>
  );
};

export default ChoosenAreas;
