import React, { memo } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";

import Text from "components/common/Text";

interface CardPropsI {
  className?: string;
  title: string;
  level?: number;
  isActive?: boolean;
  stepLevel?: number;
  checked?: boolean;
  handleActiveCard?(id: number): void;
}

const Card: React.FC<React.PropsWithChildren<CardPropsI>> = ({
  title,
  children,
  isActive,
  level,
  stepLevel,
  checked,
  handleActiveCard,
}) => {
  const className = isActive ? "active" : "";

  const openCard = () => {
    if (handleActiveCard && level !== undefined) {
      handleActiveCard(level);
    }
  };

  if (stepLevel)
    return (
      <div className="card_wrapper stepper">
        {!isActive && (
          <div className={`button_container ${className}`} onClick={openCard}>
            <Text className={`label-small title ${className}`} text={title} />
            <div className="check_wrapper">
              {checked && <CheckIcon className="icon" />}
              <Text
                className={`label-small title ${checked ? "checked" : ""}`}
                text={`Step ${stepLevel}`}
              />
            </div>
          </div>
        )}
        <div className={`card_content ${className}`}>{children}</div>
      </div>
    );

  return (
    <div className="card_wrapper">
      <div className={`button_container ${className}`} onClick={openCard}>
        <Text className={`label-small title ${className}`} text={title} />
        {isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      <div className={`card_content ${className}`}>{children}</div>
    </div>
  );
};

export default memo(Card);
