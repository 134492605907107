import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppShallowSelector,
  useAppSelector,
} from "hooks/redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "components/common/Button";
import AutocompleteInput from "components/common/AutocompleteInput";

import { AdminSignInSchema } from "common/validationSchemas";
import { useMobileContext } from "context/mobileContext";
import { getAgentEmails, refreshAdminLogin } from "store/asyncActions/login";
import {
  getAgentEmailListSelector,
  getIsNextAgentEmailListSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { AgentsEmailList } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";

interface AdminSelectAgentFormT {
  email: string;
}

const SecondStepAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const isNext = useAppSelector(getIsNextAgentEmailListSelector);
  const emailList = useAppShallowSelector(getAgentEmailListSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);

  const [limit, setLimit] = useState(20);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState<boolean>(false);

  const formik = useFormik<AdminSelectAgentFormT>({
    initialValues: {
      email: "",
    },
    onSubmit({ email }) {
      dispatch(refreshAdminLogin({ email: email }))
        .unwrap()
        .then(() => {
          if (roleUser === 2) return navigate(routesConstant.agentMyMeeting);
          if (roleUser === 3) return navigate(routesConstant.booking);
        });
    },
    validationSchema: AdminSignInSchema().pick(["email"]),
    validateOnBlur: true,
  });

  const handlePickOptionItem = (option: AgentsEmailList) =>
    formik.setFieldValue("email", option.email);

  const handleLoadRequest = () => {
    if (isNext) {
      dispatch(getAgentEmails({ limit: limit + 20 }));
      setLimit((prev) => prev + 20);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);

    if (e.target.value) {
      dispatch(getAgentEmails({ limit: limit, email: e.target.value }));
      setLimit((prev) => prev + 20);
    }
  };

  useEffect(() => {
    dispatch(getAgentEmails({ limit: limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={`${isMobile ? "mobile_form_group" : ""}`}>
      <form
        className="agent_signin_form_container"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <AutocompleteInput
          label={t("translation.adminLoginPage.select")}
          name="email"
          className="row-margin-bottom"
          placeholder={t("translation.adminLoginPage.selectPlaceholder")}
          value={formik.values.email}
          errorMessage={
            (formik.touched.email ? formik.errors.email : "") as string
          }
          options={emailList}
          isOpen={isOpenAddressPopup}
          isNextAddress={isNext}
          emptyStateMessage={t("translation.adminLoginPage.noEmail")}
          handleClosePopup={setIsOpenAddressPopup}
          handlePickOptionItem={handlePickOptionItem}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          handleLoadRequest={handleLoadRequest}
        />
        <Button
          sizeVariant={isMobile ? undefined : "Large"}
          type="submit"
          className="login_btn"
          onClick={formik.handleSubmit}
        >
          {`${t("translation.adminLoginPage.loginButton")}`}
        </Button>
      </form>
    </div>
  );
};

export default SecondStepAuth;
