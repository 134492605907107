import React, { useState } from "react";
import StickyButton from "mobile_components/StickyButton";
import { Outlet } from "react-router-dom";

import AsideSection from "./components/AsideSection";
import { useMobileContext } from "context/mobileContext";
import Card from "mobile_components/Card";
import AgentProfileGeneralPage from "pages/AgentProfileGeneralPage";
import AgentProfileFocusAreasPage from "pages/AgentProfileFocusAreasPage";
import AgentProfileConnectedCalendars from "pages/AgentProfileConnectedCalendars";
import AgentProfileOnlinePreferencePage from "pages/AgentProfileOnlinePreferencePage";
import { useTranslation } from "react-i18next";

const AgentProfilePage = () => {
  const [activeCardId, setActiveCardId] = useState(0);
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const handleActiveCard = (id: number) => {
    if (id === activeCardId) return setActiveCardId(0);
    setActiveCardId(id);
  }

  const extraStyles = isMobile && activeCardId === 1 ? {paddingBottom: "100px"} : {};

  return (
    <div className={`${isMobile ? "mobile_container" : ""} page_container agent_profile_page`} style={extraStyles}>
      <AsideSection />
      <div className="main_section">
      {isMobile ? (
          <>
            <Card 
              title={t('translation.agentProfilePage.settings')}
              level={1} 
              isActive={activeCardId === 1}
              handleActiveCard={handleActiveCard}
            >
              <AgentProfileGeneralPage />
            </Card>
            <Card 
              title={t('translation.agentProfilePage.area')}
              level={2} 
              isActive={activeCardId === 2}
              handleActiveCard={handleActiveCard}
            >
              <AgentProfileFocusAreasPage />
            </Card>
            <Card 
              title={t('translation.agentProfilePage.calendars')}
              level={3} 
              isActive={activeCardId === 3}
              handleActiveCard={handleActiveCard}
            >
              <AgentProfileConnectedCalendars />
            </Card>
            <Card 
              title={t('translation.agentProfilePage.preference')}
              level={4} 
              isActive={activeCardId === 4}
              handleActiveCard={handleActiveCard}
            >
              <AgentProfileOnlinePreferencePage />
            </Card>
            {activeCardId === 0 && (
              <StickyButton
                className="label-bold"
                type="submit"
                name="submit_button"
                disabled={true}
                >
                  {t('translation.agentProfilePage.saveButton')}
              </StickyButton>
            )}
          </>
         ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default AgentProfilePage;
