import React, { useMemo } from "react";
import InputMask from "react-input-mask";

import { useTranslation } from "react-i18next";
import TextInput from "components/common/TextInput";
import { TextInputPropsI } from "components/common/TextInput/TextInput";

type PhoneTextInputProps = TextInputPropsI & {
  value: string;
};

const PhoneTextInput: React.FC<PhoneTextInputProps> = ({
  value,
  onChange,
  onBlur,
  ...rest
}) => {
  const { t } = useTranslation();

  const mask = useMemo(() => {
    if (value?.[0] === "+")
      return [
        /[0-9+]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ];

    return [
      /[0-9+]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      " ",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ];
  }, [value]);

  return (
    <InputMask
      mask={mask}
      value={value}
      maskPlaceholder={null}
      onChange={onChange}
      onBlur={onBlur}
      disabled={rest.disabled}
    >
      <TextInput
        label={t("translation.inputLabel.phone")}
        placeholder="4612 345688"
        name="phone"
        autoComplete="off"
        {...rest}
      />
    </InputMask>
  );
};

export default PhoneTextInput;
