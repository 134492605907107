import { Navigate } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";
import Panels, { TabsI } from "components/common/Panels";
import ClientAuthPanel from "./components/ClientAuthPanel";
import AgentAuthPanel from "./components/AgentAuthPanel";
import { useAppSelector } from "hooks/redux";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { Role } from "store/models/profile";
import Text from "components/common/Text";
import { useTranslation } from "react-i18next";
import { useAuthContext, AuthTabs, AuthAgentViewType } from "./AuthContext";
import { useEffect, useState } from "react";
import { lsApi } from "utils/localStorageApi";

const AuthPage = () => {
  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAuth = useAppSelector(getIsAuthUserSelector);

  const { isMobile, activeTab, viewType, setActiveTab } = useAuthContext();

  const { t } = useTranslation();

  const [agentTitle, setAgentTitle] = useState(
    t("translation.agentAuthPage.title")
  );
  const [agentSubTitle, setAgentSubtitle] = useState(
    t("translation.agentAuthPage.subtitle")
  );

  const tabs: TabsI[] = [
    { title: t("translation.userType.client") },
    { title: t("translation.userType.agent") },
  ];

  const title =
    activeTab === AuthTabs.CLIEN
      ? t("translation.clienAuthPage.title")
      : agentTitle;
  const subTitle =
    activeTab === AuthTabs.CLIEN
      ? t("translation.clienAuthPage.subtitle")
      : agentSubTitle;

  const handleClickTab = (index: AuthTabs) => setActiveTab(index);

  useEffect(() => {
    if (viewType === AuthAgentViewType.AUTH_FORM) {
      setAgentTitle(t("translation.agentAuthPage.title"));
      return setAgentSubtitle(t("translation.agentAuthPage.subtitle"));
    }

    if (viewType === AuthAgentViewType.FORGOT_PASSWORD_SEND_EMAIL) {
      setAgentTitle(t("translation.agentAuthPage.forgotPassword"));
      return setAgentSubtitle(t("translation.agentAuthPage.enterEmail"));
    }

    if (viewType === AuthAgentViewType.FORGOT_PASSWORD_EMAIL_WAS_SENT) {
      setAgentTitle(t("translation.agentAuthPage.emailWasSent"));
      return setAgentSubtitle(t("translation.agentAuthPage.checkEmail"));
    }

    if (viewType === AuthAgentViewType.FORGOT_PASSWORD_UPDATE_PASSWORD) {
      setAgentTitle(t("translation.agentAuthPage.updatePassword"));
      return setAgentSubtitle(t("translation.agentAuthPage.createPassword"));
    }

    if (viewType === AuthAgentViewType.PASSWORD_WAS_UPDATED_FAILED) {
      setAgentTitle(t("translation.agentAuthPage.updateFailed"));
      return setAgentSubtitle(
        t("translation.agentAuthPage.updateFailedDescription")
      );
    }

    if (viewType === AuthAgentViewType.PASSWORD_WAS_UPDATED_SUCCESS) {
      setAgentTitle(t("translation.agentAuthPage.updateSuccess"));
      return setAgentSubtitle(
        t("translation.agentAuthPage.updateSuccessDescription")
      );
    }
  }, [viewType, t]);

  useEffect(() => {
    return () => lsApi.removeItem("previousLocation");
  }, []);

  const containerClassName = isMobile
    ? "mobile_auth_page_container mobile_container"
    : "page_container auth_page_container";

  const isAuthGlobal = lsApi.get("accessToken");

  if (isAuth && isAuthGlobal) {
    const PDFDownloadingId = lsApi.get("isRedirectToPDFDownloading");
    if (PDFDownloadingId) {
      return (
        <Navigate
          to={`${routesConstant.customAnalysisDownloadPDF}/${PDFDownloadingId}`}
          replace
        />
      );
    }

    return (
      <Navigate
        to={
          roleUser === Role.AGENT
            ? routesConstant.agentMyMeeting
            : routesConstant.booking
        }
        replace
      />
    );
  }

  return (
    <div className={containerClassName}>
      {isMobile && (
        <div className="title_wrapper">
          <Text
            text={title}
            variant="title-medium"
            className="title-large title"
            component={"h1"}
          />
          <Text
            text={subTitle}
            variant="paragraph-body"
            className="paragraph-body"
            component={"h2"}
          />
        </div>
      )}
      <Panels
        tabs={tabs}
        activeTabIndex={activeTab}
        onClickTab={handleClickTab}
        classNameBodyContainer="auth_panels_body_container"
        classNameTab="label-bold auth_panels_tab"
        tabButtonWidth={213}
        panelWidth={476}
        isMobile={isMobile}
      >
        {activeTab === AuthTabs.CLIEN && <ClientAuthPanel />}
        {activeTab === AuthTabs.AGENT && <AgentAuthPanel />}
      </Panels>
    </div>
  );
};

export default AuthPage;
