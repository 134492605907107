
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import Select, { SelectItemValue } from "components/common/Select";
import Text from "components/common/Text";
import Button from "components/common/Button";

import { useMobileContext } from "context/mobileContext";
import { SelectItemI } from "constants/bookingMeetingForm";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { getAgentDelayHoursSelector } from "store/reducers/agents";
import { getDelayHours, setDelayHours } from "store/asyncActions/agents";

const delayWorkingHours: SelectItemI<string, number>[] = [
  {
    title: `1${i18next.t("translation.agentProfilePreference.hour")}`,
    value: 1,
  },
  {
    title: `4${i18next.t("translation.agentProfilePreference.hour")}`,
    value: 4,
  },
  {
    title: `12${i18next.t("translation.agentProfilePreference.hour")}`,
    value: 12,
  },
  {
    title: `24${i18next.t("translation.agentProfilePreference.hour")}`,
    value: 24,
  }
];

const DelayWorkingHours = () => {
  const { isMobile } = useMobileContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const hour = useAppShallowSelector(getAgentDelayHoursSelector);

  const [seletedDelayHour, setSeletedDelayHour] = useState<SelectItemValue>(delayWorkingHours[0]);

  const handleSelectItem = (option: SelectItemValue) => setSeletedDelayHour(option);

  const handleClickSave = async () => {
    await dispatch(setDelayHours({delay_hours: Number(seletedDelayHour.value)}))
  }

  useEffect(() => {
    if (hour) {
      const h = delayWorkingHours.find(delHour => delHour.value === hour) || delayWorkingHours[0];
      setSeletedDelayHour(h);
    }
  }, [hour]);

  useEffect(() => {
    dispatch(getDelayHours());
  }, [dispatch]);

  return (
    <div className={`delay_working_time ${isMobile ? "mobile" : ""}`}>
      <Text className={`title label-bold ${isMobile ? "" : ""}`}>
        {t("translation.agentProfilePreference.delayTitle")}
      </Text>
      <Text className={`description label-medium ${isMobile ? "" : ""}`}>
        {t("translation.agentProfilePreference.delayDescription")}
      </Text>
     <Select
        className={`sort_filter ${isMobile ? "noPlaceholder" : ""}`}
        handleClickSelectItem={handleSelectItem}
        value={seletedDelayHour.title}
        controlProps={{
          placeholder: seletedDelayHour.title || t("translation.agentProfilePreference.chooseTime")
        }}
      >
        {delayWorkingHours.map((item) => {
          const selectedIcon = item.value === seletedDelayHour.value;

          return (
            <Select.SelectItem
              key={item.title}
              title={item.title}
              value={item.value}
              icon={selectedIcon ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon}
            />
          )
        })}
      </Select>
      <Button
        className="save_time_button"
        sizeVariant={isMobile ? "Medium" : "Large"}
        disabled={seletedDelayHour.value === hour}
        onClick={handleClickSave}
      >
        {t("translation.agentProfilePreference.apply")}
      </Button>
    </div>
  );
};

export default DelayWorkingHours;
