import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { routesConstant, RoutesT } from "constants/appRoutesConstants";
import Link from "components/common/Link";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";

import { Role } from "store/models/profile";
import { setIsPreBookingHidden } from "store/reducers/booking";

interface NavMenuI {
  closeMenu?(): void;
  isMobile?: boolean;
}

interface MenuI {
  handleClick?(): void;
  isMobile?: boolean;
}

const NavMenu: React.FC<NavMenuI> = ({ closeMenu, isMobile }) => {
  const isAuth = useAppShallowSelector(getIsAuthUserSelector);
  const roleUser = useAppShallowSelector(getRoleProfileSelector);

  const handleClick = () => {
    if (isMobile && closeMenu) {
      closeMenu();
    }
  };

  return (
    <nav className="nav_menu_container">
      {roleUser === Role.AGENT && isAuth ? (
        <AgentNavMenu handleClick={handleClick} isMobile={isMobile} />
      ) : (
        <ClientNavMenu handleClick={handleClick} isMobile={isMobile} />
      )}
    </nav>
  );
};

const AgentNavMenu = ({ handleClick, isMobile }: MenuI) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const myMeetingActiveClassName = [
    routesConstant.agentMyMeeting,
    routesConstant.agentPreviousMeerings,
  ].includes(pathname as RoutesT)
    ? "active"
    : "";

  const myProfileActiveClassName = [
    routesConstant.agentProfile,
    routesConstant.agentProfileConnectedCalendars,
    routesConstant.agentProfileFocusAreas,
    routesConstant.agentProfileOnlinePreference,
  ].includes(pathname as RoutesT)
    ? "active"
    : "";

  return (
    <ul className="nav_list">
      <li className="nav_list_item">
        <Link
          to={routesConstant.agentMyMeeting}
          variant="navigation-link"
          className={`nav_list_item_link ${myMeetingActiveClassName}`}
          onClick={handleClick}
        >
          {`${t("translation.header.meetings")}`}
        </Link>
      </li>
      <li className="nav_list_item">
        <Link
          to={routesConstant.agentProfile}
          variant="navigation-link"
          className={`nav_list_item_link ${myProfileActiveClassName}`}
          onClick={handleClick}
        >
          {!isMobile
            ? `${t("translation.header.profile")}`
            : `${t("translation.header.myProfile")}`}
        </Link>
      </li>
    </ul>
  );
};

const ClientNavMenu = ({ handleClick, isMobile }: MenuI) => {
  const isAuth = useAppShallowSelector(getIsAuthUserSelector);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const redirectToBookingPage = () => {
    dispatch(setIsPreBookingHidden(false));
    if (handleClick) handleClick();
  };

  const bookingActiveClassName = [routesConstant.booking].includes(
    pathname as RoutesT
  )
    ? "active"
    : "";

  return (
    <ul className="nav_list">
      {isMobile && isAuth && (
        <>
          <li className="nav_list_item">
            <Link
              to={routesConstant.clientMyMeetingsLocation}
              variant="navigation-link"
              className={`nav_list_item_link`}
              onClick={handleClick}
            >
              {`${t("translation.header.meetings")}`}
            </Link>
          </li>
          <li className="nav_list_item">
            <Link
              to={routesConstant.clientProfile}
              variant="navigation-link"
              className={`nav_list_item_link`}
              onClick={handleClick}
            >
              {!isMobile
                ? `${t("translation.header.profile")}`
                : `${t("translation.header.myProfile")}`}
            </Link>
          </li>
        </>
      )}
      <li className="nav_list_item">
        <Link
          to={routesConstant.booking}
          variant="navigation-link"
          className={`nav_list_item_link ${bookingActiveClassName}`}
          onClick={redirectToBookingPage}
        >
          {`${t("translation.header.bookMeet")}`}
        </Link>
      </li>
      <li className="nav_list_item">
        <Link
          href={process.env.REACT_APP_PUBLIC_URL_HOW_IT_WORKS}
          variant="navigation-link"
          className={`nav_list_item_link`}
          onClick={handleClick}
          native
        >
          {`${t("translation.header.howBook")}`}
        </Link>
      </li>
      <li className="nav_list_item">
        <Link
          href={process.env.REACT_APP_PUBLIC_URL_FAQ}
          variant="navigation-link"
          className={`nav_list_item_link`}
          onClick={handleClick}
          native
        >
          {`${t("translation.header.faq")}`}
        </Link>
      </li>
      {isMobile && <LinksNavMenu />}
    </ul>
  );
};

const LinksNavMenu = ({ handleClick }: MenuI) => {
  const { t } = useTranslation();
  const [isLinkMenuOpened, setIsLinkMenuOpened] = useState(false);

  const handleOpenMenu = () => setIsLinkMenuOpened(!isLinkMenuOpened);

  return (
    <>
      <li className="nav_list_item">
        <Link
          href={process.env.REACT_APP_PUBLIC_URL_ABOUT}
          variant="navigation-link"
          className={`nav_list_item_link`}
          onClick={handleClick}
          native
        >
          {`${t("translation.header.about")}`}
        </Link>
      </li>
      <li className="nav_list_item">
        <Link
          href="#"
          variant="navigation-link"
          className={`nav_list_item_link`}
          onClick={handleClick}
          native
        >
          {`${t("translation.header.articles")}`}
        </Link>
      </li>
      {/* <li className="nav_list_item">
        <div className={`nav_list_item_link full`} onClick={handleOpenMenu}>
          <span className="navigation-link">{`${t(
            "translation.header.agents"
          )}`}</span>
          <span className="icon">
            {isLinkMenuOpened ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </span>
        </div>
      </li>
      {isLinkMenuOpened && (
        <div className="subtitles">
          <li className="nav_list_item">
            <Link
              href="#"
              variant="navigation-link"
              className={`nav_list_item_link`}
              onClick={handleClick}
              native
            >
              Broker Stockholm
            </Link>
          </li>
          <li className="nav_list_item">
            <Link
              href="#"
              variant="navigation-link"
              className={`nav_list_item_link`}
              onClick={handleClick}
              native
            >
              Broker Malmö
            </Link>
          </li>
          <li className="nav_list_item">
            <Link
              href="#"
              variant="navigation-link"
              className={`nav_list_item_link`}
              onClick={handleClick}
              native
            >
              Broker Helsingborg
            </Link>
          </li>
        </div>
      )} */}
    </>
  );
};

export default NavMenu;
