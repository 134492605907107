import React, { memo } from "react";
import Button, { ButtonProps } from "@mui/material/Button";

import Spinner from "components/common/Spinner";

import { getChosenAgentsLengthSelector } from "store/reducers/booking";
import { useSelector } from "react-redux";

interface StickyButtonStyledProps extends ButtonProps<React.ElementType> {
  showLoadingIndicator?: boolean;
  blueContainer?: boolean;
  sizeVariant?: "Large" | "Medium" | "Small" | "Small-round";
}

const StickyButtonStyled: React.FC<StickyButtonStyledProps> = ({
  children,
  blueContainer,
  className = "",
  sizeVariant = "Medium",
  showLoadingIndicator = false,
  ...rest
}) => {
  const isChosenAgentsList = Boolean(
    useSelector(getChosenAgentsLengthSelector)
  );

  return (
    <div
      className={`sticky_button_wrapper ${
        blueContainer ? "blueContainer" : ""
      } ${isChosenAgentsList ? "isChosenAgentsList" : ""}`}
    >
      <Button
        className={`styled_button ${sizeVariant} ${className}`}
        variant="contained"
        color="primary"
        fullWidth={false}
        {...rest}
      >
        {children}
        {showLoadingIndicator && (
          <span className="button_spinner_wrap">
            <Spinner size={24} className="button_spinner" />
          </span>
        )}
      </Button>
    </div>
  );
};

export default memo(StickyButtonStyled);
