import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import { TypeHousingRequestDataI } from "store/models/typeHousing";
import { TypeHousingI } from "store/models/agent";

export class TypeHousingApi extends CRUD {
  getTypeHousing(): AxiosPromise<TypeHousingI[]> {
    return this.request({
      url: `${this.url}/`,
    });
  }

  updateAgentTypeHousing(
    data: TypeHousingRequestDataI
  ): AxiosPromise<TypeHousingRequestDataI> {
    return this.request({
      url: `${this.url}/agent_type_housing/`,
      method: "PATCH",
      data,
    });
  }
}

export default function typeHousingApi(request: AxiosInstance) {
  return new TypeHousingApi({
    url: "/type_housing",
    request,
  });
}
