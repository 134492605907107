import { useTranslation } from "react-i18next";

import Panels from "components/common/Panels";
import CreateForm from "./components/CreateForm";
import GoBackButton from "components/GoBackButton";
import { useMobileContext } from "context/mobileContext";


const AuthCreatePage = () => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  
  return (
    <div className={`${isMobile ? "mobile_container" : ""} page_container auth_create_page_container`}>
      <GoBackButton
        className="auth_create_back_button"
        title={t("translation.clienAuthPage.backToLogIn")}
        isMobile={isMobile}
      />
      {!isMobile ? (
        <Panels
          activeTabIndex={0}
          tabs={[{ title: t("translation.userType.client") }]}
          panelWidth={476}
          tabButtonWidth={212}
          classNameTab="panel_button"
        >
          <CreateForm />
        </Panels>
      ) : (
        <CreateForm />
      )}
    </div>
  );
};

export default AuthCreatePage;
