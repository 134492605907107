import React, { memo } from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";

import Text from "components/common/Text";
import InputLabelText from "../InputLabelText";

export interface TextInputPropsI extends InputBaseProps {
  errorMessage?: string;
  classNameContainer?: string;
  className?: string;
  helperTextClassName?: string;
  label?: string;
  helperText?: string;
  isRequared?: boolean;
}

const TextInput: React.FC<TextInputPropsI> = ({
  classNameContainer = "",
  className = "",
  helperTextClassName= "",
  errorMessage = "",
  label = "",
  helperText = "",
  isRequared = false,
  ...rest
}) => {
  return (
    <div className={`text_input_container ${classNameContainer}`}>
      <InputLabelText
        className="text_input_label"
        label={label}
        isRequared={isRequared}
      />
      <InputBase
        className={`text_input paragraph-body ${className}`}
        error={!!errorMessage}
        fullWidth
        autoComplete="off"
        {...rest}
      />
      {!!helperText && !errorMessage && (
        <Text
          text={helperText}
          variant="paragraph-body"
          className={`text_input_text helper_text ${helperTextClassName}`}
        />
      )}
      {!!errorMessage && (
        <Text
          text={errorMessage}
          variant="error-text"
          className="text_input_text error_text"
        />
      )}
    </div>
  );
};

export default memo(TextInput);
