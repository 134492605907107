import { lsApi } from "./localStorageApi";

type TimeoutT = ReturnType<typeof setTimeout>;
type IntervalT = ReturnType<typeof setInterval>;
type CallBackT = () => void;

interface ConstructorI {
  timeout: number;
  onTimeout: CallBackT;
  onExpired: CallBackT;
}

class IdleTimer {
  private timeout: number;
  private timeoutTracker?: TimeoutT;
  private onTimeout: CallBackT;
  private interval?: IntervalT;

  constructor({ timeout, onTimeout, onExpired }: ConstructorI) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const _et = lsApi.get("_expiredTime") as string;

    const expiredTime = parseInt(_et, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.tracker();
    this.startInterval();
  }

  private startInterval = () => {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(lsApi.get("_expiredTime") as string, 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  };

  private updateExpiredTime = () => {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      const _value = Date.now() + Number(this.timeout) * 1000;

      lsApi.set("_expiredTime", _value);
    }, 300);
  };

  private tracker = () => {
    window.addEventListener("mousemove", this.updateExpiredTime);
    window.addEventListener("scroll", this.updateExpiredTime);
    window.addEventListener("keydown", this.updateExpiredTime);
  };

  public cleanUp = () => {
    lsApi.removeItem("_expiredTime");
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener("mousemove", this.updateExpiredTime);
    window.removeEventListener("scroll", this.updateExpiredTime);
    window.removeEventListener("keydown", this.updateExpiredTime);
  };
}

export default IdleTimer;
