import React from "react";
import { Navigate } from "react-router-dom";

import { routesConstant, RoutesT } from "constants/appRoutesConstants";

import { useAppSelector } from "hooks/redux";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { Role } from "store/models/profile";

interface PrivateRouteProps {
  children: React.ReactElement;
  userRoleOnly?: Role;
  navigateTo?: RoutesT;
  requaredAuth?: boolean;
}

const RequireAuth: React.FC<PrivateRouteProps> = ({
  children,
  userRoleOnly,
  navigateTo,
  requaredAuth = true,
}) => {
  const isAuth = useAppSelector(getIsAuthUserSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);

  if (requaredAuth && !isAuth)
    return <Navigate to={routesConstant.auth} replace />;

  if (roleUser === userRoleOnly || (!requaredAuth && !isAuth)) return children;

  return <Navigate to={navigateTo || routesConstant.auth} replace />;
};

export default RequireAuth;
