import React, { useRef, useEffect } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import uniqueId from "lodash/uniqueId";
import { defaultNS } from "i18n";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { useCalendarContext } from "../useCalendarContext";
import { HourSlotItemProps } from "../types";
import TimeSlotLayout from "./TimeSlotLayout";
import TimeSlotItem from "./TimeSlotItem";

const HourSlotItem: React.FC<HourSlotItemProps> = ({
  hour,
  availableTimeSlots,
  day,
  isOneSelected
}) => {
  const { selectedHourSlot, handleSelectHourSlot, handleCloseOpenedHourSlots } =
    useCalendarContext();
  const { t } = useTranslation();

  const hourSlotItemRef = useRef<HTMLDivElement>(null);

  const timeSlotsSize = availableTimeSlots.length;

  const isSelectedHourSlot =
    selectedHourSlot.day === day && selectedHourSlot.hour === hour;

  const isSelectedAnotherHourSlot = !!(
    selectedHourSlot.day &&
    selectedHourSlot.hour &&
    !isSelectedHourSlot
  );

  const timeRangeText = `${hour}:00 - ${Number(hour) + 1}:00`;

  const isSwedish = defaultNS === 'sv';

  const timeSlotSizeText = `${timeSlotsSize} ${!isSwedish ? t("translation.calendarPage.open") : ""} ${
    timeSlotsSize === 1 ? t("translation.calendarPage.slot") : t("translation.calendarPage.slots")
  }`;

  const handleClickItem = () => {
    if (isSelectedAnotherHourSlot) return;
    handleSelectHourSlot({ day, hour });
  };

  useEffect(() => {
    if (isSelectedHourSlot) {
      setTimeout(() => {
        hourSlotItemRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 200);
    }
  }, [isSelectedHourSlot]);

  return (
    <TimeSlotLayout
      ref={hourSlotItemRef}
      isHourTimeSlot={true}
      isOpenHourTimeSlot={isSelectedHourSlot}
      isOpenAnotherHourTimeSlot={isSelectedAnotherHourSlot}
    >
      <button
        className={`time_slot_button ${
          isSelectedHourSlot ? "time_slot_button_open" : ""
        } ${isOneSelected ? "one_selected" : ""}`}
        onClick={handleClickItem}
      >
        <Text variant="label-medium" className="time_slot_time_range_text">
          {timeRangeText}
        </Text>
        {!!timeSlotsSize && (
          <Text variant="paragraph-body">{timeSlotSizeText}</Text>
        )}
      </button>
      {isSelectedHourSlot && (
        <>
          <div className={`timeslots_available ${isOneSelected ? "one_selected" : ""}`}>
            {availableTimeSlots.map((timeslot, index) => {
              return (
                <TimeSlotItem
                  key={uniqueId(index + timeslot.start_time)}
                  startDate={timeslot.start_time}
                  endDate={timeslot.end_time}
                  agensCount={timeslot.agents}
                />
              );
            })}
          </div>
          <button
            className="selected_hour_time_slot_close_button"
            onClick={handleCloseOpenedHourSlots}
          >
            <ExpandLessIcon className="close_button_icon" />
          </button>
        </>
      )}
    </TimeSlotLayout>
  );
};

export default HourSlotItem;
