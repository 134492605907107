import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import {
  AddressesStoredListI,
  AddressesResponceI,
  StoredAddressesI,
  SellerAddressResponceI,
  SellerAddress,
} from "store/models/locations";
import {
  addAddressToSeller,
  checkIsPostalCodeActive,
  getAddressesByPostalCode,
  getAddressesByStreet,
  getSellerAddresses,
} from "store/asyncActions/locations";

interface AddressesSliseI {
  isFetching: boolean;
  isFetchingPostalCodeActiveStatus: boolean;
  addresses: AddressesStoredListI;
  sellerAddress: SellerAddress;
}

const initialState: AddressesSliseI = {
  isFetching: false,
  isFetchingPostalCodeActiveStatus: false,
  addresses: {
    count: 0,
    next: "",
    results: [],
  },
  sellerAddress: {} as SellerAddress,
};

// store full list of suggestion addresses
export const getAddressesListSelector = createTypedSelector(
  (state) => state.addresses.addresses.results
);

export const getIsNextAddressesListSelector = createTypedSelector((state) =>
  Boolean(state.addresses.addresses.next)
);

// store authorised seller address data from BE
export const getSellerAddressSelector = createTypedSelector(
  (state) => state.addresses.sellerAddress
);

export const getIsFetchingPostalCodeActiveStatusSelector = createTypedSelector(
  (state) => state.addresses.isFetchingPostalCodeActiveStatus
);

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    clearAddresses: (state) => {
      state.addresses = initialState.addresses;
    },
    clearSellerAddress: (state) => {
      state.sellerAddress = initialState.sellerAddress;
    },
  },
  extraReducers: {
    [getAddressesByStreet.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAddressesByStreet.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.addresses.next = action.payload.next;
      state.addresses.results = [
        ...action.payload.results.map(
          (address: AddressesResponceI): StoredAddressesI => {
            return {
              address_id: address.id,
              street: address.street.name,
              houseNumber: address.number,
              postalCode: address.street.postal_code,
              district: address.street?.district || "",
              county: address.street?.county || "",
              formattedAddress: `${address.street.name} ${address.number}, ${address.street.postal_code}`,
              formattedPostalCode: `${address.street.postal_code}, ${address.street.name} ${address.number}`,
            };
          }
        ),
      ];
    },
    [getAddressesByPostalCode.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAddressesByPostalCode.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.addresses.next = action.payload.next;
      state.addresses.results = [
        ...action.payload.results.map(
          (address: AddressesResponceI): StoredAddressesI => {
            return {
              address_id: address.id,
              street: address.street.name,
              houseNumber: address.number,
              postalCode: address.street.postal_code,
              district: address.street?.district || "",
              county: address.street?.county || "",
              formattedAddress: `${address.street.name} ${address.number}, ${address.street.postal_code}`,
              formattedPostalCode: `${address.street.postal_code}, ${address.street.name} ${address.number}`,
            };
          }
        ),
      ];
    },
    [addAddressToSeller.fulfilled.type]: (
      state,
      action: PayloadAction<SellerAddressResponceI>
    ) => {
      state.sellerAddress = {
        address: `${action.payload?.address?.street?.name}, ${action.payload?.address?.number}`,
        areaNumber: action.payload?.address?.street?.postal_code,
        address_id: action.payload?.address.id,
        district: action.payload?.address?.street?.district || "",
        county: action.payload?.address?.street?.county || "",
      };
    },
    [getSellerAddresses.fulfilled.type]: (
      state,
      action: PayloadAction<SellerAddressResponceI>
    ) => {
      state.sellerAddress = {
        address: `${action.payload?.address?.street?.name}, ${action.payload?.address?.number}`,
        areaNumber: action.payload?.address?.street?.postal_code,
        address_id: action.payload?.address.id,
        district: action.payload?.address?.street?.district || "",
        county: action.payload?.address?.street?.county || "",
      };
    },
    [checkIsPostalCodeActive.pending.type]: (state) => {
      state.isFetchingPostalCodeActiveStatus = true;
    },
    [checkIsPostalCodeActive.fulfilled.type]: (state) => {
      state.isFetchingPostalCodeActiveStatus = false;
    },
    [checkIsPostalCodeActive.rejected.type]: (state) => {
      state.isFetchingPostalCodeActiveStatus = false;
    },
  },
});

export const { clearAddresses, clearSellerAddress } = addressesSlice.actions;

export default addressesSlice.reducer;
