import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "components/common/Text";
import Button from "components/common/Button";
import Link from "components/common/Link";
import BankidLogo from "components/Svg/BankidLogo";
import GoogleLogo from "components/Svg/GoogleLogo";

import { getBankIdAuthInitiate } from "store/asyncActions/bankId";
import {
  getGoogleUrl,
  googleAuthorization,
} from "store/asyncActions/googleAuth";

import { useAuthContext } from "../AuthContext";
import { getIsRedirectCreateSellerSelector } from "store/reducers/googleAuth";
import { routesConstant } from "constants/appRoutesConstants";

enum SearchParams {
  code = "code",
}

const ClientAuthPanel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const googleAuthCodeQueryParam = useRef(searchParams.get(SearchParams.code));

  const isRedirectCreactApp = useAppSelector(getIsRedirectCreateSellerSelector);

  const handleConnectByBankId = () => {
    dispatch(getBankIdAuthInitiate());
  };

  const handleConnectByGoogle = () => {
    dispatch(getGoogleUrl());
  };

  useEffect(() => {
    if (googleAuthCodeQueryParam.current) {
      dispatch(googleAuthorization({ code: googleAuthCodeQueryParam.current }));
      setSearchParams("", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAuthCodeQueryParam.current, dispatch]);

  useEffect(() => {
    if (isRedirectCreactApp) {
      navigate(routesConstant.authCreate);
    }
  }, [isRedirectCreactApp, navigate]);

  return (
    <div className="panel_item client_auth_body">
      {!isMobile && (
        <>
          <Text
            text={`${t("translation.clienAuthPage.title")}`}
            variant="title-medium"
            className="panel_item_title"
          />
          <Text
            text={`${t("translation.clienAuthPage.subtitle")}`}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <div className="button_container">
        <Button
          className="login_bankid_button"
          sizeVariant="Large"
          onClick={handleConnectByBankId}
        >
          <BankidLogo className="button_icon" />
          {`${t("translation.clienAuthPage.bankIDbutton")}`}
        </Button>
        <div className="beetwen_buttons">
          <hr className="line" />
          <Text
            text={t("translation.qrPopup.or")}
            variant="paragraph-body"
            className="or"
          />
          <hr className="line" />
        </div>
        <Button
          className="login_google_button"
          sizeVariant="Large"
          onClick={handleConnectByGoogle}
        >
          <GoogleLogo className="button_icon" />
          {`${t("translation.clienAuthPage.googleButton")}`}
        </Button>
      </div>
      <div className="bottom_section">
        <Text
          className={`${isMobile ? "paragraph-body-small" : "bottom_text"}`}
        >
          {`${t("translation.clienAuthPage.agreement")}`}{" "}
          <Link
            to={process.env.REACT_APP_USER_POLICY}
            className="text_link"
            target="_blank"
            rel="noopener noreferrer"
            native
          >
            {" "}
            {`${t("translation.clienAuthPage.userPolicy")}`}.
          </Link>
        </Text>
      </div>
    </div>
  );
};

export default ClientAuthPanel;
