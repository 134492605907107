import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  ConsentScreenUrlRequestI,
  ConsentUrlParams,
  CalendarAuthDataI,
  AgentCalendarResponceI,
  TeamConnectResponseI,
  ConnectTeamQueryParams,
  AgentCalendarI,
  AgentCalendarIdI,
  AgentCalendarRequestI,
  ConnectTeamUrlQueryParams,
} from "store/models/calendars";

export class CalendarsApi extends CRUD {
  getConsentScreenUrl(
    params: ConsentUrlParams
  ): AxiosPromise<ConsentScreenUrlRequestI> {
    return this.request({
      url: `${this.url}/get_calendar_consent_screen_url/`,
      params,
    });
  }

  сalendarAuth(data: CalendarAuthDataI): AxiosPromise<AgentCalendarResponceI> {
    return this.request({
      url: `${this.url}/auth/`,
      method: "POST",
      data,
    });
  }

  disconnectCalendars(): AxiosPromise {
    return this.request({
      url: `${this.url}/disconnect/`,
      method: "DELETE",
    });
  }

  getConnectTeamUrl(
    params: ConnectTeamUrlQueryParams
  ): AxiosPromise<TeamConnectResponseI> {
    return this.request({
      url: `${this.url}/conferencing_profile/url_connected/`,
      params
    });
  }

  connectTeam(
    params: ConnectTeamQueryParams
  ): AxiosPromise<ConnectTeamQueryParams> {
    return this.request({
      url: `${this.url}/conferencing_profile/connected/`,
      params,
    });
  }

  getAgentOtherCalendarsForConnection(): AxiosPromise<AgentCalendarI> {
    return this.request({
      url: `${this.url}/other_calendar/read_agent/`,
    });
  }

  getAgentOtherCalendars(): AxiosPromise<AgentCalendarI> {
    return this.request({
      url: `${this.url}/other_calendar/`,
    });
  }

  saveOtherCalendarsToAgent(data: AgentCalendarRequestI): AxiosPromise<AgentCalendarI> {
    return this.request({
      url: `${this.url}/other_calendar/save_for_agent/`,
      method: "POST",
      data,
    });
  }

  deleteOtherCalendarsFromAgent(data: AgentCalendarIdI): AxiosPromise<AgentCalendarIdI> {
    return this.request({
      url: `${this.url}/other_calendar/disconnect_for_agent/`,
      method: "DELETE",
      data,
    });
  }
}

export default function calendarApi(request: AxiosInstance) {
  return new CalendarsApi({
    url: "/calendars",
    request,
  });
}
