import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";

import api from "api";
import {
  CalendarAuthDataI,
  ConsentUrlParams,
  AgentCalendarIdI,
  AgentCalendarRequestI,
  ConnectTeamUrlQueryParams,
  ConnectTeamQueryParams,
} from "store/models/calendars";
import { showAlerts } from "store/reducers/alerts";
import { profileAboutSelf } from "store/asyncActions/profile";

export const getConsentScreenUrl = createAsyncThunk(
  "calendars/getConsentScreenUrl",
  async (params: ConsentUrlParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.calendars.getConsentScreenUrl(params);
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message:
            error.response.data?.detail ||
            i18next.t("translation.alertMessage.failedCalendarConnect"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const calendarAuth = createAsyncThunk(
  "calendars/calendarAuth",
  async (body: CalendarAuthDataI, { rejectWithValue, dispatch }) => {
    const { isMobile, ...rest } = body;
    try {
      const { data, status } = await api.calendars.сalendarAuth(rest);
      if (status === 205) {
        dispatch(profileAboutSelf());
      }

      const isEmailChanged = data.is_change_email_agent || false;

      if (isEmailChanged) {
        dispatch(
          showAlerts({
            message: i18next.t("translation.alertMessage.agentEmailChanged"),
            type: "warning",
            autoHideDuration: 10000,
          })
        );
      }

      if (isMobile) {
        dispatch(
          showAlerts({
            message: i18next.t(
              "translation.alertMessage.successCalendarConnect"
            ),
            type: "success",
            autoHideDuration: 10000,
          })
        );
      }

      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: `${error.response.data.detail[0]}`,
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const checkConnectCalendar = createAsyncThunk(
  "calendars/checkConnectCalendar",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.agent.checkConnectCalendar();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const disconnectCalendars = createAsyncThunk(
  "calendars/disconnectCalendars",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { status } = await api.calendars.disconnectCalendars();
      return status;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: `${error.response.data.detail.detail}`,
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getConnectTeamUrl = createAsyncThunk(
  "calendars/getConnectTeamUrl",
  async (params: ConnectTeamUrlQueryParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.calendars.getConnectTeamUrl(params);
      return data;
    } catch (error) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedCalendarConnect"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue("Somethink went wrong");
    }
  }
);

export const connectTeam = createAsyncThunk(
  "calendars/connectTeam",
  async (params: ConnectTeamQueryParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.calendars.connectTeam(params);
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedCalendarConnect"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentOtherCalendarsForConnection = createAsyncThunk(
  "calendars/getAgentOtherCalendars",
  async (_, { rejectWithValue }) => {
    try {
      const { data } =
        await api.calendars.getAgentOtherCalendarsForConnection();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentOtherCalendars = createAsyncThunk(
  "calendars/getAgentsOtherCalendars",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.calendars.getAgentOtherCalendars();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveOtherCalendarsToAgent = createAsyncThunk(
  "locations/saveOtherCalendarsToAgent",
  async (body: AgentCalendarRequestI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.calendars.saveOtherCalendarsToAgent(body);
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.successCalendarConnect"),
          type: "success",
          autoHideDuration: 10000,
        })
      );
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedCalendarConnect"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteOtherCalendarsFromAgent = createAsyncThunk(
  "locations/deleteOtherCalendarsFromAgent",
  async (body: AgentCalendarIdI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.calendars.deleteOtherCalendarsFromAgent(body);
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.successCalendarDeleted"),
          type: "success",
          autoHideDuration: 10000,
        })
      );
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedCalendarDeleted"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
