import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import uniqueId from "lodash/uniqueId";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SortIcon from "@mui/icons-material/Sort";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import TextInput from "components/common/TextInput";
import Button from "components/common/Button";

import { getAgencyList } from "store/asyncActions/agents";
import {
  getIsNextAgencyListSelector,
  setSelectedAgencyList,
} from "store/reducers/agents";
import { AgencyI } from "store/models/agent";

import Spinner from "../Spinner";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import { getDateQueryFormat } from "components/BookingMeetingCalendar/BookingMeetingCalendar";

interface AgencyFilterPropsI {
  options: AgencyI[];
}

const AgencyFilter: React.FC<AgencyFilterPropsI> = ({ options }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    preBookingFormik: {
      values: { residenceType, areaNumber },
    },
  } = useBookingFrom();
  const isNext = useAppShallowSelector(getIsNextAgencyListSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [requestLimit, setRequestLimit] = useState(5);
  const [selectedList, setSelectedList] = useState<AgencyI[]>([]);

  const handleAgencyList = (limit: number, name?: string) => {
    dispatch(
      getAgencyList({
        limit: limit,
        type_housing_id: residenceType,
        postal_code: `${areaNumber.replace(" ", "").slice(0, 5)}`,
        ...(name ? { name: name } : {}),
      })
    )
      .unwrap()
      .then(() => setRequestLimit(requestLimit + 5));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value);
    handleAgencyList(5, event.target.value);
  };

  const handleLoadRequest = () => {
    if (isNext) {
      handleAgencyList(requestLimit, searchStr);
    }
  };

  const handleChangeCheckbox = (item: AgencyI) => (event: any) => {
    const checked = event.target.checked;

    if (checked) return setSelectedList([...selectedList, item]);

    return setSelectedList([
      ...selectedList.filter((agency: AgencyI) => agency.id !== item.id),
    ]);
  };

  const handleOpenPopup = () => setIsOpen((prev) => !prev);

  const handleClosePopup = () => setIsOpen(false);

  const handleClearSelectedList = () => setSelectedList([]);

  useEffect(() => {
    if (isOpen && !isNext) {
      handleAgencyList(requestLimit, searchStr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  useEffect(() => {
    dispatch(setSelectedAgencyList(selectedList));
  }, [dispatch, selectedList]);

  return (
    <ClickAwayListener onClickAway={handleClosePopup}>
      <div className="checkbox_container">
        <div
          className={`checkbox_control_container placeholder`}
          onClick={handleOpenPopup}
        >
          <div className="paragraph-body title">
            {t("translation.agentBookingList.agenciesFilter")}
          </div>
          <SortIcon />
        </div>
        {isOpen && (
          <div className="checkbox_body">
            <TextInput
              placeholder={t("translation.bookingPage.search")}
              classNameContainer="checkbox_search"
              name="search"
              value={searchStr}
              onChange={handleSearch}
            />
            <FormGroup className="wrapper">
              {options.length ? (
                <InfiniteScroll
                  dataLength={options.length}
                  key={uniqueId()}
                  hasMore={isNext}
                  height={250}
                  className="scroll"
                  next={handleLoadRequest}
                  loader={
                    <div className="spinner_container">
                      <Spinner size={20} />
                    </div>
                  }
                >
                  {options.map((item: AgencyI) => {
                    const isChecked = selectedList.some(
                      (agency) => agency.id === item.id
                    );

                    return (
                      <FormControlLabel
                        key={uniqueId(item.id)}
                        className="checkbox_item paragraph-medium"
                        control={<Checkbox checked={isChecked} />}
                        label={item.name}
                        onChange={handleChangeCheckbox(item)}
                      />
                    );
                  })}
                </InfiniteScroll>
              ) : (
                <div className="paragraph-small empty">
                  {t("translation.bookingPage.noAgencies")}
                </div>
              )}
            </FormGroup>
            <div className="button_wrapper">
              <Button
                fullWidth
                className="reset_button label-small"
                variant="outlined"
                onClick={handleClearSelectedList}
              >
                {t("translation.bookingPage.clearFilter")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AgencyFilter;
