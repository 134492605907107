import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import { useMobileContext } from "context/mobileContext";
import {
  CustomerAnalysisFormikT,
  QuestionItemCustomAnalysisI,
  QuestionsCustomAnalysisI,
} from "store/models/customerAnalysis";
import {
  getCustomerAnalysisQuestions,
  saveCustomerAnalysisAnswers,
} from "store/asyncActions/customerAnalysis";
import {
  getCustomerAnalysisFetchingSelector,
  getCustomerAnalysisQuestionsSelector,
} from "store/reducers/customerAnalysis";

import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import PageLoader from "components/PageLoader";
import Text from "components/common/Text";
import { routesConstant } from "constants/appRoutesConstants";
import { getStructuredText } from "utils/getStructuredText";

enum FieldType {
  textField = "TXT",
  radioButton = "Rd",
  radioButtonText = "RdTXT",
  checkbox = "Chk",
  checkboxText = "ChkTXT",
}

const CustomerAnalysis = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const { customerId } = useParams();

  const data = useAppShallowSelector(getCustomerAnalysisQuestionsSelector);
  const isFetching = useAppShallowSelector(getCustomerAnalysisFetchingSelector);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const formik = useFormik({
    initialValues: {} as CustomerAnalysisFormikT,
    onSubmit(values) {
      const checkFieldLength = () => {
        let isValid = true;
        Object.entries(values).map(([key, val]) => {
          if (val.length > 500) {
            isValid = false;
            formik.setFieldError(
              key,
              t("translation.validation.max", { max: 500 })
            );
          }
        });

        return isValid;
      };

      if (customerId && checkFieldLength()) {
        dispatch(
          saveCustomerAnalysisAnswers({
            id: customerId,
            body: { questions: values },
          })
        );
        navigate(`${routesConstant.clientMyMeetingsLocation}`);
      }
    },
  });

  const handleCheckbox = (field: string, val: string) => () => {
    const valuesList = (formik.values as { [key: string]: string[] })[
      `${field}`
    ];

    formik.setFieldValue(
      field,
      !valuesList?.includes(val)
        ? [...valuesList, val]
        : valuesList.filter((item) => item !== val)
    );
  };

  const handleRedirectToMeetings = () =>
    navigate(`${routesConstant.clientMyMeetingsLocation}`);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerAnalysisQuestions(customerId));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    if (data) {
      const questionsValues = data?.questions
        ? [
            ...data.questions.map((item) =>
              Object.values(
                item.values.map((val: QuestionItemCustomAnalysisI) => val)
              )
            ),
          ].flat(1)
        : [];

      const questions = questionsValues.reduce((acc, questionsItem) => {
        const { question, answer, type } = questionsItem;
        const isCheckbox =
          type === FieldType.checkbox || type === FieldType.checkboxText;

        return { ...acc, [question]: answer || (isCheckbox ? [] : "") };
      }, {}) as CustomerAnalysisFormikT;

      formik.setValues(questions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isAnswersFilled = Object.values(formik.values).every((val) => {
      let value = false;
      if (typeof val === "string") value = Boolean(val);
      if (Array.isArray(val)) value = Boolean(val.length);

      return value === true;
    });

    setIsButtonDisabled(!isAnswersFilled);
  }, [formik.values]);

  if (isFetching) return <PageLoader />;

  return (
    <div
      className={`page_container customer_analysis_page_container ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      {isEmpty(data) && (
        <div className="empty_state_wrapper">
          <div className="empty_state">
            <Text
              text={t("translation.customerAnalysis.emptyState")}
              className="label-medium empty_title"
            />
          </div>
          <div className="button_container">
            <Button
              sizeVariant={isMobile ? "Small" : "Medium"}
              variant="contained"
              className="form_button"
              type="submit"
              onClick={handleRedirectToMeetings}
            >
              {t("translation.customerAnalysis.emptyStateButton")}
            </Button>
          </div>
        </div>
      )}
      {data?.questions && (
        <div className="wrapper">
          <div className="notification_text">
            <Text
              text={t("translation.customerAnalysis.title")}
              className="paragraph-body title"
            />{" "}
            <br />
            <br />
            <div className="paragraph-body">
              {getStructuredText(t("translation.customerAnalysis.description"))}
            </div>
          </div>
          {data.questions.map(
            (section: QuestionsCustomAnalysisI, ind: number) => {
              return (
                <div className="question_section" key={ind}>
                  <div className="question_section_top">
                    <h2
                      className={`${isMobile ? "title-small20" : "label-bold"}`}
                    >
                      {section.title}
                    </h2>
                  </div>
                  <div className="question_section_wrapper">
                    {section.values.map(
                      (item: QuestionItemCustomAnalysisI, ind: number) => {
                        return (
                          <div className="question_section_container" key={ind}>
                            <h4 className={`question-title`}>
                              {item.question}
                            </h4>
                            {item.type === FieldType.textField && (
                              <TextInput
                                placeholder={t(
                                  "translation.customerAnalysis.questionPlaceHolder"
                                )}
                                className="text_input"
                                name={item.question}
                                value={
                                  (formik.values as { [key: string]: string })[
                                    `${item.question}`
                                  ] as string
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  !!(
                                    formik.errors as { [key: string]: string }
                                  )[`${item.question}`]
                                }
                                errorMessage={
                                  (formik.errors as { [key: string]: string })[
                                    `${item.question}`
                                  ] as string
                                }
                              />
                            )}
                            {item.options &&
                              (item.type === FieldType.radioButton ||
                                item.type === FieldType.radioButtonText) && (
                                <RadioGroup>
                                  {item.options.map(
                                    (checkItem: string, ind: number) => (
                                      <FormControlLabel
                                        key={ind}
                                        value={checkItem}
                                        name={item.question}
                                        label={checkItem}
                                        onChange={formik.handleChange}
                                        control={<Radio />}
                                      />
                                    )
                                  )}
                                </RadioGroup>
                              )}
                            {item.options &&
                              (item.type === FieldType.checkbox ||
                                item.type === FieldType.checkboxText) && (
                                <FormGroup>
                                  {item.options?.map(
                                    (checkItem: string, ind: number) => {
                                      const isChecked = (
                                        formik.values as {
                                          [key: string]: string[];
                                        }
                                      )[`${item.question}`]?.some(
                                        (val) => val === checkItem
                                      );

                                      return (
                                        <FormControlLabel
                                          key={ind}
                                          className="checkbox_label"
                                          onChange={handleCheckbox(
                                            item.question,
                                            checkItem
                                          )}
                                          control={
                                            <Checkbox
                                              name={item.question}
                                              checked={!!isChecked}
                                            />
                                          }
                                          name={item.question}
                                          label={checkItem}
                                        />
                                      );
                                    }
                                  )}
                                </FormGroup>
                              )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            }
          )}
          <div className="button_container">
            <Button
              sizeVariant={isMobile ? "Small" : "Medium"}
              variant="contained"
              className="form_button"
              type="submit"
              disabled={isButtonDisabled}
              onClick={formik.handleSubmit}
            >
              {t("translation.customerAnalysis.send")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerAnalysis;
