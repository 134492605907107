import { AxiosInstance, AxiosPromise } from "axios";

import {
  GoogleAuthInitiateParamI,
  GoogleAuthInitiateResponseI,
  GoogleURLResponseI,
} from "store/models/googleAuth";

import CRUD from "../base";

export class GoogleAuth extends CRUD {
  googleAuthInitiate(
    data: GoogleAuthInitiateParamI
  ): AxiosPromise<GoogleAuthInitiateResponseI> {
    return this.request({
      url: `${this.url}/authorize/`,
      method: "POST",
      data,
    });
  }

  getGoogleUrl(): AxiosPromise<GoogleURLResponseI> {
    return this.request({
      url: `${this.url}/get_uri/`,
    });
  }
}

export default function googleAuth(request: AxiosInstance) {
  return new GoogleAuth({
    url: "/google_auth",
    request,
  });
}
