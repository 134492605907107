import React from "react";
import { useMobileContext } from "context/mobileContext";
import { MobileViewType } from "../BookingMeetingCalendar";

import { useFilterCalendarContext } from "../useFilterCalendarContext";
import Text from "components/common/Text";
import CalendarColumn from "./CalendarColumn";
import { useCalendarContext } from "../useCalendarContext";
import { useAppSelector } from "hooks/redux";
import {
  getIsFetchingTimeSlotsSelector,
  getIsTimeSlotsEmptyListSelector,
  getNoSlotsMessageSelector,
} from "store/reducers/booking";
import { useTranslation } from "react-i18next";
interface ICalendarBody {
  mobileView?: MobileViewType;
}

const CalendarBody: React.FC<ICalendarBody> = ({ mobileView }) => {
  const isTimeSlotsExist = useAppSelector(getIsTimeSlotsEmptyListSelector);

  const { dataOfSelectedWeek } = useFilterCalendarContext();
  const { isMobile } = useMobileContext();
  const { selectedHourSlot } = useCalendarContext();

  const isOpenHourSlot = Boolean(selectedHourSlot.day && selectedHourSlot.hour);

  const selectedWeek = isMobile
    ? mobileView === MobileViewType.threeDays
      ? dataOfSelectedWeek.slice(0, 3)
      : dataOfSelectedWeek.slice(0, 1)
    : dataOfSelectedWeek;

  return (
    <div
      className={`calendar_body_container ${
        isOpenHourSlot ? "open_slot" : ""
      } ${!isTimeSlotsExist ? "calendar_body_container_empty" : ""}`}
    >
      {!isMobile && <div className="mock_transparent_block"></div>}
      {isTimeSlotsExist ? (
        selectedWeek.map((day) => {
          return (
            <CalendarColumn
              key={day.day}
              day={day.day}
              isOneSelected={mobileView === MobileViewType.today}
            />
          );
        })
      ) : (
        <LoadingOrEmptyList />
      )}
      {!isMobile && <div className="mock_transparent_block"></div>}
    </div>
  );
};

export default CalendarBody;

const LoadingOrEmptyList = () => {
  const { t } = useTranslation();
  const isFetching = useAppSelector(getIsFetchingTimeSlotsSelector);
  const message = useAppSelector(getNoSlotsMessageSelector);

  return (
    <div className="calendar_body_empty_list">
      <Text variant="title-small" className="empty_message">
        {isFetching
          ? t("translation.bookingPage.loading")
          : message || t("translation.bookingPage.noSlots")}
      </Text>
    </div>
  );
};
