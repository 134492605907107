import React from "react";
import Slider from "@mui/material/Slider";

import * as timeConfig from "../config";
import { WorkingSlotsI } from "store/models/agent";

interface SelectTimePropsI {
  workingTimeSliderId: string;
  startWorkDay: number;
  endWorkDay: number;
  workingTimes: WorkingSlotsI[];
  disabled: boolean;
  isTimeError: boolean;
  setWorkingTime: (newValue: WorkingSlotsI[]) => void;
}

const SelectTime: React.FC<SelectTimePropsI> = ({
  startWorkDay,
  endWorkDay,
  workingTimes,
  disabled,
  isTimeError,
  workingTimeSliderId: selectionId,
  setWorkingTime,
}) => {
  const handleChange = (
    _event: Event,
    newValue: number[] | number,
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      const updatedTimeSelection = workingTimes.map(item => {
        if (item.workingTimeSliderId === selectionId) {
          return {
            ...item,
            start_work_minute: Math.min(
              newValue[0],
              endWorkDay - timeConfig.minDistance
            ),
            end_work_minute: endWorkDay,
          }
        }

        return item
      });

      setWorkingTime(updatedTimeSelection)
    } else {
      const updatedTimeSelection = workingTimes.map(item => {
        if (item.workingTimeSliderId === selectionId) {
          return {
            ...item,
            start_work_minute: startWorkDay,
            end_work_minute: Math.max(
              newValue[1],
              startWorkDay + timeConfig.minDistance
            )
          }
        }

        return item
      });

      setWorkingTime(updatedTimeSelection);
    }
  };

  return (
    <Slider
      value={[startWorkDay, endWorkDay]}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={timeConfig.valueToLabel}
      step={timeConfig.minDistance}
      marks={timeConfig.getMarks()}
      components={{
        Mark: () => <span style={{ display: "none" }} />,
      }}
      min={timeConfig.startWorkingDayMin}
      max={timeConfig.endWorkingDayMin}
      className={`time_slider ${isTimeError ? "error" : ""}`}
      disabled={disabled}
    />
  );
};

export default SelectTime;
