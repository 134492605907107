import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import WhiteWrapBlock from "components/WhiteWrapBlock";
import Select from "components/common/Select";
import Text from "components/common/Text";
import { getCustomerAnalysisPDF } from "store/asyncActions/customerAnalysis";
import Pdf from "components/Svg/Pdf";

import { MeetingItemInterface } from "../NextMeetingList";

interface AdditionalMenuI {
  customerAnalysis: string | null;
}

const AgentMeetingListItem: React.FC<MeetingItemInterface> = ({
  title,
  typeHouse,
  customerAnalysis,
  isMobile,
  street,
  postalCode,
  sellerName,
  isToday,
  rooms,
  areaSqm,
  sellerEmail,
  sellerPhoneNumber
}) => {
  const classNameToday = isToday ? "today" : "";

  return (
    <>
      <WhiteWrapBlock className={`meeting_item ${classNameToday}`}>
        <div className="row title_section">
          <Text
            text={title}
            className={`h4 title agent_title ${isMobile ? "title-small20-bold" : ""}`}
            tooltipTitle={title}
            variant="title-small"
          />
          <AdditionalMenu customerAnalysis={customerAnalysis} />
        </div>

        <div className="row">
          <span className="location_item">
            <MapsHomeWorkOutlinedIcon className="icon block" />
              <Text
                text={typeHouse}
                className="location_item_text street"
                variant="paragraph-body"
              />
          </span> 
        </div>

        <div className="title_section row">
          <span className="location_item baseline">
            <PlaceOutlinedIcon className="icon block" />
            <div className="location_container">
              <Text
                text={street}
                className="location_item_text street"
                variant="paragraph-body"
              />
              <Text
                text={postalCode}
                className="location_item_text"
                variant="paragraph-body"
              />
            </div>
          </span> 
        </div>

        <div className="row">
          <span className="location_item">
            <MeetingRoomOutlinedIcon className="icon" />
            <Text
              text={rooms}
              className="location_item_text standart"
              variant="paragraph-body"
            />
          </span>
          <span className="location_item">
            <SquareFootOutlinedIcon className="icon" />
            <Text
              text={areaSqm}
              className="location_item_text standart"
              variant="paragraph-body"
            />
          </span>
        </div>

        <div className="line" />

        <div className="row title_section">
          <span className="location_item">
            <AccountCircleOutlinedIcon className="icon" />
            <Text
              text={sellerName}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>

        <div className="row title_section">
          <span className="location_item">
            <EmailOutlinedIcon className="icon" />
            <Text
              text={sellerEmail}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>

        <div className="row">
          <span className="location_item">
            <LocalPhoneOutlinedIcon className="icon" />
            <Text
              text={sellerPhoneNumber}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>
    
      </WhiteWrapBlock>
    </>
  );
};

const AdditionalMenu: React.FC<AdditionalMenuI> = ({customerAnalysis}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenCustomerAnalisys = () => {
    if (customerAnalysis) {
       dispatch(getCustomerAnalysisPDF(customerAnalysis));
    }
  };

  return (
    <div className="additional_menu">
      <Select
        alignBody="right"
        isCloseAfterClick
        renderControl={(props) => (
          <span 
            className="dots"
            onClick={props.onClick as MouseEventHandler}
          >
            <MoreVertIcon />
          </span>
        )}
      >
        <Select.SelectItem
          title={t("translation.agentMeetings.customerAnalisys")}
          value="add"
          type="link"
          onClick={handleOpenCustomerAnalisys}
          endIcon={Pdf}
          disabled={!customerAnalysis}
        />
      </Select>
    </div>
  )
}

export default AgentMeetingListItem;
