import React from "react";

import Text, { TextPropsInterface } from "../Text";
import RequaredStar from "components/Svg/RequaredStar";

interface PropsI extends TextPropsInterface {
  className?: string;
  label?: string;
  isRequared?: boolean;
}

const InputLabelText: React.FC<PropsI> = ({
  className = "",
  label = "",
  isRequared = false,
  ...rest
}) => {
  return (
    <Text
      variant="label-small"
      className={`input_label_text ${className} ${isRequared ? 'requared' : ''}`}
      {...rest}
    >
      {label}
      {isRequared && <RequaredStar className="requared_icon" />}
    </Text>
  );
};

export default InputLabelText;
