import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useLocation } from "react-router";

import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { getDataTypeHousingSelector } from "store/reducers/typeHousing";
import { ContactMeSchema } from "common/validationSchemas";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import { getProfileSelector } from "store/reducers/profile";
import { sendEmailAboutDirectBookMeeting } from "store/asyncActions/agents";
import { routesConstant } from "constants/appRoutesConstants";
import { clearSelectedAgentsToDirectContact } from "store/reducers/agents";

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";
import TextInput from "components/common/TextInput";
import Select, { SelectItemValue } from "components/common/Select";
import SellerAddress from "components/SellerAddress";
import Checkbox from "components/common/CheckBox";
import PhoneTextInput from "components/PhoneTextInput";
import { trackingAgentSentContactMeForm } from "utils/facebookTracking";

interface ContactMeModalPropsI {
  isOpen: boolean;
  agentEmails: string[];
  agentsData: { id: string; agentName: string }[];
  onClickBack: () => void;
}

interface ContactMeFormInterface {
  name: string;
  email: string;
  phone: string;
  residenceType: string;
  address: string;
  areaNumber: string;
  message: string;
  acceptedPolicy: boolean;
  address_id: number;
}

const ContactMeModal: React.FC<ContactMeModalPropsI> = ({
  isOpen,
  agentEmails,
  agentsData,
  onClickBack,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { pathname } = useLocation();

  const {
    preBookingFormik: {
      values: { areaNumber, address, residenceType, address_id },
    },
  } = useBookingFrom();

  const residenceTypes = useAppShallowSelector(getDataTypeHousingSelector);
  const userProfile = useAppShallowSelector(getProfileSelector);

  const formik = useFormik<ContactMeFormInterface>({
    initialValues: {
      name:
        userProfile.first_name && userProfile.last_name
          ? `${userProfile.first_name} ${userProfile.last_name}`
          : "",
      email: userProfile.email || "",
      phone: userProfile.phone_number || "",
      residenceType: residenceType || "",
      address: address || "",
      areaNumber: areaNumber || "",
      message: "",
      acceptedPolicy: userProfile.gdpr_consent || false,
      address_id: address_id || 0,
    },
    onSubmit(values) {
      const data = {
        seller_name: values.name,
        seller_email: values.email,
        seller_phone: values.phone,
        type_housing_id: values.residenceType,
        address_id: values.address_id,
        agent_emails: agentEmails,
        description: values.message,
      };

      dispatch(sendEmailAboutDirectBookMeeting(data)).then(() => {
        if (pathname === routesConstant.booking) {
          dispatch(clearSelectedAgentsToDirectContact());
        }

        trackingAgentSentContactMeForm(agentsData);
        onClickBack();
      });
    },
    validationSchema: ContactMeSchema(),
  });

  const handleSelect = (fieldName: string) => (values: SelectItemValue) => {
    formik.setFieldValue(fieldName, values.value, false);
  };

  return (
    <Modal
      open={isOpen}
      isCloseButton
      handleClose={onClickBack}
      classNameDialog={`contact_me_modal_dialog_container ${
        isMobile ? "mobile" : ""
      }`}
      title={t("translation.agentPresentationProfilePage.contactMeModalTitle")}
    >
      <div className="modal_wrapper">
        <div className="input_row">
          <TextInput
            label={t("translation.inputLabel.name")}
            placeholder={t("translation.inputPlaceholder.name")}
            name="name"
            isRequared
            value={formik.values.name}
            errorMessage={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextInput
            label={t("translation.inputLabel.email")}
            placeholder={t("translation.inputPlaceholder.email")}
            name="email"
            isRequared
            value={formik.values.email}
            errorMessage={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="input_row">
          <PhoneTextInput
            label={t("translation.inputLabel.phone")}
            placeholder={t("translation.inputPlaceholder.phone")}
            isRequared
            name="phone"
            value={formik.values.phone}
            errorMessage={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Select
            controlProps={{
              label: t("translation.inputLabel.residence"),
              placeholder: t("translation.inputPlaceholder.dropdown"),
              name: "residenceType",
              isRequared: true,

              errorMessage:
                !formik.values.residenceType &&
                formik.errors.residenceType &&
                formik.touched.residenceType
                  ? formik.errors.residenceType
                  : "",
            }}
            handleClickSelectItem={handleSelect("residenceType")}
            value={formik.values.residenceType}
            className={isMobile ? "row-margin-bottom" : ""}
          >
            {residenceTypes.map((item) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <Select.SelectItem
                key={item.id}
                title={item.name!}
                value={item.id}
              />
            ))}
          </Select>
        </div>
        <div className="input_row without_bottom_space">
          <SellerAddress formik={formik} />
        </div>

        <TextInput
          label={t(
            "translation.agentPresentationProfilePage.messageFieldLabel"
          )}
          placeholder={t(
            "translation.agentPresentationProfilePage.messageFieldPlaceholder"
          )}
          className="message_input"
          helperTextClassName={isMobile ? "label-extra-small" : ""}
          multiline
          isRequared
          minRows={4}
          name="message"
          value={formik.values.message}
          errorMessage={
            formik.touched.message && formik.errors.message
              ? formik.errors.message
              : ""
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Checkbox
          isLink
          lable={t("translation.clienCreateFormPage.checkbox")}
          name="acceptedPolicy"
          className="checkbox"
          checked={formik.values.acceptedPolicy}
          error={
            formik.touched.acceptedPolicy && formik.errors.acceptedPolicy
              ? formik.errors.acceptedPolicy
              : ""
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div className="modal_buttons_container">
          <Button className="modal_button" onClick={formik.handleSubmit}>
            {t("translation.agentPresentationProfilePage.sendButton")}
          </Button>
          <Button
            variant="outlined"
            className="modal_button"
            onClick={onClickBack}
          >
            {t("translation.agentPresentationProfilePage.cancelButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactMeModal;
