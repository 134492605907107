import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";
import { GoogleAuthInitiateParamI } from "store/models/googleAuth";
import { showAlerts } from "store/reducers/alerts";
import { setTokens } from "utils/auth";

import { profileAboutSelf } from "./profile";

export const getGoogleUrl = createAsyncThunk(
  "googleAuth/getGoogleUrl",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.googleAuth.getGoogleUrl();
      window.open(res.data.google_auth_uri, "_parent");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const googleAuthorization = createAsyncThunk(
  "googleAuth/googleAuthorization",
  async (body: GoogleAuthInitiateParamI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.googleAuth.googleAuthInitiate(body);

      if (data.jwt_pair) {
        setTokens(data.jwt_pair);
        dispatch(profileAboutSelf());
      }

      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: error.response.data?.detail[0],
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
