import React from "react";

import TopSection from "./components/TopSection";
import BottomSection from "./components/BottomSection";
import { useMobileContext } from "context/mobileContext";

const AgentMeetingPage: React.FC = () => {
  const { isMobile } = useMobileContext();

  return (
    <div
      className={`page_container agent_meeting_page ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      <TopSection />
      {!isMobile && <BottomSection />}
    </div>
  );
};

export default AgentMeetingPage;
