import { useMobileContext } from "context/mobileContext";
import Logo from "components/Svg/Logo";
import { Role } from "store/models/profile";
import { useAppSelector } from "hooks/redux";
import { getRoleProfileSelector } from "store/reducers/profile";

const LinkLogo = ({ className = "" }: { className?: string }) => {
  const { isMobile } = useMobileContext();
  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAgent = roleUser === Role.AGENT;

  return (
    <a href={!isAgent ? process.env.REACT_APP_PUBLIC_URL_BASE : undefined}>
      <Logo isMobile={isMobile} className={className} />
    </a>
  );
};

export default LinkLogo;
