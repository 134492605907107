import React from "react";
import { whiteColor } from "constants/colors";

import { IconTypesInterface } from "./iconTypesInterface";

const NextArow: React.FC<IconTypesInterface> = ({
  className = "",
  color = whiteColor,
}) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5 0L11.09 1.41L16.67 7H4.5V9H16.67L11.09 14.59L12.5 16L20.5 8L12.5 0Z"
        fill={color}
      />
    </svg>
  );
};

export default NextArow;
