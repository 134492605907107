import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import { mapWorkingScheduleData } from "utils/mapWorkingScheduleData";
import {
  userLogin,
  logout,
  profileAboutSelf,
  changeAgentAvatar,
  deleteAgentAvatar,
  updateAgentProfile,
  updateAgentTypeHousing,
  getAgentWorkingSchedule,
  updateAgentWorkingSchedule,
  createUser,
  editClientProfile,
} from "../asyncActions/profile";

import {
  UsersI,
  AgentProfileUpdateI,
  AgentsEmailListResponce,
} from "../models/profile";
import {
  AgentI,
  AgentAvatarI,
  TypeHousingI,
  WorkingScheduleDataI,
  WorkingScheduleT,
} from "../models/agent";
import { getAgentEmails } from "store/asyncActions/login";

interface ProfileReducerI {
  isAuth: boolean;
  isLoadingProfile: boolean; // for spinner after login
  isInitProfile: boolean; // for spinner in time init app (call profileAboutSelf(true))
  isFetchingUpadateAvatar: boolean; // update profile form
  isFetchingDeleteAvatar: boolean; // delete avatar
  isFetchingUpdateProfile: boolean;
  profile: UsersI;
  agentWorkingSchedule: WorkingScheduleT;
  agentEmailList: AgentsEmailListResponce;
}

const initialState: ProfileReducerI = {
  isAuth: false,
  isInitProfile: true,
  isLoadingProfile: false,
  isFetchingUpadateAvatar: false,
  isFetchingDeleteAvatar: false,
  isFetchingUpdateProfile: false,
  profile: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: null,
    gdpr_consent: false,
    client_feedback: [],
    what_clients_say: "",
    agent_profile: {} as AgentI,
  },
  agentWorkingSchedule: [],
  agentEmailList: {
    next: "",
    results: [],
  },
};

export const getProfileIdSelector = createTypedSelector(
  (state) => state.profile.profile.id
);

export const getRoleProfileSelector = createTypedSelector(
  (state) => state.profile.profile.role
);

export const getIsAuthUserSelector = createTypedSelector(
  (state) => state.profile.isAuth
);

export const getIsLoadingProfileSelector = createTypedSelector(
  (state) => state.profile.isLoadingProfile
);

export const getIsInitProfileSelector = createTypedSelector(
  (state) => state.profile.isInitProfile
);

export const getProfileSelector = createTypedSelector(
  (state) => state.profile.profile
);

export const getIsFetchingProfileSelector = createTypedSelector(
  (state) => state.profile.isFetchingUpadateAvatar
);

export const getIsFetchingDeleteAvatarSelector = createTypedSelector(
  (state) => state.profile.isFetchingDeleteAvatar
);

export const getIsFetchingUpdateProfileSelector = createTypedSelector(
  (state) => state.profile.isFetchingUpdateProfile
);

export const getAgentWorkingScheduleSelector = createTypedSelector(
  (state) => state.profile.agentWorkingSchedule
);

export const getAgentEmailListSelector = createTypedSelector(
  (state) => state.profile.agentEmailList.results
);

export const getIsNextAgentEmailListSelector = createTypedSelector((state) =>
  Boolean(state.profile.agentEmailList.next)
);

export const getMappedAgentWorkingScheduleSelector = createSelector(
  getAgentWorkingScheduleSelector,
  (data) => mapWorkingScheduleData(data)
);

export const getAgentTypeHousingSelector = createSelector(
  getProfileSelector,
  (profile) => {
    return profile.agent_profile?.type_housing?.map((item) => item.id) || [];
  }
);

export const getAgentProfileAvatarSelector = createSelector(
  getProfileSelector,
  (profile) => profile?.agent_profile?.image
);

export const getAgentProfileGeneralFormSelector = createSelector(
  getProfileSelector,
  (profile) => ({
    id: profile.id,
    firstName: profile.first_name || "",
    lastName: profile.last_name || "",
    email: profile.email || "",
    phone: profile.phone_number || "",
    title: profile.agent_profile?.title || "",
    presentation: profile.agent_profile?.presentation || "",
    whatClientsSay: profile.agent_profile?.what_clients_say || "",

    accomplishments_one: profile.agent_profile?.client_feedback[0] || "",
    accomplishments_two: profile.agent_profile?.client_feedback[1] || "",
    accomplishments_three: profile.agent_profile?.client_feedback[2] || "",
  })
);

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setIsLoaderProfile: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProfile = action.payload;
    },
  },
  extraReducers: {
    [userLogin.pending.type]: (state) => {
      state.isLoadingProfile = true;
    },
    [userLogin.rejected.type]: (state) => {
      state.isLoadingProfile = false;
      state.isAuth = false;
    },
    [profileAboutSelf.fulfilled.type]: (
      state,
      action: PayloadAction<UsersI>
    ) => {
      state.isLoadingProfile = false;
      state.isInitProfile = false;
      state.isAuth = true;
      state.profile = action.payload;
    },
    [profileAboutSelf.rejected.type]: (state) => {
      state.isAuth = false;
      state.isLoadingProfile = false;
      state.isInitProfile = false;
      state.profile = initialState.profile;
    },
    [logout.fulfilled.type]: (state) => {
      state.isAuth = false;
      state.profile = initialState.profile;
    },
    [changeAgentAvatar.pending.type]: (state) => {
      state.isFetchingUpadateAvatar = true;
    },
    [changeAgentAvatar.fulfilled.type]: (
      state,
      action: PayloadAction<AgentAvatarI>
    ) => {
      if (state.profile.agent_profile) {
        state.profile.agent_profile.image = action.payload.image;
        state.isFetchingUpadateAvatar = false;
      }
    },
    [changeAgentAvatar.rejected.type]: (state) => {
      state.isFetchingUpadateAvatar = false;
    },
    [deleteAgentAvatar.pending.type]: (state) => {
      state.isFetchingDeleteAvatar = true;
    },
    [deleteAgentAvatar.fulfilled.type]: (
      state,
      action: PayloadAction<AgentAvatarI>
    ) => {
      if (state.profile.agent_profile) {
        state.profile.agent_profile.image = action.payload.image;
        state.isFetchingDeleteAvatar = false;
      }
    },
    [deleteAgentAvatar.rejected.type]: (state) => {
      state.isFetchingDeleteAvatar = false;
    },
    [updateAgentProfile.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [updateAgentProfile.fulfilled.type]: (
      state,
      action: PayloadAction<AgentProfileUpdateI>
    ) => {
      state.isFetchingUpdateProfile = false;
      state.profile.phone_number = action.payload.phone_number;
      if (state.profile.agent_profile) {
        state.profile.agent_profile.title = action.payload.agent_profile.title;
        state.profile.agent_profile.presentation =
          action.payload.agent_profile.presentation;
      }
    },
    [updateAgentProfile.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [updateAgentTypeHousing.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [updateAgentTypeHousing.fulfilled.type]: (
      state,
      action: PayloadAction<TypeHousingI[]>
    ) => {
      state.isFetchingUpdateProfile = false;
      if (state.profile?.agent_profile?.type_housing) {
        state.profile.agent_profile.type_housing = action.payload;
      }
    },
    [updateAgentTypeHousing.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [getAgentWorkingSchedule.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [getAgentWorkingSchedule.fulfilled.type]: (
      state,
      action: PayloadAction<WorkingScheduleDataI>
    ) => {
      state.isFetchingUpdateProfile = false;
      state.agentWorkingSchedule = action.payload.working_schedule;
    },
    [getAgentWorkingSchedule.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
      state.agentWorkingSchedule = [];
    },
    [updateAgentWorkingSchedule.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [updateAgentWorkingSchedule.fulfilled.type]: (
      state,
      action: PayloadAction<WorkingScheduleDataI>
    ) => {
      state.isFetchingUpdateProfile = false;
      state.agentWorkingSchedule = action.payload.working_schedule;
    },
    [updateAgentWorkingSchedule.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [createUser.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [createUser.fulfilled.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [createUser.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [editClientProfile.pending.type]: (state) => {
      state.isFetchingUpdateProfile = true;
    },
    [editClientProfile.fulfilled.type]: (
      state,
      action: PayloadAction<UsersI>
    ) => {
      state.isFetchingUpdateProfile = false;
      state.profile = action.payload;
    },
    [editClientProfile.rejected.type]: (state) => {
      state.isFetchingUpdateProfile = false;
    },
    [getAgentEmails.fulfilled.type]: (
      state,
      action: PayloadAction<AgentsEmailListResponce>
    ) => {
      state.agentEmailList = {
        next: action.payload.next,
        results: action.payload.results,
      };
    },
  },
});

export const { setIsLoaderProfile } = profileSlice.actions;

export default profileSlice.reducer;
