import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import LinkLogo from "components/LinkLogo";

import FooterContainer from "./mobile_components/FooterContainer";
import NavMenu from "./components/NavMenu";

interface MobileHeaderI {
  isShowTabs: boolean;
}

const MobileHeader: React.FC<MobileHeaderI> = ({ isShowTabs }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  const closeMenu = () => {
    setIsMenuOpened(false);
    setMenuHeight(0);
    document.body.style.overflow = "auto";
  };

  const openMenu = () => {
    setIsMenuOpened(true);
    setMenuHeight(window.innerHeight);
    document.body.style.overflow = "hidden";
  };

  return (
    <div className="mobile_header_wrapper">
      <header className="mobile_header">
        <div className="mobile_header_container">
          <LinkLogo />
          {isShowTabs && (
            <button
              className="burger_button"
              onClick={isMenuOpened ? closeMenu : openMenu}
            >
              {isMenuOpened ? (
                <CloseIcon className="icon" />
              ) : (
                <MenuIcon className="icon" />
              )}
            </button>
          )}
        </div>
      </header>
      <div
        className="mobile_dropdown_wrapper"
        style={
          isMenuOpened ? { pointerEvents: "auto" } : { pointerEvents: "none" }
        }
      >
        <AnimateHeight
          id="animated-menu"
          className="mobile_dropdown_menu"
          duration={300}
          style={
            isMenuOpened ? { overflow: "scroll", paddingBottom: "106px" } : {}
          }
          height={menuHeight}
        >
          <NavMenu closeMenu={closeMenu} isMobile />
          <FooterContainer closeMenu={closeMenu} isMenuOpened={isMenuOpened} />
        </AnimateHeight>
      </div>
    </div>
  );
};

export default MobileHeader;
