import { Route } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";
import DownloadingAnalysisPDF from "pages/DownloadingAnalysisPDF/DownloadingAnalysisPDF";

export const downloadingPdfRoute = (
  <Route
    path={`${routesConstant.customAnalysisDownloadPDF}/:customerAnalysisId`}
    element={<DownloadingAnalysisPDF />}
  />
);
