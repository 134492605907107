export const minDistance = 30;
export const minInHours = 60;
export const startWorkingDayHours = 6;
//const startWorkingDayLunchHours = 13;
export const startWorkingDayMin = minInHours * startWorkingDayHours;
//const startWorkingDayLunchMin = startWorkingDayLunchHours * minInHours;
//const endWorkingDayLunchHours = 14;
export const endWorkingDayHours = 22;
export const endWorkingDayMin = minInHours * endWorkingDayHours;
//const endWorkingDayLunchMin = endWorkingDayLunchHours * minInHours;

interface MarkI {
  value: number;
  label: string;
}

export function valueToLabel(value: number) {
  const selectedHours = Math.floor(value / minInHours);
  const selectedMinutes = value % minInHours ? value % minInHours : "00";
  return `${selectedHours}:${selectedMinutes}`;
}

export function valueToNewDate(value: number) {
  const hour = Math.floor(value / minInHours);
  const minute = value % minInHours;
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDay(),
    hour,
    minute
  );
}

export function dateToMinutes(value: Date) {
  const hour = new Date(value).getHours();
  const minute = new Date(value).getMinutes();
  return hour * 60 + minute;
}

export const getMarks = (): MarkI[] => {
  const res: MarkI[] = [];
  for (let i = startWorkingDayMin; i <= endWorkingDayMin; i += minInHours) {
    res.push({ value: i, label: valueToLabel(i) });
  }
  return res;
};

export const includedWorkingHour = (): Date[] => {
  const includedHours = [];
  for (let i = startWorkingDayHours; i <= endWorkingDayHours; i++) {
    if (i !== endWorkingDayHours) {
      includedHours.push(new Date(2011, 0, 1, i, 0, 0, 0), new Date(2011, 0, 1, i, 30, 0, 0))
    } else {
      includedHours.push(new Date(2011, 0, 1, i, 0, 0, 0))
    }
  }

  return includedHours;
}
