import React, { useEffect, useState } from "react";
import uniqueId from "lodash/uniqueId";
import {
  format,
  parseISO,
  formatDistanceToNow,
  isToday,
  isTomorrow,
} from "date-fns";
import { sv, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { utcToZonedTime } from "date-fns-tz";
import { defaultNS } from "i18n";
import { isWindows } from "react-device-detect";

import Text from "components/common/Text";
import { UpcomingMeetingData } from "store/models/meetings";
import { useInfinityScroll } from "hooks/useInfinityScroll";
import { timeZoneSV } from "constants/timeZoneConstants";

import SellerMeetingListItem from "./components/SellerMeetingListItem";
import AgentMeetingListItem from "./components/AgentMeetingListItem";

export interface MeetingItemInterface {
  title: string;
  timeDistance?: string;
  meetingTime?: string;
  date?: string;
  typeHouse?: string;
  customerAnalysis: string | null;
  isMobile?: boolean;
  isToday?: boolean;
  street?: string;
  postalCode?: string;
  sellerName?: string;
  rooms?: string;
  areaSqm?: string;
  agentMeetingId?: number;
  agencyName?: string;
  sellerEmail?: string;
  sellerPhoneNumber?: string;
}

interface NextMeetingListPropsI {
  className?: string;
  isMobile?: boolean;
  isAgent?: boolean;
  data: UpcomingMeetingData[];
  isInfinytyScroll?: boolean;
  handleInfinytyScroll?: () => void;
}

const NextMeetingList: React.FC<NextMeetingListPropsI> = ({
  className,
  isMobile,
  isAgent,
  data = [],
  handleInfinytyScroll,
}) => {
  const paginationScrollTargetRef =
    useInfinityScroll<HTMLSpanElement>(handleInfinytyScroll);
  const { t } = useTranslation();
  const [isShowShadow, setIsShowShadow] = useState(true);

  const handleScroll = (e: any) => {
    const bottom = isWindows
      ? Math.round(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight
      : Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop) ===
        Math.floor(e.target.clientHeight);

    if (bottom) {
      return setIsShowShadow(false);
    }
    setIsShowShadow(true);
  };

  useEffect(() => setIsShowShadow(data.length > 2), [data]);

  return (
    <div
      className={`meeting_list_container ${className} 
        ${isMobile ? "mobile" : ""} ${
        isAgent ? "agent_meeting_list" : "seller_meeting_list"
      }`}
    >
      {isShowShadow && !isMobile && <div className="shadow_overlay" />}
      <div className="meeting_list" onScroll={handleScroll}>
        {data.length ? (
          data.map((meeting) => {
            const {
              start_time,
              end_time,
              event,
              agent,
              seller,
              agency,
              address,
              customer_analysis,
              id: agentMeetingId,
            } = meeting || {};

            const {
              first_name: agentFirstName = "",
              last_name: agentLastName = "",
            } = agent || {};
            const {
              first_name: sellerFirstName = "",
              last_name: sellerLastName = "",
            } = seller || {};

            const {
              type_housing = "",
              number_of_rooms = "",
              area_sqm,
            } = event || {};

            // Data for right time distance pointer
            const isTodayStartTime = isToday(parseISO(start_time));
            const isTomorrowTime = isTomorrow(parseISO(start_time));

            const sellerTimeDistance = isTodayStartTime
              ? t("translation.clientMeetingPage.today")
              : isTomorrowTime
              ? t("translation.clientMeetingPage.tomorrow")
              : `${
                  formatDistanceToNow(parseISO(start_time)).match(/\d+/)?.[0]
                } ${t("translation.clientMeetingPage.day")}`;

            // Data for meeting time
            const meetingTime = `${format(
              utcToZonedTime(parseISO(start_time), timeZoneSV),
              "kk:mm"
            )}-${format(
              utcToZonedTime(parseISO(end_time), timeZoneSV),
              "kk:mm"
            )}`;

            // Data for date and week day
            const lang = defaultNS === "en" ? enUS : sv;
            const date = format(parseISO(start_time), "EEE, d LLL", {
              locale: lang,
            });

            // Data for customer analysis
            const customerAnalysis = customer_analysis || "";

            // Data for address
            const street = address
              ? `${address.street.name} ${address.number}`
              : "";
            const postalCode = address ? address.street.postal_code : "";

            // Agent data for seller name
            const sellerName = `${sellerFirstName} ${sellerLastName}`;

            // Data for rooms and sqm
            const rooms = number_of_rooms
              ? number_of_rooms == 1
                ? `${number_of_rooms} ${t(
                    "translation.clientMeetingPage.room"
                  )}`
                : `${number_of_rooms} ${t(
                    "translation.clientMeetingPage.rooms"
                  )}`
              : t("translation.clientMeetingPage.noRooms");
            const areaSqm = area_sqm ? `${area_sqm}m2` : "n/a";

            // Data for agency name
            const agencyName = agency?.name || "";

            const agentTitle = `${date}, ${meetingTime}`;
            const sellerTitle = `${agentFirstName} ${agentLastName}`;

            if (isAgent) {
              return (
                <AgentMeetingListItem
                  key={uniqueId(`${start_time}`)}
                  isToday={isTodayStartTime}
                  title={agentTitle}
                  typeHouse={type_housing}
                  street={street}
                  postalCode={postalCode}
                  rooms={rooms}
                  areaSqm={areaSqm}
                  sellerName={sellerName}
                  customerAnalysis={customerAnalysis}
                  sellerEmail={seller.email}
                  sellerPhoneNumber={seller.phone_number}
                />
              );
            }

            return (
              <SellerMeetingListItem
                key={uniqueId(`${start_time}`)}
                title={sellerTitle}
                timeDistance={sellerTimeDistance}
                meetingTime={meetingTime}
                date={date}
                customerAnalysis={customerAnalysis}
                street={street}
                postalCode={postalCode}
                isMobile={!!isMobile}
                isToday={isTodayStartTime}
                rooms={rooms}
                areaSqm={areaSqm}
                agentMeetingId={agentMeetingId}
                agencyName={agencyName}
              />
            );
          })
        ) : (
          <div className="empty_list_container">
            <Text variant="title-small" className="empty_list_text">
              {t("translation.clientMeetingPage.noMeetings")}
            </Text>
          </div>
        )}
        <span ref={paginationScrollTargetRef}></span>
      </div>
    </div>
  );
};

export default NextMeetingList;
