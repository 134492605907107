import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppShallowSelector } from "hooks/redux";
import Alert from "@mui/material/Alert";

import { getDataTypeHousingSelector } from "store/reducers/typeHousing";
import { useMobileContext } from "context/mobileContext";
import { trackingFirstStep } from "utils/facebookTracking";

import { realEstateGoalSelects } from "constants/bookingMeetingForm";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Select, { SelectItemValue } from "components/common/Select";
import NextArow from "components/Svg/NextArow";
import StickyButton from "mobile_components/StickyButton";

import { FormProps } from "../types";

const FormPartOne: React.FC<FormProps> = ({
  handleClickNext,
  formik,
  disabledStep,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const residenceTypes = useAppShallowSelector(getDataTypeHousingSelector);

  const handleSelect = (fieldName: string) => (values: SelectItemValue) => {
    if (fieldName === "residenceType") {
      const residenceName =
        residenceTypes.find((type) => type.id === values.value)?.name || "";
      formik.setFieldValue("residenceName", residenceName);
    }
    formik.setFieldValue(fieldName, values.value);
  };

  const handleClickNextForm = async () => {
    if (formik.values.residenceType && formik.values.target) {
      if (typeof handleClickNext === "function") handleClickNext();
    } else {
      formik.setTouched({ residenceType: true, target: true }, true);
    }
  };

  useEffect(() => {
    trackingFirstStep(formik.values);
  }, []);

  return (
    <>
      {!isMobile && (
        <Text
          text={t("translation.bookingPage.firstStepTitle")}
          variant="title-medium"
          className="form_title"
        />
      )}
      <Select
        controlProps={{
          label: t("translation.inputLabel.estate"),
          placeholder: t("translation.inputPlaceholder.dropdown"),
          name: "target",
          isRequared: true,
          disabled: disabledStep,
          errorMessage:
            !formik.values.target &&
            formik.errors.target &&
            formik.touched.target
              ? formik.errors.target
              : "",
        }}
        handleClickSelectItem={handleSelect("target")}
        value={formik.values.target}
        className="row-margin-bottom"
      >
        {realEstateGoalSelects.map((item) => (
          <Select.SelectItem
            key={item.title}
            title={t(`translation.realEstateGoal.${item.title}`)}
            value={item.value}
          />
        ))}
      </Select>
      <Select
        controlProps={{
          label: t("translation.inputLabel.residence"),
          placeholder: t("translation.inputPlaceholder.dropdown"),
          name: "residenceType",
          isRequared: true,
          disabled: disabledStep,
          errorMessage:
            !formik.values.residenceType &&
            formik.errors.residenceType &&
            formik.touched.residenceType
              ? formik.errors.residenceType
              : "",
        }}
        handleClickSelectItem={handleSelect("residenceType")}
        value={formik.values.residenceType}
        className={isMobile ? "row-margin-bottom" : ""}
      >
        {residenceTypes.map((item) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Select.SelectItem key={item.id} title={item.name!} value={item.id} />
        ))}
      </Select>

      {disabledStep && (
        <Alert
          severity="error"
          className="disabled_inputs_notification topSpace"
        >
          {t("translation.alertMessage.disabledAddress")}
        </Alert>
      )}
      <div className="bootom_buttons_container">
        {!isMobile ? (
          <Button
            sizeVariant="Large"
            type="button"
            onClick={handleClickNextForm}
            className="next_button "
          >
            {t("translation.bookingPage.nextStep")}
            <NextArow className="button_icon " />
          </Button>
        ) : (
          <StickyButton
            sizeVariant="Large"
            type="button"
            onClick={handleClickNextForm}
            className="next_button "
          >
            {t("translation.bookingPage.nextStep")}
            <NextArow className="button_icon " />
          </StickyButton>
        )}
      </div>
    </>
  );
};

export default FormPartOne;
