import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { AgentCalendarI } from "store/models/calendars";
import { CalendarType } from "./CalendarConnectionSection";
import { useMobileContext } from "context/mobileContext";
import GoBackButton from "components/GoBackButton";
import { routesConstant } from "constants/appRoutesConstants";

interface AdministrateCalendarsModalPropsI {
  isOpen: boolean;
  calendars: AgentCalendarI[];
  calendarType: CalendarType | null;
  selectedCalendarList: AgentCalendarI[];
  onClickBack(): void;
  onClickSave(calendars: AgentCalendarI[]): void;
  setSelectedCalendarList(calendars: AgentCalendarI[]): void;
}

const AdministrateCalendarsModal: React.FC<
  AdministrateCalendarsModalPropsI
> = ({
  isOpen,
  calendars,
  calendarType,
  selectedCalendarList,
  onClickBack,
  onClickSave,
  setSelectedCalendarList,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const title =
    calendarType === CalendarType.addModal
      ? t("translation.agentCalendar.addPopupTitle")
      : t("translation.agentCalendar.deletePopupTitle");

  const handleChangeCalendarCheckbox =
    (item: AgentCalendarI) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const selectedItem = {
        calendar_id: item.calendar_id,
        name: item.name,
        ...(item.id ? { id: item.id } : {}),
      } as AgentCalendarI;

      if (checked)
        return setSelectedCalendarList([...selectedCalendarList, selectedItem]);

      return setSelectedCalendarList([
        ...selectedCalendarList.filter(
          (item) => item.calendar_id !== selectedItem.calendar_id
        ),
      ]);
    };

  const handleSaveCalendar = () => onClickSave(selectedCalendarList);

  const handleGoBackMobile = () => onClickBack();

  return (
    <Modal
      open={isOpen}
      isCloseButton={!isMobile}
      classNameDialog={`administrate_calendar_modal_container ${
        isMobile ? "mobile" : ""
      }`}
      title={!isMobile ? title : undefined}
      handleClose={!isMobile ? onClickBack : undefined}
    >
      <div className="calendar_modal_wrapper">
        {isMobile && (
          <>
            <span onClick={handleGoBackMobile} className="close_button_wrapper">
              <GoBackButton
                isMobile={isMobile}
                className="back_button"
                to={routesConstant.agentProfile}
              />
            </span>
            <Text className="title-small20 mobile_title">{title}</Text>
          </>
        )}
        <Text
          variant="paragraph-body-bold"
          className={`modal_message ${isMobile ? "label-medium" : ""}`}
        >
          {t("translation.agentCalendar.description")}
        </Text>
        <div className="modal_calendar_container">
          {calendars.length ? (
            <FormGroup>
              {calendars.map((item: AgentCalendarI) => {
                return (
                  <FormControlLabel
                    key={item.calendar_id}
                    className="calendar_item paragraph-medium administrat_calendar_item"
                    control={
                      <Checkbox
                        checked={item.is_used}
                        onChange={handleChangeCalendarCheckbox(item)}
                        name="gilad"
                      />
                    }
                    label={item.name}
                  />
                );
              })}
            </FormGroup>
          ) : (
            <div className="no_calendars_title paragraph-medium">
              {t("translation.agentCalendar.noCalendars")}
            </div>
          )}
        </div>
        <div className="modal_buttons_container">
          <Button
            className="modal_button"
            onClick={handleSaveCalendar}
            disabled={!calendars.length}
          >
            {t("translation.agentCalendar.saveButton")}
          </Button>
          <Button
            variant="outlined"
            className="modal_button"
            onClick={onClickBack}
          >
            {t("translation.agentCalendar.cancelButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AdministrateCalendarsModal;
