import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";

import api from "api";
import { setTokens, deleteToken, getToken } from "utils/auth";
import { LoginDataI, WorkingScheduleDataI } from "../models/agent";
import {
  AgentProfileUpdateI,
  CreateUserDataI,
  EditUserDataT,
  RestorePasswordRequestData,
  ChangePasswordRequestData,
  Role,
} from "../models/profile";
import { TypeHousingRequestDataI } from "../models/typeHousing";
import { showAlerts } from "store/reducers/alerts";
import { getSellerAddresses } from "./locations";
import { ChosenAgentI } from "store/models/booking";
import { setIsPreBookingHidden } from "store/reducers/booking";
import { RootState } from "store";
import { trackingAgentLogin } from "utils/facebookTracking";
import { clearPrefillData } from "store/reducers/bankId";
import { clearPrefillGoogleData } from "store/reducers/googleAuth";

export const profileAboutSelf = createAsyncThunk(
  "profile/profileAboutSelf",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      if (token) {
        api.token = token;
        const res = await api.profile.aboutSelf();
        const isSeller = res.data.role === Role.SELLER;
        if (isSeller) {
          dispatch(getSellerAddresses());
        }
        return res.data;
      } else {
        throw new Error("Account isn't auth");
      }
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: error.response.data?.detail.detail,
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const userLogin = createAsyncThunk(
  "profile/userLogin",
  async (data: LoginDataI, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.login.userLogin(data);
      setTokens(res.data);
      if (res.status === 200) {
        trackingAgentLogin();
      }
      dispatch(profileAboutSelf());
    } catch (error: any) {
      dispatch(
        showAlerts({
          message:
            error.response.data?.detail ||
            i18next.t("translation.alertMessage.loginFailed"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "profile/logout",
  async (_, { dispatch, getState }) => {
    (async () => {
      const _state = getState() as RootState;

      if (_state.booking.chosenAgents.length) {
        return _state.booking.chosenAgents.forEach(
          async (agent: ChosenAgentI) => {
            await api.agent
              .cancelReserveTimeSlot({
                start_time: agent.selectedTimeSlot.startDate,
                agent_id: agent.id,
              })
              .finally(() => {
                deleteToken();
                api.token = undefined;
              });
          }
        );
      }

      deleteToken();
      dispatch(setIsPreBookingHidden(false));

      dispatch(clearPrefillData());
      dispatch(clearPrefillGoogleData());
      api.token = undefined;
    })();
  }
);

export const changeAgentAvatar = createAsyncThunk(
  "profile/changeAgentAvatar",
  async (file: FormData, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.agent.changeAgentAvatar(file);
      if (res.data) {
        dispatch(profileAboutSelf());
      }
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAgentAvatar = createAsyncThunk(
  "profile/deleteAgentAvatar",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.agent.changeAgentAvatar();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAgentProfile = createAsyncThunk(
  "profile/updateAgentProfile",
  async (body: AgentProfileUpdateI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.agent.updateAgentProfile(body);
      dispatch(
        showAlerts({
          type: "success",
          title: "Success",
          message: i18next.t("translation.alertMessage.successEditedProfile"),
        })
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAgentTypeHousing = createAsyncThunk(
  "profile/updateAgentTypeHousing",
  async (body: TypeHousingRequestDataI, { rejectWithValue }) => {
    try {
      const { data } = await api.typeHousing.updateAgentTypeHousing(body);
      return data.type_housing_ids.map((item) => ({ id: item }));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "profile/createUser",
  async (body: CreateUserDataI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.profile.createUser(body);

      if (data.jwt_pair) {
        dispatch(
          showAlerts({
            type: "success",
            title: "Success",
            message: i18next.t(
              "translation.alertMessage.successCreatedProfile"
            ),
            autoHideDuration: 10000,
          })
        );

        setTokens(data.jwt_pair);
        dispatch(profileAboutSelf());
      }

      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          type: "error",
          title: "Error",
          message: i18next.t("translation.alertMessage.failedCreatedProfile"),
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const editClientProfile = createAsyncThunk(
  "profile/editClientProfile",
  async (body: EditUserDataT, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.profile.editUser(body);
      dispatch(
        showAlerts({
          type: "success",
          title: "Success",
          message: i18next.t("translation.alertMessage.successUpdatedProfile"),
          autoHideDuration: 10000,
        })
      );
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          type: "error",
          title: "Error",
          message:
            error.response.data?.detail?.user ||
            i18next.t("translation.alertMessage.failedUpdatedProfile"),
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentWorkingSchedule = createAsyncThunk(
  "profile/getAgentWorkingSchedule",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.agent.getAgentWorkingSchedule();

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAgentWorkingSchedule = createAsyncThunk(
  "profile/updateAgentWorkingSchedule",
  async (body: WorkingScheduleDataI, { rejectWithValue }) => {
    try {
      const { data } = await api.agent.updateWorkingSchedule(body);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const passwordReatoreSendEmail = createAsyncThunk(
  "profile/passwordReatoreSendEmail",
  async (data: RestorePasswordRequestData, { rejectWithValue }) => {
    try {
      await api.passwordRecovery.sendEmail(data);
      return true;
    } catch (error) {
      return rejectWithValue("send email was failed");
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (data: ChangePasswordRequestData) => {
    try {
      await api.passwordRecovery.changePassword(data);
      return true;
    } catch (error) {
      throw "change password was wrong";
    }
  }
);
