import React, { memo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import { StoredAddressesI } from "store/models/locations";
import { AgentsEmailList } from "store/models/profile";
import Text from "components/common/Text";

import InputLabelText from "../InputLabelText";
import Spinner from "../Spinner";

type OptionT = StoredAddressesI | AgentsEmailList;

export interface TextInputPropsI extends InputBaseProps {
  errorMessage?: string;
  classNameContainer?: string;
  className?: string;
  helperTextClassName?: string;
  label?: string;
  helperText?: string;
  isRequared?: boolean;
  options?: OptionT[];
  isOpen: boolean;
  isNextAddress: boolean;
  emptyStateMessage: string;
  handleClosePopup(val: boolean): void;
  handlePickOptionItem(val: OptionT): void;
  handleLoadRequest(): void;
}

const AutocompleteInput: React.FC<TextInputPropsI> = ({
  classNameContainer = "",
  className = "",
  helperTextClassName = "",
  errorMessage = "",
  label = "",
  helperText = "",
  isRequared = true,
  options,
  isOpen,
  name,
  isNextAddress,
  emptyStateMessage,
  handleClosePopup,
  handlePickOptionItem,
  handleLoadRequest,
  disabled,
  ...rest
}) => {
  const onClickAway = () => handleClosePopup(false);

  const onFocusClick = () => {
    if (disabled) return;
    handleClosePopup(!isOpen);
  };

  const handleOptionItem = (option: OptionT) => () => {
    handlePickOptionItem(option);
    onClickAway();
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={`text_input_container ${classNameContainer}`}
        onClick={onFocusClick}
      >
        <InputLabelText
          className="text_input_label"
          label={label}
          isRequared={isRequared}
        />
        <div className="selector_container">
          <InputBase
            className={`text_input paragraph-body ${className}`}
            autoComplete="off"
            error={!!errorMessage}
            fullWidth
            name={name}
            disabled={disabled}
            {...rest}
          />
          {isOpen ? (
            <KeyboardArrowUpOutlinedIcon />
          ) : (
            <KeyboardArrowDownOutlinedIcon />
          )}
        </div>
        {isOpen ? (
          <div className="dropdown custom_slider">
            {options?.length ? (
              <InfiniteScroll
                dataLength={options.length}
                loader={
                  <div className="spinner_container">
                    <Spinner size={20} />
                  </div>
                }
                hasMore={isNextAddress}
                height="100%"
                next={handleLoadRequest}
                className="infinite_scroll"
              >
                {options.map((option: OptionT, ind: number) => (
                  <div
                    onClick={handleOptionItem(option)}
                    className="dropdown_item paragraph-body"
                    key={ind}
                  >
                    {name === "email"
                      ? (option as AgentsEmailList).email
                      : name === "address"
                      ? (option as StoredAddressesI).formattedAddress
                      : (option as StoredAddressesI).formattedPostalCode}
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className="dropdown_item paragraph-body">
                {emptyStateMessage}
              </div>
            )}
          </div>
        ) : null}
        {!!helperText && !errorMessage && (
          <Text
            text={helperText}
            variant="paragraph-body"
            className={`text_input_text helper_text ${helperTextClassName}`}
          />
        )}
        {!!errorMessage && (
          <Text
            text={errorMessage}
            variant="error-text"
            className="text_input_text error_text"
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default memo(AutocompleteInput);
