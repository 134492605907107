import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";

interface BookSelectedTimeModalPropsI {
  isOpen: boolean;
  isAuth: boolean;
  onClickBack: () => void;
  onClickContinue: () => void;
}

const BookSelectedTimeModal: React.FC<BookSelectedTimeModalPropsI> = ({
  isOpen,
  isAuth,
  onClickBack,
  onClickContinue,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  return (
    <Modal
      open={isOpen}
      isCloseButton={false}
      classNameDialog={`select_time_modal_dialog_container ${
        isMobile ? "mobile" : ""
      }`}
      title={t("translation.calendarPage.selectTimeModalTitle")}
    >
      <>
        <Text variant="paragraph-body-bold" className="description">
          {isAuth
            ? t("translation.calendarPage.selectTimeModalAuthDescription")
            : t("translation.calendarPage.selectTimeModalUnauthDescription")}
        </Text>
        <div className="modal_buttons_container">
          <Button
            variant="outlined"
            className="modal_button"
            id="cancel_select_time_button"
            onClick={onClickBack}
          >
            {t("translation.calendarPage.selectTimeModalCancelButton")}
          </Button>
          <Button
            className="modal_button"
            onClick={onClickContinue}
            id="book_select_time_button"
          >
            {isAuth
              ? t("translation.calendarPage.selectTimeModalBookButton")
              : t("translation.calendarPage.selectTimeModalLoginButton")}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default BookSelectedTimeModal;
