import AgentAuthForm from "./AgentAuthForm";
import AgentForgotPasswordForm from "./AgentForgotPasswordForm";
import AgentForgotPasswordEmailWasSent from "./AgentForgotPasswordEmailWasSent";
import AgentUpdatePassword from "./AgentUpdatePassword";
import AgentUpdatePasswordSuccess from "./AgentUpdatePasswordSuccess";
import AgentUpdatePasswordFailed from "./AgentUpdatePasswordFailed";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";

const AgentAuthPanel = () => {
  const { viewType } = useAuthContext();

  return (
    <div className="panel_item client_auth_body">
      {viewType === AuthAgentViewType.AUTH_FORM && <AgentAuthForm />}
      {viewType === AuthAgentViewType.FORGOT_PASSWORD_SEND_EMAIL && (
        <AgentForgotPasswordForm />
      )}
      {viewType === AuthAgentViewType.FORGOT_PASSWORD_EMAIL_WAS_SENT && (
        <AgentForgotPasswordEmailWasSent />
      )}
      {viewType === AuthAgentViewType.FORGOT_PASSWORD_UPDATE_PASSWORD && (
        <AgentUpdatePassword />
      )}
      {viewType === AuthAgentViewType.PASSWORD_WAS_UPDATED_SUCCESS && (
        <AgentUpdatePasswordSuccess />
      )}
      {viewType === AuthAgentViewType.PASSWORD_WAS_UPDATED_FAILED && (
        <AgentUpdatePasswordFailed />
      )}
    </div>
  );
};

export default AgentAuthPanel;
