import { useAppShallowSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { getAgentsSelector } from "store/reducers/agents";
import Text from "components/common/Text";

import AgentItem from "./AgentItem";

const AgentsList = () => {
  const agents = useAppShallowSelector(getAgentsSelector);
  const { t } = useTranslation();

  if (!agents.length)
    return (
      <Text variant="title-small" className="empty_message">
        {t("translation.bookingPage.noAgents")}
      </Text>
    );

  return (
    <div className="agents_list_container">
      {agents.map((agent) => (
        <AgentItem key={agent.id} {...agent} />
      ))}
    </div>
  );
};

export default AgentsList;
