import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DeistrictsStateI, DistrictI } from "../models/district";
import { getDistricts } from "../asyncActions/districts";
import { createTypedSelector } from "store/utils";

const initialState: DeistrictsStateI = {
  isFetchingDistricts: false,
  data: [],
};

export const getDistrictsSelector = createTypedSelector(
  (state) => state.districts.data
);

const districtsSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {
    clearDistricts: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [getDistricts.pending.type]: (state) => {
      state.isFetchingDistricts = true;
    },
    [getDistricts.fulfilled.type]: (
      state,
      action: PayloadAction<DistrictI[]>
    ) => {
      state.isFetchingDistricts = false;
      state.data = action.payload;
    },
    [getDistricts.rejected.type]: (state) => {
      state.isFetchingDistricts = false;
    },
  },
});

export const { clearDistricts } = districtsSlice.actions;

export default districtsSlice.reducer;
