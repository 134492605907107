import AvatarBlock from "./components/AvatarBlock";
import ProfileFormBlock from "./components/ProfileFormBlock";

const AgentProfileGeneralPage = () => {
  
  return (
    <div className="main_section_container">
      <AvatarBlock />
      <ProfileFormBlock />
    </div>
  );
};

export default AgentProfileGeneralPage;
