import { BookingValueI } from "store/models/booking";

import { lsApi } from "./localStorageApi";
import { getAddressData } from "common/inputAddressRule";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface AgentI {
  id: string;
  name: string;
}

export const trackingPrebookingSteps = () => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "Lead",
    context: values.target,
    object: {
      type: values.residenceName,
    },
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingStartBookingSteps = () => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "StartBooking",
    context: values.target,
    object: {
      type: values.residenceName,
    },
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingCreateAccount = () => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "CreateAccount",
    context: values.target,
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingViewAgent = (
  agentId: string,
  firstName: string,
  lastName: string
) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "ViewAgent",
    context: values.target,
    agent: {
      id: agentId,
      name: `${firstName} ${lastName}`,
    },
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingReserveAgentSlot = (
  agentId: string,
  firstName: string,
  lastName: string
) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "ReserveAgentSlot",
    context: values.target,
    agent: {
      id: agentId,
      name: `${firstName} ${lastName}`,
    },
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingConfirmAgentReserverations = (agents: AgentI[]) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "ConfirmAgentReserverations",
    context: values.target,
    agents: agents,
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingBookAgents = (agents: AgentI[]) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "BookAgents",
    context: values.target,
    agents: agents,
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingFailedLead = () => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "FailedLead",
    context: values.target,
    object: {
      type: values.residenceName,
    },
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingFirstStep = (values: BookingValueI) => {
  if (!values) return;

  window.dataLayer.push({
    event: "FunnelStep1",
    context: values.target,
  });
};

export const trackingSecondStep = (values: BookingValueI) => {
  if (!values) return;

  window.dataLayer.push({
    event: "FunnelStep2",
    context: values.target,
    object: {
      type: values.residenceName,
    },
  });
};

export const trackingStartFillingSecondStep = (values: BookingValueI) => {
  if (!values) return;

  window.dataLayer.push({
    event: "StartStep2",
    context: values.target,
    object: {
      type: values.residenceName,
    },
  });
};

export const trackingAgentLogin = () => {
  window.dataLayer.push({
    event: "login",
    user_type: "agent",
  });
};

export const trackingAgentOpenContactMeForm = (
  agentsData: { id: string; agentName: string }[]
) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "StartAgentForm",
    context: "SEL",
    agents: agentsData,
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};

export const trackingAgentSentContactMeForm = (
  agentsData: { id: string; agentName: string }[]
) => {
  const values = lsApi.get<BookingValueI>("bookingForm");
  if (!values) return;

  const { postalCode, streetName } = getAddressData(
    values.areaNumber,
    values.address
  );

  window.dataLayer.push({
    event: "FinishAgentForm",
    context: "SEL",
    agents: agentsData,
    location: {
      postalArea: streetName,
      district: values.district,
      county: values.county,
      zip: postalCode,
    },
  });
};
