import React, { memo, PropsWithChildren } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useCalendarContext } from "../useCalendarContext";

interface TimeSlotLayoutProps {
  isSelected?: boolean;
  isHourTimeSlot?: boolean;
  isOpenHourTimeSlot?: boolean;
  isOpenAnotherHourTimeSlot?: boolean;
}

const TimeSlotLayout = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<TimeSlotLayoutProps>
>(
  (
    {
      children,
      isSelected = false,
      isHourTimeSlot = false,
      isOpenHourTimeSlot = false,
      isOpenAnotherHourTimeSlot = false,
    },
    ref
  ) => {
    const { handleCloseOpenedHourSlots } = useCalendarContext();

    const classNameIsSelected = isSelected ? "selected_timeslot" : "";
    const classNameIsHourTimeSlot = isHourTimeSlot
      ? "hour_time_slot"
      : "time_time_slot";
    const classNameIsOpenHourTimeSlot =
      isOpenHourTimeSlot && isHourTimeSlot ? "hour_time_slot_open" : "";
    const classNameIsOpenAnotherHourTimeSlot =
      isOpenAnotherHourTimeSlot && isHourTimeSlot
        ? "hour_time_slot_open_another"
        : "";

    const handleOutsideClick = (e: MouseEvent) => {
      e.preventDefault();

      // if user clicks somewhere except continue or back button on 'BookSelectedTimeModal'
      // close opened hour slots
      if (
        (e.target as HTMLElement)?.id !== "book_select_time_button" &&
        (e.target as HTMLElement)?.id !== "cancel_select_time_button"
      ) {
        handleCloseOpenedHourSlots();
      }
    };

    return (
      <div
        className={`timeslot_container hour_time_slot ${classNameIsSelected} ${classNameIsHourTimeSlot} ${classNameIsOpenHourTimeSlot} ${classNameIsOpenAnotherHourTimeSlot}`}
        ref={ref}
      >
        <div
          className={`timeslot_wrap ${
            isOpenHourTimeSlot ? "timeslot_wrap_open" : ""
          }`}
        >
          <OutsideClickHandler
            disabled={!isOpenHourTimeSlot}
            onOutsideClick={handleOutsideClick}
          >
            {children}
          </OutsideClickHandler>
        </div>
      </div>
    );
  }
);

TimeSlotLayout.displayName = "TimeSlotLayout";

export default memo(TimeSlotLayout);
