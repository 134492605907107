import React, { memo } from "react";

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import TextInput from "components/common/TextInput";

import { SelectControlPropsI } from "../Select";

const SelectControl: React.FC<SelectControlPropsI> = ({
  value = "",
  placeholder = "",
  isOpen,
  startAdornment,
  withSearch,
  endAdornment,
  ...rest
}) => {
  const classNameOpened = isOpen ? "open" : "";
  const classNamePlaceholder = !value && placeholder ? "placeholder" : "";

  const _startAdornmentWithWrap = startAdornment ? (
    <span className="icon_start">{startAdornment}</span>
  ) : null;

  const _value = withSearch && isOpen ? value : value || placeholder;

  return (
    <TextInput
      className={`select_control ${classNameOpened} ${classNamePlaceholder}`}
      endAdornment={
        endAdornment ? (
          <span className="end_adornment_wrap">{endAdornment}</span>
        ) : (
          <span className="arrow_icon">{isOpen ?
          <KeyboardArrowUpOutlinedIcon /> :
          <KeyboardArrowDownOutlinedIcon />
          }</span>
        )
      }
      {...rest}
      value={_value}
      placeholder={placeholder}
      startAdornment={_startAdornmentWithWrap}
      type={withSearch && isOpen ? "text" : "button"}
    />
  );
};

export default memo(SelectControl);
