import React, { MouseEventHandler } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";

import Select, { SelectItemValue } from "components/common/Select";
import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";

interface PropsI {
  handleOpenCalendarsModal?(val: string): void;
}

const CalendarDetailsPopup: React.FC<PropsI> = ({handleOpenCalendarsModal}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const handleClick = (e: SelectItemValue) => {
    if (handleOpenCalendarsModal) handleOpenCalendarsModal(e.value as string)
  } 

  return (
    <Select
      alignBody={isMobile ? "right" : "left"}
      isCloseAfterClick
      renderControl={(props) => (
        <Button
          variant="outlined"
          sizeVariant={!isMobile ? "Large" : "Medium"}
          className="more_google_calendar_details"
          onClick={props.onClick as MouseEventHandler}
          startIcon={<MoreVertIcon />}
        />
      )}
    >
      <Select.SelectItem
        title={t('translation.agentCalendar.addPopupTitle')}
        value="add"
        type="link"
        onClick={handleClick}
      />
      <Select.SelectItem
        title={t('translation.agentCalendar.deletePopupTitle')}
        value="delete"
        type="link"
        onClick={handleClick}
      />
    </Select>
  );
};

export default CalendarDetailsPopup;
