import React, { memo } from "react";

import { memoEqual } from "utils/memoEqual";

interface RowPropsI {
  className?: string;
}

const Row: React.FC<React.PropsWithChildren<RowPropsI>> = ({
  children,
  className = "",
}) => {
  return <tr className={`table_row ${className}`}>{children}</tr>;
};

export default memo(Row, memoEqual);
