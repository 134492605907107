import { useFormik } from "formik";

import Text from "components/common/Text";
import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import { userLogin } from "store/asyncActions/profile";
import { useAppDispatch } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { AgentSignInSchema } from "common/validationSchemas";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";

interface AgentFormT {
  email: string;
  password: string;
}

const AgentAuthForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile, setViewType } = useAuthContext();

  const formik = useFormik<AgentFormT>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit(values) {
      dispatch(userLogin(values));
    },
    validationSchema: AgentSignInSchema(),
    validateOnBlur: true,
  });

  return (
    <>
      {!isMobile && (
        <>
          <Text
            text={t("translation.agentAuthPage.title")}
            variant="title-medium"
            className="panel_item_title"
          />
          <Text
            text={t("translation.agentAuthPage.subtitle")}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <form
        className="agent_signin_form_container"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div className={`${isMobile ? "mobile_form_group" : ""}`}>
          <TextInput
            label={`${t("translation.inputLabel.email")}`}
            placeholder={`${t("translation.inputPlaceholder.email")}`}
            classNameContainer="agent_sing_input"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ""
            }
          />
          <TextInput
            label={`${t("translation.inputLabel.password")}`}
            placeholder={`${t("translation.inputPlaceholder.password")}`}
            classNameContainer="agent_sing_input last"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ""
            }
          />
          <Button
            sizeVariant={isMobile ? undefined : "Large"}
            type="submit"
            className="login_btn"
          >
            {`${t("translation.agentAuthPage.loginButton")}`}
          </Button>
        </div>
      </form>
      <div className="bottom_section agent_bottom_section">
        <Text className="support_text" variant="paragraph-body-bold">
          {t("translation.agentAuthPage.forgotPassword")}?
        </Text>
        <Button
          variant="text"
          onClick={() => {
            setViewType(AuthAgentViewType.FORGOT_PASSWORD_SEND_EMAIL)
          }}
          className="text_link reset_password_button"
          sizeVariant="Small"
        >
          {t("translation.agentAuthPage.resetPassword")}
        </Button>
      </div>
    </>
  );
};

export default AgentAuthForm;
