import AgentProfileAvatarSection from "components/AgentProfileAvatarSection";
import Text from "components/common/Text";
import ChoiseWorkingDays from "./components/ChoiseWorkingDays";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";
import DelayWorkingHours from "./components/DelayWorkingHours";

const AgentProfileOnlinePreferencePage = () => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  return (
    <div className="agent_profile_connect_calendar main_section_container longer">
      <AgentProfileAvatarSection />
      <div className="page_title_container">
        <Text variant="title-small20" className={isMobile ? "mobile_title" : "title"}>
          {t("translation.agentProfilePreference.workingHours")}
        </Text>
        <Text variant="paragraph-body">
        {t("translation.agentProfilePreference.body")}
        </Text>
      </div>
      <ChoiseWorkingDays />
      <DelayWorkingHours />
    </div>
  );
};

export default AgentProfileOnlinePreferencePage;
