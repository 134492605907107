import React, { memo } from "react";

import Row from "./Row";
import Cell from "./Cell";

import { HeaderCellDataI, HandleRemoteSortT } from "../Table.type";
import { memoEqual } from "utils/memoEqual";

interface TableHeaderPropsI {
  headerCellData: HeaderCellDataI[];
  handleSort?: HandleRemoteSortT;
}

const TableHeader: React.FC<TableHeaderPropsI> = ({
  headerCellData = [],
  handleSort,
}) => {
  return (
    <thead className="table_header">
      <Row>
        {headerCellData.map((headerItem) => {
          return (
            <Cell
              key={headerItem.dataField}
              content={headerItem.caption}
              dataField={headerItem.dataField}
              isHeader
              isSort={headerItem.isSort}
              sortField={headerItem.sortField}
              direction={headerItem.sortDirection}
              handleSort={handleSort}
            />
          );
        })}
      </Row>
    </thead>
  );
};

export default memo(TableHeader, memoEqual);
