import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AlertsStateI } from "store/models/alerts";
import { createTypedSelector } from "../utils";

const initialState: AlertsStateI = {
  isOpen: false,
  type: "success",
  message: "",
  title: "",
  autoHideDuration: 5000,
  persist: false,
};

export const getAlertsStateSelector = createTypedSelector(
  (state) => state.alerts
);

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showAlerts: (
      state,
      action: PayloadAction<Omit<AlertsStateI, "isOpen">>
    ) => {
      state.isOpen = true;
      state.type = action.payload.type || initialState.type;
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.autoHideDuration = action.payload.persist
        ? 0
        : action.payload.autoHideDuration ?? initialState.autoHideDuration;
      state.persist = action.payload.persist;
    },
    closeAlert: (state) => {
      state.isOpen = false;
      state.type = initialState.type;
      state.title = initialState.title;
      state.message = initialState.message;
      state.autoHideDuration = initialState.autoHideDuration;
      state.persist = initialState.persist;
    },
  },
});

export const { showAlerts, closeAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
