import React from "react";
import RoundAvatar from "components/RoundAvatar";

import Text from "components/common/Text";
import { useAppSelector, useAppShallowSelector } from "hooks/redux";
import {
  getAgentProfileGeneralFormSelector,
  getAgentProfileAvatarSelector,
} from "store/reducers/profile";
import { useMobileContext } from "context/mobileContext";

const AgentProfileAvatarSection: React.FC = () => {
  const { firstName, lastName } = useAppShallowSelector(
    getAgentProfileGeneralFormSelector
  );
  const avatarSrc = useAppSelector(getAgentProfileAvatarSelector);
  const { isMobile } = useMobileContext();

  return (
    <div className={`${isMobile ? "mobile" : ""} avatar_section`}>
      <RoundAvatar className="avatar" src={avatarSrc} />
      <Text variant="title-small" className={`content_container ${isMobile ? "title-small20" : ""}`}>
        <span className="content">{`${firstName} ${lastName}`}</span> 
      </Text>
    </div>
  );
};

export default AgentProfileAvatarSection;
