import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { format, add, fromUnixTime, getUnixTime } from "date-fns";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { routesConstant } from "constants/appRoutesConstants";
import { FreeTimeSlotsQueryParams } from "store/models/agent";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { getShortAgentDetailSelector } from "store/reducers/agents";
import {
  clearFreeTimeSlots,
  getIsFetchingReserveTimeSlotSelector,
  setIsOpenConfirmBookingWindow,
  setIsPreBookingHidden,
} from "store/reducers/booking";
import {
  cancelReserveTimeSlot,
  getAgentTimeSlots,
  reserveTimeSlot,
} from "store/asyncActions/booking";
import { useMobileContext } from "context/mobileContext";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import BlockLoader from "components/BlockLoader";
import { trackingReserveAgentSlot } from "utils/facebookTracking";
import { getAddressData } from "common/inputAddressRule";
import { Role } from "store/models/profile";
import { lsApi } from "utils/localStorageApi";
import { BookingValueI } from "store/models/booking";
import { checkIsPostalCodeActive } from "store/asyncActions/locations";

import { useFilterCalendarContext } from "./useFilterCalendarContext";
import { useCalendarContext } from "./useCalendarContext";
import Filter from "./components/Filter";
import BookSelectedTimeModal from "./components/BookSelectedTimeModal";
import Calendar from "./components/Calendar";
import AddressAbsentModal from "pages/BookingPage/components/AddressAbsentModal";

export enum MobileViewType {
  today = "today",
  threeDays = "threeDays",
}

// format
export const getDateQueryFormat = (timestamo: number): string => {
  return format(fromUnixTime(timestamo), "YYY-MM-dd");
};

const BookingMeetingCalendar = () => {
  const location = useLocation() as { state: { changeTime: boolean } };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();

  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAgent = roleUser === Role.AGENT;

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [mobileView, setMobileView] = useState<MobileViewType>(
    MobileViewType.threeDays
  );

  const { firstDayOfCalendarTimestamp, currentDayTimestamp } =
    useFilterCalendarContext();

  const isNextDay = firstDayOfCalendarTimestamp !== currentDayTimestamp;

  const currentDayOfCalendar = getUnixTime(
    fromUnixTime(firstDayOfCalendarTimestamp)
  );

  const lastDayOfCalendar = getUnixTime(
    add(fromUnixTime(firstDayOfCalendarTimestamp), {
      days: !isMobile ? 3 : mobileView === MobileViewType.threeDays ? 2 : 1,
    })
  );

  const { isAgentCalendar, selectedTimeSlot, handleCloseOpenedHourSlots } =
    useCalendarContext();

  const isAuth = useAppSelector(getIsAuthUserSelector);
  const { id: agentId, ...restAgentData } = useAppSelector(
    getShortAgentDetailSelector
  );

  // Temporary solution. Change it after added house data to profile
  const storageValues = lsApi.get<BookingValueI>("bookingForm");
  const {
    preBookingFormik: {
      values: { address_id, areaNumber, address, residenceType },
    },
  } = useBookingFrom();
  const postalCodeValue = getAddressData(areaNumber, address).postalCode;

  const params = useMemo(() => {
    // send request without params to get data for next 7 days
    if (!isNextDay) {
      if (!isMobile)
        return {
          start_date: getDateQueryFormat(currentDayOfCalendar),
          end_date: getDateQueryFormat(lastDayOfCalendar),
        };

      return {
        start_date: getDateQueryFormat(currentDayOfCalendar),
        end_date:
          mobileView === MobileViewType.threeDays
            ? getDateQueryFormat(lastDayOfCalendar)
            : undefined,
      };
    }

    // send request only for selected day
    if (isMobile && mobileView === MobileViewType.today) {
      return {
        start_date: getDateQueryFormat(currentDayOfCalendar),
      };
    }

    return {
      start_date: getDateQueryFormat(currentDayOfCalendar),
      end_date: getDateQueryFormat(lastDayOfCalendar),
    };
  }, [
    isNextDay,
    currentDayOfCalendar,
    isMobile,
    lastDayOfCalendar,
    mobileView,
  ]) as FreeTimeSlotsQueryParams;

  const loadAgentTimeSlots = useCallback(
    (agentId: string, params?: FreeTimeSlotsQueryParams) => {
      dispatch(
        getAgentTimeSlots({
          id: agentId,
          params,
        })
      );
    },
    [dispatch]
  );

  const handleClickBookSelectedTime = async () => {
    dispatch(
      checkIsPostalCodeActive({
        id: postalCodeValue,
        type_housing_id: residenceType,
      })
    ).then(async (data) => {
      if (!data.payload) {
        handleCloseOpenedHourSlots();
        return setIsNotificationModalOpen(true);
      }

      if (selectedTimeSlot) {
        if (location?.state?.changeTime) {
          await dispatch(
            cancelReserveTimeSlot({
              startDate: selectedTimeSlot.startDate,
              id: agentId,
              isChangeTime: true,
            })
          );
        }
        dispatch(
          reserveTimeSlot({
            startDate: selectedTimeSlot.startDate,
            agent_id: agentId,
            address_id: Number(address_id),
            // Temporary solution. Change it after added house data to profile
            // and get it from values
            type_housing_id: storageValues?.residenceType || residenceType,
            postal_code: Number(postalCodeValue),
          })
        )
          .unwrap()
          .then(() => {
            trackingReserveAgentSlot(
              agentId,
              restAgentData.firstName,
              restAgentData.lastName
            );
            handleCloseOpenedHourSlots();
            dispatch(setIsOpenConfirmBookingWindow(true));
            navigate(routesConstant.booking);
            dispatch(setIsPreBookingHidden(true));
          });
      }
    });
  };

  const handleRedirect = () =>
    navigate(routesConstant.auth, {
      state: {
        agentId: agentId,
      },
    });

  useEffect(() => {
    /// load agent timeslots effect
    if (isAgentCalendar && agentId) {
      loadAgentTimeSlots(agentId, params);
    }
  }, [agentId, isAgentCalendar, mobileView, loadAgentTimeSlots, params]);

  useEffect(() => {
    return () => {
      handleCloseOpenedHourSlots();
      dispatch(clearFreeTimeSlots());
    };
  }, [dispatch, handleCloseOpenedHourSlots]);

  return (
    <>
      <div
        className={`booking_meeting_calendar_container ${
          isMobile ? "mobile_container" : ""
        }`}
      >
        <div className="booking_meeting_calendar_wrap">
          <Filter mobileView={mobileView} setMobileView={setMobileView} />
          {isAgentCalendar ? <Calendar mobileView={mobileView} /> : null}
        </div>
      </div>
      <BlockLoader selector={getIsFetchingReserveTimeSlotSelector} />
      {isAgentCalendar && !isAgent && (
        <BookSelectedTimeModal
          isOpen={!!selectedTimeSlot}
          isAuth={isAuth}
          onClickBack={handleCloseOpenedHourSlots}
          onClickContinue={
            isAuth ? handleClickBookSelectedTime : handleRedirect
          }
        />
      )}
      <AddressAbsentModal
        isOpen={isNotificationModalOpen}
        navigateToPrebooking={true}
        onClose={() => setIsNotificationModalOpen(false)}
      />
    </>
  );
};

export default BookingMeetingCalendar;
