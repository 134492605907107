import React, { memo } from "react";
import Button, { ButtonProps } from "@mui/material/Button";

import Spinner from "components/common/Spinner";

interface ButtonStyledProps extends ButtonProps<React.ElementType> {
  showLoadingIndicator?: boolean;
  sizeVariant?: "Large" | "Medium" | "Small" | "Small-round";
}

const ButtonStyled: React.FC<ButtonStyledProps> = ({
  children,
  className = "",
  sizeVariant = "Medium",
  showLoadingIndicator = false,
  ...rest
}) => {
  return (
    <Button
      className={`styled_button ${sizeVariant} ${className}`}
      variant="contained"
      color="primary"
      fullWidth={false}
      {...rest}
    >
      {children}
      {showLoadingIndicator && (
        <span className="button_spinner_wrap">
          <Spinner size={24} className="button_spinner" />
        </span>
      )}
    </Button>
  );
};

export default memo(ButtonStyled);
