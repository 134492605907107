import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/common/Modal";
import Button from "components/common/Button";

import { useMobileContext } from "context/mobileContext";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { deleteMeeting } from "store/asyncActions/meetings";
import { getIsFetchingDeletingMeetingSelector } from "store/reducers/meetings";
import PageLoader from "components/PageLoader";


interface DeleteMeetingModalPropsI {
  isOpen: boolean;
  agentMeetingId?: number;
  setIsOpenModal(isOpen: boolean): void;
}

const DeleteMeetingModal: React.FC<DeleteMeetingModalPropsI> = ({
  isOpen,
  agentMeetingId,
  setIsOpenModal,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const dispatch = useAppDispatch();
  const isLoading = useAppShallowSelector(getIsFetchingDeletingMeetingSelector);

  if (isLoading) return <PageLoader />

  const handleClose = () => setIsOpenModal(false);

  const handleDeleteMeeting = () => {
    if (agentMeetingId) {
      dispatch(deleteMeeting({
        id: `${agentMeetingId}`
      }))
      .unwrap()
      .then(() => setIsOpenModal(false));
    }
  }

  return (
    <Modal
      open={isOpen}
      isCloseButton={!isMobile}
      classNameDialog={`delete_meeting_modal_container ${isMobile ? "mobile" : ""}`}
      title={t("translation.agentMeetings.cancelMeetingModalTitle")}
      handleClose={handleClose}
    >
      <div className="modal_wrapper">
        <div className="modal_buttons_container">
          <Button className="modal_button" onClick={handleDeleteMeeting}>
            {t("translation.agentMeetings.cancelMeetingApplyButton")}
          </Button>
          <Button
            variant="outlined"
            className="modal_button"
            onClick={handleClose}
          >
            {t("translation.agentMeetings.cancelMeetingCloseButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteMeetingModal;
