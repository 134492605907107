import React from "react";
import Modal, { ModalProps } from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Text from "components/common/Text";
import { useMobileContext } from "context/mobileContext";

interface ModalPropsI extends ModalProps {
  title?: string;
  classNameContainer?: string;
  classNameDialog?: string;
  handleClose?: () => void;
  isCloseButton?: boolean;
  //children?: React.ReactNode | React.ReactChild;
}

const ModalStyled: React.FC<ModalPropsI> = ({
  title = "",
  classNameContainer = "",
  classNameDialog = "",
  children,
  handleClose,
  isCloseButton = true,
  ...rest
}) => {
  const { isMobile } = useMobileContext();
  const _handleClose = () => {
    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  return (
    <Modal className={`modal_container ${classNameContainer}`} {...rest}>
      <div className={`${isMobile ? 'mobile' : ''} modal_wrap ${classNameDialog}`}>
        {isCloseButton && (
          <IconButton className="close_button" onClick={_handleClose}>
            <CloseIcon className={`${isMobile ? 'mobile_' : ''}close_button_icon`} />
          </IconButton>
        )}
        {!!title && (
          <Text text={title} variant="title-medium" className={`${isMobile ? "title-small-bold" : ""} modal_title`} />
        )}
        {children}
      </div>
    </Modal>
  );
};

export default ModalStyled;
