import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "components/Layout";
import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import { useAppSelector } from "hooks/redux";
import { getRoleProfileSelector } from "store/reducers/profile";
import { authRoutes } from "./authRoutes";
import { agentRoutes } from "./agentRoutes";
import { agentPresentationProfileRoute } from "./agentPresentationProfileRoute";
import { bookingRoute } from "./bookingRoute";
import { clientMyMeetingsRoute } from "./clientMyMeetingsRoute";
import { sellerProfileRoutes } from "./sellerProfileRoutes";
import { unknown404Route } from "./404";
import { customerAnalysisRoute } from "./customerAnalysisRoute";
import { adminAuthRoute } from "./adminLoginRoute";
import { thankYouPageRoute } from "./thankYouPage";
import { downloadingPdfRoute } from "./downloadingPdfRoute";

const RootRoutes = () => {
  const roleUser = useAppSelector(getRoleProfileSelector);

  return (
    <Routes>
      <Route path={routesConstant.rootPage} element={<Layout />}>
        <Route
          index
          element={
            <Navigate
              to={
                roleUser === Role.ADMIN ?
                routesConstant.adminAuthAbsolutely :
                roleUser === Role.AGENT
                  ? routesConstant.agentAbsolutely
                  : routesConstant.bookingAbsolutely
              }
              replace
            />
          }
        />
        {authRoutes}
        {agentRoutes}
        {agentPresentationProfileRoute}
        {bookingRoute}
        {clientMyMeetingsRoute}
        {sellerProfileRoutes}
        {customerAnalysisRoute}
        {thankYouPageRoute}
        {adminAuthRoute}
        {unknown404Route}
        {downloadingPdfRoute}
      </Route>
    </Routes>
  );
};

export default RootRoutes;
