import { Route } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";

export const unknown404Route = (
  <Route
    path={routesConstant.unknown}
    element={<span>Page not found! 404</span>}
  />
);
