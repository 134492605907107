import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultNS } from "i18n";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Text from "components/common/Text";
import { PassedMeetingData } from "store/models/meetings";

type ItemSectionType = {
  data: PassedMeetingData[];
};

const MobileListSection = ({ data }: ItemSectionType) => {
  const { t } = useTranslation();

  const [activeMeeting, setActiveMeeting] = useState(data[0]);
  const activeMeetIndex = data.findIndex(
    (item) => item.id === activeMeeting?.id
  );

  const customerName = activeMeeting
    ? activeMeeting.firstName + " " + activeMeeting.lastName
    : "";

  const getDateTitle = (num: string) => {
    const date = new Date(num);
    const lang = defaultNS === "en" ? "en-EN" : "sv-SE";

    const month = date.toLocaleDateString(lang, { month: "long" });

    return `${date.getDate()} ${month} ${date.getFullYear()}`;
  };

  const handleClickPrev = () => {
    if (activeMeeting?.id === data[0].id) {
      return setActiveMeeting(data[data.length - 1]);
    }

    setActiveMeeting(data[activeMeetIndex - 1]);
  };

  const handleClickNext = () => {
    if (activeMeeting?.id === data[data.length - 1].id) {
      return setActiveMeeting(data[0]);
    }

    setActiveMeeting(data[activeMeetIndex + 1]);
  };

  useEffect(() => {
    setActiveMeeting(data[0]);
  }, [data]);

  return (
    <div className="item_section">
      <div className="date_wrapper">
        <button onClick={handleClickPrev} className="navigate_button_container">
          <KeyboardArrowLeftIcon className="navigate_button" />
        </button>
        <Text
          text={
            activeMeeting
              ? getDateTitle(activeMeeting?.date)
              : t("translation.clientMeetingPage.date")
          }
          className="title title-small20"
          variant="small-header"
        />
        <button onClick={handleClickNext} className="navigate_button_container">
          <KeyboardArrowRightIcon className="navigate_button" />
        </button>
      </div>
      <div className="data_wrapper">
        {!data.length ? (
          <Text
            text={t("translation.clientMeetingPage.noPrevMeetings")}
            className="empty_state paragraph-body"
          />
        ) : (
          <>
            <div className="section">
              <Text text={customerName} className="value paragraph-body" />
              <Text
                text={t("translation.agentMeetings.customer")}
                className="name navigation-link"
              />
            </div>
            <div className="section">
              <Text
                text={activeMeeting?.email}
                className="value paragraph-body"
              />
              <Text
                text={t("translation.agentMeetings.mail")}
                className="name navigation-link"
              />
            </div>
            <div className="section">
              <Text
                text={activeMeeting?.phone}
                className="value paragraph-body"
              />
              <Text
                text={t("translation.agentMeetings.phone")}
                className="name navigation-link"
              />
            </div>
            <div className="section">
              <Text
                text={activeMeeting?.street}
                className="value paragraph-body"
              />
              <Text
                text={t("translation.agentMeetings.street")}
                className="name navigation-link"
              />
            </div>
            <div className="section">
              <Text
                text={activeMeeting?.areaNumber}
                className="value paragraph-body"
              />
              <Text
                text={t("translation.agentMeetings.city")}
                className="name navigation-link"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileListSection;
