import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import App from "components/App";
import ScrollToTop from "components/ScrollToTop";
import { setupStore } from "store";
import { theme } from "theme";

import "./i18n";
import "styles/index.scss";
import { MobileContextProvider } from "context/mobileContext";

export const store = setupStore();

ReactDOM.render(
  <BrowserRouter basename="/app">
    <ScrollToTop />
    <SnackbarProvider maxSnack={6}>
      <MobileContextProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </MobileContextProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
