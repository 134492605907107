import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ScheduleIcon from "@mui/icons-material/Schedule";

import WhiteWrapBlock from "components/WhiteWrapBlock";
import Select from "components/common/Select";
import Text from "components/common/Text";
import { getCustomerAnalysisPDF } from "store/asyncActions/customerAnalysis";
import Pdf from "components/Svg/Pdf";

import { MeetingItemInterface } from "../NextMeetingList";
import DeleteMeetingModal from "./DeleteMeetingModal";

interface AdditionalMenuI {
  customerAnalysis: string | null;
  setIsOpenDeleteMeetingModal(isOpen: boolean): void;
}

const SellerMeetingListItem: React.FC<MeetingItemInterface> = ({
  title,
  timeDistance,
  meetingTime,
  date,
  customerAnalysis,
  isMobile,
  street,
  postalCode,
  isToday,
  agentMeetingId,
  agencyName
}) => {
  const [isOpenDeleteMeetingModal, setIsOpenDeleteMeetingModal] = useState(false);

  const classNameToday = isToday ? "today" : "";

  return (
    <>
      <DeleteMeetingModal 
        isOpen={isOpenDeleteMeetingModal} 
        agentMeetingId={agentMeetingId}
        setIsOpenModal={setIsOpenDeleteMeetingModal}
      />
      <WhiteWrapBlock className={`meeting_item ${classNameToday}`}>
        <div className={`${agencyName ? "special_gap" : ""} row title_section`}>
          <Text
            text={title}
            className={`h4 title ${isMobile ? "title-small20-bold" : ""}`}
            tooltipTitle={title}
            variant="title-small"
          />
          {isMobile ? (
            <AdditionalMenu 
              customerAnalysis={customerAnalysis} 
              setIsOpenDeleteMeetingModal={setIsOpenDeleteMeetingModal} />
          ) : (
            <Text
              text={timeDistance}
              className={`subtitle ${classNameToday}_subtitle`}
              variant="label-medium"
            />
          )}
        </div>
        <div className={`${!isMobile ? "title_section" : ""} row`}>
          <Text
            text={agencyName}
            className="location_item_text street"
            variant="paragraph-body"
          />
        </div>

        {!isMobile && (
          <div className="row">
            <span className="location_item">
              <InsertInvitationIcon className="icon" />
              <Text
                text={date}
                className="location_item_text"
                variant="paragraph-body"
              />
            </span>
            <span className="location_item">
              <ScheduleIcon className="icon" />
              <Text
                text={meetingTime}
                className="location_item_text"
                variant="paragraph-body"
              />
            </span>
            <AdditionalMenu 
              customerAnalysis={customerAnalysis}
              setIsOpenDeleteMeetingModal={setIsOpenDeleteMeetingModal} /> 
          </div>
        )}

        <div className="title_section row">
          <span className="location_item baseline">
            <PlaceOutlinedIcon className="icon block" />
            <div className="location_container">
              <Text
                text={street}
                className="location_item_text street"
                variant="paragraph-body"
              />
              <Text
                text={postalCode}
                className="location_item_text"
                variant="paragraph-body"
              />
            </div>
          </span>
          {isMobile && (
            <div className="mini_location_item_container">
              <span className="mini_location_item">
                <Text
                  text={timeDistance}
                  className="label-extra-small distance"
                  variant="paragraph-body"
                />
                </span>
                <span className="mini_location_item">
                <Text
                  text={meetingTime}
                  className="label-extra-small time"
                  variant="paragraph-body"
                />
              </span>
            </div>
          )}
        </div>
      </WhiteWrapBlock>
    </>
  );
};

const AdditionalMenu: React.FC<AdditionalMenuI> = ({customerAnalysis, setIsOpenDeleteMeetingModal}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancelMeeting = () => setIsOpenDeleteMeetingModal(true);

  const handleOpenCustomerAnalisys = () => {
    if (customerAnalysis) {
       dispatch(getCustomerAnalysisPDF(customerAnalysis));
    }
  };

  return (
    <div className="additional_menu">
      <Select
        alignBody="right"
        isCloseAfterClick
        renderControl={(props) => (
          <span 
            className="dots"
            onClick={props.onClick as MouseEventHandler}
          >
            <MoreVertIcon />
          </span>
        )}
      >
        <Select.SelectItem
          title={t("translation.agentMeetings.customerAnalisys")}
          value="add"
          type="link"
          onClick={handleOpenCustomerAnalisys}
          endIcon={Pdf}
          disabled={!customerAnalysis}
        />
        <Select.SelectItem
          title={t("translation.agentMeetings.cancelMeeting")}
          value="add"
          type="link"
          className="cancel_button"
          onClick={handleCancelMeeting}
        />
      </Select>
    </div>
  )
}

export default SellerMeetingListItem;
