import { AxiosInstance, AxiosPromise } from "axios";
import { CustomerAnalysisRequest } from "store/models/customerAnalysis";
import CRUD from "../base";


export class CustomerAnalysisAPI extends CRUD {
  getQuestions(id: string): AxiosPromise {
    return this.request({
      url: `${this.url}/${id}/`,
    });
  }

  saveAnswers(id: string, data: CustomerAnalysisRequest): AxiosPromise {
    return this.request({
      url: `${this.url}/${id}/save_answers/`,
      method: "PATCH",
      data,
    });
  }

  getCustomerAnalysisPDF(id: string): AxiosPromise {
    return this.request({
      url: `${this.url}/${id}/pdf/`,
    });
  }
}

export default function customerAnalysisApi(request: AxiosInstance) {
  return new CustomerAnalysisAPI({
    url: "/customer_analysis",
    request,
  });
}
