import { Route } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import ClientMyMeetingPage from "pages/ClientMyMeetingPage";

export const clientMyMeetingsRoute = (
  <Route
    path={routesConstant.clientMyMeetingsLocationAbsolutely}
    element={
      <RequireAuth
        userRoleOnly={Role.SELLER}
        navigateTo={routesConstant.rootPage}
        requaredAuth={true}
      >
        <ClientMyMeetingPage />
      </RequireAuth>
    }
  />
);
