import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import uniqueId from "lodash/uniqueId";

import Text from "components/common/Text";
import TableSection from "./components/TableSection";
import GoBackButton from "components/GoBackButton";
import { routesConstant } from "constants/appRoutesConstants";
import { useMobileContext } from "context/mobileContext";
import MobileListSection from "./components/MobileListSection";

import { getPassedMeetings } from "store/asyncActions/meetings";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { getPassedMeetingsSelector } from "store/reducers/meetings";

const AgentPreviousMeetingsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const passedMeetings = useAppShallowSelector(getPassedMeetingsSelector).map(
    (meeting) => ({ ...meeting, id: uniqueId() })
  );

  useEffect(() => {
    dispatch(getPassedMeetings());
  }, [dispatch]);

  return (
    <div
      className={`page_container agent_previous_meeting_page ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      <GoBackButton
        to={routesConstant.agentMyMeeting}
        className="back_upcoming_meeting"
        title={t("translation.agentMeetings.upcomingMeetButton")}
      />
      <Text
        text={t("translation.agentMeetings.prevMeetTitle")}
        className="page_title"
        component={"h1"}
        variant="small-header"
      />
      {isMobile ? (
        <MobileListSection data={passedMeetings} />
      ) : (
        <TableSection data={passedMeetings} />
      )}
    </div>
  );
};

export default AgentPreviousMeetingsPage;
