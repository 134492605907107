import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  BankIdAuthInitialI,
  CheckCollectBankIdResponceI,
  UpdateQrCodeI,
} from "store/models/bankId";

export class BankId extends CRUD {
  authInitiate(): AxiosPromise<BankIdAuthInitialI> {
    return this.request({
      url: `${this.url}/auth-initiate/`,
      method: "POST",
    });
  }

  checkCollect(orderRef: string): AxiosPromise<CheckCollectBankIdResponceI> {
    return this.request({
      url: `${this.url}/collect/${orderRef}/`,
    });
  }

  updateQrCode(orderRef: string): AxiosPromise<UpdateQrCodeI> {
    return this.request({
      url: `${this.url}/get-qr-code/${orderRef}/`,
    });
  }
}

export default function bankId(request: AxiosInstance) {
  return new BankId({
    url: "/bankid",
    request,
  });
}
