import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import {
  getConsentScreenUrl,
  checkConnectCalendar,
  calendarAuth,
  disconnectCalendars,
  getConnectTeamUrl,
  getAgentOtherCalendarsForConnection,
  getAgentOtherCalendars,
  saveOtherCalendarsToAgent,
  deleteOtherCalendarsFromAgent,
} from "../asyncActions/calendars";
import {
  ConsentScreenUrlRequestI,
  //CalendarProvider,
  AgentCalendarResponceI,
  TeamConnectResponseI,
  ConferencingProfile,
  ProviderType,
  AgentCalendarI,
} from "../models/calendars";

interface CalendarState {
  connectUrl: string | null;
  isFetching: boolean;
  connectStatus: ProviderType | null;
  conferencingProfile: ConferencingProfile[];
  agentOtherCalendars: AgentCalendarI[];
}

const initialState: CalendarState = {
  connectUrl: null,
  isFetching: false,
  connectStatus: null,
  conferencingProfile: [],
  agentOtherCalendars: []
};

export const getConnectStatusSelector = createTypedSelector(
  (state) => state.calendars.connectStatus
);

export const getCalendarConnectUrlSelector = createTypedSelector(
  (state) => state.calendars.connectUrl
);

export const getIsFetchingCalendarSelector = createTypedSelector(
  (state) => state.calendars.isFetching
);

export const getConferencingProfileSelector = createTypedSelector(
  (state) => state.calendars.conferencingProfile
);

export const getAgentOtherCalendarsSelector = createTypedSelector(
  (state) => state.calendars.agentOtherCalendars
);

const calendarsSlice = createSlice({
  name: "calendars",
  initialState,
  reducers: {
    clearConnectGoogleCalendarUrl: (state) => {
      state.connectUrl = null;
    },
  },
  extraReducers: {
    [getConsentScreenUrl.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getConsentScreenUrl.fulfilled.type]: (
      state,
      action: PayloadAction<ConsentScreenUrlRequestI>
    ) => {
      state.connectUrl = action.payload.url;
      state.isFetching = false;
    },
    [getConsentScreenUrl.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getConnectTeamUrl.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getConnectTeamUrl.fulfilled.type]: (
      state,
      action: PayloadAction<TeamConnectResponseI>
    ) => {
      state.connectUrl = action.payload.url_connected;
      state.isFetching = false;
    },
    [getConnectTeamUrl.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [calendarAuth.pending.type]: (state) => {
      state.isFetching = true;
    },
    [calendarAuth.fulfilled.type]: (
      state,
      action: PayloadAction<AgentCalendarResponceI>
    ) => {
      state.connectStatus = action.payload.calendar?.calendar_provider || null;
      state.isFetching = false;
    },
    [calendarAuth.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [checkConnectCalendar.pending.type]: (state) => {
      state.isFetching = true;
    },
    [checkConnectCalendar.fulfilled.type]: (
      state,
      action: PayloadAction<AgentCalendarResponceI>
    ) => {
      state.connectStatus = action.payload.calendar?.calendar_provider || null;
      state.conferencingProfile =
        action.payload.calendar?.conferencing_profiles || [];
      state.isFetching = false;
    },
    [checkConnectCalendar.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [disconnectCalendars.pending.type]: (state) => {
      state.isFetching = true;
    },
    [disconnectCalendars.fulfilled.type]: (state) => {
      state.isFetching = false;
      state.connectStatus = null;
    },
    [disconnectCalendars.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getAgentOtherCalendarsForConnection.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAgentOtherCalendarsForConnection.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.agentOtherCalendars = action.payload || [];
    },
    [getAgentOtherCalendarsForConnection.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getAgentOtherCalendars.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAgentOtherCalendars.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.agentOtherCalendars = action.payload || [];
    },
    [getAgentOtherCalendars.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [saveOtherCalendarsToAgent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [saveOtherCalendarsToAgent.fulfilled.type]: (state) => {
      state.isFetching = false;
    },
    [saveOtherCalendarsToAgent.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [deleteOtherCalendarsFromAgent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [deleteOtherCalendarsFromAgent.fulfilled.type]: (state) => {
      state.isFetching = false;
    },
    [deleteOtherCalendarsFromAgent.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const { clearConnectGoogleCalendarUrl } = calendarsSlice.actions;

export default calendarsSlice.reducer;
