import { Outlet, useLocation } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import { routesConstant, RoutesT } from "constants/appRoutesConstants";
import { useMobileContext } from "context/mobileContext";

const Layout = () => {
  const { pathname } = useLocation();
  const { isMobile } = useMobileContext();
 
   const isHideHeader = pathname.includes(routesConstant.agentPresentationProfile) || 
   [
     routesConstant.authCreate,
     routesConstant.booking
   ].includes(pathname as RoutesT)
   
   const isHideMobileFooter = pathname.includes(routesConstant.customAnalysisAbsolutely) || [
    routesConstant.authCreate,
    routesConstant.clientProfile,
    routesConstant.agentProfile,
    routesConstant.agentProfileFocusAreas,
    routesConstant.agentProfileConnectedCalendars,
    routesConstant.agentProfileOnlinePreference,
    routesConstant.booking,
    routesConstant.customAnalysisAbsolutely
  ].includes(pathname as RoutesT)

  const isHideFooter = pathname.includes(routesConstant.customAnalysisAbsolutely);

  return (
    <>
      {isMobile ? (!isHideHeader ? <Header /> : null) : <Header />}
      <main className="container">
        <Outlet />
      </main>
      {(isHideMobileFooter && isMobile) ? null : !isHideFooter ? <Footer /> : null}
    </>
  );
};

export default Layout;
