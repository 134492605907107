import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import i18next from "i18next";
import { isEqual, isEmpty } from "lodash";

import { BookingValueI } from "store/models/booking";
import { setIsPreBookingHidden } from "store/reducers/booking";
import {
  getIsAuthUserSelector,
  getProfileSelector,
} from "store/reducers/profile";
import { getSellerAddressSelector } from "store/reducers/addresses";
import { SellerAddress } from "store/models/locations";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";
import { showAlerts } from "store/reducers/alerts";

import { BookingSignupSchema } from "common/validationSchemas";

import { lsApi } from "utils/localStorageApi";
import { getToken } from "utils/auth";
import {
  trackingPrebookingSteps,
  trackingStartBookingSteps,
} from "utils/facebookTracking";

import { BookingFormikT } from "./types";

const initialBookingValues: BookingValueI = {
  target: "",
  residenceType: "",
  residenceName: "",
  address: "",
  areaNumber: "",
  district: "",
  county: "",
  numberRooms: "",
  numberOfSqm: "",
  address_id: null,
};

export const useBookingFrom = (): {
  preBookingFormik: BookingFormikT;
  setPreBookingAddressToStorage(props: SellerAddress): void;
} => {
  const dispatch = useAppDispatch();
  const token = getToken();
  const isAuth = useAppSelector(getIsAuthUserSelector);
  const userProfile = useAppShallowSelector(getProfileSelector);
  const userAddresses = useAppShallowSelector(getSellerAddressSelector);

  const defaultBookingFilterValues =
    useRef<BookingValueI>(initialBookingValues);

  const storageValues = lsApi.get<BookingValueI>("bookingForm");

  const preBookingFormik = useFormik<BookingValueI>({
    initialValues: storageValues || defaultBookingFilterValues.current,
    onSubmit: (values) => {
      // if user is unauthorised continue standart flow
      if (!isAuth) {
        lsApi.set("bookingForm", values);
        dispatch(setIsPreBookingHidden(true));
        trackingPrebookingSteps();
        return;
      }

      // if user is authorised check agents into blue box
      // while they exist - don't save new data
      dispatch(getAgentsListBlueBox()).then((data: any) => {
        if (data.payload?.length) {
          // check if user tries to change something (on the second tab flow)
          if (
            !isEqual(
              storageValues || defaultBookingFilterValues.current,
              values
            )
          ) {
            dispatch(
              showAlerts({
                type: "error",
                title: "Error",
                message: i18next.t("translation.alertMessage.disabledAddress"),
                autoHideDuration: 10000,
              })
            );
          }

          return dispatch(setIsPreBookingHidden(true));
        }

        lsApi.set("bookingForm", values);
        dispatch(setIsPreBookingHidden(true));
        trackingStartBookingSteps();
      });
    },

    validationSchema: BookingSignupSchema(),
    validateOnChange: true,
    validateOnBlur: true,
  });

  // Added into values and local storage data about address from create form
  // and client page profile page
  const setPreBookingAddressToStorage = (props: SellerAddress) => {
    lsApi.set("bookingForm", {
      ...storageValues,
      address: props.address,
      areaNumber: props.areaNumber,
      address_id: props.address_id,
      district: props.district,
      county: props.county,
    });
  };

  // Added into values and local storage data from user profile
  // (address and user data)
  useEffect(() => {
    if (isAuth && token) {
      let preBookingData = {
        ...preBookingFormik.values,
        // TODO: change it after save data into profile
        numberRooms: storageValues?.numberRooms || "",
        numberOfSqm: storageValues?.numberOfSqm || "",
        target: storageValues?.target || "",
        residenceType: storageValues?.residenceType || "",
        residenceName: storageValues?.residenceName || "",
      };

      if (isEmpty(storageValues) && !isEmpty(userAddresses)) {
        preBookingData = {
          ...preBookingData,
          address: userAddresses.address,
          areaNumber: userAddresses.areaNumber,
          address_id: userAddresses.address_id,
          district: userAddresses.district || "",
          county: userAddresses.county || "",
        };
      }
      preBookingFormik.setValues(preBookingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, token, userAddresses, userProfile]);

  // If local storage is empty redirect user to pre-booking steps
  useEffect(() => {
    if (isEmpty(storageValues)) {
      dispatch(setIsPreBookingHidden(false));
    }
  }, [storageValues, dispatch]);

  return { preBookingFormik, setPreBookingAddressToStorage };
};
