import React from "react";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppShallowSelector } from "hooks/redux";
import { getChosenAgentsSelector } from "store/reducers/booking";
import { getIsConfirmingBookMeetSelector } from "store/reducers/agents";

interface SwitchCalendarModalPropsI {
  isOpen: boolean;
  onClickProceed: () => void;
  onClickContinue: () => void;
}

const ProposeSelectMoreAgentModal: React.FC<SwitchCalendarModalPropsI> = ({
  isOpen,
  onClickProceed,
  onClickContinue,
}) => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const chosenAgents = useAppShallowSelector(getChosenAgentsSelector);
  const isConfirmingBookMeet = useAppSelector(getIsConfirmingBookMeetSelector);

  return (
    <Modal
      open={isOpen}
      isCloseButton={false}
      classNameDialog={`select_more_agents_modal_dialog_container ${
        isMobile ? "mobile" : ""
      }`}
    >
      <>
        <Text
          variant={isMobile ? "title-small" : "title-medium"}
          className="modal_title_text"
        >
          {t("translation.proceedPopup.title", {
            value: chosenAgents?.length || "X",
          })}
        </Text>
        <Text variant="paragraph-body-bold" className="swith_modal_message">
          {t("translation.proceedPopup.description")}
        </Text>
        <div className="modal_buttons_container">
          <Button
            className="modal_button"
            onClick={onClickContinue}
            sizeVariant={isMobile ? "Small" : "Medium"}
          >
            {t("translation.proceedPopup.continue")}
          </Button>
          <Button
            variant="outlined"
            className="modal_button"
            sizeVariant={isMobile ? "Small" : "Medium"}
            onClick={onClickProceed}
            disabled={isConfirmingBookMeet}
            showLoadingIndicator={isConfirmingBookMeet}
          >
            {t("translation.proceedPopup.proceed")}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default ProposeSelectMoreAgentModal;
