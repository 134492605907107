import AgentProfileAvatarSection from "components/AgentProfileAvatarSection";
import AddFocusAreaSection from "./components/AddFocusAreaSection";
import ResidenceType from "./components/ResidenceType";
import ChoosenAreas from "./components/ChoosenAreas";

const AgentProfileFocusAreasPage = () => {
  return (
    <div className="agent_profile_focus_area_page main_section_container">
      <AgentProfileAvatarSection />
      <AddFocusAreaSection />
      <ResidenceType />
      <ChoosenAreas />
    </div>
  );
};

export default AgentProfileFocusAreasPage;
