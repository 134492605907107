import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import uniqueId from "lodash/uniqueId";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import InputLabelText from "components/common/InputLabelText";
import Button from "components/common/Button";
import CheckBox from "components/common/CheckBox";
import Select from "components/common/Select";

import * as timeConfig from "../config";
import SelectTime from "./SelectTime";
import Text from "components/common/Text";
import {
  getAgentWorkingSchedule,
  updateAgentWorkingSchedule,
} from "store/asyncActions/profile";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getMappedAgentWorkingScheduleSelector,
  getIsFetchingUpdateProfileSelector,
} from "store/reducers/profile";
import { WorkingScheduleT, WorkingSlotsI } from "store/models/agent";
import { WorkingDayItemI } from "utils/mapWorkingScheduleData";
import { usePrevious } from "hooks/usePrev";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";
import { showAlerts } from "store/reducers/alerts";

const defaultWorkingTime = [
  {
    workingTimeSliderId: uniqueId(),
    start_work_minute: timeConfig.startWorkingDayMin,
    end_work_minute: timeConfig.endWorkingDayMin,
  },
];

const getOverlappedWorkingHours = (workingSchedule: WorkingScheduleT) => {
  const overlappedWorkingSchedule = workingSchedule
    .map((workingDay) => {
      const overlappedSlotList = [];

      for (let i = 0; i < workingDay.working_slots.length; i++) {
        for (let j = i + 1; j < workingDay.working_slots.length; j++) {
          const slot1 = workingDay.working_slots[i];
          const slot2 = workingDay.working_slots[j];
          const isOverlapped = areIntervalsOverlapping(
            {
              start: Number(slot1.start_work_minute),
              end: Number(slot1.end_work_minute),
            },
            {
              start: Number(slot2.start_work_minute),
              end: Number(slot2.end_work_minute),
            },
            { inclusive: true }
          );

          if (isOverlapped) {
            overlappedSlotList.push(slot1, slot2);
          }
        }
      }

      // filter out duplicates and return result
      const uniqueOverlappedSlots = Array.from(new Set(overlappedSlotList));
      return {
        day: workingDay.day,
        working_slots: uniqueOverlappedSlots,
      };
    })
    .filter((schedule) => schedule.working_slots.length > 0);

  return overlappedWorkingSchedule;
};

const ChoiseWorkingDays = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const wokringDaysValues = useAppShallowSelector(
    getMappedAgentWorkingScheduleSelector
  );

  const isFetching = useAppSelector(getIsFetchingUpdateProfileSelector);

  const [chosenDays, setChosenDays] = useState<WorkingDayItemI>(
    {} as WorkingDayItemI
  );

  const prevChosenDays = usePrevious(chosenDays);

  const [workingTimes, setWorkingTime] =
    useState<WorkingSlotsI[]>(defaultWorkingTime);
  const [overlappedWorkingTimes, setOverlappedWorkingTimes] =
    useState<WorkingScheduleT>([]);

  const [isNotWorkingDay, setIsNotWorkingDay] = useState<boolean>(false);

  const isSelectedTimeAvailable = (date: number) =>
    timeConfig
      .includedWorkingHour()
      .some((item) => timeConfig.dateToMinutes(item) === date);

  const handleSelectStartTime = (workingTime: WorkingSlotsI, date: Date) => {
    const time = timeConfig.dateToMinutes(date);
    const updatedTime =
      time >= workingTime.end_work_minute
        ? workingTime.end_work_minute - timeConfig.minDistance
        : time;

    const updatedTimeSelection = workingTimes.map((item) => {
      if (item.workingTimeSliderId === workingTime.workingTimeSliderId) {
        return {
          ...item,
          start_work_minute: updatedTime,
          end_work_minute: workingTime.end_work_minute,
        };
      }

      return item;
    });

    setWorkingTime(updatedTimeSelection);
  };

  const handleSelectEndTime = (workingTime: WorkingSlotsI, date: Date) => {
    const time = timeConfig.dateToMinutes(date);
    const updatedTime =
      time <= workingTime.start_work_minute
        ? workingTime.start_work_minute + timeConfig.minDistance
        : time;

    const updatedTimeSelection = workingTimes.map((item) => {
      if (item.workingTimeSliderId === workingTime.workingTimeSliderId) {
        return {
          ...item,
          start_work_minute: workingTime.start_work_minute,
          end_work_minute: updatedTime,
        };
      }

      return item;
    });

    setWorkingTime(updatedTimeSelection);
  };

  const handleSelectDay = (
    _event: React.MouseEvent<HTMLElement>,
    newFormats: WorkingDayItemI
  ) => {
    setChosenDays(newFormats);
    setOverlappedWorkingTimes([]);
  };

  const handleClickSave = () => {
    const newWorkingSchedule = wokringDaysValues.reduce(
      (res, item): WorkingScheduleT => {
        if (item.value === chosenDays.value) {
          if (isNotWorkingDay) return res;
          res.push({
            day: chosenDays.value,
            working_slots: workingTimes,
          });
        } else if (item.working_slots) {
          res.push({
            day: item.value,
            working_slots: item.working_slots,
          });
        }
        return res;
      },
      [] as WorkingScheduleT
    );

    const overlappedWorkingSchedule =
      getOverlappedWorkingHours(newWorkingSchedule);
    setOverlappedWorkingTimes(overlappedWorkingSchedule);

    if (overlappedWorkingSchedule.length) {
      dispatch(
        showAlerts({
          message: t("translation.agentProfilePreference.periodsTimeError"),
          type: "error",
          autoHideDuration: 10000,
        })
      );
    } else {
      dispatch(
        updateAgentWorkingSchedule({
          working_schedule: newWorkingSchedule,
        })
      );
      setChosenDays({} as WorkingDayItemI);
      setWorkingTime(defaultWorkingTime);
    }
  };

  const handleChangeIsNotWorkingDayCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsNotWorkingDay(checked);
    if (checked) {
      setWorkingTime(defaultWorkingTime);
    } else if (chosenDays?.working_slots) {
      setWorkingTime(chosenDays?.working_slots);
    }
  };

  const handleAddTimeSlider = (
    day: WorkingDayItemI,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    event?.stopPropagation();

    setWorkingTime([
      ...workingTimes,
      {
        ...defaultWorkingTime[0],
        workingTimeSliderId: uniqueId(),
      },
    ]);
  };

  const handleDeleteTimeSlider = (id?: string) => () => {
    const updatedWorkingTimes = workingTimes.filter((item) => {
      if (item.workingTimeSliderId !== id) return true;

      return false;
    });

    setWorkingTime(updatedWorkingTimes);
  };

  const AdditionalMenu = ({ day }: { day: WorkingDayItemI }) => {
    return (
      <Select
        alignBody={isMobile ? (day.value === 1 ? "left" : "right") : "right"}
        className="additional_button"
        isCloseAfterClick
        renderControl={(props) => {
          const handleClick = (
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            if (isEmpty(chosenDays) || chosenDays.labelKey !== day.labelKey)
              return null;

            e.stopPropagation();
            props.onClick && props.onClick(e);
          };
          return (
            <span className="dots" onClick={handleClick}>
              <MoreVertIcon fontSize="small" />
            </span>
          );
        }}
      >
        <Select.SelectItem
          title={t("translation.agentProfilePreference.addTimeButton")}
          value="add"
          type="button"
          onClick={(_, event) => handleAddTimeSlider(day, event)}
        />
      </Select>
    );
  };

  useEffect(() => {
    dispatch(getAgentWorkingSchedule());
  }, [dispatch]);

  useEffect(() => {
    if (chosenDays?.working_slots) {
      setWorkingTime(chosenDays?.working_slots);
    } else {
      setWorkingTime(defaultWorkingTime);
    }
  }, [chosenDays?.working_slots]);

  useEffect(() => {
    if (!isEqual(chosenDays, prevChosenDays) && isNotWorkingDay) {
      setIsNotWorkingDay(false);
    }
  }, [isNotWorkingDay, chosenDays, prevChosenDays]);

  const isMobileButtonDisabled = workingTimes.some(
    (day) =>
      !isSelectedTimeAvailable(day.start_work_minute) ||
      !isSelectedTimeAvailable(day.end_work_minute)
  );

  return (
    <div className={`choise_working_days ${isMobile ? "mobile" : ""}`}>
      <InputLabelText
        label={t("translation.agentProfilePreference.workingDays")}
      />
      <Text
        variant="paragraph-body"
        className={`helper_text ${isMobile ? "label-extra-small" : ""}`}
      >
        {t("translation.agentProfilePreference.availableDay")}
      </Text>
      <div className="group_wrapper">
        <ToggleButtonGroup
          value={chosenDays}
          onChange={handleSelectDay}
          aria-label="text formatting"
          className="choise_working_days_buttons"
          fullWidth
          orientation="horizontal"
          exclusive
        >
          {wokringDaysValues.map((day) => {
            if (!day) return;

            const classNameIsActive = day.working_slots ? "active" : "";

            return (
              <ToggleButton
                key={day.value}
                value={day}
                disableRipple
                className={`choise_working_days_button ${classNameIsActive}`}
              >
                <span className="button_label">{day.labelKey}</span>
                {day.working_slots?.length ? (
                  day.working_slots.map((workingSlot) => {
                    const { start_work_minute, end_work_minute } =
                      workingSlot || {};
                    return (
                      <React.Fragment key={workingSlot.workingTimeSliderId}>
                        <span className="button_time">
                          {`${timeConfig.valueToLabel(
                            start_work_minute
                          )} - ${timeConfig.valueToLabel(end_work_minute)}`}
                        </span>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <span className="button_time">--:-- - --:--</span>
                )}
                {chosenDays?.value && chosenDays?.labelKey === day.labelKey ? (
                  workingTimes.length < 3 && !isNotWorkingDay ? (
                    <AdditionalMenu day={day} />
                  ) : null
                ) : day.working_slots &&
                  day.working_slots.length >= 3 ? null : (
                  <AdditionalMenu day={day} />
                )}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <CheckBox
        lable={t("translation.agentProfilePreference.dayOff")}
        className="not_working_day_checkbox"
        disabled={isEmpty(chosenDays) || !chosenDays?.working_slots}
        checked={isNotWorkingDay}
        onChange={handleChangeIsNotWorkingDayCheckbox}
      />
      {!isMobile ? (
        workingTimes.map((workingTime, ind) => {
          const isTimeError = overlappedWorkingTimes.some(
            (time) =>
              time.day === chosenDays?.value &&
              time.working_slots.some(
                (slot) =>
                  slot.workingTimeSliderId === workingTime.workingTimeSliderId
              )
          );
          return (
            <div
              className={`${ind === 0 ? "" : "spaced"} time_slider_wrapper`}
              key={workingTime.workingTimeSliderId}
            >
              <SelectTime
                isTimeError={isTimeError}
                workingTimes={workingTimes}
                disabled={isEmpty(chosenDays) || isNotWorkingDay}
                workingTimeSliderId={
                  workingTime.workingTimeSliderId || uniqueId()
                }
                startWorkDay={workingTime.start_work_minute}
                endWorkDay={workingTime.end_work_minute}
                setWorkingTime={setWorkingTime}
              />
              {workingTimes.length > 1 && (
                <div
                  className="delete_time_slider_button"
                  onClick={handleDeleteTimeSlider(
                    workingTime.workingTimeSliderId
                  )}
                >
                  <DeleteOutlineIcon fontSize="medium" />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="time_picker_container">
          {!isEmpty(chosenDays) &&
            workingTimes.map((workingTime) => {
              const isTimeError = overlappedWorkingTimes.some(
                (time) =>
                  time.day === chosenDays?.value &&
                  time.working_slots.some(
                    (slot) =>
                      slot.workingTimeSliderId ===
                      workingTime.workingTimeSliderId
                  )
              );
              return (
                <React.Fragment key={workingTime.workingTimeSliderId}>
                  <div className="selected_time">
                    <DatePicker
                      preventOpenOnFocus
                      className={`time_picker ${
                        isTimeError ||
                        !isSelectedTimeAvailable(workingTime.start_work_minute)
                          ? "error"
                          : ""
                      }`}
                      selected={timeConfig.valueToNewDate(
                        workingTime.start_work_minute
                      )}
                      onChange={(date: Date) =>
                        handleSelectStartTime(workingTime, date)
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      popperPlacement="top"
                      customInput={<input readOnly inputMode="none" />}
                      disabled={isEmpty(chosenDays) || isNotWorkingDay}
                      includeTimes={timeConfig.includedWorkingHour()}
                    />
                    <Text className="paragraph-body-small text first">
                      {t("translation.agentProfilePreference.from")}
                    </Text>
                    <ArrowForwardIcon className="arrow" />
                    <Text className="paragraph-body-small text last">
                      {t("translation.agentProfilePreference.to")}
                    </Text>
                    <DatePicker
                      preventOpenOnFocus
                      className={`time_picker ${
                        isTimeError ||
                        !isSelectedTimeAvailable(workingTime.end_work_minute)
                          ? "error"
                          : ""
                      }`}
                      selected={timeConfig.valueToNewDate(
                        workingTime.end_work_minute
                      )}
                      onChange={(date: Date) =>
                        handleSelectEndTime(workingTime, date)
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      popperPlacement="top"
                      locale={"en-GB"}
                      customInput={<input readOnly inputMode="none" />}
                      disabled={isEmpty(chosenDays) || isNotWorkingDay}
                      includeTimes={timeConfig.includedWorkingHour()}
                    />

                    {workingTimes.length > 1 && (
                      <div
                        className="delete_time_slider_button"
                        onClick={handleDeleteTimeSlider(
                          workingTime.workingTimeSliderId
                        )}
                      >
                        <DeleteOutlineIcon fontSize="medium" />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      )}
      <Button
        className="save_time_button"
        sizeVariant={isMobile ? "Medium" : "Large"}
        disabled={
          isFetching ||
          isEmpty(chosenDays) ||
          isEqual(chosenDays.working_slots, workingTimes) ||
          isMobileButtonDisabled
        }
        onClick={handleClickSave}
        showLoadingIndicator={isFetching}
      >
        {t("translation.agentProfilePreference.saveTime")}
      </Button>
    </div>
  );
};

export default ChoiseWorkingDays;
