import React from "react";
import { useLocation } from "react-router-dom";

import { useMobileContext } from "context/mobileContext";
import LinkLogo from "components/LinkLogo";
import { routesConstant } from "constants/appRoutesConstants";
import { useAppSelector } from "hooks/redux";
import { getRoleProfileSelector } from "store/reducers/profile";
import { Role } from "store/models/profile";

import NavMenu from "./components/NavMenu";
import HeaderProfile from "./components/HeaderProfile";
import HeaderLinkMenu from "./components/HeaderLinkMenu";

import MobileHeader from "./MobileHeader";

const Header: React.FC = () => {
  const { isMobile } = useMobileContext();
  const { pathname } = useLocation();
  const roleUser = useAppSelector(getRoleProfileSelector);

  const isShowTabs = !pathname.includes(
    routesConstant.customAnalysisAbsolutely
  );
  const isShowHeaderContent = !pathname.includes(
    routesConstant.adminAuthAbsolutely
  );

  if (isMobile) {
    return <MobileHeader isShowTabs={isShowTabs && isShowHeaderContent} />;
  }

  return (
    <header className="header">
      <div className="header_container">
        {isShowHeaderContent && (
          <>
            <LinkLogo />
            {isShowTabs ? (
              <>
                <NavMenu />
                <div className="menus_container">
                  <HeaderProfile />
                  {roleUser !== Role.AGENT && <HeaderLinkMenu />}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
