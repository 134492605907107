import { Route, Navigate, Outlet } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import AgentMeetingPage from "pages/AgentMeetingPage";
import AgentPreviousMeetingsPage from "pages/AgentPreviousMeetingsPage";
import AgentProfileGeneralPage from "pages/AgentProfileGeneralPage";
import AgentProfilePage from "pages/AgentProfilePage";
import AgentProfileFocusAreasPage from "pages/AgentProfileFocusAreasPage";
import AgentProfileConnectedCalendars from "pages/AgentProfileConnectedCalendars";
import AgentProfileOnlinePreferencePage from "pages/AgentProfileOnlinePreferencePage";

export const agentRoutes = (
  <Route
    path={routesConstant.agentAbsolutely}
    element={
      <RequireAuth
        userRoleOnly={Role.AGENT}
        navigateTo={routesConstant.booking}
      >
        <Outlet />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <Navigate to={routesConstant.agentMyMeetingAbsolutely} replace />
      }
    />
    <Route
      path={routesConstant.agentMyMeetingAbsolutely}
      element={<AgentMeetingPage />}
    />
    <Route
      path={routesConstant.agentPreviousMeeringsAbsolutely}
      element={<AgentPreviousMeetingsPage />}
    />
    <Route
      path={routesConstant.agentProfileAbsolutely}
      element={<AgentProfilePage />}
    >
      <Route index element={<AgentProfileGeneralPage />} />
      <Route
        path={routesConstant.agentProfileFocusAreasAbsolutely}
        element={<AgentProfileFocusAreasPage />}
      />
      <Route
        path={routesConstant.agentProfileConnectedCalendarsAbsolutely}
        element={<AgentProfileConnectedCalendars />}
      />
      <Route
        path={routesConstant.agentProfileOnlinePreferenceAbsolutely}
        element={<AgentProfileOnlinePreferencePage />}
      />
    </Route>
  </Route>
);
