import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { getIsAuthUserSelector } from "store/reducers/profile";
import Text from "components/common/Text";
import { logout } from "store/asyncActions/profile";
import { routesConstant } from "constants/appRoutesConstants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearSellerAddress } from "store/reducers/addresses";

type FooterContainerProps = {
  closeMenu(): void;
  isMenuOpened?: boolean;
};

const FooterContainer: React.FC<FooterContainerProps> = ({
  closeMenu,
  isMenuOpened,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAuth = useAppSelector(getIsAuthUserSelector);

  const handleLogOut = () => {
    dispatch(logout());
    dispatch(clearSellerAddress());
    navigate(routesConstant.auth);
    closeMenu();
  };

  const handleLogIn = () => {
    navigate(`${routesConstant.auth}`);
    closeMenu();
  };

  const buttonTitle = isAuth
    ? t("translation.header.logOut")
    : t("translation.header.logIn");

  return (
    <div
      className="footer"
      style={{ position: isMenuOpened ? "fixed" : "absolute" }}
    >
      <div
        className="footer_wrapper"
        onClick={isAuth ? handleLogOut : handleLogIn}
      >
        <div className="account">
          <AccountCircleOutlinedIcon className="img" />
          <Text className="navigation-link log">{buttonTitle}</Text>
        </div>
        <KeyboardArrowRightIcon className="img" />
      </div>
    </div>
  );
};

export default FooterContainer;
