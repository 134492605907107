import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#096A5E",
      contrastText: "#ffffff",
    },
    error: {
      main: "#A90707",
      contrastText: "#ffffff",
    },
  },
});
