import React from "react";

import Button from "components/common/Button";
import Text from "components/common/Text";
import Trash from "components/Svg/Trash";

import { DistrictI } from "store/models/district";
import { useAppDispatch } from "hooks/redux";
import { removePostalCodeToAgent } from "store/asyncActions/locations";

const ChoosenAreasItem: React.FC<DistrictI> = ({ code, postal_area }) => {
  const dispatch = useAppDispatch();
  
  const _areaText = `${
    `${code}`.substring(0, 3) + " " + `${code}`.substring(3)
  } ${postal_area}`;

  const handleClick = () => {
    dispatch(removePostalCodeToAgent({ postal_code: code }));
  }

  return (
    <div className="choosen_areas_item_wrap">
      <Button
        variant="outlined"
        className="choosen_areas_item"
        sizeVariant="Medium"
        onClick={handleClick}
      >
        <Text
          variant="paragraph-body"
          className="choosen_areas_item_text"
          tooltipTitle={_areaText}
        >
          {_areaText}
        </Text>
        <Trash className="trash_icon" color="#A90707" />
      </Button>
    </div>
  );
};

export default ChoosenAreasItem;
