import { routesConstant } from "constants/appRoutesConstants";
import Text from "components/common/Text";
import Link from "components/common/Link";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";

const AsideSection = () => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  
  return (
    <aside className="aside_section">
      <div className="title_block">
        <Text text={`${t("translation.clientProfilePage.title")}`} variant="title-medium" className={`${isMobile ? "title-small20" : ""} title`} />
        {!isMobile && (<Text text={`${t("translation.clientProfilePage.subtitle")}`} variant="paragraph-body" />)}
      </div>
      {!isMobile && (
        <>
          <Link
          to={routesConstant.agentProfile}
          className="profile_link"
          isMatchActive
        >
          {t("translation.agentProfilePage.settings")}
          </Link>
          <Link
            to={routesConstant.agentProfileFocusAreas}
            className="profile_link"
            isMatchActive
          >
            {t("translation.agentProfilePage.area")}
          </Link>
          <Link
            to={routesConstant.agentProfileConnectedCalendars}
            className="profile_link"
            isMatchActive
          >
            {t("translation.agentProfilePage.calendars")}
          </Link>
          <Link
            to={routesConstant.agentProfileOnlinePreference}
            className="profile_link"
            isMatchActive
          >
            {t("translation.agentProfilePage.preference")}
          </Link>
        </>
      )}
    </aside>
  );
};

export default AsideSection;
