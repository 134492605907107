import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";

import { add, fromUnixTime, getUnixTime, startOfDay } from "date-fns";

import { useFilterCalendarContext } from "../useFilterCalendarContext";

import Text from "components/common/Text";
import { useMobileContext } from "context/mobileContext";
import { MobileViewType } from "../BookingMeetingCalendar";
import { useCalendarContext } from "../useCalendarContext";

interface ICalendarHeader {
  mobileView?: MobileViewType;
}

const CalendarHeader: React.FC<ICalendarHeader> = ({ mobileView }) => {
  const {
    dataOfSelectedWeek,
    goNextDay,
    goPrevDay,
    firstDayOfCalendarTimestamp,
  } = useFilterCalendarContext();
  const { selectedHourSlot } = useCalendarContext();
  const { isMobile } = useMobileContext();

  const isOpenHourSlot = !!selectedHourSlot.day && !!selectedHourSlot.hour;

  const currendDateUnix = getUnixTime(startOfDay(new Date()));

  // 14 days max available for time slots loading
  const maxAvailableLoadDay = getUnixTime(
    add(fromUnixTime(currendDateUnix), { days: 13 })
  );

  const lastDayOfCalendar =
    isMobile && mobileView === MobileViewType.today
      ? firstDayOfCalendarTimestamp
      : getUnixTime(
          add(fromUnixTime(firstDayOfCalendarTimestamp), {
            days: isMobile && mobileView === MobileViewType.threeDays ? 2 : 6,
          })
        );

  const disabledPrev = currendDateUnix === firstDayOfCalendarTimestamp;
  const disabledNext = lastDayOfCalendar === maxAvailableLoadDay;

  const selectedWeek = isMobile
    ? dataOfSelectedWeek.slice(0, 3)
    : dataOfSelectedWeek;

  return (
    <div className="calendar_header_container">
      {!isMobile && (
        <div className="change_week_button_container">
          <IconButton
            className="change_week_button"
            onClick={goPrevDay}
            disabled={disabledPrev || isOpenHourSlot}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
      )}

      {selectedWeek.map((day, index) => {
        const currentDate = getUnixTime(startOfDay(new Date()));
        const isToday =
          isMobile && mobileView === MobileViewType.today
            ? index === 0
            : currentDate === day.timestamp;

        const currentClassName = isToday ? "current" : "";

        return (
          <div className="calendar_column_header calendar_column" key={day.day}>
            <Text
              text={day.dayName.toUpperCase()}
              variant="label-medium"
              className={`day_name ${isMobile ? currentClassName : ""}`}
            />
            <Text
              text={day.day}
              variant="small-header"
              className={`day_number ${currentClassName}`}
            />
          </div>
        );
      })}
      {!isMobile && (
        <div className="change_week_button_container">
          <IconButton
            className="change_week_button"
            onClick={goNextDay}
            disabled={disabledNext || isOpenHourSlot}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default CalendarHeader;
