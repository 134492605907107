import { EnglishWeekDays, SwedishWeekDays } from "common/types";
import { defaultNS } from "i18n";
import uniqueId from "lodash/uniqueId";

import { WorkingScheduleT, WorkingSlotsI } from "store/models/agent";

export interface WorkingDayItemI {
  labelKey: string;
  value: SwedishWeekDays | EnglishWeekDays;
  working_slots?: WorkingSlotsI[];
}

const DAYS_IN_WEEK = 7;

export const mapWorkingScheduleData = (
  data: WorkingScheduleT
): WorkingDayItemI[] => {
  if (!data) return [];

  const WeekDays = defaultNS === "en" ? EnglishWeekDays : SwedishWeekDays;

  const mappedResponse = Array(DAYS_IN_WEEK)
    .fill(null)
    .map((_, index) => {
      const activeDay = Object.values(data).find(
        (item) => item.day === index + 1
      );
      const res: WorkingDayItemI = {
        value: index + 1,
        labelKey: WeekDays[index + 1],
      };

      if (activeDay) {
        res.working_slots = [
          ...activeDay.working_slots.map((item) => ({
            ...item,
            workingTimeSliderId: uniqueId(),
          })),
        ];
      }
      return res;
    });

  return mappedResponse;
};
