import React, { memo } from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Text from "components/common/Text";
import FormControl from "@mui/material/FormControl/FormControl";

interface CheckboxStyledProps extends CheckboxProps {
  lable: string;
  error?: string;
  isLink?: boolean;
}

const CheckboxStyled: React.FC<CheckboxStyledProps> = ({
  lable,
  className = "",
  error,
  isLink,
  ...rest
}) => {
  const displayedLabel = lable ? lable.split(" ") : "";
  return (
    <FormControl className={`control_checkbox ${error ? "error" : ""}`}>
      <div className="wrappper">
        <FormControlLabel
          className={`lable_checkbox ${className}`}
          label={
            isLink ? (
              <>
                {(displayedLabel as string[]).slice(0, 2).join(" ")}{" "}
                <a
                  target="_blank"
                  className={`${error ? "errored" : ""}`}
                  href={process.env.REACT_APP_GDPR}
                  rel="noreferrer"
                >
                  {displayedLabel.slice(-1)}
                </a>
              </>
            ) : (
              lable
            )
          }
          control={<Checkbox className={"checkbox_styled"} {...rest} />}
        />
      </div>

      {error && (
        <Text
          text={error}
          className="styled_text error-text text_input_text error_text error_message"
        />
      )}
    </FormControl>
  );
};

export default memo(CheckboxStyled);
