import { store } from "../index";
import axios, { AxiosError, AxiosInstance } from "axios";

import { baseUrl } from "constants/envConstants";
import { checkExtToken, getRefreshToken, setAccesToken } from "utils/auth";
import { logout } from "store/asyncActions/profile";

import profile, { Profile } from "./routes/profile";
import bankId, { BankId } from "./routes/bankId";
import googleAuth, { GoogleAuth } from "./routes/googleAuth";
import agentAPI, { AgentAPI } from "./routes/agent";
import loginAPI, { LoginAPI } from "./routes/login";
import typeHousingApi, { TypeHousingApi } from "./routes/typeHousing";
import districtsApi, { DistrictsApi } from "./routes/districts";
import calendarApi, { CalendarsApi } from "./routes/calendars";
import meetingsApi, { MeetingsApi } from "./routes/meetings";
import passwordRecovetyApi, {
  PasswordRecovetyApi,
} from "./routes/passwordRecovery";
import locationApi, { LocationApi } from "./routes/locations";
import customerAnalysisApi, {
  CustomerAnalysisAPI,
} from "./routes/customerAnalysis";
import blueBoxApi, { BlueBoxApi } from "./routes/blueBox";

class Api {
  protected baseUrl: string;
  private _token: string | undefined;
  private instance: AxiosInstance;
  public profile: Profile;
  public bankId: BankId;
  public googleAuth: GoogleAuth;
  public agent: AgentAPI;
  public login: LoginAPI;
  public typeHousing: TypeHousingApi;
  public districts: DistrictsApi;
  public calendars: CalendarsApi;
  public meetings: MeetingsApi;
  public passwordRecovery: PasswordRecovetyApi;
  public locations: LocationApi;
  public customerAnalysis: CustomerAnalysisAPI;
  public blueBox: BlueBoxApi;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;

    this.instance = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    });

    this.instance.interceptors.request.use(async (config) => {
      if (this._token) {
        const isExpared = checkExtToken(this._token);

        if (isExpared) {
          this.token = undefined;
          const refresh = getRefreshToken();
          if (refresh) {
            try {
              const { data } = await this.login.refreshToken({ refresh });
              setAccesToken(data.access);
              this.token = data.access;
            } catch (error) {
              this.token = undefined;
            }
          }
        }

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${this._token}`,
        };
      }
      return config;
    });

    this.instance.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      }
    );

    this.profile = profile(this.instance);
    this.bankId = bankId(this.instance);
    this.googleAuth = googleAuth(this.instance);
    this.agent = agentAPI(this.instance);
    this.login = loginAPI(this.instance);
    this.typeHousing = typeHousingApi(this.instance);
    this.districts = districtsApi(this.instance);
    this.calendars = calendarApi(this.instance);
    this.meetings = meetingsApi(this.instance);
    this.passwordRecovery = passwordRecovetyApi(this.instance);
    this.locations = locationApi(this.instance);
    this.customerAnalysis = customerAnalysisApi(this.instance);
    this.blueBox = blueBoxApi(this.instance);
  }

  get token() {
    return this._token;
  }

  set token(newToken) {
    this._token = newToken;
  }
}

const api = new Api(baseUrl);

export default api;
