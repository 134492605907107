import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";

import { useMobileContext } from "context/mobileContext";
import { routesConstant } from "constants/appRoutesConstants";

interface AddressAbsentModalPropsI {
  isOpen: boolean;
  navigateToPrebooking?: boolean;
  onClose(): void;
}

const AddressAbsentModal: React.FC<AddressAbsentModalPropsI> = ({
  isOpen,
  navigateToPrebooking,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();

    if (navigateToPrebooking) {
      navigate(routesConstant.booking);
    } else {
      window.location.replace(`${process.env.REACT_APP_PUBLIC_URL}`);
    }
  };

  return (
    <Modal
      open={isOpen}
      isCloseButton={false}
      classNameDialog={`address_absent_modal_container ${
        isMobile ? "mobile" : ""
      }`}
      title={t("translation.agentCalendar.secondStepModalTitle")}
    >
      <div className="calendar_modal_wrapper">
        <Text variant="paragraph-body-bold" className="description">
          {t("translation.agentCalendar.secondStepModalDescription")}
        </Text>
        <Button className="modal_button" onClick={handleClose}>
          {t("translation.agentCalendar.secondStepModalButton")}
        </Button>
      </div>
    </Modal>
  );
};

export default AddressAbsentModal;
