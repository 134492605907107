import React, { memo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { sortEnum } from "constants/sortConstants";
import { CellPropsI } from "../Table.type";
import { memoEqual } from "utils/memoEqual";

const Cell: React.FC<CellPropsI> = ({
  isHeader = false,
  content = "",
  width,
  sortField,
  direction,
  handleSort,
  isSort = false,
  dataField,
}) => {
  const classNameIsHeader = isHeader
    ? "table_cell_header label-small"
    : "paragraph-body-bold";
  const CellComponent = isHeader ? "th" : "td";
  const CellContentWrapComponent = isHeader && isSort ? "button" : "span";

  const _handleClickSort = () => {
    if (typeof handleSort === "function" && isHeader && isSort) {
      handleSort(
        dataField,
        direction === sortEnum.ASC ? sortEnum.DESC : sortEnum.ASC
      );
    }
  };

  const sortActiveClassName = sortField === dataField ? "active" : "";

  return (
    <CellComponent className={`table_cell ${classNameIsHeader}`} width={width}>
      <CellContentWrapComponent
        className="table_cell_wrap"
        onClick={_handleClickSort}
      >
        {content}
        {isSort && isHeader && (
          <div className="sort_icons">
            <KeyboardArrowUpIcon
              className={`up_icon icon ${
                direction === sortEnum.ASC ? sortActiveClassName : ""
              }`}
            />
            <KeyboardArrowDownIcon
              className={`down_icon icon ${
                direction === sortEnum.DESC ? sortActiveClassName : ""
              }`}
            />
          </div>
        )}
      </CellContentWrapComponent>
    </CellComponent>
  );
};

export default memo(Cell, memoEqual);
