import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parseISO, formatDistanceToNowStrict } from "date-fns";

import {
  UpcomingMeetingsResponse,
  UpcomingMeetingData,
  PassedMeetingDataResponse,
  PassedMeetingData,
} from "store/models/meetings";
import {
  deleteMeeting,
  getPassedMeetings,
  getUpcomingMeetings,
  getUpcomingMeetingsNextThreeDay,
} from "store/asyncActions/meetings";

import { createTypedSelector } from "../utils";

interface InitialState {
  upcommingMeetingData: UpcomingMeetingsResponse;
  upcommingMeetingByNextThreeDay: UpcomingMeetingData[];
  isFetchingUpcomingMeeting: boolean;
  isFetchingUpcommingMeetingByNextThreeDay: boolean;
  isFetchingDeletingMeeting: boolean;
  passedMeetingData: PassedMeetingData[];
}

const initialState: InitialState = {
  isFetchingUpcomingMeeting: false,
  isFetchingUpcommingMeetingByNextThreeDay: false,
  isFetchingDeletingMeeting: false,
  upcommingMeetingData: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  upcommingMeetingByNextThreeDay: [],
  passedMeetingData: []
};

export const getCountUpcommingMeetingDataSelector = createTypedSelector(
  (state) => state.meetings.upcommingMeetingData.count
);

export const getIsFetchingUpcommingMeetingByNextThreeDaySelector =
  createTypedSelector(
    (state) => state.meetings.isFetchingUpcommingMeetingByNextThreeDay
  );

export const getUpcommingMeetingByNextThreeDaySelector = createTypedSelector(
  (state) => state.meetings.upcommingMeetingByNextThreeDay
);

export const getIsNextUpcommingMeetingDataSelector = createTypedSelector(
  (state) => Boolean(state.meetings.upcommingMeetingData.next)
);

export const getIsFetchingUpcommingMeetingSelector = createTypedSelector(
  (state) => state.meetings.isFetchingUpcomingMeeting
);

export const getUpcomingMeetingsSelector = createTypedSelector(
  (state) => state.meetings.upcommingMeetingData.results
);

export const getPassedMeetingsSelector = createTypedSelector(
  (state) => state.meetings.passedMeetingData
);

export const getIsFetchingDeletingMeetingSelector = createTypedSelector(
  (state) => state.meetings.isFetchingDeletingMeeting
);

const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    clearUpcomingMeetings: (state) => {
      state.upcommingMeetingData = initialState.upcommingMeetingData;
    },
  },
  extraReducers: {
    [getUpcomingMeetings.pending.type]: (state) => {
      state.isFetchingUpcomingMeeting = true;
    },
    [getUpcomingMeetings.fulfilled.type]: (
      state,
      action: PayloadAction<UpcomingMeetingsResponse>
    ) => {
      state.isFetchingUpcomingMeeting = false;
      state.upcommingMeetingData.count = action.payload.count;
      state.upcommingMeetingData.next = action.payload.next;
      state.upcommingMeetingData.results = [
        ...state.upcommingMeetingData.results,
        ...action.payload.results,
      ];
    },
    [getUpcomingMeetings.rejected.type]: (state) => {
      state.isFetchingUpcomingMeeting = false;
    },
    [getUpcomingMeetingsNextThreeDay.pending.type]: (state) => {
      state.isFetchingUpcommingMeetingByNextThreeDay = true;
    },
    [getUpcomingMeetingsNextThreeDay.fulfilled.type]: (
      state,
      action: PayloadAction<UpcomingMeetingData[]>
    ) => {
      state.isFetchingUpcommingMeetingByNextThreeDay = false;
      state.upcommingMeetingByNextThreeDay = action.payload.filter((meet) => {
        const [day] = formatDistanceToNowStrict(parseISO(meet.start_time), {
          unit: "day",
          addSuffix: false,
        }).split(" ");
        /// we have to show meeting for 3 days
        return Number(day) <= 3;
      });
    },
    [getUpcomingMeetingsNextThreeDay.rejected.type]: (state) => {
      state.isFetchingUpcommingMeetingByNextThreeDay = false;
    },
    [getPassedMeetings.fulfilled.type]: (state, action) => {
      state.passedMeetingData = action.payload.results.map((meet: PassedMeetingDataResponse) => ({
        firstName: meet.customer?.first_name || '',
        lastName: meet.customer?.last_name || '',
        phone: meet.customer?.phone_number || '',
        email: meet.customer?.email || '',
        event: meet.event || '',
        date: meet.date || '',
        street: meet.address ? `${meet.address.street?.name || ''} ${meet.address.number || ''}` : '',
        areaNumber: meet.address?.street?.postal_code || ''
      }));
    },
    [deleteMeeting.pending.type]: (state) => {
      state.isFetchingDeletingMeeting = true;
    },
    [deleteMeeting.fulfilled.type]: (state, action: PayloadAction<UpcomingMeetingsResponse>) => {
      state.isFetchingDeletingMeeting = false;
      state.upcommingMeetingData.count = action.payload.count;
      state.upcommingMeetingData.next = action.payload.next;
      state.upcommingMeetingData.results = action.payload.results;
    },
    [deleteMeeting.rejected.type]: (state) => {
      state.isFetchingDeletingMeeting = false;
    },
  },
});

export const { clearUpcomingMeetings } = meetingsSlice.actions;

export default meetingsSlice.reducer;
