import React, { MouseEventHandler } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useTranslation } from "react-i18next";

import { routesConstant, RoutesT } from "constants/appRoutesConstants";
import { Role } from "store/models/profile";
import Link from "components/common/Link";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import Button from "components/common/Button";
import Select from "components/common/Select";
import { logout } from "store/asyncActions/profile";
import { clearSellerAddress } from "store/reducers/addresses";
import { setIsPreBookingHidden } from "store/reducers/booking";

const HeaderProfile: React.FC = () => {
  const { pathname } = useLocation();
  const isAuth = useAppSelector(getIsAuthUserSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);
  const { t } = useTranslation();

  const activeClassName = [
    routesConstant.auth,
    routesConstant.authCreate,
  ].includes(pathname as RoutesT)
    ? "active"
    : "";

  return (
    <div className="header_profile_container">
      {isAuth ? (
        roleUser === Role.AGENT ? (
          <AgentProfileBlockButton />
        ) : (
          <ClientProfileBlockButton />
        )
      ) : (
        <Link
          to={routesConstant.authAbsolutely}
          variant="navigation-link"
          className={`nav_list_item_link profile ${activeClassName}`}
        >
          <AccountCircleOutlinedIcon className="button_icon" />
          {t("translation.header.logIn")}
        </Link>
      )}
    </div>
  );
};

const AgentProfileBlockButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(logout());
    navigate(routesConstant.auth);
  };

  return (
    <Button
      variant="outlined"
      sizeVariant="Small-round"
      className="header_profile_button"
      onClick={handleClick}
      startIcon={<AccountCircleOutlinedIcon />}
    >
      {t("translation.header.logOut")}
    </Button>
  );
};

const ClientProfileBlockButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClickLogout = () => {
    dispatch(clearSellerAddress());
    dispatch(logout());
    navigate(routesConstant.auth);
  };

  const handleRedirectPreBooking = () => {
    dispatch(setIsPreBookingHidden(false));
  };

  return (
    <Select
      alignBody="right"
      isCloseAfterClick
      handleClickSelectItem={() => null}
      renderControl={(props) => (
        <Button
          variant="outlined"
          sizeVariant="Small-round"
          className={`header_profile_button ${props.isOpen ? "open" : ""}`}
          onClick={props.onClick as MouseEventHandler}
          startIcon={<AccountCircleOutlinedIcon />}
        >
          {t("translation.header.myProfile")}
        </Button>
      )}
    >
      <Select.SelectItem
        title={t("translation.header.bookMeet")}
        type="link"
        icon={InsertInvitationOutlinedIcon}
        to={routesConstant.booking}
        onClick={handleRedirectPreBooking}
      />
      <Select.SelectItem
        title={t("translation.header.meetings")}
        type="link"
        icon={AccessTimeOutlinedIcon}
        to={routesConstant.clientMyMeetingsLocation}
      />
      <Select.SelectItem
        title={t("translation.header.profileSettings")}
        type="link"
        icon={SettingsOutlinedIcon}
        to={routesConstant.clientProfile}
      />
      <Select.SelectItem
        title={t("translation.header.logOut")}
        type="link"
        to={routesConstant.auth}
        icon={LogoutIcon}
        onClick={handleClickLogout}
      />
    </Select>
  );
};

export default HeaderProfile;
