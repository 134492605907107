import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { routesConstant } from "constants/appRoutesConstants";
import Text from "components/common/Text";
import TextInput from "components/common/TextInput";
import PhoneTextInput from "components/PhoneTextInput";
import Checkbox from "components/common/CheckBox/Checkbox";
import Button from "components/common/Button";

import { createUser } from "store/asyncActions/profile";
import {
  getBankIdUserInfoSelector,
  clearPrefillData,
} from "store/reducers/bankId";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getIsAuthUserSelector,
  getIsFetchingUpdateProfileSelector,
} from "store/reducers/profile";
import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { useTranslation } from "react-i18next";
import { CreateClientFormSchema } from "common/validationSchemas";
import SellerAddress from "components/SellerAddress";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import Link from "components/common/Link";
import { trackingCreateAccount } from "utils/facebookTracking";
import {
  getAreaName,
  getPostalCode,
  getStreetData,
} from "common/inputAddressRule";
import { getSelectedAddress } from "store/asyncActions/locations";
import { SellerAddress as SellerAddressI } from "store/models/locations";
import {
  clearPrefillGoogleData,
  getGoogleAuthUserInfoSelector,
} from "store/reducers/googleAuth";

interface CreateFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  areaNumber: string;
  district: string;
  county: string;
  gdpr: boolean;
  personal_number: string;
  address_id: number | null;
}

const CreateForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const bankIdUserInfo = useAppShallowSelector(getBankIdUserInfoSelector);
  const googleAuthUserInfo = useAppShallowSelector(
    getGoogleAuthUserInfoSelector
  );

  const {
    preBookingFormik: { values: preBookingValues },
    setPreBookingAddressToStorage,
  } = useBookingFrom();
  const isFetching = useAppSelector(getIsFetchingUpdateProfileSelector);
  const isAuth = useAppSelector(getIsAuthUserSelector);

  const formik = useFormik<CreateFormValues>({
    initialValues: {
      firstName:
        bankIdUserInfo?.given_name || googleAuthUserInfo.first_name || "",
      lastName: bankIdUserInfo?.surname || googleAuthUserInfo.last_name || "",
      personal_number:
        bankIdUserInfo?.personal_number ||
        googleAuthUserInfo.user_personal_number ||
        "",
      email: googleAuthUserInfo.email || "",
      phone: "",
      address: preBookingValues.address,
      areaNumber: preBookingValues.areaNumber,
      district: preBookingValues.district,
      county: preBookingValues.county,
      gdpr: false,
      address_id: preBookingValues.address_id || null,
    },
    onSubmit(values) {
      const { streetName, streetNumber } = getStreetData(values.address);

      dispatch(
        getSelectedAddress({
          postal_code: Number(getPostalCode(formik.values.areaNumber)),
          street_exact: streetName,
          number_exact: streetNumber,
          postal_code_area_name: getAreaName(formik.values.areaNumber),
        })
      ).then(({ payload }) => {
        if (!payload) return;
        const { address_id, address, areaNumber, district, county } =
          payload as SellerAddressI;

        dispatch(
          createUser({
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.phone,
            email: values.email,
            gdpr_consent: values.gdpr,
            personal_number: values.personal_number,
            address_id: Number(address_id),
            ...(googleAuthUserInfo.user_personal_number
              ? { provider_id: 2 }
              : {}),
          })
        );
        setPreBookingAddressToStorage({
          address: address,
          areaNumber: areaNumber,
          address_id: Number(address_id),
          district: district,
          county: county,
        });
        trackingCreateAccount();
      });
    },
    validationSchema: CreateClientFormSchema(),
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue("phone", e.target.value.replace(/[ _]+/g, ""));

  useEffect(() => {
    if (isAuth) {
      navigate(routesConstant.rootPage);
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    if (
      !(
        bankIdUserInfo?.personal_number ||
        googleAuthUserInfo?.user_personal_number
      )
    ) {
      navigate(routesConstant.auth);
    }
  }, [
    bankIdUserInfo?.personal_number,
    googleAuthUserInfo?.user_personal_number,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearPrefillData());
      dispatch(clearPrefillGoogleData());
    };
  }, [dispatch]);

  return (
    <form
      className="create_form_container"
      onSubmit={formik.handleSubmit}
      onBlur={formik.handleBlur}
      autoComplete="off"
    >
      <Text
        variant="title-medium"
        className={`${isMobile ? "title-small20" : ""} create_form_title`}
      >
        {t("translation.clienCreateFormPage.title")}
      </Text>
      {!isMobile && (
        <Text variant="paragraph-body" className="create_form_subtitle">
          {t("translation.clienCreateFormPage.subtitle")}
        </Text>
      )}
      <div className="form_row">
        <TextInput
          label={t("translation.inputLabel.firstName")}
          placeholder={t("translation.inputPlaceholder.firstName")}
          classNameContainer={`form_input first_name 
            ${
              formik.touched.firstName && formik.errors.firstName
                ? "with_error"
                : ""
            }`}
          name="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          isRequared
          errorMessage={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : ""
          }
        />
        <TextInput
          label={t("translation.inputLabel.lastName")}
          placeholder={t("translation.inputPlaceholder.lastName")}
          classNameContainer={`form_input first_name 
            ${
              formik.touched.lastName && formik.errors.lastName
                ? "with_error"
                : ""
            }`}
          name="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          isRequared
          errorMessage={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : ""
          }
        />
      </div>
      <div className={isMobile ? "form_row" : ""}>
        <TextInput
          label={t("translation.inputLabel.email")}
          placeholder={t("translation.inputPlaceholder.email")}
          name="email"
          classNameContainer="form_input email_address"
          onChange={formik.handleChange}
          value={formik.values.email}
          isRequared
          disabled={!!googleAuthUserInfo.email}
          errorMessage={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
        />
        <PhoneTextInput
          isRequared
          name="phone"
          classNameContainer="form_input phone_number"
          value={formik.values.phone}
          errorMessage={
            formik.touched.phone && formik.errors.phone
              ? formik.errors.phone
              : ""
          }
          onChange={handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <SellerAddress formik={formik} />
      {!isMobile ? (
        <>
          <Checkbox
            isLink
            lable={t("translation.clienCreateFormPage.checkbox")}
            name="gdpr"
            className="form_input booking_form_checkbox form_row"
            checked={formik.values.gdpr}
            error={
              formik.touched.gdpr && formik.errors.gdpr
                ? formik.errors.gdpr
                : ""
            }
            onChange={formik.handleChange}
          />
          <Button
            sizeVariant="Large"
            type="submit"
            name="submit_button"
            showLoadingIndicator={isFetching}
            disabled={isFetching}
            startIcon={<AccountCircleOutlinedIcon />}
          >
            {t("translation.clienCreateFormPage.createAccountButton")}
          </Button>
          <Link
            className="policy_link"
            native
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_PRIVACY_POLICY_URL_SELLER_OR_NO_USER}
          >
            {t("translation.bookingPage.readPolicy")}
          </Link>
        </>
      ) : (
        <>
          <Checkbox
            isLink
            lable={t("translation.clienCreateFormPage.checkbox")}
            name="gdpr"
            className="form_input booking_form_checkbox"
            checked={formik.values.gdpr}
            error={formik.errors.gdpr}
            onChange={formik.handleChange}
          />
          <Link
            className="policy_link"
            native
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_PRIVACY_POLICY_URL_SELLER_OR_NO_USER}
          >
            {t("translation.bookingPage.readPolicy")}
          </Link>
          <StickyButton
            sizeVariant="Large"
            type="submit"
            name="submit_button"
            showLoadingIndicator={isFetching}
            disabled={isFetching}
            startIcon={<AccountCircleOutlinedIcon />}
          >
            {t("translation.clienCreateFormPage.createAccountButton")}
          </StickyButton>
        </>
      )}
    </form>
  );
};

export default CreateForm;
