import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { useCallback, useRef, useEffect } from "react";
import { getUpcomingMeetings } from "store/asyncActions/meetings";
import { UpcomingMeetingData } from "store/models/meetings";
import {
  clearUpcomingMeetings,
  getCountUpcommingMeetingDataSelector,
  getUpcomingMeetingsSelector,
} from "store/reducers/meetings";
import { LOAD_ALL_UPCOMING_MEETINGS_LIMIT } from "constants/limitLoadConstants";

export const useUpcomingMeetins = (): [UpcomingMeetingData[], () => void] => {
  const dispatch = useAppDispatch();
  const offset = useRef(0);
  const isFirstLoad = useRef(true);
  const countMeetings = useAppSelector(getCountUpcommingMeetingDataSelector);
  const upcommingMeeting = useAppShallowSelector(getUpcomingMeetingsSelector);

  const handleInfinytyScroll = useCallback(() => {
    if (countMeetings > offset.current || isFirstLoad.current) {
      isFirstLoad.current = false;
      dispatch(getUpcomingMeetings({ offset: offset.current }));
      offset.current += LOAD_ALL_UPCOMING_MEETINGS_LIMIT;
    }
  }, [countMeetings, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearUpcomingMeetings());
    };
  }, [dispatch]);

  return [upcommingMeeting, handleInfinytyScroll];
};
