import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import { LoginDataI } from "store/models/agent";
import { AdminLoginParamsI, AgentsEmailListResponce, RefreshAdminLoginParamsI, RefreshTokenDataI, TokensI } from "store/models/profile";

export class LoginAPI extends CRUD {
  userLogin(data: LoginDataI): AxiosPromise<TokensI> {
    return this.request({
      url: `${this.url}/`,
      data,
      method: "POST",
    });
  }

  refreshToken(data: RefreshTokenDataI): AxiosPromise<TokensI> {
    return this.request({
      url: `${this.url}/refresh/`,
      method: "POST",
      data,
      timeout: 10000,
    });
  }

  adminLogin(params: AdminLoginParamsI): AxiosPromise<AgentsEmailListResponce> {
    return this.request({
      url: `${this.url}/admin_login/`,
      params
    });
  }

  refreshAdminLogin(data: RefreshAdminLoginParamsI): AxiosPromise<TokensI> {
    return this.request({
      url: `${this.url}/admin_login/refresh/`,
      method: "POST",
      data,
      timeout: 10000,
    });
  }
}

export default function loginAPI(request: AxiosInstance) {
  return new LoginAPI({
    url: "/login",
    request,
  });
}
