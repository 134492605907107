import { AxiosInstance, AxiosPromise } from "axios";

import CRUD from "../base";
import {
  RestorePasswordRequestData,
  ChangePasswordRequestData,
} from "store/models/profile";

export class PasswordRecovetyApi extends CRUD {
  sendEmail(data: RestorePasswordRequestData): AxiosPromise<string> {
    return this.request({
      url: `${this.url}/send_email/`,
      data,
      method: "POST",
    });
  }

  changePassword(data: ChangePasswordRequestData): AxiosPromise {
    return this.request({
      url: `${this.url}/change_password/`,
      data,
      method: "POST",
    });
  }
}

export default function passwordRecovetyApi(request: AxiosInstance) {
  return new PasswordRecovetyApi({
    url: "/password_recovery",
    request,
  });
}

//change_password
