import { useEffect, useRef, memo, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import SortIcon from "@mui/icons-material/Sort";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import GroupsIcon from "@mui/icons-material/Groups";

import Text from "components/common/Text";
import Select, { SelectItemValue } from "components/common/Select";
import AgentsList from "./components/AgentsList";
import Button from "components/common/Button";
import BlockLoader from "components/BlockLoader";
import { getAgentsList } from "store/asyncActions/agents";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { AgentsFilterParamsI } from "store/models/agent";
import {
  getAllAgentsCountSelector,
  getCurrentAgentCountSelector,
  getAgentsIsFetchingSelector,
  getAgencyListSelector,
  getSelectedAgencyListSelector,
  getNextAgentsList,
  getSelectedAgentsToDirectContactSelector,
  getAgentsSelector,
} from "store/reducers/agents";
import { LOAD_AGENTS_LIST_PAGE_LIMIT } from "constants/limitLoadConstants";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";
import { SelectItemI } from "constants/bookingMeetingForm";
import { AgentSortTitle, SortDirectionT } from "constants/sortConstants";
import AgencyFilter from "components/common/AgencyFilter";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import { routesConstant } from "constants/appRoutesConstants";

import Sticky from "react-sticky-el";
import ContactMeModal from "pages/AgentPresentationProfilePage/components/ContactMeModal";
import { trackingAgentOpenContactMeForm } from "utils/facebookTracking";

export const agentFilterSelects: SelectItemI<
  AgentSortTitle,
  SortDirectionT | "default"
>[] = [
  {
    title: "DefaultSort",
    value: "default",
  },
  {
    title: "AlphabetASCSort",
    value: "asc",
  },
  {
    title: "AlphabetDESCSort",
    value: "desc",
  },
];

// interface for backRoute logic, if user clicks back from agent page
// we save previous state of agents list on booking page and don't send a new request
// if no, reset this location on the pre-booking steps
export interface LocationStateI {
  backRoute: string;
}

const AgentBookingList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const allAgentsCount = useAppSelector(getAllAgentsCountSelector);
  const agencyList = useAppShallowSelector(getAgencyListSelector);
  const selectedAgentsToDirectContactList = useAppShallowSelector(
    getSelectedAgentsToDirectContactSelector
  );

  const agents = useAppShallowSelector(getAgentsSelector);

  const selectedAgencyList = useAppShallowSelector(
    getSelectedAgencyListSelector
  );
  const currentAgentsCount = useAppSelector(getCurrentAgentCountSelector);
  const isAgentsFetching = useAppSelector(getAgentsIsFetchingSelector);
  const location = useLocation() as { state: LocationStateI };

  const [isContactMeModalOpened, setIsContactMeModalOpened] = useState(false);
  const [sortValue, setSortValue] = useState<SelectItemValue | null>(
    agentFilterSelects[0]
  );
  const {
    preBookingFormik: {
      values: { residenceType, areaNumber },
    },
  } = useBookingFrom();

  const isBackRouteAgentPresentation = useRef<boolean>(
    location?.state?.backRoute === routesConstant.agentPresentationProfile
  );

  const params = useMemo(() => {
    const areaNumberValue = +areaNumber.replace(" ", "").slice(0, 5);
    const agencyList = selectedAgencyList.join(";");

    const params = {
      type_housing_id: residenceType,
      ...(areaNumber ? { postal_code: areaNumberValue } : {}),
      ...(selectedAgencyList.length ? { agency: agencyList } : {}),
    } as AgentsFilterParamsI;

    return params;
  }, [residenceType, areaNumber, selectedAgencyList]) as AgentsFilterParamsI;

  const handleClickShowMore = () => dispatch(getNextAgentsList());

  const handleCloseContactMeModal = () => setIsContactMeModalOpened(false);

  const handleOpenContactMeModal = () => {
    trackingAgentOpenContactMeForm(
      agents
        .filter((agent) =>
          selectedAgentsToDirectContactList.some(
            (selectedAgentEmail) => selectedAgentEmail === agent.email
          )
        )
        .map((agent) => ({
          id: agent.id,
          agentName: `${agent.firstName} ${agent.lastName}`,
        }))
    );
    setIsContactMeModalOpened(true);
  };

  const handleSelect = (selectOption: SelectItemValue) => {
    setSortValue(selectOption);
    const orderingValue =
      selectOption.value === "asc" ? "first_name" : "-first_name";
    const isDefault = selectOption.value === "default";

    dispatch(
      getAgentsList({
        ...params,
        ...(!isDefault ? { ordering: orderingValue } : {}),
      })
    );
  };

  useEffect(() => {
    if (!isBackRouteAgentPresentation.current) {
      dispatch(
        getAgentsList({
          ...params,
        })
      );
    }
  }, [dispatch, residenceType, params, isBackRouteAgentPresentation]);

  useEffect(() => {
    isBackRouteAgentPresentation.current = false;

    return () => {
      if (location?.state?.backRoute) {
        location.state.backRoute = "";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHidenShowMoreButton =
    currentAgentsCount >= allAgentsCount ||
    allAgentsCount < LOAD_AGENTS_LIST_PAGE_LIMIT;

  return (
    <div
      className={`agent_booking_list_container ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      <div className="agent_booking_list">
        <div className="top_section">
          <div className="title_block">
            <Text variant="title-large" className="title">
              {t("translation.agentBookingList.title")}
            </Text>
            <Text variant="paragraph-body">
              {t("translation.agentBookingList.description")}
            </Text>
          </div>
          <div className="filters_container">
            <AgencyFilter options={agencyList} />
            <Select
              className={`sort_filter ${isMobile ? "noPlaceholder" : ""}`}
              handleClickSelectItem={handleSelect}
              value={sortValue?.title}
              controlProps={{
                placeholder: t("translation.agentBookingList.agentFilter"),
                endAdornment: <SortIcon />,
              }}
            >
              {agentFilterSelects.map((item) => {
                const selectedIcon = item.value === sortValue?.value;
                return (
                  <Select.SelectItem
                    key={item.title}
                    title={t(`translation.agentSortType.${item.title}`)}
                    value={item.value}
                    icon={
                      selectedIcon
                        ? RadioButtonCheckedIcon
                        : RadioButtonUncheckedIcon
                    }
                  />
                );
              })}
            </Select>
          </div>
        </div>

        {selectedAgentsToDirectContactList.length ? (
          <Sticky
            stickyStyle={{
              top: isMobile ? "62px" : "68px",
              backgroundColor: "#fff9ed",
              zIndex: 1001,
              ...(isMobile
                ? {
                    width: "100%",
                    left: 0,
                    padding: "0 16px",
                  }
                : {}),
            }}
          >
            <div className="selected_agents_header">
              <Button
                sizeVariant="Large"
                className="send_agents_button"
                onClick={handleOpenContactMeModal}
              >
                <Text className="send_agents_text">
                  {t("translation.calendarPage.selectAgentsToContactMeButton")}
                </Text>
              </Button>

              <div className="selected_agents_count_container">
                <div className="count_container">
                  <span className="count">
                    {selectedAgentsToDirectContactList.length}
                  </span>
                  <GroupsIcon />
                </div>
                <Text className="title-small selected_agents_title">
                  {t("translation.calendarPage.selectedAgents")}
                </Text>
              </div>
            </div>
          </Sticky>
        ) : null}
        {!isAgentsFetching && <AgentsList />}
        {!isAgentsFetching && !isHidenShowMoreButton && (
          <Button
            sizeVariant="Large"
            variant="outlined"
            className="show_more_button"
            onClick={handleClickShowMore}
          >
            {t("translation.agentBookingList.showMore")}
          </Button>
        )}
      </div>
      <BlockLoader selector={getAgentsIsFetchingSelector} />
      <ContactMeModal
        isOpen={isContactMeModalOpened}
        agentsData={agents
          .filter((agent) =>
            selectedAgentsToDirectContactList.some(
              (selectedAgentEmail) => selectedAgentEmail === agent.email
            )
          )
          .map((agent) => ({
            id: agent.id,
            agentName: `${agent.firstName} ${agent.lastName}`,
          }))}
        agentEmails={selectedAgentsToDirectContactList}
        onClickBack={handleCloseContactMeModal}
      />
    </div>
  );
};

export default memo(AgentBookingList);
