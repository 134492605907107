import SmsFailedIcon from "@mui/icons-material/SmsFailed";

import Text from "components/common/Text";
import Button from "components/common/Button";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";

const AgentUpdatePasswordFailed = () => {
  const { isMobile, setViewType } = useAuthContext();
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <>
          <Text variant="title-medium" className="panel_item_title">
            {t("translation.agentAuthPage.updateFailed")}
            <SmsFailedIcon className="restore_password_icon" color="error" />
          </Text>
          <Text
            text={t("translation.agentAuthPage.updateFailedDescription")}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <div className="button_wrapper">
        <Button
          sizeVariant={isMobile ? "Medium" : "Large"}
          type="button"
          className="login_btn"
          onClick={() => setViewType(AuthAgentViewType.AUTH_FORM)}
        >
          {t("translation.agentAuthPage.backToLogin")}
        </Button>
      </div>
    </>
  );
};

export default AgentUpdatePasswordFailed;
