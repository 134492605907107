import React from "react";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { useTranslation } from "react-i18next";

interface SwitchCalendarModalPropsI {
  isOpen: boolean;
  onClickBack: () => void;
  onClickContinue: () => void;
}

const SwitchCalendarModal: React.FC<SwitchCalendarModalPropsI> = ({
  isOpen,
  onClickBack,
  onClickContinue,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      isCloseButton={false}
      classNameDialog="swith_modal_dialog_container"
      title={t("translation.agentProfileCalendars.otherCalendarTitle")}
    >
      <>
        <Text variant="paragraph-body-bold" className="swith_modal_message">
          {t("translation.agentProfileCalendars.otherCalendarDescription")}
        </Text>
        <div className="modal_buttons_container">
          <Button className="modal_button" onClick={onClickContinue}>
            {t("translation.agentProfileCalendars.continueButton")}
          </Button>
          <Button
            variant="outlined"
            className="modal_button"
            onClick={onClickBack}
          >
            {t("translation.agentProfileCalendars.backButton")}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default SwitchCalendarModal;
