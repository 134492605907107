import { createAsyncThunk } from "@reduxjs/toolkit";
import addMinutes from "date-fns/addMinutes";
import api from "api";
import { BlueBoxResponseI } from "store/models/blueBox";
import { lsApi } from "utils/localStorageApi";

export const getAgentsListBlueBox = createAsyncThunk(
  "blueBox/getAgentsListBlueBox",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.blueBox.getBlueBoxList();

      // set bookingMeetingAgentsList key into localStorage for WP logic
      if (data.length) {
        lsApi.set("bookingMeetingAgentsList", true);
      } else {
        lsApi.removeItem("bookingMeetingAgentsList");
      }

      const response = data.map((agent: BlueBoxResponseI) => {
        return {
          selectedTimeSlot: {
            startDate: agent.start_time,
            endDate: addMinutes(new Date(agent.start_time), 15).toISOString(),
          },
          address_id: agent.address_id,
          type_housing_id: agent.type_housing_id,
          postal_code: agent.postal_code,
          id: agent.agent_id,
          fotoUrl: agent.agent_details?.agent_profile.image,
          firstName: agent.agent_details?.first_name,
          lastName: agent.agent_details?.last_name,
          agencyLogo: agent.agent_details?.agent_profile.agency.logotype,
        };
      });

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
