import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCustomerAnalysisQuestions } from "store/asyncActions/customerAnalysis";
import { CustomAnalysisI } from "store/models/customerAnalysis";

import { createTypedSelector } from "../utils";

interface CustomerAnalysisSliseI {
  isFetching: boolean;
  customerQuestions: CustomAnalysisI;
}

const initialState: CustomerAnalysisSliseI = {
  isFetching: false,
  customerQuestions: {} as CustomAnalysisI,
};

export const getCustomerAnalysisQuestionsSelector = createTypedSelector(
  (state) => state.customerAnalysis.customerQuestions
);

export const getCustomerAnalysisFetchingSelector = createTypedSelector(
  (state) => state.customerAnalysis.isFetching
);

const customerAnalysisSlice = createSlice({
  name: "customerAnalysis",
  initialState,
  reducers: {},
  extraReducers: {
    [getCustomerAnalysisQuestions.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCustomerAnalysisQuestions.fulfilled.type]: (
      state,
      action: PayloadAction<CustomAnalysisI>
    ) => {
      state.customerQuestions = action.payload;
      state.isFetching = false;
    },
    [getCustomerAnalysisQuestions.rejected.type]: (state) => {
      state.customerQuestions = {} as CustomAnalysisI;
      state.isFetching = false;
    },
  },
});

export default customerAnalysisSlice.reducer;
