import { getUnixTime } from "date-fns";
import { lsApi } from "./localStorageApi";

interface AuthTokenI {
  refresh: string;
  access: string;
}

interface DetailTokenI {
  token_type: "access" | "refresh";
  exp: number;
  iat: number;
  jti: string;
  user_id: string;
}

export const getToken = (): string | null => {
  const _accessToken = lsApi.get<string>("accessToken");
  return _accessToken || null;
};

export const getRefreshToken = (): string | null => {
  const _accessToken = lsApi.get<string>("refreshToken");
  return _accessToken || null;
};

export const setTokens = (data: AuthTokenI): void => {
  lsApi.set("accessToken", data.access);
  lsApi.set("refreshToken", data.refresh);
};

export const setAccesToken = (token: string): void => {
  lsApi.set("accessToken", token);
};

export const deleteToken = (): void => {
  lsApi.clear();
};

export const checkExtToken = (_token: string): boolean => {
  if (!_token || typeof _token !== "string") return false;

  const detailToken: DetailTokenI = JSON.parse(atob(_token.split(".")[1]));

  return detailToken.exp ? detailToken.exp <= getUnixTime(new Date()) : true;
};
