import React, { memo } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import Text from "components/common/Text";
import { AdressLabelForFilterPropsI } from "../types";
import { useCalendarContext } from "../useCalendarContext";

const AdressLabelForFilter: React.FC<AdressLabelForFilterPropsI> = ({
  areaNumber,
  address
}) => {
  const { isAgentCalendar } = useCalendarContext();
  const titleForBookingPlaceFilter = `${address}`;

  const areaNumberWithFormat = `${areaNumber.substring(
    0,
    3
  )} ${areaNumber.substring(3)}`;

  if (!areaNumber && !address) return null;

  return (
    <span className={`address_label_container ${isAgentCalendar ? 'agent_page' : ''}`}>
      {(address) && (
        <>
          <PlaceOutlinedIcon className="address_label_icon" />
          <Text
            text={titleForBookingPlaceFilter}
            variant="paragraph-body"
            className="address_label_address"
          />
        </>
      )}
      <Text
        text={areaNumberWithFormat}
        variant="paragraph-body"
        className="address_label_areanumber"
      />
    </span>
  );
};

export default memo(AdressLabelForFilter);
