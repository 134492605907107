export const getPostalCode = (value: string) =>
  value.replace(" ", "").slice(0, 5);
export const getAreaName = (value: string) =>
  value.replace(" ", "").slice(5).trim();

export const getAddressData = (areaNumber: string, address: string) => {
  const postalCode = areaNumber.replace(" ", "").slice(0, 5);
  const streetName = address.match(/[A-Za-zéäöåÄÖÅÉ]+/)?.[0];

  return { postalCode, streetName };
};

export const getStreetData = (value: string) => {
  let streetName = "";
  let streetNumber = "";
  let address = /[0-9a-zéåäöA-ZÅÄÖÉ][a-zéäöåÄÖÅÉ,:-\s]*[0-9]*/i.exec(
    value
  )?.[0];

  if (address && /[0-9a-zéåäöA-ZÅÄÖÉ]*[a-zéåäöA-ZÅÄÖÉ:-\s]+/gi.test(address)) {
    streetName =
      address
        .match(/[0-9a-zéåäöA-ZÅÄÖÉ]*[a-zéåäöA-ZÅÄÖÉ:-\s]+/gi)?.[0]
        .trimEnd() || "";
    address = address.replace(streetName, "");
  }

  if (address && /\d+/gi.test(address)) {
    streetNumber = address.match(/[0-9-]+/gi)?.[0] || "";
  }

  return { streetName, streetNumber };
};
