import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import Button from "components/common/Button";
import { RoutesT } from "constants/appRoutesConstants";

interface GoBackButtonPropsI {
  to?: RoutesT | string;
  className?: string;
  title?: string;
  stateToBack?: any;
  isMobile?: boolean;
}

const GoBackButton: React.FC<GoBackButtonPropsI> = ({
  to,
  className = "",
  title,
  stateToBack,
  isMobile,
}) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    if (to) {
      navigate(to, { state: stateToBack });
    } else {
      navigate(-1);
    }
  }, [navigate, stateToBack, to]);

  return (
    <div className={`go_back_button_container ${className}`}>
      {!isMobile ? (
        <Button variant="text" onClick={handleClick} className="go_back_button">
          <ArrowBackIcon className="back_button_icon" />
          {title}
        </Button>
      ) : (
        <div className="close_button_wrapper">
          <button className="close_button" onClick={handleClick}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default React.memo(GoBackButton);
