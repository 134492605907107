import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { routesConstant } from "constants/appRoutesConstants";
import { AgentShortDataItem } from "store/models/agent";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";
import {
  trackingAgentOpenContactMeForm,
  trackingViewAgent,
} from "utils/facebookTracking";

import expertLabel from "../../../assets/img/badge.svg";
import checkedIcon from "../../../assets/img/checkedIcon.svg";
import emptyCheckedIcon from "../../../assets/img/emptyCheckedIcon.svg";
import rateIcon from "../../../assets/img/star_rate.svg";
import ContactMeModal from "pages/AgentPresentationProfilePage/components/ContactMeModal";
import { DomainName } from "pages/AgentPresentationProfilePage/components/AgentDetail";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import {
  getSelectedAgentsToDirectContactSelector,
  setSelectedAgentsToDirectContact,
} from "store/reducers/agents";

const AgentItem: React.FC<AgentShortDataItem> = ({
  firstName,
  lastName,
  fotoUrl,
  agencyLogo,
  id,
  isExpert,
  maxRating,
  ratingDomain,
  reviews,
  salesLastSixMonths,
  yearSalesLast,
  email,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedAgentsToDirectContactList = useAppShallowSelector(
    getSelectedAgentsToDirectContactSelector
  );

  const isAgentSelected = selectedAgentsToDirectContactList.some(
    (item) => item === email
  );

  const [isRatingHovered, setIsRatingHovered] = useState<boolean>(false);
  const [isCheckboxHovered, setIsCheckboxHovered] = useState<boolean>(false);
  const [isContactMeModalOpened, setIsContactMeModalOpened] = useState(false);

  const { isMobile } = useMobileContext();

  const { t } = useTranslation();

  const ratingDomainInformation = `${t(
    "translation.calendarPage.takenSource"
  )} ${ratingDomain}`;

  const handleMouseEnter = () => setIsRatingHovered(true);

  const handleMouseLeave = () => setIsRatingHovered(false);

  const handleMouseCheckboxEnter = () => setIsCheckboxHovered(true);

  const handleMouseCheckboxLeave = () => setIsCheckboxHovered(false);

  const handleCloseContactMeModal = () => setIsContactMeModalOpened(false);

  const handleOpenContactMeModal = () => {
    trackingAgentOpenContactMeForm([
      { id: id, agentName: `${firstName} ${lastName}` },
    ]);
    setIsContactMeModalOpened(true);
  };

  const handleSelectAgent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    dispatch(setSelectedAgentsToDirectContact(email));
  };

  const handleClickMobileRate = () => {
    if (!isMobile) return;

    setIsRatingHovered(!isRatingHovered);
  };

  const handleClickAgentDetail = () => {
    navigate(`${routesConstant.agentPresentationProfile}/${id}`, {
      state: { backRoute: routesConstant.booking },
    });
    trackingViewAgent(id, firstName, lastName);
  };

  return (
    <>
      <div className="agent_item_container">
        <div className="agent_item_wrap">
          <div
            className="agent_selected_checkbox_container"
            onClick={handleSelectAgent}
            onMouseEnter={handleMouseCheckboxEnter}
            onMouseLeave={handleMouseCheckboxLeave}
          >
            {isAgentSelected ? (
              <img src={checkedIcon} alt="checked icon" />
            ) : (
              <img src={emptyCheckedIcon} alt="unchecked icon" />
            )}

            {!isMobile && isCheckboxHovered && (
              <div className="checkbox_tooltip_container">
                <Text
                  text={t("translation.calendarPage.selectedAgentsHoverText")}
                  variant="paragraph-body-bold"
                  className="tooltip_text"
                />
              </div>
            )}
          </div>

          <div className="foto_container" onClick={handleClickAgentDetail}>
            {isExpert && (
              <div className="expert_label_container">
                <img src={expertLabel} alt="expert label" />
              </div>
            )}

            <div
              className="rate_container"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClickMobileRate}
            >
              <img src={rateIcon} alt="rate star icon" />
              <Text
                text={maxRating ? `${maxRating.toFixed(1)}/5.0` : "-/5.0"}
                variant="label-small"
                className="rate_text"
              />

              {maxRating && isRatingHovered && (
                <div className="rate_tooltip_container">
                  <Text
                    text={ratingDomainInformation}
                    variant="paragraph-body-bold"
                    className="rate_tooltip_text"
                  />
                </div>
              )}
            </div>

            {fotoUrl ? (
              <img src={fotoUrl} alt="agent foto" className="foto" />
            ) : (
              <AccountCircleIcon className="no_logo_icon" />
            )}
            <div className="foto_blured_container"></div>
          </div>
          <div className="bottom_section">
            <Text
              text={`${firstName} ${lastName}`}
              variant={isMobile ? "title-small20" : "title-small"}
              className="name"
            />
            <div className="agency_logo_container">
              {agencyLogo ? (
                <img
                  src={agencyLogo}
                  alt="Agency logo"
                  className="agency_logo"
                />
              ) : null}
            </div>

            <div>
              <div className="statistic_container">
                <Text text={reviews || "-"} className="statistic_value" />
                <Text
                  text={` ${t(
                    "translation.agentPresentationProfilePage.reviewOn"
                  )} ${ratingDomain || "-"} `}
                  className="statistic_description"
                />
              </div>
              <div className="statistic_container">
                <Text
                  text={
                    ratingDomain
                      ? ratingDomain === DomainName.reco
                        ? yearSalesLast || "-"
                        : salesLastSixMonths || "-"
                      : "-"
                  }
                  className="statistic_value"
                />{" "}
                <Text
                  text={t(
                    "translation.agentPresentationProfilePage.lastMonthSales",
                    {
                      monthCount: ratingDomain
                        ? ratingDomain === DomainName.reco
                          ? 12
                          : 6
                        : "X",
                    }
                  )}
                  className="statistic_description"
                />
              </div>
            </div>

            <div className="buttons_container">
              <Button
                variant="outlined"
                className="button_schedule"
                sizeVariant={isMobile ? "Small" : "Medium"}
                onClick={handleClickAgentDetail}
              >
                {t("translation.calendarPage.agentProfile")}
              </Button>
              <Button
                className="button_schedule"
                sizeVariant={isMobile ? "Small" : "Medium"}
                onClick={handleOpenContactMeModal}
              >
                {t("translation.calendarPage.contactAgent")}
              </Button>
            </div>
          </div>
        </div>
        <ContactMeModal
          isOpen={isContactMeModalOpened}
          agentEmails={[email]}
          agentsData={[{ id: id, agentName: `${firstName} ${lastName}` }]}
          onClickBack={handleCloseContactMeModal}
        />
      </div>
    </>
  );
};

export default memo(AgentItem);
