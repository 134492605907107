import React, { memo } from "react";
import { useDispatch } from "react-redux";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";
import { format, isToday, isTomorrow, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootRounded";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

import WhiteWrapBlock from "components/WhiteWrapBlock";
import Text from "components/common/Text";
import Button from "components/common/Button";

import { useAppShallowSelector } from "hooks/redux";
import { getUpcomingMeetingsSelector } from "store/reducers/meetings";
import { getCustomerAnalysisPDF } from "store/asyncActions/customerAnalysis";
import { timeZoneSV } from "constants/timeZoneConstants";

interface ListItemInterface {
  title: string;
  typeHouse: string;
  street: string;
  postalCode: string;
  rooms: string;
  areaSqm: string;
  sellerName: string;
  id?: string;
  customerAnalysis: string | null;
  sellerPhoneNumber?: string;
  sellerEmail?: string;
}

const BottomSectionList: React.FC = () => {
  const allUpcomingMeeting = useAppShallowSelector(getUpcomingMeetingsSelector);
  const { t } = useTranslation();

  return (
    <div className="metting_list">
      {allUpcomingMeeting.length ? (
        allUpcomingMeeting.map((meeting) => {
          const {
            start_time,
            end_time,
            event,
            seller,
            address,
            customer_analysis,
          } = meeting || {};

          const {
            first_name: sellerFirstName = "",
            last_name: sellerLastName = "",
          } = seller || {};

          const {
            type_housing = "",
            number_of_rooms = "",
            area_sqm,
          } = event || {};

          // Data for customer analysis
          const customerAnalysis = customer_analysis || "";

          // Data for address
          const street = address
            ? `${address.street.name} ${address.number}`
            : "";
          const postalCode = address ? address.street.postal_code : "";

          // Agent data for seller name
          const sellerName = `${sellerFirstName} ${sellerLastName}`;
          const sellerEmail = seller.email;
          const sellerPhoneNumber = seller.phone_number;

          // Data for rooms and sqm
          const rooms = number_of_rooms
            ? number_of_rooms == 1
              ? `${number_of_rooms} ${t("translation.clientMeetingPage.room")}`
              : `${number_of_rooms} ${t("translation.clientMeetingPage.rooms")}`
            : t("translation.clientMeetingPage.noRooms");
          const areaSqm = area_sqm ? `${area_sqm}m2` : "n/a";

          // Data for right time distance pointer
          const isTodayStartTime = isToday(parseISO(start_time));
          const isTomorrowTime = isTomorrow(parseISO(start_time));

          const startEndTimeWithDataString = `${format(
            utcToZonedTime(parseISO(start_time), timeZoneSV),
            "d MMM, kk:mm"
          )}-${format(
            utcToZonedTime(parseISO(end_time), timeZoneSV),
            "kk:mm"
          )}`;

          const agentTimeDistance = isTodayStartTime
            ? `${t("translation.clientMeetingPage.today")}`
            : isTomorrowTime
            ? `${t("translation.clientMeetingPage.tomorrow")}`
            : startEndTimeWithDataString;

          return (
            <BottomSectionListItem
              key={uniqueId(`${start_time}`)}
              title={agentTimeDistance}
              typeHouse={type_housing}
              street={street}
              postalCode={postalCode}
              rooms={rooms}
              areaSqm={areaSqm}
              sellerName={sellerName}
              customerAnalysis={customerAnalysis}
              sellerEmail={sellerEmail}
              sellerPhoneNumber={sellerPhoneNumber}
            />
          );
        })
      ) : (
        <div className="empty_list_container">
          <Text variant="title-small" className="empty_list_text">
            {t("translation.agentMeetings.noUpcomingMeetings")}
          </Text>
        </div>
      )}
    </div>
  );
};

export default memo(BottomSectionList);

const BottomSectionListItem: React.FC<ListItemInterface> = ({
  title,
  typeHouse,
  customerAnalysis,
  street,
  postalCode,
  sellerName,
  rooms,
  areaSqm,
  sellerPhoneNumber,
  sellerEmail,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenCustomerAnalisys = () => {
    if (customerAnalysis) {
      dispatch(getCustomerAnalysisPDF(customerAnalysis));
    }
  };

  return (
    <div className="item_container">
      <WhiteWrapBlock className="meeting_item">
        <div className="row title_section">
          <Text
            text={title}
            className={`h4 title agent_title`}
            tooltipTitle={title}
            variant="title-small"
          />
        </div>
        <div className="row">
          <span className="location_item">
            <MapsHomeWorkOutlinedIcon className="icon block" />
            <Text
              text={typeHouse}
              className="location_item_text street"
              variant="paragraph-body"
            />
          </span>
        </div>
        <div className="row title_section">
          <span className="location_item baseline">
            <PlaceOutlinedIcon className="icon" />
            <div className="location_container">
              <Text
                text={street}
                className="location_item_text street"
                variant="paragraph-body"
              />
              <Text
                text={postalCode}
                className="location_item_text"
                variant="paragraph-body"
              />
            </div>
          </span>
        </div>
        <div className="row">
          <span className="location_item">
            <MeetingRoomOutlinedIcon className="icon" />
            <Text
              text={rooms}
              className="location_item_text standart"
              variant="paragraph-body"
            />
          </span>
          <span className="location_item">
            <SquareFootOutlinedIcon className="icon" />
            <Text
              text={areaSqm}
              className="location_item_text standart"
              variant="paragraph-body"
            />
          </span>
        </div>
        <div className="line" />

        <div className="row title_section">
          <span className="location_item">
            <AccountCircleOutlinedIcon className="icon" />
            <Text
              text={sellerName}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>

        <div className="row title_section">
          <span className="location_item">
            <EmailOutlinedIcon className="icon" />
            <Text
              text={sellerEmail}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>

        <div className="row title_section">
          <span className="location_item">
            <LocalPhoneOutlinedIcon className="icon" />
            <Text
              text={sellerPhoneNumber}
              className="location_item_text"
              variant="paragraph-body"
            />
          </span>
        </div>

        <Button
          variant="outlined"
          className="meeting_item_button"
          sizeVariant="Medium"
          disabled={!customerAnalysis}
          onClick={handleOpenCustomerAnalisys}
          endIcon={<PictureAsPdfOutlinedIcon />}
        >
          <span className="button_text">
            {t("translation.agentMeetings.customerAnalisys")}
          </span>
        </Button>
      </WhiteWrapBlock>
    </div>
  );
};
