import React, { memo } from "react";

import PhotoCamera from "components/Svg/PhotoCamera";
interface AgentFotoPropsI {
  url: string | undefined;
}

const AgentFoto: React.FC<AgentFotoPropsI> = ({ url }) => {
  return (
    <div className="agent_foto_container">
      {url ? (
        <img alt="Agent personal foto" src={url} className="agent_foto" />
      ) : (
        <PhotoCamera className="phoco_camera_icon" />
      )}
    </div>
  );
};

export default memo(AgentFoto);
