import { FC, createContext, useCallback, useState, useMemo } from "react";
import { defaultNS } from "i18n";
import {
  format,
  add,
  sub,
  fromUnixTime,
  getUnixTime,
  startOfDay,
  getDate,
} from "date-fns";
import { sv, enUS } from "date-fns/locale";

import { DayI, FilterCalendarContextValues } from "./types";

export const DAYS_OF_WEEK: number[] = [1, 2, 3, 4];

const getDaysOfWeek = (startWeek: number): DayI[] => {
  const _date = fromUnixTime(startWeek);

  return DAYS_OF_WEEK.map((_item, index) => {
    const _dateOfWeek = add(_date, { days: index });
    const lang = defaultNS === "en" ? enUS : sv;

    const day = getDate(_dateOfWeek);
    const dayName = format(_dateOfWeek, "EEE", { locale: lang });
    const timestamp = getUnixTime(_dateOfWeek);
    return { day: day, dayName: dayName, timestamp };
  });
};

export const FilterCalendarContext = createContext<FilterCalendarContextValues>(
  {} as FilterCalendarContextValues
);

const FilterContext: FC = ({ children }) => {
  const currentDayTimestamp = getUnixTime(startOfDay(new Date()));

  const [firstDayOfCalendarTimestamp, setFirstDayOfCalendarTimestamp] =
    useState<number>(currentDayTimestamp);

  const dataOfSelectedWeek = useMemo(
    () => getDaysOfWeek(firstDayOfCalendarTimestamp),
    [firstDayOfCalendarTimestamp]
  );

  const goNextDay = useCallback(() => {
    setFirstDayOfCalendarTimestamp((firstDayOfCalendarTimestampPrev) =>
      getUnixTime(
        add(fromUnixTime(firstDayOfCalendarTimestampPrev), { days: 1 })
      )
    );
  }, []);

  const goPrevDay = useCallback(() => {
    setFirstDayOfCalendarTimestamp((firstDayOfCalendarTimestampPrev) =>
      getUnixTime(
        sub(fromUnixTime(firstDayOfCalendarTimestampPrev), { days: 1 })
      )
    );
  }, []);

  const goCurrentDay = useCallback(() => {
    setFirstDayOfCalendarTimestamp(currentDayTimestamp);
  }, [currentDayTimestamp]);

  return (
    <FilterCalendarContext.Provider
      value={{
        firstDayOfCalendarTimestamp,
        dataOfSelectedWeek,
        currentDayTimestamp,
        goCurrentDay,
        goNextDay,
        goPrevDay,
      }}
    >
      {children}
    </FilterCalendarContext.Provider>
  );
};

export default FilterContext;
