import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

interface SpinnerProps extends CircularProgressProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ className = "", ...rest }) => {
  return (
    <CircularProgress className={`custom_spinner ${className}`} {...rest} />
  );
};

export default Spinner;
