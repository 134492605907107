import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import { BlueBoxResponseI } from "store/models/blueBox";

export class BlueBoxApi extends CRUD {
  getBlueBoxList(): AxiosPromise<BlueBoxResponseI[]> {
    return this.request({
      url: `${this.url}/`,
      method: "GET",
    });
  }
}

export default function blueBoxApi(request: AxiosInstance) {
  return new BlueBoxApi({
    url: "/blue_box",
    request,
  });
}
