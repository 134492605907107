import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";

import api from "api";
import {
  FreeTimeSlotsQueryParams,
  BookingMeetingBodyData,
} from "store/models/agent";
import {
  CancelReserveTimeSlotBody,
  BookingValueI,
  ReserveTimeSlotBody,
} from "store/models/booking";
import { showAlerts } from "store/reducers/alerts";
import { lsApi } from "utils/localStorageApi";

import { getAgentsListBlueBox } from "./blueBox";
import { getAgentsList } from "./agents";

export const getAgentTimeSlots = createAsyncThunk(
  "booking/getAgentTimeSlots",
  async (
    { id, params }: { id: string; params?: FreeTimeSlotsQueryParams },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.agent.getAgentFreeTimeSlots(id, params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.detail);
    }
  }
);

export const reserveTimeSlot = createAsyncThunk(
  "booking/reserveTimeSlot",
  async (body: ReserveTimeSlotBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(getAgentsListBlueBox());
      await api.agent.reserveTimeSlot({
        agent_id: body.agent_id,
        start_time: body.startDate,
        type_housing_id: body.type_housing_id,
        postal_code: body.postal_code,
        address_id: body.address_id || 0,
      });
      dispatch(getAgentsListBlueBox());
      return body;
    } catch (error: any) {
      const errorDetail = error.response.data.detail;
      dispatch(
        showAlerts({
          title: "Booked failed",
          // Temporary solution. Fix after add type_housing_id into profile
          message: `${
            typeof errorDetail === "string"
              ? error.response.data.detail
              : error.response.data.detail.type_housing_id[0]
              ? i18next.t("translation.alertMessage.prebookingStepsMissing")
              : ""
          }`,
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const cancelReserveTimeSlot = createAsyncThunk(
  "booking/cancelReserveTimeSlot",
  async (body: CancelReserveTimeSlotBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(getAgentsListBlueBox());
      await api.agent.cancelReserveTimeSlot({
        agent_id: body.id,
        start_time: body.startDate,
      });
      dispatch(getAgentsListBlueBox());
      if (!body.isChangeTime) {
        const values = lsApi.get<BookingValueI>("bookingForm");

        if (values) {
          dispatch(
            getAgentsList({
              type_housing_id: values.residenceType,
              postal_code: +values.areaNumber.replace(" ", "").slice(0, 5),
            })
          );
        }
      }

      return body.id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const confirmBookingMeeting = createAsyncThunk(
  "booking/confirmBookingMeeting",
  async (body: BookingMeetingBodyData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.agent.bookingMeeting(body);
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.successScheduledMeeting"
          ),
          type: "success",
          autoHideDuration: 6000,
        })
      );
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.alertMessage.failedScheduledMeeting"),
          type: "error",
          persist: true,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
