import React from "react";

import PhotoCamera from "components/Svg/PhotoCamera";

interface RoundAvatarPropsI {
  className?: string;
  src?: string | undefined;
  alt?: string;
}

const RoundAvatar: React.FC<RoundAvatarPropsI> = ({
  className = "",
  src,
  alt = "avatar",
}) => {
  return (
    <div className={`avatar_container ${className}`}>
      {src ? (
        <img src={src} alt={alt} className="avatar_img" />
      ) : (
        <PhotoCamera className="avatar_no_src_icon" />
      )}
    </div>
  );
};

export default RoundAvatar;
