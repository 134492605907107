import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { getCustomerAnalysisPDF } from "store/asyncActions/customerAnalysis";
import {
  getIsAuthUserSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { routesConstant } from "constants/appRoutesConstants";
import { lsApi } from "utils/localStorageApi";
import { Role } from "store/models/profile";

const DownloadingAnalysisPDF = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { customerAnalysisId } = useParams();

  const isAuth = useAppSelector(getIsAuthUserSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);

  const isAgent = roleUser === Role.AGENT;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isAuth) {
      lsApi.set("isRedirectToPDFDownloading", customerAnalysisId);
      return navigate(routesConstant.auth);
    }

    if (customerAnalysisId) {
      dispatch(getCustomerAnalysisPDF(customerAnalysisId))
        .unwrap()
        .then(() => {
          timer = setTimeout(() => {
            navigate(
              isAgent
                ? routesConstant.agentMyMeeting
                : routesConstant.clientMyMeetingsLocation,
              {
                replace: true,
              }
            );
          }, 2000);
        })
        .catch(() => {
          timer = setTimeout(() => {
            navigate(routesConstant.booking);
          }, 2000);
        })
        .finally(() => {
          lsApi.removeItem("isRedirectToPDFDownloading");
        });
    }

    return () => clearTimeout(timer);
  }, [isAuth, isAgent, customerAnalysisId, dispatch, navigate]);

  return null;
};

export default DownloadingAnalysisPDF;
