import React, { memo } from "react";
import { Link, useMatch } from "react-router-dom";

import Text from "components/common/Text";
import { SelectItemPropsI } from "../Select";

const SelectItem: React.FC<SelectItemPropsI> = ({
  title,
  value,
  className = "",
  onClick,
  type = "button",
  to = "",
  icon: Icon,
  selected,
  endIcon: EndIcon,
  disabled
}) => {
  const match = useMatch(to);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (typeof onClick === "function") {
      onClick({ title, value }, event);
    }
  };

  const _icon = Icon ? (
    <span className="select_item_icon">
      <Icon />
    </span>
  ) : null;

  const _endIcon = EndIcon ? (
    <span className="select_item_end_icon">
      <EndIcon />
    </span>
  ) : null;

  const matchClassName = match ? "match" : "";
  const classNameSelected = selected ? "selected" : "";

  return type === "link" ? (
    <Link
      to={to}
      className={`paragraph-medium select_item select_item_link ${className} ${matchClassName} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {_icon}
      <Text>{title}</Text>
      {_endIcon}
    </Link>
  ) : (
    <button
      type="button"
      className={`select_item paragraph-medium ${className} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {_icon}
      <Text className={classNameSelected}>{title}</Text>
      {_endIcon}
    </button>
  );
};

export default memo(SelectItem);
