import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  AgentAvatarI,
  WorkingScheduleDataI,
  AgentsFilterParamsI,
  AgentsListResponceI,
  FreeTimeSlotsQueryParams,
  AgentsTimeSlots,
  ReserveTimeSlotRequestParams,
  CancelTimeSlotRequestParams,
  BookingMeetingBodyData,
  AgencyListParamsI,
  AgencyListResponceI,
  AgentDelayHourI,
  SendEmailAboutDirectBookMeetingRequest,
} from "store/models/agent";
import { AgentCalendarResponceI } from "store/models/calendars";
import { UsersI, AgentProfileUpdateI } from "store/models/profile";
import { PostalCodeListResponceI } from "store/models/locations";

export class AgentAPI extends CRUD {
  getAgents(params: AgentsFilterParamsI): AxiosPromise<AgentsListResponceI> {
    return this.request({
      url: `${this.url}/available_timeslots/get_agents/`,
      params,
      method: "POST",
    });
  }

  /// if you want to delete an avatar does not need to pass param - data
  changeAgentAvatar(data?: FormData): AxiosPromise<AgentAvatarI> {
    return this.request({
      url: `${this.url}/image/`,
      method: "POST",
      data,
    });
  }

  updateAgentProfile({
    id,
    ...rest
  }: AgentProfileUpdateI): AxiosPromise<AgentProfileUpdateI> {
    return this.request({
      url: `${this.url}/${id}/`,
      data: rest,
      method: "PATCH",
    });
  }

  getAgentById(id: string): AxiosPromise<UsersI> {
    return this.request({
      url: `${this.url}/${id}/`,
    });
  }

  getAgentWorkingSchedule(): AxiosPromise<WorkingScheduleDataI> {
    return this.request({
      url: `${this.url}/working_schedule/`,
    });
  }

  updateWorkingSchedule(
    data: WorkingScheduleDataI
  ): AxiosPromise<WorkingScheduleDataI> {
    return this.request({
      url: `${this.url}/working_schedule/add_working_schedule/`,
      method: "PATCH",
      data,
    });
  }

  checkConnectCalendar(): AxiosPromise<AgentCalendarResponceI> {
    return this.request({
      url: `${this.url}/connected_calendars/`,
    });
  }

  getAgentFreeTimeSlots(
    id: string,
    params?: FreeTimeSlotsQueryParams
  ): AxiosPromise<AgentsTimeSlots> {
    return this.request({
      url: `${this.url}/${id}/available_slots/`,
      params,
    });
  }

  reserveTimeSlot(data: ReserveTimeSlotRequestParams): AxiosPromise {
    return this.request({
      url: `${this.url}/available_timeslots/block_timeslot_temporary/`,
      data,
      method: "POST",
    });
  }

  cancelReserveTimeSlot(data: CancelTimeSlotRequestParams): AxiosPromise {
    return this.request({
      url: `${this.url}/available_timeslots/unblock_timeslot_temporary/`,
      data,
      method: "POST",
    });
  }

  bookingMeeting(data: BookingMeetingBodyData): AxiosPromise {
    return this.request({
      url: `${this.url}/available_timeslots/booking_meeting/`,
      data,
      method: "POST",
    });
  }

  getAgencyList(params?: AgencyListParamsI): AxiosPromise<AgencyListResponceI> {
    return this.request({
      url: `${this.url}/agency/`,
      params,
    });
  }

  getDelayHours(): AxiosPromise<AgentDelayHourI> {
    return this.request({
      url: `${this.url}/delay_hours/get/`,
    });
  }

  setDelayHours(data: AgentDelayHourI): AxiosPromise<AgentDelayHourI> {
    return this.request({
      url: `${this.url}/delay_hours/set/`,
      data,
      method: "PATCH",
    });
  }

  getAgentPostalCode(): AxiosPromise<PostalCodeListResponceI> {
    return this.request({
      url: `${this.url}/get_postal_codes/?limit=1000`,
      method: "GET",
    });
  }

  sendEmailAboutDirectBookMeeting(
    data: SendEmailAboutDirectBookMeetingRequest
  ): AxiosPromise {
    return this.request({
      url: `${this.url}/send_email_about_book_meeting/`,
      data,
      method: "POST",
    });
  }
}

export default function agentAPI(request: AxiosInstance) {
  return new AgentAPI({
    url: "/agent",
    request,
  });
}
