import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { routesConstant, RoutesT } from "constants/appRoutesConstants";
import GoBackButton from "components/GoBackButton";
import PageLoader from "components/PageLoader";
import AgentDetail from "./components/AgentDetail";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getAgentDetail } from "store/asyncActions/agents";
import {
  getAgentsIsFetchingSelector,
  clearAgentProfile,
} from "store/reducers/agents";
import { clearFreeTimeSlots } from "store/reducers/booking";
import { useTranslation } from "react-i18next";
import { useMobileContext } from "context/mobileContext";
import { useCalendarContext } from "components/BookingMeetingCalendar/useCalendarContext";

interface LocationStateI {
  backRoute: RoutesT;
  changeTime: boolean;
}

const AgentPresentationProfilePage = () => {
  const { agentId = "" } = useParams();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();
  const location = useLocation() as { state: LocationStateI };

  const dispatch = useAppDispatch();
  const { handleCloseOpenedHourSlots } = useCalendarContext();
  const isFetching = useAppSelector(getAgentsIsFetchingSelector);

  useEffect(() => {
    dispatch(getAgentDetail(agentId));

    return () => {
      dispatch(clearAgentProfile());
      dispatch(clearFreeTimeSlots());
      handleCloseOpenedHourSlots && handleCloseOpenedHourSlots();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, dispatch]);

  return (
    <div
      className={`container agent_presentation_profile_page ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      <div className="page_container agent_detail_container">
        <GoBackButton
          title={t("translation.agentPresentationProfilePage.backButton")}
          className="back_button"
          to={location?.state?.backRoute || undefined}
          stateToBack={{
            backRoute: routesConstant.agentPresentationProfile,
          }}
        />
        <AgentDetail isFetching={isFetching} />
      </div>
      {isFetching && <PageLoader />}
    </div>
  );
};

export default AgentPresentationProfilePage;
