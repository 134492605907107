import React, { useCallback } from "react";

import Table from "components/common/Table";
import { SortDirectionT, sortEnum } from "constants/sortConstants";
import Text from "components/common/Text";
import { PassedMeetingData } from "store/models/meetings";
import { useAppDispatch } from "hooks/redux";
import { getPassedMeetings } from "store/asyncActions/meetings";
import { useTranslation } from "react-i18next";

type TableSectionType = {
  data: PassedMeetingData[];
};

const TableSection = ({ data }: TableSectionType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSort = useCallback(
    (field: string | number | undefined, direction: SortDirectionT) => {
      const sortDirection = direction === sortEnum.DESC ? "-" : "";
      const name = field === "firstName" ? "customer" : "date";

      dispatch(getPassedMeetings({ ordering: `${sortDirection}${name}` }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table
      data={data}
      classNameContainer="table_section"
      isRemoteSort={true}
      handleRemoteSort={handleSort}
    >
      <Table.Column
        dataField="date"
        caption={t("translation.clientMeetingPage.date")}
        isSort
        type="date"
        format="dd/MM/yyyy"
      />
      <Table.Column
        dataField="firstName"
        caption={t("translation.clientMeetingPage.customer")}
        renderCell={CustomerColumn}
        width={"auto"}
        isSort
      />
      <Table.Column
        dataField="phone"
        caption={t("translation.agentMeetings.phone")}
      />
      <Table.Column
        dataField="street"
        caption={t("translation.agentMeetings.street")}
      />
      <Table.Column
        dataField="areaNumber"
        caption={t("translation.agentMeetings.city")}
      />
    </Table>
  );
};

const CustomerColumn: React.FC<{ data: PassedMeetingData }> = ({ data }) => {
  const { firstName, lastName, email } = data;

  return (
    <div className="customer_table_cell">
      <Text
        text={`${firstName} ${lastName}`}
        className="name"
        variant="paragraph-body-bold"
      />
      <Text text={email} className="email" variant="paragraph-body" />
    </div>
  );
};

export default TableSection;
