import { RoutesT } from "constants/appRoutesConstants";

export enum EnglishWeekDays {
  MON = 1,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
  SUN,
}

export enum SwedishWeekDays {
  MÅN = 1,
  TIS,
  ONS,
  TOR,
  FRE,
  LÖR,
  SÖN,
}

export interface NavigateStateI {
  backRoute?: RoutesT;
}
