import React from "react";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import { useMobileContext } from "context/mobileContext";
import { setIsOpenConfirmBookingWindow } from "store/reducers/booking";
import { useAppDispatch } from "hooks/redux";

const EmptyAgentItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const handleClickToggle = () => dispatch(setIsOpenConfirmBookingWindow(false));

  const emptyText = (
    <Text variant="label-bold" className="agent_card_name">
      <span onClick={handleClickToggle}>
        {t("translation.calendarPage.emptyAgentName")}
      </span>
    </Text>
  )

  return (
    <div className="agent_card empty_agent_card">
      <div className="agent_card_foto">
        {!isMobile && emptyText}
      </div>
       <div className="wrapper">
        {isMobile && emptyText}
      </div>
    </div>
  );
};

export default EmptyAgentItem;
