import React, { memo } from "react";

interface PropsInterface {
  children: React.ReactNode;
  className?: string;
}

const WhiteWrapBlock: React.FC<PropsInterface> = ({
  children,
  className = "",
}) => {
  return <div className={`white_wrap_block ${className}`}>{children}</div>;
};

export default memo(WhiteWrapBlock);
