import { MobileViewType } from "../BookingMeetingCalendar";

import CalendarBody from "./CalendarBody";
import CalendarHeader from "./CalendarHeader";

interface ICalendar {
  mobileView?: MobileViewType;
}

const Calendar = ({ mobileView }: ICalendar) => {
  return (
    <div className="calendar_container">
      <CalendarHeader mobileView={mobileView} />

      <div className="calendar_body_scroll_container">
        <div className="calendar_body_scroll_wrap">
          <CalendarBody mobileView={mobileView} />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
