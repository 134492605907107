import React from "react";
import { redColor } from "constants/colors";

import { IconTypesInterface } from "./iconTypesInterface";

const RequaredStar: React.FC<IconTypesInterface> = ({
  className = "",
  color = redColor,
}) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.82845 0L4.51046 3.38395L7.76569 2.40347L8 4.09544L4.91213 4.39913L6.91213 7.15835L5.42259 8L3.95816 5.00651L2.62761 7.99132L1.0795 7.15835L3.06276 4.39913L0 4.08677L0.251046 2.40347L3.45607 3.38395L3.12971 0H4.82845Z"
        fill={color}
      />
    </svg>
  );
};

export default RequaredStar;
