import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getIsAuthUserSelector,
  getIsInitProfileSelector,
  getIsLoadingProfileSelector,
  getRoleProfileSelector,
} from "store/reducers/profile";
import { profileAboutSelf } from "store/asyncActions/profile";
import { Role } from "store/models/profile";
import RootRoutes from "routes";
import { useIdle } from "hooks/useIdle";
import { routesConstant } from "constants/appRoutesConstants";
import { lsApi } from "utils/localStorageApi";

import ConfirmBookingWindow from "components/ConfirmBookingWindow";
import BankIdQRCodeModal from "components/BankIdQRCodeModal";
import PageLoader from "components/PageLoader";
import AlertBox from "components/AlertBox";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";

const idleMin = process.env.REACT_APP_CUSTOM_NODE_ENV === "local" ? 1300 : 15;

function App() {
  useIdle(60 * idleMin);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isAuth = useAppSelector(getIsAuthUserSelector);
  const isInit = useAppSelector(getIsInitProfileSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);

  const isAgent = roleUser === Role.AGENT;

  useEffect(() => {
    if (isAgent) return lsApi.removeItem("previousLocation");
    if (pathname !== routesConstant.auth) lsApi.removeItem("previousLocation");
  }, [pathname, isAgent]);

  useEffect(() => {
    dispatch(profileAboutSelf());
  }, [dispatch]);

  useEffect(() => {
    const handleFocus = () => {
      dispatch(profileAboutSelf());
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    // don't send the request for get agents list here
    // use it in BookingPage because pre-booking and agents list is one route
    if (isAuth && !isAgent && pathname !== routesConstant.booking) {
      dispatch(getAgentsListBlueBox());
    }
  }, [dispatch, pathname, isAuth, isAgent]);

  if (isInit) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>
          {pathname === routesConstant.booking
            ? "Boka mäklare | HittaHem – Digitala mäklarmöten"
            : process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <RootRoutes />
      <BankIdQRCodeModal />
      <LoaderProfile />
      <AlertBox />
      {!isAgent && <ConfirmBookingWindow />}
    </>
  );
}

const LoaderProfile = () => {
  const isLoadingProfile = useAppSelector(getIsLoadingProfileSelector);

  return isLoadingProfile ? <PageLoader /> : null;
};

export default App;
