import { Route } from "react-router-dom";

import { routesConstant } from "constants/appRoutesConstants";
import AgentPresentationProfilePage from "pages/AgentPresentationProfilePage";

export const agentPresentationProfileRoute = (
  <Route
    path={`${routesConstant.agentPresentationProfileAbsolutely}/:agentId`}
    element={<AgentPresentationProfilePage />}
  />
);
