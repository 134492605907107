import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import Tooltip from "@mui/material/Tooltip";

import Button from "components/common/Button";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getIsOpenConfirmBookingWindowSelector,
  setIsOpenConfirmBookingWindow,
  setIsThankYouPageVisible,
  getChosenAgentsSelector,
} from "store/reducers/booking";
import { BookingValueI, ChosenAgentI } from "store/models/booking";
import { getIsAuthUserSelector } from "store/reducers/profile";
import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { confirmBookingMeeting } from "store/asyncActions/booking";
import { routesConstant } from "constants/appRoutesConstants";

import AgentItem from "./components/AgentItem";
import ProposeSelectMoreAgentModal from "./components/ProposeSelectMoreAgentModal";

import Text from "components/common/Text";
import EmptyAgentItem from "./components/EmptyAgentItem";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import {
  getAgentsIsFetchingSelector,
  getIsConfirmingBookMeetSelector,
} from "store/reducers/agents";
import BlockLoader from "components/BlockLoader";
import { getToken } from "utils/auth";
import { logout } from "store/asyncActions/profile";
import {
  trackingBookAgents,
  trackingConfirmAgentReserverations,
} from "utils/facebookTracking";
import { lsApi } from "utils/localStorageApi";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";

const MAX_SELECT_AGENTS = 5;

const ConfirmBookingWindow = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isConfirmWindowOpen = useSelector(
    getIsOpenConfirmBookingWindowSelector
  );
  const isConfirmingBookMeet = useAppSelector(getIsConfirmingBookMeetSelector);

  const chosenAgents = useAppShallowSelector(getChosenAgentsSelector);

  const isAuth = useAppSelector(getIsAuthUserSelector);
  const token = getToken();
  const { isMobile } = useMobileContext();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleClickToggle = () =>
    dispatch(setIsOpenConfirmBookingWindow(!isConfirmWindowOpen));

  const classNameOpen = isConfirmWindowOpen ? "open" : "";
  const {
    preBookingFormik: { values },
  } = useBookingFrom();

  // Temporary solution. Change it after added house data to profile
  const storageValues = lsApi.get<BookingValueI>("bookingForm");

  const handleClickProceed = debounce(() => {
    if (isConfirmingBookMeet) return;

    setIsOpenModal(false);
    dispatch(getAgentsListBlueBox()).then((data) => {
      const agents = data.payload as ChosenAgentI[];

      if (!agents.length) {
        // close blue box if no agents into
        dispatch(setIsOpenConfirmBookingWindow(false));
        return;
      }

      const agentBookingList = agents.map((agent: ChosenAgentI) => ({
        agent_id: agent.id,
        start_time: agent.selectedTimeSlot.startDate,
        seller_choice: {
          area_sqm: storageValues?.numberOfSqm || values.numberOfSqm || null,
          estate_goal: storageValues?.target || values.target,
          number_of_rooms:
            Number(storageValues?.numberRooms) ||
            Number(values.numberRooms) ||
            null,
          type_housing: storageValues?.residenceType || values.residenceType,
          address_id: Number(agent.address_id),
        },
      }));

      if (!token) {
        return dispatch(logout());
      }

      dispatch(confirmBookingMeeting({ book_agent: agentBookingList }))
        .unwrap()
        .then(() => {
          const agentListForAnalytics = chosenAgents.map(
            (agent: ChosenAgentI) => ({
              id: agent.id,
              name: `${agent.firstName} ${agent.lastName}`,
            })
          );
          trackingBookAgents(agentListForAnalytics);
          dispatch(setIsThankYouPageVisible(true));
          navigate(routesConstant.thankYouPageAbsolutely);
        });
    });
  }, 500);

  const handleClickContinue = () => {
    setIsOpenModal(false);
  };

  const handleClickConfirm = () => {
    if (chosenAgents.length < 3) {
      setIsOpenModal(true);
    } else {
      handleClickProceed();
    }
  };

  const ConfirmWindowController = () => (
    <div onClick={handleClickToggle} className="confirm_window_controller">
      <button className="open_button" />
      <Text
        text={
          isConfirmWindowOpen
            ? t("translation.calendarPage.minimizePopup")
            : t("translation.calendarPage.maximizePopup")
        }
        variant={isMobile ? "title-small20" : "title-small"}
        className={`${
          isMobile ? "label-extra-small" : "paragraph-body"
        } information_text`}
      />
      <div className="open_icons_container">
        {isConfirmWindowOpen ? (
          <KeyboardArrowDownOutlinedIcon className="icon" />
        ) : (
          <KeyboardArrowUpOutlinedIcon className="icon" />
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (isConfirmWindowOpen) {
      const agentListForAnalytics = chosenAgents.map((agent) => ({
        id: agent.id,
        name: `${agent.firstName} ${agent.lastName}`,
      }));
      trackingConfirmAgentReserverations(agentListForAnalytics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmWindowOpen]);

  if (!chosenAgents.length || !isAuth) return null;

  return (
    <div
      className={`confirm_booking_window ${classNameOpen} ${
        isMobile ? "mobile" : ""
      }`}
      style={
        isConfirmWindowOpen && isMobile
          ? { overflow: "scroll" }
          : { overflow: "hidden" }
      }
    >
      {isConfirmWindowOpen ? (
        <ConfirmWindowController />
      ) : (
        <Tooltip
          style={{ width: "100%" }}
          arrow
          placement="top"
          title={t("translation.calendarPage.confirmWindowTooltip")}
        >
          <div>
            {" "}
            <ConfirmWindowController />{" "}
          </div>
        </Tooltip>
      )}
      <div className="confirm_booking_container">
        <div className="selected_agents_card_container">
          {Array(MAX_SELECT_AGENTS)
            .fill(1)
            .map((_, index) => {
              const agent = chosenAgents[index];
              return (
                <div className="agent_card_wrap" key={index}>
                  {agent ? (
                    <AgentItem agentsData={agent} />
                  ) : (
                    <EmptyAgentItem />
                  )}
                </div>
              );
            })}
        </div>
        {isMobile ? (
          <StickyButton
            sizeVariant="Large"
            blueContainer
            onClick={handleClickConfirm}
            disabled={!isConfirmWindowOpen || isConfirmingBookMeet}
            showLoadingIndicator={isConfirmingBookMeet}
            startIcon={<CheckCircleOutlineOutlinedIcon />}
          >
            {t("translation.calendarPage.confirmBooking")}
          </StickyButton>
        ) : (
          <Button
            sizeVariant="Large"
            startIcon={<CheckCircleOutlineOutlinedIcon />}
            onClick={handleClickConfirm}
            disabled={isConfirmingBookMeet}
            showLoadingIndicator={isConfirmingBookMeet}
          >
            {t("translation.calendarPage.confirmBooking")}
          </Button>
        )}
      </div>
      <ProposeSelectMoreAgentModal
        isOpen={isOpenModal}
        onClickContinue={handleClickContinue}
        onClickProceed={handleClickProceed}
      />
      <BlockLoader selector={getAgentsIsFetchingSelector} />
    </div>
  );
};

export default ConfirmBookingWindow;
