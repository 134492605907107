import { useEffect, useRef, useCallback, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import { routesConstant } from "constants/appRoutesConstants";
import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Link from "components/common/Link";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getIsOpenBankIdQRCodeModalSelector,
  setIsOpenBankIdQrCodeModal,
  getBankIdAuthInitiateSelector,
  getCheckCollectSelector,
  getIsRedirectCreateSellerSelector,
  clearBankIdState,
} from "store/reducers/bankId";
import { checkCollectBankId, updateQrCode } from "store/asyncActions/bankId";
import { useMobileContext } from "context/mobileContext";
import logo from "assets/img/colored-logo.png";

const BankIdQRCodeModal = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const interval = useRef<NodeJS.Timeout>();

  const updateQrInterval = useRef<NodeJS.Timeout>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation() as { state: { agentId: string } };
  const { isMobile } = useMobileContext();

  const isRedirectCreactApp = useAppSelector(getIsRedirectCreateSellerSelector);

  const { order_ref, qr_content, auto_start_token } = useAppShallowSelector(
    getBankIdAuthInitiateSelector
  );
  const { status, hint_code } = useAppShallowSelector(getCheckCollectSelector);

  const handleCloseModal = useCallback(() => {
    dispatch(setIsOpenBankIdQrCodeModal(false));
    dispatch(clearBankIdState());
  }, [dispatch]);

  useEffect(() => {
    if (status === "pending") {
      interval.current = setInterval(() => {
        dispatch(checkCollectBankId(order_ref));
      }, 2000);
    }
    if ((status === "complete" || status === "failed") && interval.current) {
      clearInterval(interval.current);
    }

    return () => interval.current && clearInterval(interval.current);
  }, [dispatch, status, order_ref]);

  useEffect(() => {
    if (
      status === "pending" &&
      hint_code === "outstandingTransaction" &&
      order_ref
    ) {
      if (updateQrInterval.current) clearInterval(updateQrInterval.current);

      updateQrInterval.current = setInterval(() => {
        dispatch(updateQrCode(order_ref));
      }, 4000);
    }
    if (
      (status === "complete" ||
        status === "failed" ||
        hint_code === "userSign") &&
      updateQrInterval.current
    ) {
      clearInterval(updateQrInterval.current);
    }

    return () =>
      updateQrInterval.current && clearInterval(updateQrInterval.current);
  }, [dispatch, status, hint_code, order_ref]);

  useEffect(() => {
    let closeTimeout: NodeJS.Timeout;
    const wordPressPreviousUrl = localStorage.getItem(
      "previousLocation"
    ) as string;
    if (status === "complete") {
      if (isRedirectCreactApp) {
        navigate(routesConstant.authCreate);
      } else {
        if (wordPressPreviousUrl) {
          localStorage.removeItem("previousLocation");
          window.location.replace(wordPressPreviousUrl);
        }
        if (location?.state?.agentId && !isRedirectCreactApp) {
          navigate(
            `${routesConstant.agentPresentationProfile}/${location.state.agentId}`,
            {
              state: {
                backRoute: routesConstant.booking,
              },
            }
          );
          location.state.agentId = "";
        }
      }
      closeTimeout = setTimeout(() => {
        handleCloseModal();
      }, 500);
    }
    return () => {
      clearTimeout(closeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleCloseModal, isRedirectCreactApp, navigate, status]);

  useEffect(() => {
    if (pathname !== routesConstant.auth) return handleCloseModal();
  }, [pathname, handleCloseModal]);

  const classNameScaned =
    hint_code !== "outstandingTransaction" ? "scanned" : "";

  if (isMobile) {
    return (
      <Modal
        open={true}
        classNameContainer="mobile_modal_qr_code_container"
        handleClose={handleCloseModal}
      >
        <>
          <Text
            text={t("translation.qrPopup.modalDescription")}
            className="label-medium modal_message"
          />
          <Button
            sizeVariant="Large"
            startIcon={<PhoneAndroidIcon />}
            LinkComponent={"a"}
            href={`bankid:///?autostarttoken=${auto_start_token}&redirect=null`}
            className="bankId_button mobile"
          >
            {t("translation.qrPopup.modalButtonName")}
          </Button>
        </>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      classNameContainer="modal_qr_code_container"
      handleClose={handleCloseModal}
    >
      <>
        <img src={logo} className="logo" alt="logo" />
        <Text
          text={t("translation.qrPopup.title")}
          className="title-medium title"
        />
        <div className="qr_code_container">
          <span className={`qr_code_wrap ${classNameScaned}`}>
            <QRCodeSVG value={qr_content} />
          </span>
          {hint_code === "userSign" && (
            <CircularProgress className="scanned_loader" />
          )}
          {status === "complete" && (
            <CheckCircleOutlineIcon color="primary" className="complete_icon" />
          )}
          {status === "failed" && (
            <CancelOutlinedIcon className="complete_icon cancel_icon" />
          )}
        </div>
        <div className="list">
          <Text
            text={t("translation.qrPopup.firstListItem")}
            className="item_list paragraph-body-bold"
          />
          <Text
            text={t("translation.qrPopup.secondListItem")}
            className="item_list paragraph-body-bold"
          />
          <Text
            text={t("translation.qrPopup.thirdListItem")}
            className="item_list paragraph-body-bold"
          />
          <Text
            text={t("translation.qrPopup.forthListItem")}
            className="item_list paragraph-body-bold"
          />
        </div>
        <div>
          <Text
            text={t("translation.qrPopup.question")}
            className="paragraph-body-bold center"
          />
        </div>
        <div className="link">
          <Link
            className="span"
            native
            target="_blank"
            rel="noopener noreferrer"
            href={`bankid:///?autostarttoken=${auto_start_token}&redirect=null`}
          >
            {t("translation.qrPopup.mobileLogin")}
          </Link>
        </div>
      </>
    </Modal>
  );
};

const BankIdQRCodeModalMemo = memo(BankIdQRCodeModal);

const BackIdQrModalContainer = () => {
  const isOpen = useAppSelector(getIsOpenBankIdQRCodeModalSelector);
  return isOpen ? <BankIdQRCodeModalMemo /> : null;
};

export default memo(BackIdQrModalContainer);
