import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { format, add, fromUnixTime, getUnixTime, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { sv, enUS } from "date-fns/locale";
import { defaultNS } from "i18n";

import { useCalendarContext } from "../useCalendarContext";
import { useFilterCalendarContext } from "../useFilterCalendarContext";
import Button from "components/common/Button";
import Text from "components/common/Text";
import { useAppSelector } from "hooks/redux";
import { getIsFetchingTimeSlotsSelector } from "store/reducers/booking";
import Spinner from "components/common/Spinner";
import { useMobileContext } from "context/mobileContext";
import { MobileViewType } from "../BookingMeetingCalendar";

interface IFilterMonthSection {
  mobileView?: MobileViewType;
  setMobileView?(val: MobileViewType): void;
}

const FilterMonthSection = ({
  mobileView,
  setMobileView,
}: IFilterMonthSection) => {
  const { isAgentCalendar, selectedHourSlot } = useCalendarContext();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const { goCurrentDay, goNextDay, goPrevDay, firstDayOfCalendarTimestamp } =
    useFilterCalendarContext();

  const handleDayType = (type: MobileViewType) => {
    goCurrentDay();
    if (isMobile && setMobileView) {
      setMobileView(type);
    }
  };

  const isOpenHourSlot = !!selectedHourSlot.day && !!selectedHourSlot.hour;

  const firstDayOfCalendar = fromUnixTime(firstDayOfCalendarTimestamp);
  const currendDateUnix = getUnixTime(startOfDay(new Date()));

  /// 14 days max available for time slots loading
  const maxAvailableLoadDay = getUnixTime(
    add(fromUnixTime(currendDateUnix), { days: 13 })
  );

  const lastDayOfCalendar =
    isMobile && mobileView === MobileViewType.today
      ? firstDayOfCalendarTimestamp
      : getUnixTime(
          add(fromUnixTime(firstDayOfCalendarTimestamp), {
            days: isMobile && mobileView === MobileViewType.threeDays ? 2 : 6,
          })
        );

  const lang = defaultNS === "en" ? enUS : sv;

  const month = format(firstDayOfCalendar, "MMMM", { locale: lang });
  const lastDayMonth = format(add(firstDayOfCalendar, { days: 6 }), "MMMM", {
    locale: lang,
  });

  const monthTextTitle =
    month === lastDayMonth
      ? `${month} ${format(firstDayOfCalendar, "yyyy")}`
      : `${month} ${format(
          firstDayOfCalendar,
          "yy"
        )} - ${lastDayMonth} ${format(firstDayOfCalendar, "yy")}`;

  const disabledPrev = currendDateUnix === firstDayOfCalendarTimestamp;
  const disabledNext = lastDayOfCalendar === maxAvailableLoadDay;

  const activeButtonStyles = {
    background: "#096A5E",
    color: "#ffffff",
  };

  return (
    <div className="filter_month_section">
      {isAgentCalendar && (
        <>
          <div>
            <Button
              variant="outlined"
              sizeVariant="Medium"
              className="filter_month_today_button"
              onClick={() => handleDayType(MobileViewType.today)}
              disabled={isOpenHourSlot}
              style={
                isMobile && mobileView === MobileViewType.today
                  ? activeButtonStyles
                  : {}
              }
            >
              {t("translation.calendarPage.today")}
            </Button>
            {isMobile && (
              <Button
                variant="outlined"
                sizeVariant="Medium"
                className="filter_month_today_button"
                onClick={() => handleDayType(MobileViewType.threeDays)}
                disabled={isOpenHourSlot}
                style={
                  mobileView === MobileViewType.threeDays
                    ? activeButtonStyles
                    : {}
                }
              >
                {t("translation.calendarPage.threeDays")}
              </Button>
            )}
          </div>
          {isMobile && (
            <div className="change_week_buttons">
              <IconButton
                className="change_week_button"
                onClick={goPrevDay}
                disabled={disabledPrev || isOpenHourSlot}
              >
                <ArrowBackIosNewIcon />
              </IconButton>

              <Text
                text={monthTextTitle}
                variant="title-small"
                className="month_text"
              />

              <IconButton
                className="change_week_button"
                onClick={goNextDay}
                disabled={disabledNext || isOpenHourSlot}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          )}
          {!isMobile && (
            <Text
              text={monthTextTitle}
              variant="title-small"
              className="month_text"
            />
          )}
        </>
      )}
      {!isAgentCalendar && (
        <Text
          text={monthTextTitle}
          variant="title-small"
          className="month_text"
        />
      )}
    </div>
  );
};

export default FilterMonthSection;
