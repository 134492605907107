import { createContext, useEffect, useState, useContext } from "react";
import { isMobile as isMobileDevice} from "react-device-detect";

interface MobileContextInterface {
  isMobile: boolean;
}

interface ContextProviderInterface {
  children: JSX.Element | JSX.Element[];
}

export const MobileContext = createContext<MobileContextInterface>({isMobile: false});

export const MobileContextProvider = ({ children }: ContextProviderInterface) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => setIsMobile(isMobileDevice), [])

  const value = {
    isMobile: isMobile
  }

  return (
    <MobileContext.Provider value={value}>
      {children}
    </MobileContext.Provider>
  );
}

export const useMobileContext = () => useContext(MobileContext);
