import React from "react";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { sv, enUS } from 'date-fns/locale';

import Text from "components/common/Text";
import { timeZoneSV } from "constants/timeZoneConstants";
import { BookingMeetingResponceI } from "store/models/agent";
import { defaultNS } from "i18n";

interface MeetingCardPropsI {
  meeting: BookingMeetingResponceI;
}

const MeetingCard: React.FC<MeetingCardPropsI> = ({ meeting }) => {
  const {agent_first_name: agentFirstName = "", agent_last_name: agentLastName = ""} = meeting;

  const meetingTime = `${format(utcToZonedTime(parseISO(meeting.start_time), timeZoneSV),
    "kk:mm"
  )}-${format(utcToZonedTime(parseISO(meeting.end_time), timeZoneSV), "kk:mm")}`;

  const lang = defaultNS === 'en' ? enUS : sv;
  const date = format(parseISO(meeting.date), "EEE, d LLL", {locale: lang});

  return (
    <div className="meeting_card">
      <div className="agent-information information_container">
        <Text variant="label-small" className="agent">
          {`${agentFirstName} ${agentLastName}`}
        </Text>
        <Text variant="paragraph-body-small" className="agency">
          {meeting.agency}
        </Text>
      </div>
      <div className="line" />
      <div className="meeting-information information_container">
        <span className="meeting_item date">
          <InsertInvitationIcon className="icon" />
          <Text
            text={date}
            className="navigation-link"
          />
        </span>
        <span className="meeting_item">
          <ScheduleIcon className="icon" />
          <Text
            text={meetingTime}
            className="navigation-link"
          />
        </span>
      </div>
    </div>
  );
};

export default MeetingCard;
