/// key of i18n translation files (en or sv)
export type RealEstateGoalT =
  | "SellResidence"
  | "BuyResidence"
  | "Advisory"
  | "Valuation";
export type ResidenceTypeT =
  | "Apartment"
  | "House"
  | "HolidayHome"
  | "ChainHouse"
  | "Other";

export type NumberOfRoomsT = "1" | "2" | "3" | "4" | "5+";

export interface SelectItemI<T, K = string | number> {
  title: T;
  value: K;
}

export type EstateGoal = "SEL" | "BUY" | "ADV" | "VAL";

export const realEstateGoalSelects: SelectItemI<RealEstateGoalT, EstateGoal>[] =
  [
    {
      title: "SellResidence",
      value: "SEL",
    },
    // {
    //   title: "BuyResidence",
    //   value: "BUY",
    // },
    {
      title: "Advisory",
      value: "ADV",
    },
    {
      title: "Valuation",
      value: "VAL",
    },
  ];

export const residenceTypeSelects: SelectItemI<ResidenceTypeT>[] = [
  {
    title: "Apartment",
    value: "Apartment",
  },
  {
    title: "House",
    value: "House",
  },
  {
    title: "HolidayHome",
    value: "Holiday home",
  },
  {
    title: "ChainHouse",
    value: "Chain house",
  },
  {
    title: "Other",
    value: "Other",
  },
];

export const numberOfRoomsSelect: SelectItemI<NumberOfRoomsT>[] = [
  {
    title: "1",
    value: 1,
  },
  {
    title: "2",
    value: 2,
  },
  {
    title: "3",
    value: 3,
  },
  {
    title: "4",
    value: 4,
  },
  {
    title: "5+",
    value: 5,
  },
];
