import React, { memo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import BottomSectionList from "./BottomSectionList";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getUpcomingMeetings } from "store/asyncActions/meetings";
import {
  getIsNextUpcommingMeetingDataSelector,
  clearUpcomingMeetings,
  getIsFetchingUpcommingMeetingSelector,
} from "store/reducers/meetings";
import { LOAD_ALL_UPCOMING_MEETINGS_LIMIT } from "constants/limitLoadConstants";

const BottomSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isNext = useAppSelector(getIsNextUpcommingMeetingDataSelector);
  const isFetching = useAppSelector(getIsFetchingUpcommingMeetingSelector);
  const offset = useRef(LOAD_ALL_UPCOMING_MEETINGS_LIMIT);

  const handleClickShowMore = () => {
    dispatch(getUpcomingMeetings({ offset: offset.current }));
    offset.current += LOAD_ALL_UPCOMING_MEETINGS_LIMIT;
  };

  useEffect(() => {
    dispatch(getUpcomingMeetings({ offset: 0 }));
    return () => {
      dispatch(clearUpcomingMeetings());
    };
  }, [dispatch]);

  return (
    <div className="meeteng_page_bottom_section">
      <Text
        text={t("translation.agentMeetings.upcomingMeetings")}
        className="bottom_section_title"
        component="h2"
        variant="small-header"
      />
      <BottomSectionList />
      {isNext && (
        <Button
          variant="outlined"
          sizeVariant="Large"
          className="show_more_button"
          onClick={handleClickShowMore}
          showLoadingIndicator={isFetching}
          disabled={isFetching}
        >
          {t("translation.agentMeetings.showMore")}
        </Button>
      )}
    </div>
  );
};

export default memo(BottomSection);
