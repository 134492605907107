import React, { useRef, useEffect, useCallback } from "react";

type Callback = () => void;

export const useInfinityScroll = <T extends HTMLElement>(
  callback?: Callback
): React.RefObject<T> => {
  const scrollElemRef = useRef<T>(null);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && callback) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (scrollElemRef.current) {
      observer.observe(scrollElemRef.current);
    }
    return () => {
      if (scrollElemRef.current) observer.unobserve(scrollElemRef.current);
    };
  }, [handleObserver]);

  return scrollElemRef;
};
