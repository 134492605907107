export type ProviderTypeT = "GOO" | "365" | "EXC";

export enum ProviderType {
  GOOGLE = "GOO",
  MICROSOFT_365 = "365",
  MICROSOFT_EXCHANGE = "EXC",
  TEAM = "ms_teams",
}

export interface ConsentUrlParams {
  calendar_provider: ProviderTypeT;
}

export interface ConsentScreenUrlRequestI {
  url: string;
}

export interface CalendarAuthDataI {
  agent_id: string;
  code: string;
  calendar_provider: ProviderTypeT;
  isMobile?: boolean;
}

export enum CalendarProvider {
  Google = 1,
  Microsoft,
  TEAM,
  ZOOM,
}

export interface ConferencingProfile {
  id: number;
  name: string;
  connected_profile_id: string;
  calendar_info: string;
}

export interface AgentCalendarProvideI {
  calendar_provider: ProviderType;
  conferencing_profiles: ConferencingProfile[];
}

export interface AgentCalendarResponceI {
  calendar: AgentCalendarProvideI | null;
  is_change_email_agent: boolean;
}

export interface TeamConnectResponseI {
  url_connected: string;
}

export interface ConnectTeamQueryParams {
  last_linked_profile_id: string;
  agent_id: string;
  provider_name: string;
}

export interface ConnectTeamUrlQueryParams {
  provider_name: string;
}

export interface AgentCalendarI {
  name: string;
  calendar_id: string;
  is_used?: boolean;
  id?: number;
}

export interface AgentCalendarRequestI {
  calendar: AgentCalendarI[];
}

export interface AgentCalendarIdI {
  other_calendar_ids: number[];
}
