import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";

type VariantT =
  | "main-header"
  | "medium-header"
  | "small-header"
  | "title-large"
  | "title-medium"
  | "title-small"
  | "title-small20"
  | "label-bold"
  | "label-medium"
  | "label-small"
  | "paragraph-large"
  | "paragraph-medium"
  | "paragraph-body"
  | "paragraph-body-bold"
  | "paragraph-body-small"
  | "note"
  | "error-text";

export interface TextPropsInterface {
  component?: React.ElementType;
  text?: string | number;
  className?: string;
  tooltipProps?: TooltipProps;
  tooltipTitle?: string;
  variant?: VariantT;
}

const Text: React.FC<TextPropsInterface> = ({
  component = "span",
  text = "",
  children,
  className = "",
  tooltipProps,
  tooltipTitle = "",
  variant = "",
}) => {
  const Component = component;
  return (
    <Tooltip title={tooltipTitle} placement="top" {...tooltipProps}>
      <Component className={`styled_text ${variant} ${className}`}>
        {children || text}
      </Component>
    </Tooltip>
  );
};

export default Text;
