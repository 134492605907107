import React from "react";

import { IconTypesInterface } from "./iconTypesInterface";

const Logo: React.FC<IconTypesInterface> = ({ className = "", isMobile }) => {
  return (
    <svg
      width={isMobile ? "132" : "162"}
      height="36"
      viewBox="0 0 162 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M14.8229 -0.00292969C6.63763 -0.00292969 0 6.65853 0 14.8732C0 23.0878 12.6959 35.9971 14.8229 35.9971C16.9499 35.9971 29.6419 23.0878 29.6419 14.8732C29.6419 6.65853 23.0081 -0.00292969 14.8229 -0.00292969ZM20.2162 18.6702C18.6375 20.2585 17.0665 21.8 15.4839 23.3883C15.0251 23.8488 14.609 23.8566 14.1229 23.3688C12.5403 21.7805 11.0238 20.2624 9.44122 18.6702C9.27402 18.5102 8.00249 17.2185 8.22413 15.4351C8.31356 14.7405 8.64797 13.9834 9.40234 13.2263C12.132 10.4907 14.8307 14.4361 14.8307 14.4361C14.8307 14.4361 17.4865 10.5024 20.2279 13.2537C22.9692 16.0088 20.2162 18.6702 20.2162 18.6702Z" fill="#1D1D1B"/>
      <path d="M48.7267 8.01074V16.6976H39.1221V8.01074H36.4663V28.8107H39.1221V19.0429H48.7267V28.8107H51.3786V8.01074H48.7267Z" fill="#1D1D1B"/>
      <path d="M54.2441 13.3994V28.8101H56.865V13.3994H54.2441Z" fill="#1D1D1B"/>
      <path d="M57.2966 9.72587C57.2966 10.7717 56.5695 11.439 55.5585 11.439C54.5475 11.439 53.8203 10.7717 53.8203 9.72587C53.8203 8.68001 54.5475 8.0127 55.5585 8.0127C56.5695 8.0127 57.2966 8.71123 57.2966 9.72587Z" fill="#1D1D1B"/>
      <path d="M77.5674 28.5253C76.7469 28.9078 75.7048 29.1926 74.6588 29.1926C71.373 29.1926 70.2687 27.1009 70.2687 24.0219V15.5263H64.0899V24.0843C64.0899 25.9536 64.7198 27.0307 66.458 27.0307C67.0296 27.0307 67.5973 26.937 68.2273 26.6522L68.7639 28.5214C67.9434 28.9039 66.9013 29.1887 65.8553 29.1887C62.5695 29.1887 61.4652 27.097 61.4652 24.018V15.5224H59.1904V13.5556L61.5935 13.3956L61.9085 8.45117H64.086V13.3956H70.3892L70.7042 8.45117H72.8818V13.3956H77.0541V15.5224H72.8818V24.0804C72.8818 25.9497 73.5117 27.0268 75.2537 27.0268C75.8214 27.0268 76.3892 26.9331 77.0191 26.6482L77.5557 28.5175L77.5674 28.5253Z" fill="#1D1D1B"/>
      <path d="M85.5231 13.0205C83.0578 13.0205 80.9075 14.0664 79.4882 15.0186L80.4992 16.7942C81.6968 16.002 83.3105 15.2059 85.0798 15.2059C87.5762 15.2059 88.2061 17.079 88.2372 19.0108C81.6657 19.7405 78.8232 21.3912 78.8232 24.8137C78.8232 27.6039 80.7519 29.1883 83.3105 29.1883C85.2353 29.1883 86.9113 28.2049 88.3656 26.9717H88.4589L88.7116 28.8098H90.862V19.3308C90.862 15.5259 89.2482 13.0205 85.5231 13.0205ZM88.2372 25.0673C86.7518 26.3668 85.5231 27.0342 84.0377 27.0342C82.5523 27.0342 81.3819 26.3668 81.3819 24.6537C81.3819 22.6595 83.0578 21.4849 88.2372 20.8839V25.0673Z" fill="#1D1D1B"/>
      <path d="M106.132 8.01074V16.6976H96.5274V8.01074H93.8716V28.8107H96.5274V19.0429H106.132V28.8107H108.784V8.01074H106.132Z" fill="#1D1D1B"/>
      <path d="M123.91 20.2478C123.91 15.8108 121.667 13.0205 117.751 13.0205C114.244 13.0205 110.861 16.0956 110.861 21.1064C110.861 26.1171 114.084 29.1883 118.19 29.1883C120.31 29.1883 121.951 28.5561 123.276 27.6039L122.363 25.922C121.286 26.6517 119.991 27.0654 118.509 27.0654C115.601 27.0654 113.672 24.9737 113.454 21.801H123.782C123.879 21.2937 123.91 20.8176 123.91 20.2478ZM113.454 19.7717C113.769 16.8254 115.663 15.1122 117.813 15.1122C120.15 15.1122 121.573 16.7278 121.573 19.7717H113.454Z" fill="#1D1D1B"/>
      <path d="M142.326 13.0205C140.463 13.0205 138.85 14.2264 137.271 15.9356C136.641 14.1288 135.377 13.0205 133.005 13.0205C131.174 13.0205 129.595 14.16 128.238 15.6195H128.141L127.919 13.399H125.773V28.8098H128.394V17.6488C129.817 16.0644 131.08 15.3034 132.216 15.3034C134.176 15.3034 135.062 16.5093 135.062 19.3932V28.8098H137.683V17.6488C139.102 16.0644 140.335 15.3034 141.537 15.3034C143.465 15.3034 144.379 16.5093 144.379 19.3932V28.8098H147V19.042C147 15.1122 145.487 13.0205 142.326 13.0205Z" fill="#1D1D1B"/>
    </svg>
  );
};

export default Logo;
