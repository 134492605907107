import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";

import api from "api";

import { showAlerts } from "store/reducers/alerts";
import {
  AddressesDataByPostalCodeI,
  AddressesDataByStreetI,
  AddressesResponceI,
  AgentPostalCodeDataI,
  PostalCodeActiveReqParamsI,
  SelectedAddressesDataI,
  SellerAddressI,
} from "store/models/locations";
import { getAgentPostalCode } from "./agents";

export const addPostalCodeToAgent = createAsyncThunk(
  "locations/addPostalCodeToAgent",
  async (body: AgentPostalCodeDataI, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.locations.addPostalCodeToAgent(body);
      if (status === 200) {
        dispatch(getAgentPostalCode());
      }
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.failedAddedAgentAddress"
          ),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const removePostalCodeToAgent = createAsyncThunk(
  "locations/removePostalCodeToAgent",
  async (body: AgentPostalCodeDataI, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.locations.removePostalCodeToAgent(
        body
      );
      if (status === 200) {
        dispatch(getAgentPostalCode());
      }
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.failedRemovedAgentAddress"
          ),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

// request for get all addresses list by street
export const getAddressesByStreet = createAsyncThunk(
  "locations/getAddressesByStreet",
  async (param: AddressesDataByStreetI, { rejectWithValue }) => {
    try {
      const { data } = await api.locations.getAddresses(param);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// request for get all addresses list by postal code
export const getAddressesByPostalCode = createAsyncThunk(
  "locations/getAddressesByPostalCode",
  async (param: AddressesDataByPostalCodeI, { rejectWithValue }) => {
    try {
      const { data } = await api.locations.getAddresses(param);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// request to get data for selected address from suggestion list
// or for address from input written by itselfs
export const getSelectedAddress = createAsyncThunk(
  "locations/getSelectedAddress",
  async (param: SelectedAddressesDataI, { rejectWithValue, dispatch }) => {
    // check that all necessary fields exist
    if (Object.values(param).some((value) => !value)) {
      dispatch(
        showAlerts({
          message: i18next.t("translation.bookingPage.notRealAddress"),
          type: "error",
          autoHideDuration: 10000,
        })
      );

      return null;
    }

    try {
      const { data } = await api.locations.getAddresses(param);

      const response = data.results.length
        ? data.results.map((address: AddressesResponceI) => ({
            address: `${address?.street?.name}, ${address?.number}`,
            areaNumber: address?.street?.postal_code,
            address_id: address.id,
            district: address?.street?.district || "",
            county: address?.street?.county || "",
          }))[0]
        : null;

      // check that selected or written address exists
      if (!response) {
        dispatch(
          showAlerts({
            message: i18next.t("translation.bookingPage.notRealAddress"),
            type: "error",
            autoHideDuration: 10000,
          })
        );
      }

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addAddressToSeller = createAsyncThunk(
  "locations/addAddressToSeller",
  async (body: SellerAddressI, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.locations.addAddressToSeller(body);
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.successAddedSellerAddress"
          ),
          type: "success",
          autoHideDuration: 10000,
        })
      );
      return data;
    } catch (error: any) {
      dispatch(
        showAlerts({
          message: i18next.t(
            "translation.alertMessage.failedAddedSellerAddress"
          ),
          type: "error",
          autoHideDuration: 10000,
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getSellerAddresses = createAsyncThunk(
  "locations/getSellerAddresses",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.locations.getSellerAddresses();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkIsPostalCodeActive = createAsyncThunk(
  "locations/checkIsPostalCodeActive",
  async (
    { id, type_housing_id }: PostalCodeActiveReqParamsI,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.locations.checkIsPostalCodeActive({
        id,
        type_housing_id,
      });
      return data.check_is_postal_code_available;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
