import { useFormik } from "formik";
import Button from "components/common/Button";

import { useAppDispatch } from "hooks/redux";
import { useMobileContext } from "context/mobileContext";
import { useTranslation } from "react-i18next";
import AreaNumberTextInput from "components/AreaNumberTextInput";
import { addPostalCodeToAgent } from "store/asyncActions/locations";

interface FormFields {
  postalСode: string;
}

const AddFocusAreaSection = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const formik = useFormik<FormFields>({
    initialValues: { postalСode: "" },
    onSubmit({ postalСode }, { resetForm }) {
      dispatch(addPostalCodeToAgent({ postal_code: +postalСode }));
      resetForm();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('postalСode', e.target.value.replace(/ +/g, ""));

  const disabledButton = !(formik.values.postalСode && formik.values.postalСode.length > 4)

  return (
    <form className={`${isMobile ? "mobile" : ""} add_focus_area_section`} onSubmit={formik.handleSubmit} autoComplete="off">
      <AreaNumberTextInput
        format={`### ##`}
        placeholder={t('translation.agentProfileFocusArea.zipCode')}
        helperText={t('translation.agentProfileFocusArea.zipCodeHelper')}
        classNameContainer="area_number_input"
        value={formik.values.postalСode}
        name="postalСode"
        onChange={handleChange}
      />
      <Button
        sizeVariant="Medium"
        type="submit"
        disabled={disabledButton}
      >
        {t('translation.agentProfileFocusArea.addArea')}
      </Button>
    </form>
  );
};

export default AddFocusAreaSection;
