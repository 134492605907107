import Text from "components/common/Text";
import Button from "components/common/Button";
import { AuthAgentViewType, useAuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";

const AgentForgotPasswordEmailWasSent = () => {
  const { isMobile, setViewType } = useAuthContext();
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <>
          <Text
            text={t("translation.agentAuthPage.emailWasSent")}
            variant="title-medium"
            className="panel_item_title"
          />
          <Text
            text={t("translation.agentAuthPage.checkEmail")}
            variant="paragraph-body"
            className="panel_item_subtitle"
          />
        </>
      )}
      <div className="button_wrapper">
        <Button
          sizeVariant={isMobile ? "Medium" : "Large"}
          type="button"
          className="login_btn back_to_login"
          onClick={() => setViewType(AuthAgentViewType.AUTH_FORM)}
        >
          {t("translation.agentAuthPage.backToLogin")}{" "}
        </Button>
      </div>
    </>
  );
};

export default AgentForgotPasswordEmailWasSent;
