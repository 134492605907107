import { Route } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import AdminAuthPage from "pages/AdminAuthPage";

export const adminAuthRoute = (
  <Route
    path={routesConstant.adminAuthAbsolutely}
    element={
      <RequireAuth
        userRoleOnly={Role.ADMIN}
        navigateTo={routesConstant.rootPage}
        requaredAuth={false}
      >
        <AdminAuthPage />
      </RequireAuth>
    }
  />
);
