import React, { memo } from "react";

import Text from "../Text";

export interface TabsI {
  title: string;
}

interface PanelsPropsI {
  className?: string;
  tabs: TabsI[];
  onClickTab?: (tabIndex: number) => void;
  activeTabIndex: number;
  disabledTab?: { [key: number]: boolean };
  classNameBodyContainer?: string;
  classNameTab?: string;
  panelWidth?: number;
  tabButtonWidth?: number;
  isMobile?: boolean;
}

const Panels: React.FC<React.PropsWithChildren<PanelsPropsI>> = ({
  className = "",
  tabs,
  children,
  onClickTab,
  activeTabIndex,
  disabledTab,
  classNameBodyContainer = "",
  classNameTab = "",
  tabButtonWidth = 161,
  panelWidth = 0,
  isMobile,
}) => {
  const handleClickTab = (index: number) => () => {
    if (typeof onClickTab === "function") onClickTab(index);
  };

  const renderTabs = () => {
    return (
      <>
        <div className={`${isMobile ? "mobile_" : ""}tabs_buttons_container`}>
          {tabs.map((tab, index) => {
            const isActive = activeTabIndex === index;
            const activeClassName = isActive ? "active" : "";

            return (
              <button
                key={tab.title}
                className={`${
                  isMobile ? "mobile_" : ""
                }tab_button title-small ${classNameTab} ${activeClassName}`}
                onClick={handleClickTab(index)}
                style={{
                  zIndex: isActive ? 10 : 9 - index,
                }}
                disabled={(disabledTab && disabledTab[index]) || isActive}
              >
                <Text className={`panel_button_text ${activeClassName}-text`}>
                  {tab.title}
                </Text>
              </button>
            );
          })}
        </div>

        <div
          className={`${
            !isMobile
              ? `content_block_container ${classNameBodyContainer}`
              : "container"
          }`}
        >
          {children}
        </div>
      </>
    );
  };

  return (
    <>
      {!isMobile ? (
        <div
          className={`panels_container ${className}`}
          style={{
            width: `${panelWidth || tabButtonWidth * tabs.length + 35}px`,
          }}
        >
          {renderTabs()}
        </div>
      ) : (
        renderTabs()
      )}
    </>
  );
};

export default memo(Panels);
