import React from "react";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

interface ToggleButtonPropsI extends ToggleButtonProps {
  className?: string;
  isMobile?: boolean;
}

const ToggleButtonStyled: React.FC<ToggleButtonPropsI> = ({
  children,
  className = "",
  isMobile,
  ...rest
}) => {
  return (
    <div className={`${isMobile ? "mobile" : ""} toggle_button_custom_container`}>
      <ToggleButton className={`toggle_button_custom ${className}`} {...rest}>
        {children}
      </ToggleButton>
    </div>
  );
};

export default ToggleButtonStyled;
