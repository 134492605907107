import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "api";
import { DistrictsSearchParams } from "../models/district";

export const getDistricts = createAsyncThunk(
  "districts/getDistricts",
  async (params: DistrictsSearchParams, { rejectWithValue }) => {
    try {
      const { data } = await api.districts.getDistrics(params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
