import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { getTypeHousing } from "store/asyncActions/typeHousing";
import { getIsPreBookingHiddenSelector } from "store/reducers/booking";
import { getIsAuthUserSelector } from "store/reducers/profile";
import { useMobileContext } from "context/mobileContext";

import Text from "components/common/Text";
import Footer from "components/Footer";
import Header from "components/Header";
import AgentBookingList from "components/AgentBookingList";
import Filter from "components/BookingMeetingCalendar/components/Filter";
import AuthFrom from "./components/AuthFrom";
import FilterContext from "components/BookingMeetingCalendar/FilterCalendarContext";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";
import {clearSelectedAgentsToDirectContact} from "../../store/reducers/agents";

const BookingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const isPreBookingHidden = useAppSelector(getIsPreBookingHiddenSelector);
  const isAuth = useAppSelector(getIsAuthUserSelector);
  
  useEffect(()=>{
    if(!isPreBookingHidden){
      dispatch(clearSelectedAgentsToDirectContact())
    }
  },[isPreBookingHidden])
  
  useEffect(() => {
    dispatch(getTypeHousing());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAgentsListBlueBox());
    }
  }, [dispatch, isAuth, isPreBookingHidden]);

  return (
    <>
      {isPreBookingHidden ? (
        <>
          {isMobile && <Header />}
          <div
            className={`page_container client_booking_page booking_filled_page_container ${
              isMobile ? "mobile" : ""
            }`}
          >
            <FilterContext>
              <div className="filter_wrapper">
                <Filter />
              </div>
              <AgentBookingList />
            </FilterContext>
          </div>
          {isMobile && <Footer />}
        </>
      ) : (
        <div
          className={`page_container client_booking_page booking_prefill_page_container ${
            isMobile ? "mobile_container" : ""
          }`}
        >
          {!isMobile && (
            <Text
              component={"h1"}
              text={t("translation.bookingPage.title")}
              className="booking_page_title"
              variant="main-header"
            />
          )}
          <AuthFrom />
        </div>
      )}
    </>
  );
};

export default BookingPage;
