import React, { useEffect, useState } from "react";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import googleIcon from "assets/img/google_calendar_icon.png";
import microsoftEx from "assets/img/microsoft_exchange.png";
import microsoft365 from "assets/img/365.png";
import team from "assets/img/microsoft_office.png";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { ProviderType } from "store/models/calendars";
import CalendarDetailsPopup from "./CalendarDetailsPopup";
import { useTranslation } from "react-i18next";

interface PropsI {
  type: ProviderType;
  isConnectedStatus: boolean;
  className?: string;
  isFetching?: boolean;
  isMobile?: boolean;
  isDisabled?: boolean;
  handleClick(): void;
  handleOpenCalendarsModal?(val: string): void;
}

const ConnectItem: React.FC<PropsI> = ({
  handleClick,
  handleOpenCalendarsModal,
  type,
  isConnectedStatus,
  className = "",
  isFetching,
  isDisabled,
  isMobile,
}) => {
  const { t } = useTranslation();

  const isDisabledTeamsButton = type === ProviderType.TEAM && isDisabled;
  const [isHoveredDisabledTeamsButton, setIsHoveredDisabledTeamsButton] =
    useState(false);

  const handleHoverDisabledTeamsButton = () =>
    setIsHoveredDisabledTeamsButton(true);

  const handleLeaveDisabledTeamsButton = () =>
    setIsHoveredDisabledTeamsButton(false);

  let imgSrc;
  let title = "";
  let description = "";

  switch (type) {
    case ProviderType.GOOGLE:
      imgSrc = googleIcon;
      title = "Google Calendar";
      description = t("translation.agentProfileCalendars.google");
      break;
    case ProviderType.MICROSOFT_365:
      imgSrc = microsoft365;
      title = "Microsoft 365";
      description = t("translation.agentProfileCalendars.365");
      break;
    case ProviderType.MICROSOFT_EXCHANGE:
      imgSrc = microsoftEx;
      title = "Microsoft Exchange";
      description = t("translation.agentProfileCalendars.microsoftExchange");
      break;
    case ProviderType.TEAM:
      imgSrc = team;
      title = "Microsoft Teams";
      description = t("translation.agentProfileCalendars.teams");
      break;
    default:
      imgSrc = "";
      title = "";
      description = "";
  }

  const isConnectedGoogleOrMicrosoft =
    (type === ProviderType.GOOGLE || type === ProviderType.MICROSOFT_365) &&
    isConnectedStatus;

  return (
    <div
      className={`connect_calendar_item ${className} ${
        isMobile ? "mobile" : ""
      }`}
    >
      <div className="name_service_section">
        <img src={imgSrc} className="name_service_logo" alt="service logo" />
        <div className="title_block">
          <Text
            variant="paragraph-body-bold"
            text={title}
            className="name_service_title"
          />
          <Text variant="paragraph-body" text={description} />
        </div>
      </div>
      {type === ProviderType.TEAM && isConnectedStatus ? (
        <TaskAltRoundedIcon
          color="primary"
          className="zoom_connected_done_icon"
        />
      ) : (
        <div className="google_buttons_wrapper">
          {isDisabledTeamsButton && isHoveredDisabledTeamsButton && (
            <div className="tooltip_message">
              {t("translation.agentProfileCalendars.disabledTeams")}
            </div>
          )}
          <div
            className="connect_calendar_item_button_wrapper"
            onMouseEnter={
              isDisabledTeamsButton && !isMobile
                ? handleHoverDisabledTeamsButton
                : undefined
            }
            onMouseLeave={
              isDisabledTeamsButton && !isMobile
                ? handleLeaveDisabledTeamsButton
                : undefined
            }
          >
            <Button
              onClick={handleClick}
              sizeVariant={isMobile ? "Medium" : "Large"}
              variant={isConnectedStatus ? "outlined" : "contained"}
              className="connect_calendar_item_button"
              showLoadingIndicator={isFetching}
              disabled={isDisabled || isFetching}
            >
              <Text className={isConnectedStatus ? "connected" : ""}>
                {isConnectedStatus
                  ? t("translation.agentProfileCalendars.disconnect")
                  : t("translation.agentProfileCalendars.connect")}
              </Text>
            </Button>

            {isDisabledTeamsButton && isMobile && (
              <Text className="disabled_teams_button_hint">
                {t("translation.agentProfileCalendars.disabledTeams")}
              </Text>
            )}
          </div>
          {isConnectedGoogleOrMicrosoft && (
            <CalendarDetailsPopup
              handleOpenCalendarsModal={handleOpenCalendarsModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ConnectItem;
