import { Route } from "react-router-dom";

import { Role } from "store/models/profile";
import { routesConstant } from "constants/appRoutesConstants";
import RequireAuth from "components/RequireAuth";
import ThankYouPage from "pages/ThankYouPage";

export const thankYouPageRoute = (
  <Route
    path={`${routesConstant.thankYouPageAbsolutely}`}
    element={
      <RequireAuth
        userRoleOnly={Role.SELLER}
        navigateTo={routesConstant.rootPage}
        requaredAuth={true}
      >
        <ThankYouPage />
      </RequireAuth>
    }
  />
);
