import React from "react";
import { blackColor } from "constants/colors";

import { IconTypesInterface } from "./iconTypesInterface";

const PhotoCamera: React.FC<IconTypesInterface> = ({
  className = "",
  color = blackColor,
}) => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.8267 3.33366L19.2667 6.00033H24.6667V22.0003H3.33341V6.00033H8.73341L11.1734 3.33366H16.8267ZM18.0001 0.666992H10.0001L7.56008 3.33366H3.33341C1.86675 3.33366 0.666748 4.53366 0.666748 6.00033V22.0003C0.666748 23.467 1.86675 24.667 3.33341 24.667H24.6667C26.1334 24.667 27.3334 23.467 27.3334 22.0003V6.00033C27.3334 4.53366 26.1334 3.33366 24.6667 3.33366H20.4401L18.0001 0.666992ZM14.0001 10.0003C16.2001 10.0003 18.0001 11.8003 18.0001 14.0003C18.0001 16.2003 16.2001 18.0003 14.0001 18.0003C11.8001 18.0003 10.0001 16.2003 10.0001 14.0003C10.0001 11.8003 11.8001 10.0003 14.0001 10.0003ZM14.0001 7.33366C10.3201 7.33366 7.33341 10.3203 7.33341 14.0003C7.33341 17.6803 10.3201 20.667 14.0001 20.667C17.6801 20.667 20.6667 17.6803 20.6667 14.0003C20.6667 10.3203 17.6801 7.33366 14.0001 7.33366Z"
        fill={color}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default PhotoCamera;
