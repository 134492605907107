import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";

import Button from "components/common/Button";
import { useMobileContext } from "context/mobileContext";
import StickyButton from "mobile_components/StickyButton";
import { ClientLocationProfileSchema } from "common/validationSchemas";
import {
  addAddressToSeller,
  getSelectedAddress,
} from "store/asyncActions/locations";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";
import { getSellerAddressSelector } from "store/reducers/addresses";
import {
  getAreaName,
  getPostalCode,
  getStreetData,
} from "common/inputAddressRule";
import { useSelector } from "react-redux";
import { getChosenAgentsLengthSelector } from "store/reducers/booking";
import { SellerAddress as SellerAddressI } from "store/models/locations";
import SellerAddress from "components/SellerAddress";
import { getAgentsListBlueBox } from "store/asyncActions/blueBox";
import { showAlerts } from "store/reducers/alerts";

export interface ClientLocationFormI {
  address: string;
  areaNumber: string;
  district: string;
  county: string;
  address_id: number;
}

const ClientLocationProfilePage = () => {
  const { isMobile } = useMobileContext();
  const { preBookingFormik, setPreBookingAddressToStorage } = useBookingFrom();
  const userAddresses = useAppShallowSelector(getSellerAddressSelector);
  const isAgentsInBlueBox = Boolean(useSelector(getChosenAgentsLengthSelector));

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik<ClientLocationFormI>({
    initialValues: {
      address: userAddresses.address || preBookingFormik.values.address,
      areaNumber:
        userAddresses.areaNumber || preBookingFormik.values.areaNumber,
      address_id:
        userAddresses.address_id || Number(preBookingFormik.values.address_id),
      district: preBookingFormik.values.district,
      county: preBookingFormik.values.county,
    },
    onSubmit({ address: addressValue }) {
      dispatch(getAgentsListBlueBox()).then((data: any) => {
        if (data.payload?.length) {
          dispatch(
            showAlerts({
              type: "error",
              title: "Error",
              message: t("translation.alertMessage.disabledAddress"),
              autoHideDuration: 10000,
            })
          );

          return;
        }
        const { streetName, streetNumber } = getStreetData(addressValue);

        dispatch(
          getSelectedAddress({
            postal_code: Number(getPostalCode(formik.values.areaNumber)),
            street_exact: streetName,
            number_exact: streetNumber,
            postal_code_area_name: getAreaName(formik.values.areaNumber),
          })
        ).then(({ payload }) => {
          if (!payload) return;
          const { address_id, address, areaNumber, district, county } =
            payload as SellerAddressI;

          dispatch(addAddressToSeller({ address: address_id }));
          setPreBookingAddressToStorage({
            address,
            areaNumber,
            address_id,
            district,
            county,
          });
        });
      });
    },
    validationSchema: ClientLocationProfileSchema(),
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  return (
    <>
      <form
        className={`${
          isMobile ? "mobile" : ""
        } client_location_profile_form client_profile_form`}
        onSubmit={formik.handleSubmit}
        onBlur={formik.handleBlur}
        autoComplete="off"
      >
        <SellerAddress
          formik={formik}
          disabled={isAgentsInBlueBox}
          isErrorMessage={isAgentsInBlueBox}
        />
        {!isMobile ? (
          <div className="form_row">
            <Button
              sizeVariant="Large"
              type="submit"
              name="saveButton"
              disabled={!formik.dirty}
            >
              {t("translation.clientProfilePage.saveButton")}
            </Button>
          </div>
        ) : (
          <StickyButton
            className="label-bold"
            type="submit"
            name="submit_button"
            disabled={!formik.dirty}
          >
            {t("translation.clientProfilePage.saveButton")}
          </StickyButton>
        )}
      </form>
    </>
  );
};

export default ClientLocationProfilePage;
