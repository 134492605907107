import React, { memo } from "react";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

import { timeZoneSV } from "constants/timeZoneConstants";
import Text from "components/common/Text";

import { useCalendarContext } from "../useCalendarContext";
import { TimetableItemPropsI } from "../types";

import TimeSlotLayout from "./TimeSlotLayout";


const convertDateToTime = (date: string): string =>
  format(utcToZonedTime(parseISO(date), timeZoneSV), "HH:mm");

const TimeSlotItem: React.FC<TimetableItemPropsI> = ({
  startDate,
  endDate,
  agensCount
}) => {
  const { isAgentCalendar, handleClickTimeSlot, selectedTimeSlot } =
    useCalendarContext();
  const { t } = useTranslation();

  const isSelectedTimeslot = !!(
    selectedTimeSlot &&
    selectedTimeSlot.startDate === startDate &&
    selectedTimeSlot.endDate === endDate
  );

  const timeRangeText = `${convertDateToTime(startDate)} - ${convertDateToTime(
    endDate
  )}`;

  const handleClickItem = () => {
    handleClickTimeSlot({
      startDate: startDate,
      endDate: endDate,
    });
  };

  return (
    <TimeSlotLayout isHourTimeSlot={false} isSelected={isSelectedTimeslot}>
      <button className="time_slot_button" onClick={handleClickItem}>
        <Text variant="label-medium" className="time_slot_time_range_text">
          {timeRangeText}
        </Text>
        {!!agensCount && !isAgentCalendar && (
          <Text variant="paragraph-body">{`${agensCount} ${
            agensCount === 1 ? t("translation.calendarPage.agent") : t("translation.calendarPage.agents")
          }`}</Text>
        )}
      </button>
    </TimeSlotLayout>
  );
};

export default memo(TimeSlotItem);
