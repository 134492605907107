import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { googleAuthorization } from "store/asyncActions/googleAuth";
import { GoogleAuthInitiateResponseI } from "store/models/googleAuth";

import { createTypedSelector } from "../utils";

interface GoogleAuthStateI {
  isRedirectCreateSeller: boolean;
  googleUserPrefillData: {
    user_personal_number: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

const initialState: GoogleAuthStateI = {
  isRedirectCreateSeller: false,
  googleUserPrefillData: {
    user_personal_number: "",
    email: "",
    first_name: "",
    last_name: "",
  },
};

export const getIsRedirectCreateSellerSelector = createTypedSelector(
  (state) => state.googleAuth.isRedirectCreateSeller
);

export const getGoogleAuthUserInfoSelector = createTypedSelector(
  (state) => state.googleAuth.googleUserPrefillData
);

const googleAuthSlice = createSlice({
  name: "googleAuth",
  initialState,
  reducers: {
    clearPrefillGoogleData: (state) => {
      state.googleUserPrefillData = initialState.googleUserPrefillData;
      state.isRedirectCreateSeller = initialState.isRedirectCreateSeller;
    },
  },
  extraReducers: {
    [googleAuthorization.fulfilled.type]: (
      state,
      action: PayloadAction<GoogleAuthInitiateResponseI>
    ) => {
      state.isRedirectCreateSeller = !action.payload.jwt_pair;

      state.googleUserPrefillData.email = action.payload.email;
      state.googleUserPrefillData.first_name = action.payload.first_name;
      state.googleUserPrefillData.last_name = action.payload.last_name;
      state.googleUserPrefillData.user_personal_number =
        action.payload.user_personal_number;
    },
  },
});

export const { clearPrefillGoogleData } = googleAuthSlice.actions;

export default googleAuthSlice.reducer;
