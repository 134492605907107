import CRUD from "api/base";
import { AxiosInstance, AxiosPromise } from "axios";

import {
  DeleteMeetingQueryParams,
  PassedMeetingsResponse,
  UpcomingMeetingsQueryParams,
  UpcomingMeetingsResponse,
} from "store/models/meetings";
import {
  LOAD_ALL_UPCOMING_MEETINGS_LIMIT,
  LOAD_PASSED_MEETINGS_LIMIT,
} from "constants/limitLoadConstants";

export class MeetingsApi extends CRUD {
  getUpcomingMeetings(
    params?: UpcomingMeetingsQueryParams
  ): AxiosPromise<UpcomingMeetingsResponse> {
    return this.request({
      url: `${this.url}/upcoming/`,
      params: { limit: LOAD_ALL_UPCOMING_MEETINGS_LIMIT, ...params },
    });
  }

  getPassedMeetings(params?: UpcomingMeetingsQueryParams): AxiosPromise<PassedMeetingsResponse> {
    return this.request({
      url: `${this.url}/passed/`,
      params: { limit: LOAD_PASSED_MEETINGS_LIMIT, ...params },
    });
  }

  deleteMeeting(params: DeleteMeetingQueryParams) {
    return this.request({
      url: `${this.url}/canceled/${params.id}/`,
      method: "DELETE"
    });
  }
}

export default function meetingsApi(request: AxiosInstance) {
  return new MeetingsApi({
    url: "/meetings",
    request,
  });
}
