import { memo } from "react";

import { useMobileContext } from "context/mobileContext";
import { useBookingFrom } from "pages/BookingPage/useBookingForm";

import { MobileViewType } from "../BookingMeetingCalendar";
import FilterMonthSection from "./FilterMonthSection";
import AdressLabelForFilter from "./AdressLabelForFilter";
import { useCalendarContext } from "../useCalendarContext";

interface IFilter {
  mobileView?: MobileViewType;
  setMobileView?(val: MobileViewType): void;
}

const Filter = ({ mobileView, setMobileView }: IFilter) => {
  const { isMobile } = useMobileContext();
  const { preBookingFormik } = useBookingFrom();

  const { isAgentCalendar } = useCalendarContext();

  return (
    <div className={`filter_container ${isMobile ? "mobile" : ""}`}>
      {isAgentCalendar && (
        <FilterMonthSection
          mobileView={mobileView}
          setMobileView={setMobileView}
        />
      )}
      <div className="filter_options">
        <AdressLabelForFilter
          areaNumber={preBookingFormik.values.areaNumber}
          address={preBookingFormik.values.address}
        />
      </div>
    </div>
  );
};

export default memo(Filter);
