import React from "react";
import { useTranslation } from "react-i18next";

import TextInputMask, {
  TextInputMaskProps,
} from "components/common/TextInputMask";

const AreaNumberTextInput: React.FC<TextInputMaskProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <TextInputMask
      format={"### ##"}
      placeholder="111 54"
      autoComplete="off"
      label={`${t("translation.inputLabel.zipCode")}`}
      type="text"
      {...props}
    />
  );
};

export default AreaNumberTextInput;
