export type RoutesT =
  | "*"
  | "/"
  | "/auth"
  | "auth"
  | "/agent"
  | "agent"
  | "/agent/my-meetings"
  | "my-meetings"
  | "/my-meetings"
  | "/agent/previous-meetings"
  | "previous-meetings"
  | "/agent/profile"
  | "profile"
  | "/agent/profile/focus-areas"
  | "focus-areas"
  | "/agent/profile/connected-calendars"
  | "connected-calendars"
  | "/agent/profile/online-preference"
  | "online-preference"
  | "/booking"
  | "booking"
  | "/auth/create"
  | "create"
  | "/profile"
  | "/profile/location"
  | "location"
  | "/agent-profile"
  | "/customer-analysis"
  | "agent-profile"
  | "auth/b25Gt68CbSAdmin"
  | "/thank-you-page"
  | "/pdf"

interface RoutesConstantI<T extends string> {
  unknown: T;
  rootPage: T;
  auth: T;
  authAbsolutely: T;
  authCreate: T;
  authCreateAbsolutely: T;
  agent: T;
  agentAbsolutely: T;
  agentMyMeeting: T;
  agentMyMeetingAbsolutely: T;
  agentPreviousMeerings: T;
  agentPreviousMeeringsAbsolutely: T;
  agentProfile: T;
  agentProfileAbsolutely: T;
  agentProfileFocusAreas: T;
  agentProfileFocusAreasAbsolutely: T;
  agentProfileConnectedCalendars: T;
  agentProfileConnectedCalendarsAbsolutely: T;
  agentProfileOnlinePreference: T;
  agentProfileOnlinePreferenceAbsolutely: T;
  booking: T;
  bookingAbsolutely: T;
  clientProfile: T;
  clientProfileAbsolutely: T;
  clientProfileLocation: T;
  clientProfileLocationAbsolutely: T;
  clientMyMeetingsLocation: T;
  clientMyMeetingsLocationAbsolutely: T;
  agentPresentationProfile: T;
  agentPresentationProfileAbsolutely: T;
  customAnalysisAbsolutely: T;
  adminAuthAbsolutely: T;
  thankYouPageAbsolutely: T;
  customAnalysisDownloadPDF: T;
}

export const routesConstant: RoutesConstantI<RoutesT> = {
  unknown: "*",
  rootPage: "/",
  auth: "/auth",
  authAbsolutely: "auth",
  authCreate: "/auth/create",
  authCreateAbsolutely: "create",
  agent: "/agent",
  agentAbsolutely: "agent",
  agentMyMeeting: "/agent/my-meetings",
  agentMyMeetingAbsolutely: "my-meetings",
  agentPreviousMeerings: "/agent/previous-meetings",
  agentPreviousMeeringsAbsolutely: "previous-meetings",
  agentProfile: "/agent/profile",
  agentProfileAbsolutely: "profile",
  agentProfileFocusAreas: "/agent/profile/focus-areas",
  agentProfileFocusAreasAbsolutely: "focus-areas",
  agentProfileConnectedCalendars: "/agent/profile/connected-calendars",
  agentProfileConnectedCalendarsAbsolutely: "connected-calendars",
  agentProfileOnlinePreference: "/agent/profile/online-preference",
  agentProfileOnlinePreferenceAbsolutely: "online-preference",
  booking: "/booking",
  bookingAbsolutely: "booking",
  clientProfile: "/profile",
  clientProfileAbsolutely: "profile",
  clientProfileLocation: "/profile/location",
  clientProfileLocationAbsolutely: "location",
  clientMyMeetingsLocation: "/my-meetings",
  clientMyMeetingsLocationAbsolutely: "my-meetings",
  agentPresentationProfile: "/agent-profile",
  agentPresentationProfileAbsolutely: "agent-profile",
  customAnalysisAbsolutely: "/customer-analysis",
  adminAuthAbsolutely: "auth/b25Gt68CbSAdmin",
  thankYouPageAbsolutely: "/thank-you-page",
  customAnalysisDownloadPDF: "/pdf"
};
