import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import { TypeHousingI } from "../models/agent";
import { getTypeHousing } from "../asyncActions/typeHousing";

export interface TypeHosingStoreI {
  isFetching: boolean;
  data: TypeHousingI[];
}

const initialState: TypeHosingStoreI = {
  isFetching: false,
  data: [],
};

export const getIsFetchingTypeHousingSelector = createTypedSelector(
  (state) => state.typeHousing.isFetching
);

export const getDataTypeHousingSelector = createTypedSelector(
  (state) => state.typeHousing.data
);

const typeHousingSlice = createSlice({
  name: "typeHousing",
  initialState,
  reducers: {},
  extraReducers: {
    [getTypeHousing.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getTypeHousing.fulfilled.type]: (
      state,
      action: PayloadAction<TypeHousingI[]>
    ) => {
      state.isFetching = false;
      state.data = action.payload;
    },
    [getTypeHousing.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export default typeHousingSlice.reducer;
