import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Select from "components/common/Select";

const HeaderLinkMenu: React.FC = () => {
  const { t } = useTranslation();
  const [isLinkMenuOpened, setIsLinkMenuOpened] = useState(false);

  const handleOpenMenu = () => setIsLinkMenuOpened(!isLinkMenuOpened);

  const endIcon = () =>
    isLinkMenuOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  const handleRedirect = (url: string) => () => window.location.replace(url);

  return (
    <Select
      alignBody="right"
      isCloseAfterClick={false}
      handleClickSelectItem={() => null}
      renderControl={(props) => (
        <span
          className="menu_button_links"
          onClick={props.onClick as MouseEventHandler}
        >
          <MenuIcon style={{ width: "30px", height: "30px" }} />
        </span>
      )}
      renderBody={
        <div className="links_menu_container">
          <Select.SelectItem
            title={t("translation.header.about")}
            type="button"
            onClick={handleRedirect(
              `${process.env.REACT_APP_PUBLIC_URL_ABOUT}`
            )}
          />
          <Select.SelectItem
            title={t("translation.header.articles")}
            type="button"
            onClick={handleRedirect(
              `${process.env.REACT_APP_PUBLIC_URL_ARTICLES}`
            )}
          />
          {/* <Select.SelectItem
            title={t("translation.header.agents")}
            type="button"
            onClick={handleOpenMenu}
            endIcon={endIcon}
            className="end"
          />
          {isLinkMenuOpened && (
            <div className="subtitles">
              <Select.SelectItem
                title="Broker Stockholm"
                type="link"
                to="#"
                disabled
              />
              <Select.SelectItem
                title="Broker Malmö"
                type="link"
                to="#"
                disabled
              />
              <Select.SelectItem
                title="Broker Helsingborg"
                type="link"
                to="#"
                disabled
              />
            </div>
          )} */}
        </div>
      }
    />
  );
};

export default HeaderLinkMenu;
