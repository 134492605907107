import { configureStore, combineReducers } from "@reduxjs/toolkit";

import profile from "./reducers/profile";
import agents from "./reducers/agents";
import bankId from "./reducers/bankId";
import googleAuth from "./reducers/googleAuth";
import typeHousing from "./reducers/typeHousing";
import alerts from "./reducers/alerts";
import districts from "./reducers/districts";
import booking from "./reducers/booking";
import calendars from "./reducers/calendars";
import meetings from "./reducers/meetings";
import addresses from "./reducers/addresses";
import customerAnalysis from "./reducers/customerAnalysis";

const rootReducer = combineReducers({
  profile,
  agents,
  bankId,
  googleAuth,
  typeHousing,
  alerts,
  districts,
  booking,
  calendars,
  meetings,
  addresses,
  customerAnalysis,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.REACT_APP_CUSTOM_NODE_ENV !== "production",
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
