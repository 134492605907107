import React from "react";
import PageLoader from "components/PageLoader";
import { useAppSelector } from "hooks/redux";

import { CallBackT } from "store/utils";

interface BlockLoaderPropsI {
  selector: CallBackT<unknown>;
}

const BlockLoader: React.FC<BlockLoaderPropsI> = ({ selector }) => {
  const isLoader = useAppSelector(selector);

  return isLoader ? <PageLoader /> : null;
};

export default BlockLoader;
