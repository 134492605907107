import React, { useCallback, useState } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getAgentOtherCalendarsSelector,
  getConferencingProfileSelector,
  getConnectStatusSelector,
  getIsFetchingCalendarSelector,
} from "store/reducers/calendars";
import {
  getConsentScreenUrl,
  disconnectCalendars,
  getAgentOtherCalendars,
  getAgentOtherCalendarsForConnection,
  saveOtherCalendarsToAgent,
  deleteOtherCalendarsFromAgent,
  getConnectTeamUrl,
} from "store/asyncActions/calendars";
import { AgentCalendarI, ProviderType } from "store/models/calendars";
import { lsApi } from "utils/localStorageApi";
import { useMobileContext } from "context/mobileContext";

import AdministrateCalendarsModal from "./AdministrateCalendarsModal";
import ConnectItem from "./ConnectItem";
import SwitchCalendarModal from "./SwitchCalendarModal";

export const enum CalendarType {
  addModal = "add",
  deleteModal = "delete",
}

const CalendarConnectionSection = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const [isOpenSwithModal, setIsOpenSwithModal] = useState<boolean>(false);
  const [isOpenCalendarsModal, setIsOpenCalendarsModal] =
    useState<boolean>(false);
  const [calendarType, setCalendarType] = useState<CalendarType | null>(null);

  const [selectedCalendarList, setSelectedCalendarList] = useState<
    AgentCalendarI[]
  >([]);

  const [connectAnotherCalendar, setConnectAnotherCalendar] =
    useState<ProviderType | null>(null);

  const connectStatus = useAppSelector(getConnectStatusSelector);
  const confConnectData = useAppShallowSelector(getConferencingProfileSelector);
  const otherCalendars = useAppShallowSelector(getAgentOtherCalendarsSelector);
  const isFetching = useAppSelector(getIsFetchingCalendarSelector);

  const isMicrosoftTeamConnected = !!confConnectData.length;

  const connectCalendars = useCallback(
    (type: ProviderType) => {
      const _provider =
        type === ProviderType.GOOGLE
          ? ProviderType.GOOGLE
          : type === ProviderType.MICROSOFT_365
          ? ProviderType.MICROSOFT_365
          : ProviderType.MICROSOFT_EXCHANGE;

      lsApi.set("redirectCalendarProvider", _provider);
      dispatch(getConsentScreenUrl({ calendar_provider: _provider }));
    },
    [dispatch]
  );

  const disconnectCalendar = useCallback(() => {
    dispatch(disconnectCalendars());
  }, [dispatch]);

  const handleClickConnect = useCallback(
    (type: ProviderType) => () => {
      if (connectStatus) {
        if (connectStatus === type) {
          disconnectCalendar();
        } else {
          setIsOpenSwithModal(true);
          setConnectAnotherCalendar(type);
        }
      } else {
        connectCalendars(type);
      }
    },
    [connectCalendars, connectStatus, disconnectCalendar]
  );

  const handleClickConnectAnotherCalendar = useCallback(() => {
    setIsOpenSwithModal(false);
    if (connectAnotherCalendar !== null) {
      dispatch(disconnectCalendars()).then((data) => {
        if (isEmpty((data as any).error)) {
          connectCalendars(connectAnotherCalendar);
        }
      });
      setConnectAnotherCalendar(null);
    }
  }, [dispatch, connectAnotherCalendar, connectCalendars]);

  const handleClickConnectTeam = useCallback(() => {
    if (!isMicrosoftTeamConnected) {
      dispatch(getConnectTeamUrl({ provider_name: ProviderType.TEAM }));
    }
  }, [dispatch, isMicrosoftTeamConnected]);

  const handleClickBack = useCallback(() => {
    setIsOpenSwithModal(false);
    setConnectAnotherCalendar(null);
  }, []);

  const handleCloseCalendarsModal = useCallback(() => {
    setIsOpenCalendarsModal(false);
  }, []);

  const handleOpenCalendarsModal = useCallback(
    (calendarT: CalendarType) => {
      if (calendarT === CalendarType.addModal) {
        dispatch(getAgentOtherCalendarsForConnection());
      }

      if (calendarT === CalendarType.deleteModal) {
        dispatch(getAgentOtherCalendars());
      }

      setCalendarType(calendarT);
      setIsOpenCalendarsModal(true);
    },
    [dispatch]
  );

  const handleSaveOtherCalendarsToAgent = useCallback(
    (calendars: AgentCalendarI[]) => {
      if (calendarType === CalendarType.addModal) {
        dispatch(saveOtherCalendarsToAgent({ calendar: calendars }));
      }

      if (calendarType === CalendarType.deleteModal) {
        const calendarsId = calendars
          .map((cal) => cal?.id)
          .filter(Boolean) as number[];
        dispatch(
          deleteOtherCalendarsFromAgent({ other_calendar_ids: calendarsId })
        );
      }
      setSelectedCalendarList([]);
      setIsOpenCalendarsModal(false);
    },
    [calendarType]
  );

  return (
    <div className={`calendar_conection_section ${isMobile ? "mobile" : ""}`}>
      <ConnectItem
        handleClick={handleClickConnect(ProviderType.GOOGLE)}
        type={ProviderType.GOOGLE}
        isConnectedStatus={connectStatus === ProviderType.GOOGLE}
        isMobile={isMobile}
        isFetching={isFetching}
        handleOpenCalendarsModal={handleOpenCalendarsModal}
      />
      <div className="calendar_conection_line">
        <span className="line" />
        <Text className="or" variant="paragraph-body">
          {t("translation.agentProfileCalendars.or")}
        </Text>
        <span className="line" />
      </div>
      <ConnectItem
        handleClick={handleClickConnect(ProviderType.MICROSOFT_365)}
        type={ProviderType.MICROSOFT_365}
        isConnectedStatus={connectStatus === ProviderType.MICROSOFT_365}
        isMobile={isMobile}
        isFetching={isFetching}
        handleOpenCalendarsModal={handleOpenCalendarsModal}
      />
      <ConnectItem
        type={ProviderType.TEAM}
        isConnectedStatus={isMicrosoftTeamConnected}
        isMobile={isMobile}
        isFetching={isFetching}
        isDisabled={connectStatus !== ProviderType.MICROSOFT_365}
        className="zoom_connect_item"
        handleClick={handleClickConnectTeam}
      />

      <SwitchCalendarModal
        isOpen={isOpenSwithModal}
        onClickBack={handleClickBack}
        onClickContinue={handleClickConnectAnotherCalendar}
      />
      <AdministrateCalendarsModal
        isOpen={isOpenCalendarsModal}
        calendars={otherCalendars}
        calendarType={calendarType}
        selectedCalendarList={selectedCalendarList}
        onClickBack={handleCloseCalendarsModal}
        onClickSave={handleSaveOtherCalendarsToAgent}
        setSelectedCalendarList={setSelectedCalendarList}
      />
    </div>
  );
};

export default CalendarConnectionSection;
