import { AgentI } from "./agent";

export enum Role {
  ADMIN = 1,
  AGENT = 2,
  SELLER = 3,
}

export interface TokensI {
  access: string;
  refresh: string;
}

export interface RefreshTokenDataI {
  refresh: string;
}

interface RatingI {
  rating: number;
  reviews: number;
  sales_last_six_months: number;
  year_sales_last: number;
  type_domain: string;
}

export interface UsersI {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
  phone_number: string;
  role: Role | null;
  first_time_slot?: string | null;
  max_rating?: RatingI | null;
  gdpr_consent: boolean;
  agent_profile?: AgentI;
  what_clients_say?: string;
  client_feedback?: string[];
}

export type AgentUpdateT = Pick<
  AgentI,
  "title" | "presentation" | "what_clients_say" | "client_feedback"
>;

export interface AgentProfileUpdateI
  extends Pick<UsersI, "phone_number" | "id"> {
  agent_profile: AgentUpdateT;
}
export interface CreateUserDataI
  extends Omit<UsersI, "id" | "agent_profile" | "role"> {
  personal_number: string;
  address_id: number;

  provider_id?: number;
}

export type EditUserDataT = Omit<
  UsersI,
  "agent_profile" | "role" | "gdpr_consent"
>;

export interface CreateUserResponseI {
  jwt_pair: TokensI;
}

export interface RestorePasswordRequestData {
  email: string;
  webhook_url: string;
}

export interface ChangePasswordRequestData {
  token: string;
  password: string;
}

export interface AgentsEmailList {
  email: string;
}

export interface AgentsEmailListResponce {
  next: string;
  results: AgentsEmailList[];
}

export interface AdminLoginParamsI {
  limit: number;
  email?: string;
}

export interface RefreshAdminLoginParamsI {
  email: string;
}
