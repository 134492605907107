import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import TextInput, { TextInputPropsI } from "../TextInput/TextInput";

export type TextInputMaskProps = NumberFormatProps<TextInputPropsI>;

const TextInputMask: React.FC<TextInputMaskProps> = (props) => {
  return <NumberFormat {...props} customInput={TextInput} />;
};

export default TextInputMask;
