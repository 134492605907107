import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { createTypedSelector } from "../utils";
import {
  AgentI,
  AgentsListResponceI,
  AgentShortDataItem,
  AgencyListResponceI,
  AgencyI,
  AgentDelayHourI,
  SuccessBookingResponceI,
  PostalCodesI,
} from "store/models/agent";
import { UsersI } from "store/models/profile";
import {
  getAgencyList,
  getAgentDetail,
  getAgentPostalCode,
  getAgentsList,
  getDelayHours,
  setDelayHours,
} from "store/asyncActions/agents";
import { confirmBookingMeeting } from "store/asyncActions/booking";

interface AgentsSliseI {
  isFetching: boolean;
  isConfirmingBookMeet: boolean;
  agents: AgentsListResponceI;
  displayedAgentList: UsersI[];
  agentProfile: Omit<UsersI, "role" | "address" | "areaNumber">;
  agencyList: AgencyListResponceI;
  selectedAgencyList: string[];
  delayHour: number | null;
  successBooking: SuccessBookingResponceI;
  postalCodes: PostalCodesI[];
  selectedAgentsToDirectContact: string[];
}

const initialState: AgentsSliseI = {
  isFetching: false,
  isConfirmingBookMeet: false,
  agents: {
    count: 0,
    results: [],
  },
  displayedAgentList: [],
  agentProfile: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    gdpr_consent: false,
    client_feedback: [],
    what_clients_say: "",
    agent_profile: {} as AgentI,
  },
  agencyList: {
    next: "",
    results: [],
  },
  selectedAgencyList: [],
  delayHour: null,
  successBooking: {} as SuccessBookingResponceI,
  postalCodes: [],
  selectedAgentsToDirectContact: [],
};

export const getAllAgentsCountSelector = createTypedSelector(
  (state) => state.agents.agents.count
);

export const getCurrentAgentCountSelector = createTypedSelector(
  (state) => state.agents.displayedAgentList.length
);

export const getAgencyListSelector = createTypedSelector(
  (state) => state.agents.agencyList.results
);

export const getSelectedAgencyListSelector = createTypedSelector(
  (state) => state.agents.selectedAgencyList
);

export const getIsNextAgencyListSelector = createTypedSelector((state) =>
  Boolean(state.agents.agencyList.next)
);

export const getAgentsSelector = createTypedSelector((state) =>
  state.agents.displayedAgentList.map((agent): AgentShortDataItem => {
    return {
      id: agent.id,
      firstName: agent.first_name,
      lastName: agent.last_name,
      email: agent.email,
      description: agent.agent_profile?.presentation || "",
      fotoUrl: agent.agent_profile?.image || "",
      agencyLogo: agent.agent_profile?.agency?.logotype || "",
      isExpert: agent.agent_profile?.is_expert || false,
      timeSlot: agent.first_time_slot || "",
      maxRating: agent.max_rating?.rating || null,
      ratingDomain: agent.max_rating?.type_domain || "",
      reviews: agent.max_rating?.reviews || 0,
      salesLastSixMonths: agent.max_rating?.sales_last_six_months || 0,
      yearSalesLast: agent.max_rating?.year_sales_last || 0,
    };
  })
);

export const getAgentDetailSelector = createTypedSelector(
  (state) => state.agents.agentProfile
);

export const getAgentDelayHoursSelector = createTypedSelector(
  (state) => state.agents.delayHour
);

export const getShortAgentDetailSelector = createTypedSelector(
  (state): Omit<AgentShortDataItem, "description"> => {
    const agent = state.agents.agentProfile;
    return {
      id: agent.id,
      firstName: agent.first_name,
      lastName: agent.last_name,
      email: agent.email,
      fotoUrl: agent.agent_profile?.image || "",
      agencyLogo: agent.agent_profile?.agency?.logotype || "",
      isExpert: agent.agent_profile?.is_expert || false,
      timeSlot: agent.first_time_slot || "",
      maxRating: agent.max_rating?.rating || null,
      ratingDomain: agent.max_rating?.type_domain || "",
      reviews: agent.max_rating?.reviews || 0,
      salesLastSixMonths: agent.max_rating?.sales_last_six_months || 0,
      yearSalesLast: agent.max_rating?.year_sales_last || 0,
    };
  }
);

export const getAgentsIsFetchingSelector = createTypedSelector(
  (state) => state.agents.isFetching
);

export const getIsConfirmingBookMeetSelector = createTypedSelector(
  (state) => state.agents.isConfirmingBookMeet
);

export const getSuccessBookingSelector = createTypedSelector(
  (state) => state.agents.successBooking
);

export const getAgentByIdSelector = (id: string | number) =>
  createSelector([getAgentsSelector], (agents) => {
    return agents.find((agent) => agent.id === id);
  });

export const getPostalCodesAgentFocusAreaSelector = createTypedSelector(
  (state) => state.agents.postalCodes
);

export const getSelectedAgentsToDirectContactSelector = createTypedSelector(
  (state) => state.agents.selectedAgentsToDirectContact
);

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    clearAgentProfile: (state) => {
      state.agentProfile = initialState.agentProfile;
    },
    setSelectedAgencyList: (state, action: PayloadAction<AgencyI[]>) => {
      state.selectedAgencyList = action.payload.map((agency) => agency.id);
    },
    getNextAgentsList: (state) => {
      state.displayedAgentList = state.agents.results.slice(
        0,
        state.displayedAgentList.length + 8
      );
    },
    setSelectedAgentsToDirectContact: (
      state,
      action: PayloadAction<string>
    ) => {
      const selectedEmail = action.payload;

      if (
        state.selectedAgentsToDirectContact.some(
          (item) => item === selectedEmail
        )
      ) {
        state.selectedAgentsToDirectContact =
          state.selectedAgentsToDirectContact.filter(
            (item) => item !== selectedEmail
          );
      } else {
        state.selectedAgentsToDirectContact = [
          ...state.selectedAgentsToDirectContact,
          selectedEmail,
        ];
      }
    },
    clearSelectedAgentsToDirectContact: (state) => {
      state.selectedAgentsToDirectContact = [];
    },
  },
  extraReducers: {
    [getAgentDetail.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAgentDetail.fulfilled.type]: (state, action: PayloadAction<UsersI>) => {
      state.isFetching = false;
      state.agentProfile = action.payload;
    },
    [getAgentDetail.rejected.type]: (state) => {
      state.isFetching = false;
      state.agentProfile = initialState.agentProfile;
    },
    [getAgentsList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAgentsList.fulfilled.type]: (
      state,
      action: PayloadAction<AgentsListResponceI>
    ) => {
      state.agents = action.payload;
      state.displayedAgentList = action.payload.results.slice(0, 8);
      state.isFetching = false;
    },
    [getAgencyList.fulfilled.type]: (
      state,
      action: PayloadAction<AgencyListResponceI>
    ) => {
      state.agencyList = {
        next: action.payload.next,
        results: action.payload.results,
      };
    },
    [getAgencyList.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getDelayHours.fulfilled.type]: (
      state,
      action: PayloadAction<AgentDelayHourI>
    ) => {
      state.delayHour = action.payload.delay_hours;
    },
    [setDelayHours.fulfilled.type]: (
      state,
      action: PayloadAction<AgentDelayHourI>
    ) => {
      state.delayHour = action.payload.delay_hours;
    },
    [confirmBookingMeeting.pending.type]: (state) => {
      state.isConfirmingBookMeet = true;
    },
    [confirmBookingMeeting.fulfilled.type]: (
      state,
      action: PayloadAction<SuccessBookingResponceI>
    ) => {
      state.isConfirmingBookMeet = false;
      state.successBooking = action.payload;
    },
    [confirmBookingMeeting.rejected.type]: (state) => {
      state.isConfirmingBookMeet = false;
    },
    [getAgentPostalCode.fulfilled.type]: (state, action) => {
      state.postalCodes = action.payload;
    },
  },
});

export const {
  clearAgentProfile,
  setSelectedAgencyList,
  getNextAgentsList,
  setSelectedAgentsToDirectContact,
  clearSelectedAgentsToDirectContact,
} = agentsSlice.actions;

export default agentsSlice.reducer;
