import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import NextMeetingList from "components/NextMeetingList";
import { useMobileContext } from "context/mobileContext";

import { useUpcomingMeetins } from "./useUpcomingMeetins";

const ClientMyMeetingPage = () => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();
  const [data, handleInfinytyScroll] = useUpcomingMeetins();

  return (
    <div
      className={`page_container client_my_meeting_page ${
        isMobile ? "mobile_container" : ""
      }`}
    >
      <div className="title_page_block">
        <Text
          variant="medium-header"
          className={`${
            isMobile ? "title-large title" : "client_my_meeting_title"
          }`}
        >
          {t("translation.clientMeetingPage.title")}!
        </Text>
        <Text className={`${isMobile ? "paragraph-body" : "paragraph-large"}`}>
          {t("translation.clientMeetingPage.subtitle")}
        </Text>
      </div>
      <NextMeetingList
        className="meetings_list"
        isMobile={isMobile}
        isAgent={false}
        data={data}
        isInfinytyScroll
        handleInfinytyScroll={handleInfinytyScroll}
      />
    </div>
  );
};

export default ClientMyMeetingPage;
