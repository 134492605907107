import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/redux";

import { useMobileContext } from "context/mobileContext";
import { getIsAuthUserSelector, getRoleProfileSelector } from "store/reducers/profile";

import Text from "components/common/Text";
import Panels from "components/common/Panels";

import FirstStepAuth from "./components/FirstStepAuth";
import SecondStepAuth from "./components/SecondStepAuth";

export interface AdminAuthStepI {
  setAuthStep(step: number): void;
}

const AdminAuthPage: React.FC = () => {
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  const isAuth = useAppSelector(getIsAuthUserSelector);
  const roleUser = useAppSelector(getRoleProfileSelector);
  const isAdminInSystem = isAuth && roleUser === 1;

  const [authStep, setAuthStep] = useState(isAdminInSystem ? 2 : 1);

  const containerClassName = isMobile
    ? "mobile_auth_page_container mobile_container"
    : "page_container auth_page_container";

  return (
    <div className={`page_container admin_auth_page ${isMobile ? "mobile_container" : ""}`}>
      <div className={containerClassName}>
        {isMobile && (
          <div className="title_wrapper">
            <Text
              text={t("translation.adminLoginPage.title")}
              variant="title-medium"
              className="title-large title"
              component={"h1"}
            />
            <Text
              text={t("translation.adminLoginPage.description")}
              variant="paragraph-body"
              className="paragraph-body"
              component={"h2"}
            />
          </div>
        )}
        <Panels
          tabs={[{ title: t("translation.adminLoginPage.tabTitle") }]}
          activeTabIndex={0}
          classNameBodyContainer="auth_panels_body_container"
          classNameTab="label-bold auth_panels_tab"
          tabButtonWidth={213}
          panelWidth={476}
          isMobile={isMobile}
        >
          <div className="panel_item client_auth_body">
            {!isMobile && (
              <>
                <Text
                  text={`${t("translation.adminLoginPage.title")}`}
                  variant="title-medium"
                  className="panel_item_title"
                />
                <Text
                  text={`${t("translation.adminLoginPage.description")}`}
                  variant="paragraph-body"
                  className="panel_item_subtitle"
                />
              </>
            )}
              {authStep === 1 && <FirstStepAuth setAuthStep={setAuthStep} />}
              {authStep === 2 && <SecondStepAuth />}
          </div>
        </Panels>
      </div>
    </div>
  );
};

export default AdminAuthPage;
