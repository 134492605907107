import { AxiosInstance, AxiosPromise } from "axios";
import CRUD from "../base";

import {
  AddressesListResponceI,
  AddressesRequestI,
  AgentPostalCodeDataI,
  AgentPostalCodeResponceI,
  PostalCodeActiveReqParamsI,
  PostalCodeActiveResponceI,
  SellerAddressI,
  SellerAddressResponceI,
} from "store/models/locations";

export class LocationApi extends CRUD {
  addPostalCodeToAgent(
    data: AgentPostalCodeDataI
  ): AxiosPromise<AgentPostalCodeResponceI> {
    return this.request({
      url: `${this.url}/postal_codes/add_postal_code_to_agent/`,
      method: "POST",
      data,
    });
  }

  removePostalCodeToAgent(
    data: AgentPostalCodeDataI
  ): AxiosPromise<AgentPostalCodeResponceI> {
    return this.request({
      url: `${this.url}/postal_codes/remove_postal_code_to_agent/`,
      method: "DELETE",
      data,
    });
  }

  getAddresses(
    params?: AddressesRequestI
  ): AxiosPromise<AddressesListResponceI> {
    return this.request({
      url: `${this.url}/addresses/`,
      params,
    });
  }

  addAddressToSeller(data: SellerAddressI): AxiosPromise<SellerAddressI> {
    return this.request({
      url: `${this.url}/seller/addresses/`,
      method: "POST",
      data,
    });
  }

  getSellerAddresses(): AxiosPromise<SellerAddressResponceI> {
    return this.request({
      url: `${this.url}/seller/addresses/get_my_adrress/`,
    });
  }

  checkIsPostalCodeActive({
    id,
    ...params
  }: PostalCodeActiveReqParamsI): AxiosPromise<PostalCodeActiveResponceI> {
    return this.request({
      url: `${this.url}/postal_codes/${id}/is_active/`,
      params,
    });
  }
}

export default function locationApi(request: AxiosInstance) {
  return new LocationApi({
    url: "/locations",
    request,
  });
}
