import { useFormik } from "formik";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/redux";
import { useTranslation } from "react-i18next";

import Button from "components/common/Button";
import TextInput from "components/common/TextInput";

import { userLogin } from "store/asyncActions/profile";
import { AdminSignInSchema } from "common/validationSchemas";
import { useMobileContext } from "context/mobileContext";
import { deleteToken } from "utils/auth";

import { AdminAuthStepI } from "../AdminAuthPage";

interface AdminFormT {
  email: string;
  password: string;
}

const FirstStepAuth = ({ setAuthStep }: AdminAuthStepI) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useMobileContext();

  const formik = useFormik<AdminFormT>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit(values) {
      dispatch(userLogin(values))
      .unwrap()
      .then(() => setAuthStep(2));
    },
    validationSchema: AdminSignInSchema(),
    validateOnBlur: true,
  });

  useEffect(() => deleteToken(), []);

  return (
    <form
      className="agent_signin_form_container"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <div className={`${isMobile ? "mobile_form_group" : ""}`}>
        <TextInput
          label={`${t("translation.adminLoginPage.emailLabel")}`}
          placeholder={`${t("translation.inputPlaceholder.email")}`}
          classNameContainer="agent_sing_input"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : ""
          }
        />
        <TextInput
          label={`${t("translation.adminLoginPage.passwordLabel")}`}
          placeholder={`${t("translation.inputPlaceholder.password")}`}
          classNameContainer="agent_sing_input last"
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ""
          }
        />
        <Button
          sizeVariant={isMobile ? undefined : "Large"}
          type="submit"
          className="login_btn"
        >
          {`${t("translation.adminLoginPage.next")}`}
        </Button>
      </div>
    </form>
  );
};

export default FirstStepAuth;
