import React from "react";

import FilterContext from "./FilterCalendarContext";
import CalendarContext from "./CalendarContext";
import BookingMeetingCalendar from "./BookingMeetingCalendar";
import { BookingMeetingCalendarPropsI } from "./types";

const BookingMeetingCalendarContainer: React.FC<
  BookingMeetingCalendarPropsI
> = ({ isAgentCalendar = false }) => {
  return (
    <FilterContext>
      <CalendarContext isAgentCalendar={isAgentCalendar}>
        <BookingMeetingCalendar />
      </CalendarContext>
    </FilterContext>
  );
};

export default BookingMeetingCalendarContainer;
