import { Children, useMemo } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { TablePropsI, HeaderCellDataI, ColumnT } from "./Table.type";
import { useSort } from "./useSort";

import TableHeader from "./components/TableHeader";
import Row from "./components/Row";
import Cell from "./components/Cell";
import Column from "./components/Column";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Table<PassedMeetingData extends { [key: string]: any }>({
  children,
  data = [],
  keyId,
  classNameContainer = "",
  defaultSortFiled,
  defaultSortDirection = "asc",
  isRemoteSort = false,
  handleRemoteSort,
}: TablePropsI<PassedMeetingData>) {
  const { sortData, direction, field, handleSortData } = useSort(
    data,
    defaultSortFiled,
    defaultSortDirection,
    isRemoteSort,
    handleRemoteSort
  );
  const { t } = useTranslation();

  const headerCellData = useMemo((): HeaderCellDataI<PassedMeetingData>[] => {
    const hedertsCellData: HeaderCellDataI<PassedMeetingData>[] = [];
    Children.forEach(children as ColumnT[], (child) => {
      if (child.type === Column)
        hedertsCellData.push({
          caption: child.props.caption || child.props.dataField,
          dataField: child.props.dataField,
          isSort: !!child.props.isSort,
          sortDirection: direction,
          sortField: field,
        });
    });
    return hedertsCellData;
  }, [children, direction, field]);

  return (
    <div className={`table_container ${classNameContainer}`}>
      <table className="table">
        <TableHeader
          headerCellData={headerCellData}
          handleSort={handleSortData}
        />
        {!data.length && (
          <tfoot>
            <Row className="empty_table_row">
              <th colSpan={5} className="empty_table_cell title-small20-bold">
                {t("translation.clientMeetingPage.noPrevMeetings")}
              </th>
            </Row>
          </tfoot>
        )}
        <tbody className="table_body">
          {sortData.map((item: PassedMeetingData, index: number) => {
            const _key = keyId ? item[keyId] : index;

            return (
              <Row key={_key}>
                {Children.map(children as ColumnT[], (child) => {
                  const keyField = child.props.dataField;
                  const _content = keyField ? item[keyField] : "";
                  const RenderCellComponent = child.props.renderCell;

                  const textContent = 
                    child.props.type === "date"
                      ? format(new Date(_content),
                          child.props.format || "dd/MM/yyyy"
                        )
                      : _content;

                  const contentCell = RenderCellComponent ? (
                    <RenderCellComponent data={item} />
                  ) : (
                    textContent
                  );

                  return child.type === Column ? (
                    <Cell
                      key={child.props.dataField}
                      content={contentCell}
                      width={child.props.width}
                      dataField={child.props.dataField}
                    />
                  ) : null;
                })}
              </Row>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

Table.Column = Column;

export default Table;
