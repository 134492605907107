import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Panels, { TabsI } from "components/common/Panels";
import GoBackButton from "components/GoBackButton";
import Card from "mobile_components/Card/Card";
import { LocationStateI } from "components/AgentBookingList/AgentBookingList";

import { useMobileContext } from "context/mobileContext";
import { useAppDispatch } from "hooks/redux";
import {
  getChosenAgentsLengthSelector,
  setIsPreBookingHidden,
} from "store/reducers/booking";
import { lsApi } from "utils/localStorageApi";

import { useBookingFrom } from "../useBookingForm";

import AddressAbsentModal from "./AddressAbsentModal";
import FormPartOne from "./FormPartOne";
import FormPartTwo from "./FormPartTwo";

const tabs: TabsI[] = [{ title: "1" }, { title: "2" }];

const AuthFrom: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileContext();
  const { preBookingFormik } = useBookingFrom();
  const location = useLocation() as { state: LocationStateI };

  const isAgentsInBlueBox = Boolean(useSelector(getChosenAgentsLengthSelector));

  const [activeForm, setActiveForm] = useState<number>(0);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const handleClickActiveForm = (index: number) => setActiveForm(index);

  const handleSaveForm = () => {
    // Remove backRoute to send new request for agents list in the booking page,
    // because after agent profile and back button we don't reload the page
    if (location?.state?.backRoute) {
      location.state.backRoute = "";
    }

    preBookingFormik.handleSubmit();
  };

  const goToNextForm = (indexForm: number) => () => {
    setActiveForm(indexForm);
  };

  const isErorrsInFirstStep =
    !!preBookingFormik.errors.target || !!preBookingFormik.errors.residenceType;

  const disabledTab1 =
    !preBookingFormik.values.residenceType || !preBookingFormik.values.target;
  const disabledTab2 =
    !preBookingFormik.values.address || !preBookingFormik.values.areaNumber;

  // If user filled data on WP, we need to redirect him to the third step immediately
  const isWPDataFilled = lsApi.get<string>("isWPDataFilled");
  useEffect(() => {
    if (isWPDataFilled) {
      dispatch(setIsPreBookingHidden(true));
    }
    return () => lsApi.removeItem("isWPDataFilled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWPDataFilled]);

  if (isMobile)
    return (
      <div className="booking_forms_container mobile">
        <a
          href={process.env.REACT_APP_PUBLIC_URL_BASE}
          className="link_container"
        >
          <GoBackButton isMobile={isMobile} />
        </a>
        <form className="booking_form" autoComplete="off">
          <Card
            title={t("translation.bookingPage.firstMobileCardTitle")}
            level={0}
            stepLevel={1}
            checked={!disabledTab1}
            isActive={activeForm === 0}
            handleActiveCard={handleClickActiveForm}
          >
            <FormPartOne
              formik={preBookingFormik}
              disabledStep={isAgentsInBlueBox}
              handleClickNext={goToNextForm(1)}
            />
          </Card>
          <Card
            title={t("translation.bookingPage.secondMobileCardTitle")}
            level={1}
            stepLevel={2}
            checked={!disabledTab2}
            isActive={activeForm === 1}
            handleActiveCard={!disabledTab1 ? handleClickActiveForm : undefined}
          >
            <FormPartTwo
              formik={preBookingFormik}
              disabledStep={isAgentsInBlueBox}
              handleSaveForm={handleSaveForm}
            />
          </Card>
        </form>
        <AddressAbsentModal
          isOpen={isNotificationModalOpen}
          onClose={() => setIsNotificationModalOpen(false)}
        />
      </div>
    );

  return (
    <div className="booking_forms_container">
      <Panels
        tabs={tabs}
        onClickTab={handleClickActiveForm}
        activeTabIndex={activeForm}
        disabledTab={{
          1: disabledTab1,
          2: disabledTab2,
        }}
        classNameBodyContainer="booking_tabs_body_container"
        panelWidth={476}
        tabButtonWidth={161}
      >
        <form className="booking_form" autoComplete="off">
          {activeForm === 0 && (
            <FormPartOne
              formik={preBookingFormik}
              disabledStep={isAgentsInBlueBox}
              handleClickNext={
                isErorrsInFirstStep ? undefined : goToNextForm(1)
              }
            />
          )}
          {activeForm === 1 && (
            <FormPartTwo
              formik={preBookingFormik}
              disabledStep={isAgentsInBlueBox}
              handleSaveForm={handleSaveForm}
            />
          )}
        </form>
      </Panels>

      <AddressAbsentModal
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
      />
    </div>
  );
};

export default AuthFrom;
